import { computed, ref } from 'vue'
import { FixBrokenLink, Improvement } from '@opteo/types'

import { useImprovement, checkImprovement } from '@/composition/improvement/useImprovement'
import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import { formatEntityPillLink } from '@/lib/globalUtils'

import type { ImprovementStatistic } from '.'

export function useFixBrokenLink() {
    const { improvement, lastUpdated, title } = useImprovement<
        FixBrokenLink.Body,
        typeof Improvement.RecAction.FixBrokenLink
    >()

    const {
        body: {
            url,
            reason,
            account_location: campaigns,
            clicks_per_day: rawClicksPerDay,
            cost_per_day: rawCostPerDay,
        },
    } = checkImprovement(improvement)

    const singleCampaign = campaigns.length === 1
    const copy = {
        spread: singleCampaign ? 'in' : 'across',
        campaign: singleCampaign ? 'campaign' : 'campaigns',
    }

    const formattedCampaigns = campaigns.map(campaign =>
        formatEntityPillLink({
            entityPillData: {
                type: 'campaign',
                content: campaign.campaign_name,
            },
            deepLinkParams: campaign.campaignId
                ? {
                      campaignId: campaign.campaignId,
                  }
                : null,
        })
    )
    const isLongCampaignName = computed(
        () =>
            !!(
                formattedCampaigns[0].entityPillData.content &&
                formattedCampaigns[0].entityPillData.content.length > 30
            )
    )

    const clicksPerDay = Math.round(rawClicksPerDay).toString()
    const costPerDay = useDomainMoney({ value: rawCostPerDay }).value.displayValue.value

    const improvementStatistics: ImprovementStatistic[] = [
        {
            key: 'clicks',
            value: clicksPerDay,
            title: 'Clicks Per Day',
        },
        {
            key: 'cost',
            value: costPerDay,
            title: 'Cost Per Day',
        },
    ]

    const pushActionText = ref('Recheck Link')
    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Checking broken link..',
        'Confirming link status..',
        'Broken link fixed successfully.',
    ])

    return {
        title,
        lastUpdated,
        url,
        pushMessages,
        reason,
        copy,
        formattedCampaigns,
        isLongCampaignName,
        clicksPerDay,
        costPerDay,
        improvementStatistics,
        pushActionText,
    }
}
