import { computed, ref, watch } from 'vue'

import {
    OnPushHandler,
    useImprovement,
    checkImprovement,
} from '@/composition/improvement/useImprovement'

import {
    snippetHeaders,
    languages,
    getDomainLanguageCode,
    setDomainLanguageCode,
    SnippetHeader,
} from '@/composition/utils/useLanguage'

import { CampaignLevelExtensions, Improvement } from '@opteo/types'
import { useAccount } from '@/composition/account/useAccount'

type FormElement = HTMLDivElement & { submit: () => void; valid: boolean }

type DropDownItem = { value: string; label: string }

interface HighestClickedAd {
    headlineOne: string
    headlineTwo: string
    displayUrl: string
    descriptionOne: string
    structuredSnippetsHeader: string
    structuredSnippets: string[]
}

interface AdTitleTag {
    title: string
    classes: string
}

interface SnippetExtension {
    snippet: string
    required: boolean
}

interface SnippetExample {
    example: string
    header: string
    values: string[]
}

interface PushedData {
    feed_id: string
    is_new: boolean
    header: string
    values: string[]
}

const EXPECTED_SNIPPET_COUNT = 3
const MAXIMUM_SNIPPET_COUNT = 10
const MAXIMUM_SNIPPET_LENGTH = 25

const snippetExamples: SnippetExample[] = [
    {
        example: 'Accountant Service',
        header: 'Service Catalog',
        values: ['Accountancy', 'Taxation Advice', 'Financial Advice', 'Bookkeeping', 'Audits'],
    },
    {
        example: 'Sunglasses Online Store',
        header: 'Brands',
        values: ['Burberry', 'Maui Jim', 'Oakley', 'Prada', 'Ray-Ban'],
    },
    {
        example: 'Local Business in Boston',
        header: 'Neighborhoods',
        values: ['Back Bay', 'Beacon Hill', 'Charlestown', 'North End', 'South Boston', 'West End'],
    },
]

export function useAddCampaignLevelSnippetExtension() {
    const { accountId } = useAccount()

    const { improvement, lastUpdated, title } = useImprovement<
        CampaignLevelExtensions.Body<CampaignLevelExtensions.SnippetItem>,
        typeof Improvement.RecAction.AddCampaignLevelSnippetExtension
    >()

    const {
        body: {
            campaign_name: campaignName,
            campaign_feed_items: campaignFeedItems,
            feed_items: generalFeedItems,
            highest_click_ad: {
                headline1: headlineOne,
                headline2: headlineTwo,
                display_url: displayUrl,
                description: descriptionOne,
            },
        },
        location,
    } = checkImprovement(improvement)

    // campaign mode or account mode, ie whether the prefilled items come from campaign-level or account-level
    const campaignMode = campaignFeedItems.length > 0

    const locationEntityIds = location[0].entityIds

    const snippetExtensions = ref<SnippetExtension[]>([])
    const [feedItem] = campaignMode ? campaignFeedItems : generalFeedItems

    const selectedLanguage = ref(getDomainLanguageCode(accountId.value))

    // Sometimes, the existing asset will use a header that is no longer supported.
    // In that case, we default to "Types"
    const headerExistsInPresets = Object.values(snippetHeaders)
        .flat()
        .some(lang => Object.values(lang).includes(feedItem.fields.type))

    const defaultHeader = headerExistsInPresets ? feedItem.fields.type : 'Types'

    const selectedHeader = ref<string | undefined>(defaultHeader)

    const headersDropDownItems = computed<DropDownItem[]>(() =>
        Object.entries(snippetHeaders[selectedLanguage.value]).map(([value, label]) => {
            return { value, label }
        })
    )
    const languagesDropDownItems = Object.entries(languages).map(([value, label]): DropDownItem => {
        return { value, label }
    })

    watch(selectedLanguage, () =>
        setDomainLanguageCode({ accountId: accountId.value, languageCode: selectedLanguage.value })
    )

    const headerRef = ref() // oInput select of the form
    function resetSnippetExtensions(clear: boolean) {
        snippetExtensions.value = []

        let index = 1

        feedItem.fields.snippets.forEach(snippet => {
            const required = index <= EXPECTED_SNIPPET_COUNT
            snippetExtensions.value.push({ snippet: clear ? '' : snippet, required })
            index += 1
        })
        while (snippetExtensions.value.length < MAXIMUM_SNIPPET_COUNT) {
            const required = index <= EXPECTED_SNIPPET_COUNT
            snippetExtensions.value.push({ snippet: '', required })
            index += 1
        }

        selectedLanguage.value = getDomainLanguageCode(accountId.value)
        selectedHeader.value = clear ? undefined : defaultHeader

        // At the moment, focusing a dropdown oInput doesn't work quite right.
        // It scrolls to the element but doesn't allow interacting with it via the keyboard.
        // To be improved in components-next.
        headerRef.value?.inputRef.focus()
    }

    resetSnippetExtensions(false)

    function generateHighestClickAd(): HighestClickedAd {
        const ad = { headlineOne, headlineTwo, displayUrl, descriptionOne }

        if (!snippetExtensions.value || !snippetExtensions.value.length) {
            return ad as HighestClickedAd
        }

        const structuredSnippetsHeader =
            snippetHeaders[selectedLanguage.value][selectedHeader.value as SnippetHeader]
        const structuredSnippets = snippetExtensions.value
            .map(snippetExtension => snippetExtension.snippet)
            .filter(snippet => snippet.length)

        if (!structuredSnippetsHeader && structuredSnippets.length == 0) {
            return {
                ...ad,
                structuredSnippetsHeader: 'Header',
                structuredSnippets: ['Snippet One', 'Snippet Two', 'Snippet Three'],
            }
        }
        return { ...ad, structuredSnippetsHeader, structuredSnippets }
    }

    const highestClickedAdStatic = generateHighestClickAd()
    const highestClickedAd = computed(() => generateHighestClickAd())

    const adTitleTag: AdTitleTag = { title: 'Highest Clicked Ad', classes: 'opteo-blue' }
    const snippetForm = ref<FormElement>()

    const adjustSteps = ref(['Customise Structured Snippets'])
    const pushActionText = ref('Add Structured Snippets')
    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Adding structured snippets..',
        'Confirming changes..',
        'Structured snippets added successfully.',
    ])

    const onPush: OnPushHandler<PushedData> = () => {
        snippetForm.value?.submit()

        if (!selectedHeader.value) {
            return { valid: false }
        }

        const values = snippetExtensions.value
            .map(snippetExtension => snippetExtension.snippet)
            .filter(snippet => snippet.length)

        const invalidValues = values.filter(snippet => snippet.length > MAXIMUM_SNIPPET_LENGTH)

        if (values.length < EXPECTED_SNIPPET_COUNT || invalidValues.length > 0) {
            return { valid: false }
        }

        return {
            valid: true,
            pushedData: {
                feed_id: feedItem.feedItemId,
                is_new: !campaignMode,
                header: selectedHeader.value,
                values,
            },
        }
    }

    return {
        title,
        locationEntityIds,
        pushMessages,
        onPush,
        lastUpdated,
        campaignName,
        highestClickedAd,
        highestClickedAdStatic,
        adTitleTag,
        snippetForm,
        selectedLanguage,
        selectedHeader,
        headersDropDownItems,
        languagesDropDownItems,
        snippetExtensions,
        headerRef,
        resetSnippetExtensions,
        snippetExamples,
        MAXIMUM_SNIPPET_LENGTH,
        pushActionText,
        adjustSteps,
    }
}
