import { computed } from 'vue'
import { API_URL } from '@/lib/env'
import { Endpoint } from '../api/endpoints'
import { useUser } from './useUser'

export function useBoringAvatar() {
    const { userId: currentUserId, loading } = useUser()

    const currentUserAvatarUrl = computed(() => {
        if (loading.value) return // avoid flash of boring avatar before the uploaded profile image is ready

        return getAvatarUrl(currentUserId.value)
    })

    return {
        getAvatarUrl,
        currentUserAvatarUrl,
    }
}

export const getAvatarUrl = (userId?: number | null) =>
    userId ? `${API_URL}/${Endpoint.Avatar}?userId=${userId}` : undefined
