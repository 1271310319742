import { computed, Ref, unref } from 'vue'
import { useBrowserLocation, useClipboard } from '@vueuse/core'
import { showSnackbar } from '@opteo/components-next'

export function useChatShare(chatId: Ref<string> | string) {
    const location = useBrowserLocation()
    const link = computed(() => `${location.value.origin}/c/${unref(chatId)}`)

    const { copy } = useClipboard({ source: link })

    const openInNewTab = (url: string) => {
        const newWindow = window.open(url, '_blank')
        if (newWindow) newWindow.opener = null
    }

    const setSnackbarOptions = (url: string) => {
        return {
            message: 'Sharing link copied to clipboard',
            timeout: 6000, // How long the snackbar is shown. Default: 2s, or 9s when actionHandler is specified.
            actionText: 'Open in new tab', // Button text
            actionHandler: () => openInNewTab(url), // Callback for when the button is clicked
            // snackbarMaxWidth: 480, // CSS max-width of the snackbar
        }
    }
    const copyLinkToClipboard = () => {
        // copy to clipboard
        copy()
        // show snackbar
        showSnackbar(setSnackbarOptions(link.value))
    }

    return {
        copyLinkToClipboard,
    }
}
