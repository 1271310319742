import { Platform } from '@opteo/types'
import { ChartID, ChartLabel } from './types'

export const charts = [
    {
        id: ChartID.CLICKS,
        label: ChartLabel.CLICKS,
        platforms: [
            Platform.Platform.MicrosoftAds,
            Platform.Platform.MetaAds,
            Platform.Platform.GoogleAds,
            Platform.Platform.LinkedInAds,
        ],
    },
    {
        id: ChartID.COST,
        label: ChartLabel.COST,
        platforms: [
            Platform.Platform.MicrosoftAds,
            Platform.Platform.MetaAds,
            Platform.Platform.GoogleAds,
            Platform.Platform.LinkedInAds,
        ],
    },
    {
        id: ChartID.CONVERSIONS,
        label: ChartLabel.CONVERSIONS,
        platforms: [
            Platform.Platform.MicrosoftAds,
            Platform.Platform.MetaAds,
            Platform.Platform.GoogleAds,
            Platform.Platform.LinkedInAds,
        ],
    },
    {
        id: ChartID.CONV_VAL,
        label: ChartLabel.CONV_VAL,
        platforms: [
            Platform.Platform.MicrosoftAds,
            Platform.Platform.MetaAds,
            Platform.Platform.GoogleAds,
            Platform.Platform.LinkedInAds,
        ],
    },
    {
        id: ChartID.CPA,
        label: ChartLabel.CPA,
        platforms: [
            Platform.Platform.MicrosoftAds,
            Platform.Platform.MetaAds,
            Platform.Platform.GoogleAds,
            Platform.Platform.LinkedInAds,
        ],
    },
    {
        id: ChartID.ROAS,
        label: ChartLabel.ROAS,
        platforms: [
            Platform.Platform.MicrosoftAds,
            Platform.Platform.MetaAds,
            Platform.Platform.GoogleAds,
            Platform.Platform.LinkedInAds,
        ],
    },
    {
        id: ChartID.CTR,
        label: ChartLabel.CTR,
        platforms: [
            Platform.Platform.MicrosoftAds,
            Platform.Platform.MetaAds,
            Platform.Platform.GoogleAds,
            Platform.Platform.LinkedInAds,
        ],
    },
    {
        id: ChartID.CPC,
        label: ChartLabel.CPC,
        platforms: [
            Platform.Platform.MicrosoftAds,
            Platform.Platform.MetaAds,
            Platform.Platform.GoogleAds,
            Platform.Platform.LinkedInAds,
        ],
    },
    {
        id: ChartID.CR,
        label: ChartLabel.CR,
        platforms: [
            Platform.Platform.MicrosoftAds,
            Platform.Platform.MetaAds,
            Platform.Platform.GoogleAds,
            Platform.Platform.LinkedInAds,
        ],
    },
    {
        id: ChartID.IMPRESSIONS,
        label: ChartLabel.IMPRESSIONS,
        platforms: [
            Platform.Platform.MicrosoftAds,
            Platform.Platform.MetaAds,
            Platform.Platform.GoogleAds,
            Platform.Platform.LinkedInAds,
        ],
    },
    {
        id: ChartID.VAL_PER_CONV,
        label: ChartLabel.VAL_PER_CONV,
        platforms: [
            Platform.Platform.MicrosoftAds,
            Platform.Platform.MetaAds,
            Platform.Platform.GoogleAds,
            Platform.Platform.LinkedInAds,
        ],
    },
    {
        id: ChartID.SEARCH_IMP_SHARE,
        label: ChartLabel.SEARCH_IMP_SHARE,
        platforms: [Platform.Platform.MicrosoftAds, Platform.Platform.GoogleAds],
    },
    {
        id: ChartID.QS,
        label: ChartLabel.QS,
        platforms: [Platform.Platform.MicrosoftAds, Platform.Platform.GoogleAds],
    },
    {
        id: ChartID.QS_COMPONENTS,
        label: ChartLabel.QS_COMPONENTS,
        platforms: [Platform.Platform.MicrosoftAds, Platform.Platform.GoogleAds],
    },
    { id: ChartID.REACTIONS, label: ChartLabel.REACTIONS, platforms: [Platform.Platform.MetaAds] },
    { id: ChartID.COMMENTS, label: ChartLabel.COMMENTS, platforms: [Platform.Platform.MetaAds] },
    { id: ChartID.SHARES, label: ChartLabel.SHARES, platforms: [Platform.Platform.MetaAds] },
    {
        id: ChartID.ENGAGEMENT,
        label: ChartLabel.ENGAGEMENT,
        platforms: [Platform.Platform.MetaAds],
    },
]
