<template>
    <MobileSplashContainer wide>
        Impact Statistics is available on screens <b>1200px or wider</b>, increase your window size
        or use another device. If you'd like to see us build this feature for touch devices, please
        consider submitting a feature request.
    </MobileSplashContainer>
    <Transition name="fade">
        <div v-if="dataLoading" class="loader">
            <div class="loader-container">
                <ImpactStatsLoaderAnimation />
                <div class="progress-bar-container">
                    <!-- There are 5 sets of data to load -->
                    <ProgressBar :progress="loadingProgress * (1 / 5)" />
                </div>
            </div>
        </div>
        <!-- Added the v-else-if condition here to avoid error messages, but given how the loading states are computed it should be safe -->
        <div v-else-if="costSavings && timeSavings && criticalAlerts && userStatistics">
            <PageHeader :no-margin="true" pageTitle="Impact Statistics">
                <template #right-content>
                    <Tabs
                        :links="dateRangeTabs"
                        :router="false"
                        :active="currentDateRange"
                        @clicked="setDateRange"
                    />
                </template>
            </PageHeader>
            <PageContent>
                <!-- Overview Section -->
                <div class="overview-wrapper">
                    <OverviewSection
                        v-if="timeSavings && criticalAlerts"
                        :costSavings="costSavings"
                        :timeSavings="timeSavings"
                        :criticalAlerts="criticalAlerts"
                        :period="currentDateRange"
                        :costSavingsValidating="costSavingsValidating"
                        :timeSavingsValidating="timeSavingsValidating"
                        :criticalAlertsValidating="criticalAlertsValidating"
                    />
                </div>
                <Spacer height="2.5rem" />
                <!-- Cost Savings -->
                <div class="section-wrapper">
                    <header>
                        <div class="left">
                            <Text as="h4" weight="600">Cost Savings</Text>
                        </div>
                        <oButton color="white" @clicked="downloadCostsCSV">Download CSV</oButton>
                    </header>
                    <Spacer height="2rem" />
                    <CostSectionTable
                        v-if="costsTableData.length > 0"
                        class="impact-statistics-section-table"
                        :data="costsTableData"
                        :validating="costSavingsValidating"
                    />
                    <div v-else class="empty-state-container">
                        <Text as="p" size="f-8" align="center">
                            Keep pushing improvements with Opteo to see your costs savings. Note
                            that not all improvements will be considered in this calculation.
                        </Text>
                    </div>
                </div>
                <Spacer height="2.5rem" />
                <!-- Time Savings -->
                <div class="section-wrapper">
                    <header>
                        <div class="left">
                            <Text as="h4" weight="600">Time Savings</Text>
                        </div>
                        <oButton color="white" @clicked="downloadTimeSavingsCSV"
                            >Download CSV</oButton
                        >
                    </header>
                    <Spacer height="2rem" />
                    <TimeSectionTable
                        v-if="timeTableData.length > 0"
                        class="impact-statistics-section-table"
                        :data="timeTableData"
                        :validating="timeSavingsValidating"
                    />
                    <div v-else class="empty-state-container">
                        <Text as="p" size="f-8" align="center">
                            As you use Opteo to create reports, update RSAs, start experiments, or
                            push improvements, your time savings will appear here.
                        </Text>
                    </div>
                </div>
                <Spacer height="2.5rem" />
                <!-- Critical Alerts -->
                <div class="section-wrapper">
                    <header>
                        <div class="left">
                            <Text as="h4" weight="600">Critical Alerts</Text>
                        </div>
                        <oButton color="white" @clicked="downloadAlertsCSV">Download CSV</oButton>
                    </header>
                    <Spacer height="2rem" />
                    <AlertsSectionTable
                        v-if="alertsTableData.length > 0"
                        class="impact-statistics-section-table"
                        :data="alertsTableData"
                        :validating="criticalAlertsValidating"
                    />
                    <div v-else class="empty-state-container">
                        <Text as="p" size="f-8" align="center">
                            Opteo monitors your accounts to detect unexpected changes, broken links,
                            and other anomalies. Your critical alerts will appear here.
                        </Text>
                    </div>
                </div>
                <Spacer height="2.5rem" />
                <!-- Team Activity -->
                <div class="section-wrapper">
                    <header>
                        <div class="left">
                            <Text as="h4" weight="600">Team Heatmap</Text>
                        </div>
                        <Popout
                            v-if="teamSize && teamSize > 1"
                            v-model="teamMemberPopoutOpen"
                            :trapFocus="true"
                            :externalToggleButton="true"
                            :offset="[-4, 12]"
                        >
                            <oButton
                                color="white"
                                class="team-member-switcher-button"
                                @clicked="teamMemberPopoutOpen = !teamMemberPopoutOpen"
                            >
                                <div v-if="!selectedUser" class="team-activity-popout-row">
                                    <div class="team-avatars-container transform">
                                        <div
                                            v-for="(
                                                user, index
                                            ) in calendarHeatMap?.userActivities.slice(0, 3)"
                                            :style="{ zIndex: index + 1 }"
                                            :class="[
                                                'profile-image-container',
                                                'image-' + (index + 1),
                                            ]"
                                        >
                                            <ProfileImage
                                                :width="20"
                                                :imageUrl="user.image"
                                                :avatarUrl="getAvatarUrl(user.userId)"
                                            />
                                        </div>
                                    </div>
                                    Everyone
                                </div>
                                <div v-else class="team-activity-popout-row">
                                    <div class="team-avatars-container transform">
                                        <div class="profile-image-container">
                                            <ProfileImage
                                                :width="20"
                                                :imageUrl="selectedUser.image"
                                                :avatarUrl="getAvatarUrl(selectedUser.userId)"
                                            />
                                        </div>
                                    </div>
                                    {{ selectedUser.name }}
                                </div>
                                <template #icon>
                                    <SelectArrowIcon />
                                </template>
                            </oButton>
                            <template v-slot:content>
                                <perfect-scrollbar class="team-activity-popout">
                                    <div
                                        key="team"
                                        @click="selectUser(undefined)"
                                        class="select-list-option"
                                        tabindex="0"
                                    >
                                        <div class="team-avatars-container">
                                            <div
                                                v-for="(
                                                    user, index
                                                ) in calendarHeatMap?.userActivities.slice(0, 3)"
                                                :style="{ zIndex: index + 1 }"
                                                :class="[
                                                    'profile-image-container',
                                                    'image-' + (index + 1),
                                                ]"
                                            >
                                                <ProfileImage
                                                    :width="20"
                                                    :imageUrl="user.image"
                                                    :avatarUrl="getAvatarUrl(user.userId)"
                                                />
                                            </div>
                                        </div>
                                        Everyone
                                    </div>
                                    <div
                                        v-if="calendarHeatMap"
                                        v-for="user of calendarHeatMap.userActivities"
                                        :key="user.userId"
                                        @click="selectUser(user)"
                                        class="select-list-option"
                                        tabindex="0"
                                    >
                                        <div class="profile-image-container">
                                            <ProfileImage
                                                :width="20"
                                                :imageUrl="user.image"
                                                :avatarUrl="getAvatarUrl(user.userId)"
                                            />
                                        </div>
                                        {{ user.name }}
                                    </div>
                                </perfect-scrollbar>
                            </template>
                        </Popout>
                    </header>
                    <Spacer height="2rem" />
                    <div class="heatmap-wrapper">
                        <HeatmapChart
                            :legend="false"
                            :items="heatmapContent"
                            disclaimerText="Opteo counts Improvements, Reports, RSAs and Created Experiments as activity."
                        />
                    </div>
                </div>
                <Spacer height="2.5rem" />
                <!-- Team Stats -->
                <div class="section-wrapper">
                    <header>
                        <div class="left">
                            <Text as="h4" weight="600">Team Statistics</Text>
                        </div>
                        <oButton color="white" @clicked="downloadTeamStatsCSV"
                            >Download CSV</oButton
                        >
                    </header>
                    <Spacer height="2rem" />
                    <oTable
                        :headers="teamStatsTableHeaders"
                        :items="teamStatsTableItems"
                        class="impact-statistics-section-table"
                        :per-page="12"
                    >
                        <template v-slot:header.teamMember>
                            <div class="team-member-name-container">Team Member</div>
                        </template>
                        <template v-slot:column.teamMember="cell">
                            <div class="team-member-name-container">
                                <ProfileImage
                                    :width="32"
                                    :isAdmin="cell.value.role === 'admin'"
                                    :image-url="cell.value.imageUrl"
                                    :avatar-url="getAvatarUrl(cell.value.userId)"
                                />
                                <Text as="span" size="f-8" weight="500">{{ cell.value.name }}</Text>
                            </div>
                        </template>
                    </oTable>
                </div>
            </PageContent>
        </div>
    </Transition>
</template>

<script lang="ts">
// Global
import { ref, computed } from 'vue'
import { IS } from '@opteo/types'
import { useBoringAvatar } from '@/composition/user/useBoringAvatar'
import { useAPI, Endpoint } from '@/composition/api/useAPI'
import { delay } from '@/lib/globalUtils'

// Local components
import PageHeader from '@/layouts/PageHeader.vue'
import PageContent from '@/layouts/PageContent.vue'
import MobileSplashContainer from '@/components/util/MobileSplashContainer.vue'
import OverviewSection from '@/components/impactStatistics/OverviewSection.vue'
import CostSectionTable from '@/components/impactStatistics/CostSectionTable.vue'
import TimeSectionTable from '@/components/impactStatistics/TimeSectionTable.vue'
import AlertsSectionTable from '@/components/impactStatistics/AlertsSectionTable.vue'
import ImpactStatsAccountNameCell from '@/components/impactStatistics/ImpactStatsAccountNameCell.vue'
import ImpactStatsTimeSavingsTableCell from '@/components/impactStatistics/ImpactStatsTimeSavingsTableCell.vue'
import ProgressBar from '@/components/global/ProgressBar.vue'
import ImpactStatsLoaderAnimation from '@/components/impactStatistics/ImpactStatsLoaderAnimation.vue'

// Composition
import useImpactStatistics from '@/composition/impactStatistics/useImpactStatistics'
import useImpactStatsTimeSavings from '@/composition/impactStatistics/useImpactStatsTimeSavings'

// date-fns
import subtractDays from 'date-fns/subDays'

// components-next
import {
    Spinner,
    Spacer,
    Tabs,
    oButton,
    Text,
    HeatmapChart,
    oTable,
    ProfileImage,
    Popout,
    SelectableList,
    SelectArrowIcon,
    ListItem,
} from '@opteo/components-next'

export default {
    name: 'ImpactStatistics',
    components: {
        // Local
        PageHeader,
        PageContent,
        MobileSplashContainer,
        OverviewSection,
        CostSectionTable,
        TimeSectionTable,
        AlertsSectionTable,
        ImpactStatsAccountNameCell,
        ImpactStatsTimeSavingsTableCell,
        ProgressBar,
        ImpactStatsLoaderAnimation,
        // components-next
        Spinner,
        Spacer,
        Tabs,
        oButton,
        Text,
        HeatmapChart,
        oTable,
        ProfileImage,
        Popout,
        SelectableList,
        SelectArrowIcon,
        ListItem,
    },
    setup() {
        const { formatFromMinutes } = useImpactStatsTimeSavings()

        const { getAvatarUrl } = useBoringAvatar()

        // data
        const {
            // cost savings
            costSavings,
            costSavingsValidating,
            // time savings
            timeSavings,
            timeSavingsValidating,
            // critical alerts
            criticalAlerts,
            criticalAlertsValidating,
            // user statistics
            userStatistics,
            userStatisticsValidating,
            // calendar
            calendarHeatMap,
            calendarHeatMapValidating,
            // time
            setNewDateRangeStart,
            // util
            downloadCSV,
            teamAccounts,
            teamSize,
            dataLoading,
            dataValidating,
            loadingProgress,
            team,
        } = useImpactStatistics()

        const downloadTimeSavingsCSV = () => {
            downloadCSV({ file: timeSavings.value?.timeSavingsCSV, name: 'time_savings' })
        }
        const downloadAlertsCSV = () => {
            downloadCSV({
                file: criticalAlerts.value?.criticalAlertCountsCSV,
                name: 'critical_alerts',
            })
        }
        const downloadCostsCSV = () => {
            downloadCSV({
                file: costSavings.value?.costSavingsCSV,
                name: 'costs_savings',
            })
        }
        const downloadTeamStatsCSV = () => {
            downloadCSV({ file: userStatistics.value?.userStatisticsCSV, name: 'team_statistics' })
        }

        const dateRangeTabs = computed(() => {
            return [
                { key: 'alltime', name: 'All Time', to: '', disabled: dataValidating.value },
                {
                    key: 'last365days',
                    name: 'Last 365 Days',
                    to: '',
                    disabled: dataValidating.value,
                },
                { key: 'last90days', name: 'Last 90 Days', to: '', disabled: dataValidating.value },
            ]
        })

        const currentDateRange = ref('alltime')
        const setDateRange = (range: string) => {
            if (dataValidating.value) return
            // For tabs state
            currentDateRange.value = range
            // Changing calculations
            if (range === 'alltime') {
                // Arbitrary date anterior to any Opteo activity
                setNewDateRangeStart(new Date(2000, 0))
            } else if (range === 'last365days') {
                setNewDateRangeStart(subtractDays(new Date(), 365))
            } else if (range === 'last90days') {
                setNewDateRangeStart(subtractDays(new Date(), 90))
            } else
                throw new Error(
                    `Date range = ${range}, should be either 'alltime', 'last365days', or 'last90days'.`
                )
        }

        const selectUser = async (user: User | undefined) => {
            teamMemberPopoutOpen.value = !teamMemberPopoutOpen.value
            await delay(200)
            selectedUser.value = user
        }

        const costsTableData = computed(() => costSavings.value?.costTableData ?? [])
        const alertsTableData = computed(() => criticalAlerts.value?.alertsTableData ?? [])

        const timeTableData = computed(() => timeSavings.value?.timeTableData ?? [])

        // heatmap
        type User = {
            calendarHeatMap: object
            image: string
            name: string
            role: string
            userId: number | null | undefined
            username: string
        }
        const teamMemberPopoutOpen = ref(false)
        const selectedUser = ref<undefined | User>(undefined)
        const heatmapContent = computed<IS.HeatmapChartItem[]>(() => {
            if (!selectedUser.value)
                return calendarHeatMap.value?.teamActivities
                    .calendarHeatMap as IS.HeatmapChartItem[]
            else return selectedUser.value.calendarHeatMap as IS.HeatmapChartItem[]
        })

        // team stats
        const teamStatsTableHeaders = [
            { key: 'teamMember', text: 'Team Member', noPadding: true },
            //{ key: 'email', text: 'Email' },
            { key: 'sessions', text: 'Sessions', sortable: true },
            { key: 'improvements', text: 'Improvements', sortable: true },
            { key: 'rsas', text: 'RSAs', sortable: true },
            { key: 'reports', text: 'Reports', sortable: true },
        ]

        const teamStatsTableItems = computed(() => {
            const tableRows = []

            const userStatsArray = userStatistics.value?.userStatistics
            if (userStatsArray === undefined) return

            for (let i = 0; i < userStatsArray.length; i++) {
                const userData = team.value?.find(item => item.user_id === userStatsArray[i].userId)

                tableRows.push({
                    key: userData?.user_id,
                    teamMember: {
                        name: userStatsArray[i].name,
                        role: userStatsArray[i].role,
                        userId: userStatsArray[i].userId,
                        imageUrl: userData?.avatar_filename ?? '',
                    },
                    email: userStatsArray[i].username,
                    sessions: userStatsArray[i].sessionCount,
                    improvements: userStatsArray[i].pushedImprovementCount,
                    rsas: userStatsArray[i].createdRsaCount + userStatsArray[i].updatedRsaCount,
                    reports: userStatsArray[i].createdReportCount,
                })
            }

            return tableRows
        })

        return {
            dateRangeTabs,
            setDateRange,
            selectUser,
            currentDateRange,
            costsTableData,
            timeTableData,
            alertsTableData,
            teamAccounts,
            teamSize,
            downloadCostsCSV,
            downloadTimeSavingsCSV,
            downloadAlertsCSV,
            dataLoading,
            loadingProgress,
            getAvatarUrl,
            // cost savings
            costSavings,
            costSavingsValidating,
            // time savings
            timeSavings,
            timeSavingsValidating,
            // critical alerts
            criticalAlerts,
            criticalAlertsValidating,
            // user statistics
            userStatistics,
            userStatisticsValidating,
            teamStatsTableHeaders,
            teamStatsTableItems,
            downloadTeamStatsCSV,
            // calendar heatmap
            calendarHeatMap,
            calendarHeatMapValidating,
            heatmapContent,
            teamMemberPopoutOpen,
            selectedUser,
            // date
            setNewDateRangeStart,
        }
    },
}
</script>

<!-- Scoped -->
<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.overview-wrapper {
    @include container;
    @include br-32;
    @include pa-40;
}
.section-wrapper {
    @include container;
    @include br-32;
    @include pt-28;
    @include ph-40;
    @include pb-40;
}
.section-wrapper header {
    @include flex;
    @include items-center;
    @include justify-between;
}
.section-wrapper header .left {
    @include flex;
    @include items-center;
    height: 2.75rem;
}

.team-activity-popout {
    max-height: 19.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.125rem;
    padding: 0.5rem;

    position: relative;
    overflow: hidden !important;
    overflow-anchor: none;
    -ms-overflow-style: none;
    touch-action: auto;
    -ms-touch-action: auto;
}
// Select List Option
.select-list-option {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border: none;
    text-decoration: none;
    font-family: inherit;
    cursor: pointer;
    transition:
        transform 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        background 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        box-shadow 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        opacity 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        color 0.25s cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 0.875rem;
    position: relative;
    @include f-9;
    letter-spacing: -0.0125rem;
    @include fw-500;
    user-select: none;
    -webkit-user-select: none;
    @include opteo-foreground;
    padding: 0.5rem 1rem;
}
.select-list-option:hover {
    box-shadow: $opteo-shadow;
}
.select-list-option:active {
    box-shadow: $opteo-shadow-focus;
    outline: none;
    transform: translateY(1px);
}
.select-list-option:focus {
    box-shadow: $opteo-shadow-focus;
    outline: none;
}

.team-activity-popout-row {
    @include flex;
    gap: 0.5rem;
    align-items: center;
}

.heatmap-wrapper {
    @include container;
    @include br-24;
    @include pt-24;
    @include ph-20;
    @include pb-16;
}

.team-avatars-container {
    height: 1.5rem;
    @include flex;
    @include items-center;
}
.team-avatars-container.transform {
    transform: translateY(1px);
}
.profile-image-container {
    display: inline-block;
    @include relative;
    border-radius: 999px;
    border: 2px solid white;
}
.profile-image-container.image-2,
.profile-image-container.image-3 {
    margin-left: -0.5rem;
}

.extra-team-members {
    @include container;
    position: relative;
    margin-right: 0.375rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.team-member-name-container {
    @include flex;
    @include items-center;
    @include ml-24;
    gap: 1rem;
}

.empty-state-container {
    @include w-100;
    @include flex-center;
    @include pv-40;
    p {
        max-width: 31.25rem;
        @include container;
        @include br-16;
        @include pv-28;
        @include ph-32;
    }
}

// Loader
.loader {
    @include block;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loader .o-input {
    @include block;
}

.loader-container {
    max-width: 100%;
    width: 100%;
    height: 100%;
}

.loader .progress-bar-container {
    max-width: 25rem;
    margin: 2rem auto 0 auto;
    @include pb-16;
}

.loader-text {
    text-align: center;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.48s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>

<!-- Unscoped -->
<style lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';
// Unscoped to allow adjustments to oTable children
.impact-statistics-section-table td:first-child {
    width: 100%;
}
.impact-statistics-section-table .table-search-container {
    @include flex;
    @include items-center;
}
.impact-statistics-section-table .table-search-label {
    text-align: left;
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: -0.0063rem;
    margin: 0 0 0 24px;
}
// To center image and text inside button
.team-member-switcher-button {
    padding: 0 1.25rem !important;
}
.team-member-switcher-button span {
    @include flex;
}
</style>
