import { inject, provide, watchEffect } from 'vue'
import { Endpoint, useAPI } from '@/composition/api/useAPI'
import { OcidKey } from '@/symbols'

import type { ComputedRef } from 'vue'
import type { Account } from '@opteo/types'

export type Ocid = ReturnType<typeof provideDeepLinking>

export function provideDeepLinking(accountId: ComputedRef<Account.ID | undefined>) {
    // TODO(deepLinking): do something to make it mandatory for alerts/wherever account id is not available from local storage
    const {
        data: ocid,
        loading: isOcidLoading,
        mutate,
    } = useAPI<number | null>(Endpoint.GetOcid, {
        body: () => {
            return accountId.value && { account_id: accountId.value }
        },
        uniqueId: () => accountId?.value,
    })

    const mutateOcid = (inputOcid: number) => {
        console.log('mutateOcid...', inputOcid)
        mutate(() => inputOcid)
    }

    provide(OcidKey, { ocid, isOcidLoading, mutateOcid })

    return {
        ocid,
        isOcidLoading,
        mutateOcid,
    }
}

export function useDeepLinking() {
    const ocid = inject(OcidKey)

    if (!ocid) {
        throw new Error(`ocid not provided to useDeepLinking`)
    }

    return ocid
}
