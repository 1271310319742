<template>
    <SettingsSection
        id="invoices"
        title="Invoices"
        buttonLabel="Invoice Settings"
        :buttonAction="() => (showInvoicesSettingsModal = true)"
        :noInnerContentShadow="true"
        :maxWidth="600"
    >
        <!-- Empty State (Zero Invoices) -->
        <div v-if="invoices && invoices.length < 1" class="invoices-empty-state-container">
            <img src="@/assets/img/invoices-empty-state.png" style="height: 210px" />
            <Spacer height="2rem" />
            <div class="center" style="max-width: 360px">
                <Text as="h5" size="f-5" weight="600" align="center">
                    Your invoices will appear here
                </Text>
                <Spacer height="0.875rem" />
                <Text as="p" size="f-8" align="center">
                    When your first invoice is generated, you’ll receive a copy by email and a
                    record will be stored here. To update your information, use the button at the
                    top right of the page.
                </Text>
            </div>
        </div>
        <!-- Invoices -->
        <oTable
            v-if="invoices && invoices.length > 0"
            :headers="invoiceTableHeaders"
            :items="invoiceTableRows"
            responsiveMode="columns"
        >
            <template v-slot:[`column.total`]="total">
                <Money
                    :currency="total.row.download.currency.toUpperCase() ?? 'USD'"
                    :value="total.value"
                />
            </template>
            <template v-slot:[`column.download`]="download">
                <a
                    :id="`invoice-${download.value.id}`"
                    :href="invoiceDownloadUrl(download.value)"
                    target="_blank"
                >
                    <oButton color="white" size="small">Download PDF</oButton>
                </a>
            </template>
        </oTable>
        <!-- Loading -->
        <div v-if="!invoices" class="invoices-loading">
            <Spinner />
        </div>
    </SettingsSection>

    <!-- Modal -->
    <Modal
        title="Invoice Settings"
        v-model="showInvoicesSettingsModal"
        @closed="resetUnsavedValues()"
        width="960px"
    >
        <template v-slot:content>
            <InvoiceSettings ref="InvoicesSettingsRef" :vatData="vatData" />
        </template>
        <template v-slot:buttons>
            <oButton size="medium" color="white" @clicked="showInvoicesSettingsModal = false">
                Cancel
            </oButton>
            <Spacer width="0.75rem" />
            <oButton
                @clicked="updateVatForm"
                :loading="updatingInvoiceSettings"
                :success="updatedInvoiceSettings"
                color="blue"
                ref="updateButton"
            >
                Update Invoice Settings
            </oButton>
        </template>
    </Modal>
</template>
<script lang="ts">
import {
    oButton,
    oTable,
    oInput,
    oForm,
    Modal,
    Money,
    Spacer,
    Text,
    Spinner,
} from '@opteo/components-next'
import { API_URL } from '@/lib/env'
import SettingsSection from '@/components/global/SettingsSection.vue'
import { useInvoices, StripeCustomerCharges } from '@/composition/billing/invoices/useInvoices'
import InvoicesTable from '@/components/billing/invoices/InvoicesTable.vue'
import Skeleton from '@/components/util/Skeleton.vue'

import InvoiceSettings from '@/components/billing/invoices/InvoiceSettings.vue'

export default {
    name: 'Invoices',
    setup() {
        const invoiceTableHeaders = [
            { key: 'invoice', text: 'Invoice Date' },
            { key: 'total', text: 'Total' },
            { key: 'type', text: 'Type' },
            { key: 'download', text: 'Download' },
        ]

        function invoiceDownloadUrl(invoice: StripeCustomerCharges) {
            if (invoice.not_supported) {
                return invoice.stripe_invoice_url
            }

            return `${API_URL}/render/invoice/?charge_id=${invoice.id}&is_refund=${invoice.refunded}&issue_date=${invoice.issue_date}`
        }

        return {
            invoiceTableHeaders,
            ...useInvoices(),
            invoiceDownloadUrl,
        }
    },
    components: {
        SettingsSection,
        Skeleton,
        oTable,
        oButton,
        oInput,
        oForm,
        Modal,
        Money,
        Text,
        Spacer,
        Spinner,
        InvoicesTable,
        InvoiceSettings,
    },
}
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.invoices-empty-state-container {
    @include flex;
    @include items-center;
    @include justify-center;
    @include tc;
    flex-direction: column;
    height: 30rem;
}
.invoices-loading {
    @include flex-center;
    height: 20rem;
    @include container;
}

@media screen and (max-width: $mq-767-max) {
    .invoices-empty-state-container {
        margin-bottom: 6rem;
    }
}
</style>
