import { computed, ref } from 'vue'
import {
    OnPushHandler,
    useImprovement,
    checkImprovement,
} from '@/composition/improvement/useImprovement'
import { AddSitelinkExtension, Entity } from '@opteo/types'
import { getEntityPillListContents } from '@/lib/globalUtils'
import { getEntityPillLinkList } from './utils'

type FormElement = HTMLDivElement & { submit: () => void; valid: boolean }

// TODO: use shared type, prevent duplication
// consider importing SiteLink from TextAd
interface AdSitelink {
    title: string
    line1?: string
    line2?: string
    url: string
}
interface HighestClickedAd {
    headlineOne: string
    headlineTwo: string
    displayUrl: string
    descriptionOne: string
    sitelinks: AdSitelink[]
}

interface AdTitleTag {
    title: string
    classes: string
}

interface SitelinkExtension {
    anchor: string
    line1: string
    line2: string
    url: string
    mutate: boolean
    feedItemId: string
    feedId: string
}

const EXPECTED_SITELINK_COUNT = 4
const MAXIMUM_ANCHOR_LENGTH = 25
const MAXIMUM_DESCRIPTION_LENGTH = 35

export function useAddSitelinkExtension() {
    const { improvement, lastUpdated, title } = useImprovement<AddSitelinkExtension.Body>()

    const {
        body: {
            grey_adgroups_campaign_name_uniq: campaignNames,
            incompleteCampaigns,
            highest_click_ad_data: {
                headline1: headlineOne,
                headline2: headlineTwo,
                display_url: displayUrl,
                description: descriptionOne,
            },
            sitelinks,
        },
    } = checkImprovement(improvement)

    const sitelinkExtensions = ref<SitelinkExtension[]>([])
    const anchorRefs = ref() // This will be an array of the sitelink anchor oInputs. Used for focus().

    const hasExistingSitelinks = sitelinks.some(s => s.mutate)
    const missingSitelinkCount = EXPECTED_SITELINK_COUNT - sitelinks.filter(s => s.mutate).length

    const entityPillList = getEntityPillListContents(campaignNames, Entity.EntityLocation.Campaign)
    const entityPillLinkList = getEntityPillLinkList(incompleteCampaigns)

    const sitelinkExamplePreviews = {
        accountant: [
            {
                title: 'Accountancy',
                description:
                    'Effortlessly manage your finances in real-time on our smart and secure platform.',
            },
            {
                title: 'Taxation Advice',
                description:
                    'Get hassle-free, reliable tax advice from accredited accountants and confidentials.',
            },
            {
                title: 'Financial Advice',
                description:
                    'Personal financial advice for sole traders, PAYE high-earners, freelancers and more.',
            },
            {
                title: 'Bookkeeping',
                description:
                    'Always know where you stand financially, submit your taxes and manage payroll.',
            },
        ],
        tech: [
            {
                title: '5G Enabled Phones',
                description:
                    'View and compare our fantastic range of the latest ultra-fast 5G enabled smart phones.',
            },
            {
                title: 'Laptops & Computers',
                description: `Whatever your lifestyle, we've got lots of computing tech for you to choose from.`,
            },
            {
                title: 'TV & Audio',
                description:
                    'Enjoy sound & vision to the fullest with our wide selection of TV & audio products.',
            },
            {
                title: 'Cameras & Recording',
                description:
                    'Capture your best photographic & film life yet with our cameras and recording equipment.',
            },
        ],
    }

    function resetSitelinkExtensions(clear: boolean) {
        sitelinkExtensions.value = sitelinks.map(sitelink => {
            return {
                anchor: clear ? '' : sitelink.anchor,
                line1: clear ? '' : sitelink.line1 || '',
                line2: clear ? '' : sitelink.line2 || '',
                url: clear ? '' : sitelink.url,
                mutate: sitelink.mutate,
                feedItemId: sitelink.feed_item_id,
                feedId: sitelink.feed_id,
            }
        })

        // focus first sitelink's anchor field
        const firstSitelink = (anchorRefs.value ?? [])[0]
        if (firstSitelink) {
            firstSitelink.inputRef.focus()
        }
    }

    resetSitelinkExtensions(false)

    const generateHighestClickAd = (showPlaceholders: boolean): HighestClickedAd => {
        const ad = { headlineOne, headlineTwo, displayUrl, descriptionOne }

        if (!sitelinkExtensions.value || !sitelinkExtensions.value.length) {
            return { ...ad, sitelinks: [] }
        }

        const sitelinks = sitelinkExtensions.value
            .filter(({ anchor }) => (showPlaceholders ? true : anchor.length))
            .map(({ anchor, line1, line2, url }, index) => {
                const title = anchor.length ? anchor : `Sitelink Anchor`

                let lineOne = line1
                let lineTwo = line2

                if (showPlaceholders) {
                    lineOne = line1.length ? line1 : 'Sitelink Line One'
                    lineTwo = line2.length ? line2 : 'Sitelink Line Two'
                }

                return { title: title, line1: lineOne, line2: lineTwo, url }
            })

        return { ...ad, sitelinks }
    }

    const highestClickedAdStatic = generateHighestClickAd(false)
    const highestClickedAd = computed(() => generateHighestClickAd(true))

    const adTitleTag: AdTitleTag = { title: 'Highest Clicked Ad', classes: 'opteo-blue' }
    const sitelinkForm = ref<FormElement>()

    const adjustSteps = ref(['Input Sitelink Extensions'])
    const pushActionText = ref('Add Sitelink Extensions')
    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Adding sitelink extensions..',
        'Confirming changes..',
        'Sitelink extensions added successfully.',
    ])

    const onPush: OnPushHandler<AddSitelinkExtension.Sitelink[]> = () => {
        const valid = !!sitelinkForm.value?.submit()

        if (!valid) {
            return { valid }
        }

        return {
            valid,
            pushedData: sitelinkExtensions.value.map(sitelinkExtension => {
                return {
                    anchor: sitelinkExtension.anchor,
                    line1: sitelinkExtension.line1,
                    line2: sitelinkExtension.line2,
                    url: sitelinkExtension.url,
                    mutate: sitelinkExtension.mutate,
                    feed_id: sitelinkExtension.feedId,
                    feed_item_id: sitelinkExtension.feedItemId,
                }
            }),
        }
    }

    return {
        title,
        pushMessages,
        onPush,
        lastUpdated,
        highestClickedAd,
        highestClickedAdStatic,
        adTitleTag,
        sitelinkForm,
        sitelinkExtensions,
        resetSitelinkExtensions,
        sitelinkExamplePreviews,
        entityPillList,
        entityPillLinkList,
        hasExistingSitelinks,
        missingSitelinkCount,
        MAXIMUM_ANCHOR_LENGTH,
        MAXIMUM_DESCRIPTION_LENGTH,
        anchorRefs,
        pushActionText,
        adjustSteps,
    }
}
