<template>
    <oButton
        :iconBefore="true"
        key="copySharingLinkButton"
        color="white"
        class="sharedchat-footer-button"
        @clicked="copyLinkToClipboard"
        :disabled="disabled"
    >
        <span v-if="aboveMobile">Copy Sharing Link</span>
        <span v-else>Copy URL</span>
    </oButton>
</template>
<script setup lang="ts">
// @opteo/components-next
import { oButton } from '@opteo/components-next'
// Local components
import LinkSvg from '@/components/chat/LinkSvg.vue'
import { useChatShare } from '@/composition/chat/useChatShare'
import useMediaQuery from '@/composition/global/useMediaQuery'

const props = defineProps({
    chatId: {
        type: String,
        required: true,
    },
    disabled: {
        type: Boolean,
        required: false,
    },
})

const { copyLinkToClipboard } = useChatShare(props.chatId)
const { aboveMobile } = useMediaQuery()
</script>
<style scoped lang="scss"></style>
