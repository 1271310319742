<template>
    <ImprovementView
        :push-messages="pushMessages"
        :push-action-text="pushActionText"
        :steps="adjustSteps"
        :on-push="onPush"
    >
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <Text as="p">
                    Opteo recommends adding structured snippets extensions at the account level.
                    These extensions highlight particular products or services a business might
                    offer. This should help to increase engagement and improve ad performance.
                    Structured snippets are applied at the account level, and ensure coverage across
                    all your campaigns:
                </Text>
                <Spacer height="2rem" />
                <template v-if="entityPillLinkList">
                    <EntityPillLinkList
                        v-if="
                            entityPillLinkList.length > 1 ||
                            (!aboveMobile &&
                                entityPillLinkList[0].entityPillData.content &&
                                entityPillLinkList[0].entityPillData.content.length > 30)
                        "
                        :items="entityPillLinkList"
                    />
                    <EntityPillLink
                        v-else
                        :entityPillData="{
                            type: 'campaign',
                            content: entityPillLinkList[0].entityPillData.content,
                        }"
                        :deepLinkParams="entityPillLinkList[0].deepLinkParams"
                    />
                </template>
                <template v-else>
                    <EntityPillList
                        v-if="
                            entityPillList.length > 1 ||
                            (!aboveMobile && entityPillList[0].content.length > 30)
                        "
                        :items="entityPillList"
                    />
                    <EntityPill
                        v-else
                        :type="entityPillList[0].type"
                        :content="entityPillList[0].content"
                    />
                </template>

                <Spacer height="2rem" />
                <Text as="p">
                    Click <Text as="span" weight="600" color="blue">{{ adjustSteps[0] }}</Text> to
                    write, preview and add structured snippets to your campaigns. New campaigns will
                    have structured snippets applied automatically.
                </Text>
            </ImprovementHeader>
            <ImprovementHelpLink path="/improvements/extensions/add-structured-snippets-extension">
                Click here to learn more about the benefits of adding structured snippets.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <Text as="h3" size="f-4" weight="600">Structured Snippet Examples</Text>
        </ImprovementContent>

        <ImprovementContent :ad-width="true">
            <div v-for="(snippetExample, index) in snippetExamples" :key="index">
                <AdExtensionPreview
                    :title-tag="{
                        title: snippetExample.example,
                        classes: 'opteo-blue',
                    }"
                    :structured-snippets="snippetExample"
                />
                <Spacer height="2.5rem" v-if="index + 1 < snippetExamples.length" />
            </div>
        </ImprovementContent>

        <ImprovementContent>
            <Spacer height="3rem" />
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="90" />
        </ImprovementContent>

        <template v-slot:adjust-step-1>
            <ImprovementContent>
                <ImprovementHeader title="Input Structured Snippets">
                    <Text as="p">
                        Use the form below to add account-level structured snippets to your
                        campaigns. Opteo recommends adding at least
                        <b>3</b> account-level snippets. Use the preview of your highest clicked ad
                        to see how snippets will appear on the search engine results page.
                    </Text>
                    <Spacer height="1rem" />
                    <Text as="p">
                        Click
                        <Text as="span" color="green" weight="600">{{ pushActionText }}</Text> to
                        push your structured snippets to Google Ads.
                    </Text>
                </ImprovementHeader>
            </ImprovementContent>

            <ImprovementContent :ad-width="true">
                <TextAd
                    :ad="highestClickedAd"
                    highlight="structured-snippets"
                    :title-tag="adTitleTag"
                />
            </ImprovementContent>

            <ImprovementContent>
                <Spacer height="3rem" />
                <div class="form-container">
                    <oForm class="form-inputs" ref="snippetForm">
                        <oInput
                            v-model="selectedLanguage"
                            label="Ad Language"
                            :items="languagesDropDownItems"
                            type="select"
                            name="adlanguage"
                            required
                        />
                        <Spacer height="1.5rem" />
                        <oInput
                            v-model="selectedHeader"
                            ref="headerRef"
                            label="Header (Required)"
                            :items="headersDropDownItems"
                            type="select"
                            name="header"
                            required
                        />
                        <Spacer height="1.5rem" />
                        <span v-for="(extension, index) in snippetExtensions" :key="index">
                            <oInput
                                v-model="extension.snippet"
                                :name="`snippet${index}`"
                                :label="`Snippet ${getNumberWord(index + 1)} ${
                                    extension.required ? `(Required)` : ``
                                }`"
                                :placeholder="`Snippet ${getNumberWord(index + 1)}`"
                                :required="extension.required"
                                :max-characters="MAXIMUM_SNIPPET_LENGTH"
                            />
                            <Spacer height="1.5rem" v-if="index + 1 < snippetExtensions.length" />
                        </span>
                    </oForm>
                    <div class="button-container">
                        <oButton
                            :size="aboveMobile ? 'small' : 'medium'"
                            color="white"
                            @clicked="resetSnippetExtensions"
                            :fullWidth="aboveMobile ? false : true"
                        >
                            Clear Form
                        </oButton>
                    </div>
                </div>
            </ImprovementContent>
        </template>
    </ImprovementView>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import {
    EntityPill,
    EntityPillList,
    oInput,
    oForm,
    Text,
    Spacer,
    TextAd,
    oButton,
} from '@opteo/components-next'

import EntityPillLink from '@/components/global/Entity/EntityPillLink.vue'
import EntityPillLinkList from '@/components/global/Entity/EntityPillLinkList.vue'
import { useAddStructuredSnippetsExtension } from '@/composition/improvement/types/account-level-extensions/useAddStructuredSnippetsExtension'
import { getNumberWord } from '@/lib/globalUtils'
import useMediaQuery from '@/composition/global/useMediaQuery'

// Global Components
import CircleButton from '@/components/global/CircleButton.vue'

// Improvement Components
import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import ImprovementHeader from '@/components/improvement/ImprovementHeader.vue'
import ImprovementContent from '@/components/improvement/ImprovementContent.vue'
import ImprovementCreatedDate from '@/components/improvement/ImprovementCreatedDate.vue'

// Shared Components
import AdExtensionPreview from '@/components/improvement/shared-components/AdExtensionPreview.vue'

export default defineComponent({
    name: 'AddStructuredSnippetsExtension',
    components: {
        ImprovementView,
        ImprovementHelpLink,
        ImprovementHeader,
        ImprovementContent,
        ImprovementCreatedDate,
        EntityPillLink,
        EntityPillLinkList,
        EntityPill,
        EntityPillList,
        oInput,
        oForm,
        Text,
        Spacer,
        TextAd,
        CircleButton,
        AdExtensionPreview,
        oButton,
    },
    setup() {
        const { aboveMobile } = useMediaQuery()

        return { getNumberWord, ...useAddStructuredSnippetsExtension(), aboveMobile }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.form-container {
    @include container;
    @include relative;
}

.form-inputs {
    @include pa-36;
}

.button-container {
    border-top: 1px solid;
    @include opteo-border;
    @include flex;
    @include items-center;
    @include ph-36;
    @include pv-28;
}
@media screen and (max-width: $mq-767-max) {
    .button-container {
        @include block;
        @include pa-24;
    }
    .form-inputs {
        @include pa-24;
    }
}
</style>
