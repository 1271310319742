import { Entity } from '@opteo/types'
import { formatEntityPillLink } from '@/lib/globalUtils'

import type {
    AddCallExtension,
    AddCalloutExtension,
    AddSitelinkExtension,
    AddStructuredSnippetsExtension,
} from '@opteo/types'
import type { EntityPillLinkProps } from '@/components/global/Entity/types'

type IncompleteCampaigns =
    | AddCallExtension.Body['incompleteCampaigns']
    | AddCalloutExtension.Body['incompleteCampaigns']
    | AddSitelinkExtension.Body['incompleteCampaigns']
    | AddStructuredSnippetsExtension.Body['incompleteCampaigns']

export const getEntityPillLinkList = (
    incompleteCampaigns: IncompleteCampaigns
): EntityPillLinkProps<'campaign'>[] | undefined => {
    return (
        incompleteCampaigns &&
        incompleteCampaigns.map(({ campaignName, campaignId }) => {
            return formatEntityPillLink({
                entityPillData: {
                    type: Entity.EntityLocation.Campaign,
                    content: campaignName,
                },
                deepLinkParams: {
                    campaignId,
                },
            })
        })
    )
}
