import { Entity, Improvement } from '@opteo/types'

/**
 * @description Filters an array of locations to find a certain entity type.
 * @param locationArray - The array of location items.
 * @param entityType - The `Entity.EntityLocation` type to look for.
 * @return An array of locations containing only the entity or entities corresponding to the specified type.
 */

export const filterLocationsByEntityType = <T extends Entity.EntityLocation>(
    locationArray: Improvement.AccountLocation[],
    entityType: T
) => {
    const [resLocation] = locationArray.filter(
        loc => loc.entity === entityType
    ) as Improvement.AccountLocation<T>[]
    return resLocation
}
