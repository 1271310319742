<template>
    <Section title="Accounts" :inner-shadow="false">
        <!-- Tabs, search, import, etc. -->
        <template #header-content>
            <div class="header-content">
                <div class="left">
                    <Tabs
                        :links="tabLinks"
                        :active="currentView"
                        :router="false"
                        @clicked="updateView"
                    >
                        <template v-if="newUnlinkedAccounts" #unlinked-right>
                            <transition name="notification-fade">
                                <div class="new-tag">New</div>
                            </transition>
                        </template>
                    </Tabs>
                </div>
                <div class="right">
                    <!-- Search -->
                    <div class="search-input">
                        <SmallSearchIcon class="search-icon" />
                        <oInput
                            v-if="currentView === 'linked'"
                            v-model="searchedLinkedAccounts"
                            name="searchLinkedAccounts"
                        />
                        <oInput
                            v-if="currentView === 'unlinked'"
                            v-model="searchedUnlinkedAccounts"
                            name="searchUnlinkedAccounts"
                        />
                    </div>
                    <!-- Import MCC -->
                    <oButton
                        v-if="platform === Platform.Platform.GoogleAds"
                        color="white"
                        @clicked="connectGoogleAds"
                        :icon-before="true"
                    >
                        <template #icon>
                            <GoogleAdsLogo :width="18" />
                        </template>
                        Import Google Ads MCC
                    </oButton>

                    <oButton
                        v-if="platform === Platform.Platform.MicrosoftAds"
                        color="white"
                        @clicked="connectMicrosoftAds"
                        :icon-before="true"
                    >
                        <template #icon>
                            <MicrosoftAdsLogo :width="18" />
                        </template>
                        Import Microsoft Ads MCC
                    </oButton>

                    <oButton
                        v-if="platform === Platform.Platform.MetaAds"
                        color="white"
                        @clicked="connectMetaAds"
                        :icon-before="true"
                    >
                        <template #icon>
                            <MetaAdsLogo :width="22" />
                        </template>
                        Import Meta Ads Accounts
                    </oButton>

                    <oButton
                        v-if="platform === Platform.Platform.LinkedInAds"
                        color="white"
                        @clicked="connectLinkedInAds"
                        :icon-before="true"
                    >
                        <template #icon>
                            <LinkedInLogo :width="22" />
                        </template>
                        Import LinkedIn Ads Accounts
                    </oButton>
                </div>
            </div>
        </template>
        <!-- Accounts -->
        <template #content>
            <LinkedAccountsTableLinked v-if="currentView === 'linked'" />
            <LinkedAccountsTableUnlinked v-if="currentView === 'unlinked'" />
        </template>
    </Section>

    <!-- Linked BatchBar -->
    <BatchBar
        v-model="showLinkedBatchBar"
        :title="selectedLinkedAccountsCount === 1 ? 'Account' : 'Accounts'"
        @clear-selected="clearSelectedAccounts"
        :count="selectedLinkedAccountsCount"
    >
        <template #buttons>
            <oButton
                ref="assignTeamMembersRef"
                color="blue"
                @clicked="assignTeamModalOpen = true"
                :icon-before="true"
            >
                <template #icon>
                    <UsersIcon />
                </template>
                Assign Team Members
            </oButton>
            <oButton
                ref="unlinkAccountsRef"
                color="red"
                @clicked="unlinkAccountsModalOpen = true"
                :icon-before="true"
                :loading="updatingLinkedAccounts"
            >
                <template #icon>
                    <UnlinkIcon />
                </template>
                Unlink {{ selectedLinkedAccountsCount === 1 ? 'Account' : 'Accounts' }}
            </oButton>
        </template>
    </BatchBar>

    <!-- Unlinked BatchBar -->
    <BatchBar
        v-model="showUnlinkedBatchBar"
        :title="selectedUnlinkedAccountsCount === 1 ? 'Account' : 'Accounts'"
        @clear-selected="clearSelectedAccounts"
        :count="selectedUnlinkedAccountsCount"
    >
        <template #buttons>
            <oButton
                ref="linkAccountsRef"
                color="green"
                @clicked="updateLinkedAccountStatus(true)"
                :icon-before="true"
                :loading="updatingLinkedAccounts"
            >
                <template #icon>
                    <LinkIcon />
                </template>
                Link {{ selectedUnlinkedAccountsCount === 1 ? 'Account' : 'Accounts' }}
            </oButton>
        </template>
    </BatchBar>

    <!-- Unlink Accounts Modal -->
    <Modal
        title="Unlink Accounts"
        width="466px"
        v-model="unlinkAccountsModalOpen"
        container-class="modal-z-max"
        contentMaxHeight="30rem"
    >
        <template #content>
            <Text size="f-8"
                >Please note, this will unlink the selected accounts for
                <b>everyone on your team</b>.
                <span v-if="currentUserIsConnectedToAnyQueuedAccounts"
                    >To disconnect the selected accounts from your account only, click below.</span
                ><span v-else>Click below to confirm.</span></Text
            >
            <Spacer v-if="currentUserIsConnectedToAnyQueuedAccounts" height="1.5rem" />
            <oButton
                v-if="currentUserIsConnectedToAnyQueuedAccounts"
                class="disconnect-user-button"
                color="white"
                icon-before
                @clicked="updateLinkedAccountStatus(false, true)"
            >
                <template #icon>
                    <ProfileImage
                        :width="24"
                        :height="24"
                        :image-url="profileImageUrl"
                        :avatar-url="getAvatarUrl(userId)"
                        :hide-role="true"
                    />
                </template>
                Disconnect {{ userInfo?.name }}
            </oButton>
        </template>
        <template #buttons>
            <oButton @clicked="unlinkAccountsModalOpen = false" color="white"> Cancel </oButton>
            <Spacer width="0.5rem" />
            <oButton
                @clicked="updateLinkedAccountStatus(false)"
                :loading="updatingLinkedAccounts"
                icon-before
                color="red"
            >
                <template #icon>
                    <UnlinkIcon />
                </template>
                Confirm and Unlink Accounts
            </oButton>
        </template>
    </Modal>

    <!-- Plan Limit Modal -->
    <Modal
        title="Plan Limit Reached"
        width="560px"
        v-model="planLimitWarning"
        container-class="modal-z-max"
        @closed="clearSelectedAccounts"
    >
        <template #content>
            <Text size="f-8">
                Linking this account would exceed your plan limit. You are currently subscribed to
                the <b>{{ currentPlan?.name }}</b> plan. This plan includes
                <b><Money currency="USD" :value="currentPlan?.spend_limit ?? 0" /></b> spend per
                month and <b>{{ currentPlan?.account_limit }}</b> linked accounts.
            </Text>
            <Spacer height="1.5rem" />
            <Text size="f-8">
                To continue and link this account, please head to the <b>Billing Centre</b> and
                upgrade your pricing plan to account for the space required.
            </Text>
        </template>
        <template #buttons>
            <oButton color="white" @clicked="clearSelectedAccounts">Clear Selected</oButton>
            <Spacer width="0.5rem" />
            <oButton ref="updateTeamSettingsButton" @clicked="goToBillingCentre">
                Go to Billing Centre
                <template #icon>
                    <RightArrowIcon hex="#fff" />
                </template>
            </oButton>
        </template>
    </Modal>
</template>

<script setup lang="ts">
// components-next
import {
    oButton,
    GoogleAdsLogo,
    Money,
    oInput,
    Tabs,
    Spacer,
    Text,
    Section,
    BatchBar,
    Modal,
    RightArrowIcon,
    MicrosoftAdsLogo,
    MetaAdsLogo,
    ProfileImage,
    LinkedInLogo,
} from '@opteo/components-next'

// Linked Accounts Components
import LinkedAccountsTableLinked from '@/components/linkedAccounts/LinkedAccountsTableLinked.vue'
import LinkedAccountsTableUnlinked from '@/components/linkedAccounts/LinkedAccountsTableUnlinked.vue'

// Linked Accounts Icons
import LinkIcon from '@/components/linkedAccounts/icons/LinkIcon.vue'
import UnlinkIcon from '@/components/linkedAccounts/icons/UnlinkIcon.vue'
import UsersIcon from '@/components/linkedAccounts/icons/UsersIcon.vue'
import SmallSearchIcon from '@/components/linkedAccounts/icons/SmallSearchIcon.vue'

// useLinkedAccounts
import { useLinkedAccounts } from '@/composition/linkedaccounts/useLinkedAccounts'
import { useBoringAvatar } from '@/composition/user/useBoringAvatar'
import { computed, ref, watch } from 'vue'
import { Platform } from '@opteo/types'

const {
    newUnlinkedAccounts,
    currentView,
    linkingAccounts,
    updateView,
    connectGoogleAds,
    connectMicrosoftAds,
    connectMetaAds,
    connectLinkedInAds,
    tabLinks,
    searchedLinkedAccounts,
    searchedUnlinkedAccounts,
    someUnlinkedAccountsSelected,
    selectedUnlinkedAccountsCount,
    currentPlan,
    planLimitWarning,
    assignTeamModalOpen,
    updateLinkedAccountStatus,
    updatingLinkedAccounts,
    someLinkedAccountsSelected,
    selectedLinkedAccountsCount,
    linkAccountsRef,
    unlinkAccountsRef,
    unlinkAccountsModalOpen,
    assignTeamMembersRef,
    clearSelectedAccounts,
    goToBillingCentre,
    platform,
    userId,
    userInfo,
    profileImageUrl,
    currentUserIsConnectedToAnyQueuedAccounts,
} = useLinkedAccounts()
const { getAvatarUrl } = useBoringAvatar()

watch(updatingLinkedAccounts, async newValue => {
    if (!newValue) {
        searchedLinkedAccounts.value = ''
        searchedUnlinkedAccounts.value = ''
    }
})

const showUnlinkedBatchBar = computed(
    () =>
        linkingAccounts.value &&
        (someUnlinkedAccountsSelected.value || updatingLinkedAccounts.value)
)
const showLinkedBatchBar = computed(
    () =>
        !linkingAccounts.value && (someLinkedAccountsSelected.value || updatingLinkedAccounts.value)
)
</script>

<!-- override .o-button padding-left -->
<style lang="scss" global>
.disconnect-user-button {
    padding-left: 1.125rem !important;
}
</style>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

// section header
.header-content {
    @include flex;
    @include items-center;
    justify-content: space-between;
}
.header-content .left,
.header-content .right {
    @include flex;
    @include items-center;
    gap: 0.75rem;
}

// search
.search-input {
    max-width: 12.5rem;
    @include relative;
}
.search-input :deep(input) {
    padding: 12px 14px 12px 38px;
}
.search-input .search-icon {
    @include absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0.875rem;
}

// `new` tag
.new-tag {
    background: #f2f7f6;
    color: $opteo-dark-teal;
    height: 1.5rem;
    @include flex-center;
    font-size: 0.6875rem;
    @include fw-400;
    padding: 0 0.625rem;
    @include br-999;
    @include absolute;
    top: -0.8125rem;
    right: -1.125rem;
}

// tables
:deep(.spinner-container) {
    height: 1.25rem;
    width: 1.25rem;
    @include flex-center;
}
</style>
