<template>
    <div :class="['impact-statistics-improvement-row', { 'clickable-row': isImprovement }]">
        <div class="impact-statistics-improvement-row-left">
            <!-- Avatar -->
            <Tooltip :content="improvement.account.domainName">
                <Avatar
                    :color="improvement.account.color"
                    :platform="improvement.account.platform"
                    show-account-type
                >
                    {{ improvement.account.initials }}
                </Avatar>
            </Tooltip>
            <!-- Improvement Title -->
            <h4 class="improvement-title">
                {{ improvement.title }}
            </h4>
            <!-- Location -->
            <div class="improvement-location">
                <EntityPill
                    v-for="entity in improvement.location"
                    :type="entity.entity"
                    :content="entity.label"
                    :tooltip="false"
                />
            </div>
        </div>
        <div class="impact-statistics-improvement-row-right">
            <!-- Pause Spend When Over Budget -->
            <Tooltip v-if="isPauseSpend" :maxWidth="224" :content="pauseSpendTooltipText">
                <div
                    v-if="improvement.hasBudgetAutoPause === true"
                    class="pause-spend-status-circle-on"
                />
                <div
                    v-else="improvement.hasBudgetAutoPause"
                    class="pause-spend-status-circle-off"
                />
            </Tooltip>
            <!-- Cost Saved -->
            <div class="improvement-cost-saved">
                <ColorTag title="Cost Saved" :content="costSaved" color="shadow" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, PropType } from 'vue'
import { Avatar, Tooltip, EntityPill, useMoney, Text, ColorTag } from '@opteo/components-next'
import { IS } from '@opteo/types'

export default {
    name: 'ImpactStatisticsImprovementRow',
    props: {
        improvement: {
            type: Object as PropType<IS.CostSavingOverviewInstance>,
            required: true,
        },
    },
    components: { Avatar, Tooltip, EntityPill, useMoney, Text, ColorTag },
    setup(props) {
        const costSaved = useMoney({
            value: props.improvement.savings,
            currency: 'USD',
        }).displayValue

        const isPauseSpend = computed(
            () => props.improvement.type === IS.OverviewItemInstanceType.pauseSpend
        )

        const isImprovement = computed(
            () => props.improvement.type === IS.OverviewItemInstanceType.improvement
        )

        const pauseBudgetStatus = computed(() => {
            if (!isPauseSpend) return { text: '', color: 'red' }
            else
                return props.improvement.hasBudgetAutoPause
                    ? { text: 'ON', color: 'green' }
                    : { text: 'OFF', color: 'gray' }
        })

        const pauseSpendTooltipText = computed(() => {
            const statusText = props.improvement.hasBudgetAutoPause ? 'on' : 'off'

            return `Pause Spend When Over Budget is currently turned <b>${statusText}</b> for this account.`
        })

        return { costSaved, isPauseSpend, isImprovement, pauseBudgetStatus, pauseSpendTooltipText }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.impact-statistics-improvement-row {
    @include flex;
    @include items-center;
    justify-content: space-between;
    @include pl-24;
    @include br-24;
    @include opteo-background;
    transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.25s;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    outline: none;
    overflow: hidden;
    height: 4.625rem;
    @include mb-16;
    @include relative;
    max-width: 64rem;
    margin-left: auto;
    margin-right: auto;
    gap: 2rem;
}
.impact-statistics-improvement-row-left,
.impact-statistics-improvement-row-right {
    @include flex;
    @include items-center;
}

.impact-statistics-improvement-row-left {
    gap: 1.25rem;
}

.impact-statistics-improvement-row-right {
    @include absolute;
    @include z-2;
    @include bg-opteo-white;
    right: 0;
    top: 0;
    height: 100%;
    @include flex;
    @include items-center;
    gap: 1rem;
    //width: 10.25rem;
    //@include pr-24;
    @include pl-4;
}

.impact-statistics-improvement-row:focus {
    box-shadow: $opteo-shadow-focus;
    outline: none;
}
.impact-statistics-improvement-row:active {
    outline: none;
    box-shadow: $opteo-shadow-focus;
    transform: translate3d(0, 1px, 0);
    -webkit-transform: translate3d(0, 1px, 0);
    -moz-transform: translate3d(0, 1px, 0);
}
.impact-statistics-improvement-row.last {
    @include mb-0;
}
.improvement-title {
    @include opteo-foreground;
    //@include mh-20;
    @include fw-600;
    white-space: nowrap;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.36px;
}
.improvement-location {
    @include flex;
    @include items-center;
    gap: 0.625rem;
}
.improvement-cost-saved {
    //@include absolute;
    //@include z-2;
    @include bg-opteo-white;
    //right: 0;
    //top: 0;
    height: 100%;
    @include flex;
    @include items-center;
    //width: 10.25rem;
    @include pr-24;
    //@include pl-4;
}
.pause-spend-status {
}

.pause-spend-status-circle-on,
.pause-spend-status-circle-off {
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 999rem;
}

.pause-spend-status-circle-on {
    @include bg-opteo-green;
}

.pause-spend-status-circle-off {
    background-color: rgba(0, 0, 0, 0.1);
}
.improvement-cost-saved::before {
    content: '';
    background: #fff;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgb(255, 255, 255) 80%);
    width: 6rem;
    left: -6rem;
    height: calc(100% - 8px);
    top: 4px;
    position: absolute;
    pointer-events: none;
}

.clickable-row {
    cursor: pointer;
}
</style>
