import { checkImprovement, useImprovement, UseImprovement } from '../useImprovement'
import { Improvement, PauseKeywordV2, Targets } from '@opteo/types'
import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import { useNumber, useRoas } from '@opteo/components-next'
import { computed, ref } from 'vue'
import { EntityPillLinkProps, ValidEntity } from '@/components/global/Entity/types'

interface UsePauseKeyword {
    campaignName: string
    adgroupName: string
    formattedKeyword: string
    statistics: Statistics[]
    keywordCost: number
    keywordConversions: number
    keywordConversionsValue: number
    campaignGroupTargetCpa: number
    campaignGroupTargetRoas: number
    keywordCpa: number
    keywordRoas: number
    lookbackWindow: number
    isUsingCpa: boolean
    entityPillList: EntityPillLinkProps<ValidEntity>[]
}

interface Statistics {
    key: string
    title: string
    value: string
}

export function usePauseKeywordV2(): UseImprovement<UsePauseKeyword> {
    const { improvement, lastUpdated, title } = useImprovement<
        PauseKeywordV2.Body,
        typeof Improvement.RecAction.PauseKeywordV2
    >()
    const {
        body: {
            campaign_name: campaignName,
            adgroup_name: adgroupName,
            formatted_keyword: formattedKeyword,
            keyword_metrics: {
                cost: keywordCost,
                conversions: keywordConversions,
                conversions_value: keywordConversionsValue,
            },
            campaign_group_target_cpa: campaignGroupTargetCpa,
            campaign_group_target_roas: campaignGroupTargetRoas,
            performance_mode: performanceMode,
            lookback_window: lookbackWindow,
        },
        location,
    } = checkImprovement(improvement)

    const statistics: Statistics[] = [
        {
            key: 'cost',
            value: useDomainMoney({ value: keywordCost }).value.displayValue.value,
            title: `Cost`,
        },
    ]

    const isUsingCpa = !performanceMode || performanceMode === Targets.PerformanceMode.CPA

    if (isUsingCpa) {
        statistics.push(
            {
                key: 'conversions',
                value: useNumber({ value: keywordConversions }).displayValue.value,
                title: keywordConversions === 1 ? 'Conversion' : 'Conversions',
            },
            {
                key: 'averageCpa',
                value: useDomainMoney({ value: campaignGroupTargetCpa }).value.displayValue.value,
                title: 'Average CPA',
            }
        )
    } else {
        statistics.push(
            {
                key: 'conversionsValue',
                value: useDomainMoney({ value: keywordConversionsValue }).value.displayValue.value,
                title: 'Conversion Value',
            },
            {
                key: 'averageRoas',
                value: useRoas({ value: campaignGroupTargetRoas }).displayValue.value,
                title: 'Average ROAS',
            }
        )
    }

    const keywordCpa = keywordCost / keywordConversions
    const keywordRoas = keywordConversionsValue ? keywordConversionsValue / keywordCost : 0

    if (isUsingCpa) {
        statistics.push({
            key: 'keywordCpa',
            value: useDomainMoney({ value: keywordCpa }).value.displayValue.value,
            title: 'Keyword CPA',
        })
    } else {
        statistics.push({
            key: 'keywordRoas',
            value: useRoas({ value: keywordRoas }).displayValue.value,
            title: 'Keyword ROAS',
        })
    }

    const entityPillList = location.map(loc => {
        return {
            entityPillData: { type: loc.entity, content: loc.label },
            deepLinkParams: loc.entityIds,
        }
    })

    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Pausing keyword..',
        'Confirming changes..',
        'Keyword paused successfully.',
    ])

    const pushActionText = ref('Pause Keyword')

    return {
        title,
        pushMessages,
        lastUpdated,
        campaignName,
        adgroupName,
        formattedKeyword,
        statistics,
        keywordCost,
        keywordConversions,
        keywordConversionsValue,
        campaignGroupTargetCpa,
        campaignGroupTargetRoas,
        lookbackWindow,
        keywordCpa,
        keywordRoas,
        isUsingCpa,
        pushActionText,
        entityPillList,
    }
}
