import { computed, Ref, ref, watch } from 'vue'
import sortBy from 'lodash-es/sortBy'
import {
    OnPushHandler,
    useImprovement,
    checkImprovement,
} from '@/composition/improvement/useImprovement'
import { scrollToSelector } from '@/lib/globalUtils'
import { AddRobotNegative, Improvement, AdWords, Entity } from '@opteo/types'
import { enums } from '@opteo/types/gads'
import { Endpoint, useAPI } from '@/composition/api/useAPI'
import { useDomain } from '@/composition/domain/useDomain'

type DropDownItem = { value: string; label: string }

interface ActiveEntity {
    id: number
    label: string
    type: string
    uncheckable: boolean
    children: ActiveEntityChildren[]
}

interface ActiveEntityChildren {
    id: number
    label: string
    type: string
    checked: boolean
}

type KeywordMatchTypes = Ref<{ matchType: enums.KeywordMatchType; selected: boolean }[]>

type SearchTermsWords = Ref<{ word: string; index: number; selected: boolean }[]>

const defaultSharedSetName = ref('Keyword negatives for search campaigns (auto_gen_general)')

export function useAddRobotNegative() {
    const { domainId } = useDomain()
    const { improvement, lastUpdated, title } =
        useImprovement<AddRobotNegative.AddRobotNegativeBody>()

    const {
        body: {
            word: keyword,
            impressions,
            clicks,
            conversions,
            cost,
            search_terms: searchTermsRaw,
            shared_sets: sharedSetsRaw,
        },
    } = checkImprovement(improvement)

    const searchTerms = searchTermsRaw.map(st => st.search_term)

    const adjustSteps = ref(['Classify Negative'])

    const destination = computed(() => [
        {
            key: Improvement.LocationEntity.NegativeList,
            name: 'Negative Lists',

            count: accountLevelEntity.value.checked ? 1 : 0,
        },
        {
            key: Improvement.LocationEntity.Campaign,
            name: 'Campaigns',
        },
    ])

    const accountLevelEntity = ref({
        id: 'entity-id',
        label: 'Account Level',
        type: 'account-level',
        checked: false,
    })

    const { data: activeCampaignData, loading: activeCampaignsLoading } = useAPI<
        AdWords.CampaignWithAdGroups[]
    >(Endpoint.GetCampaignsAndAdgroups, {
        body: { campaign_status_enabled: true },
        uniqueId: () => domainId.value,
        waitFor: () => domainId.value,
    })

    const campaigns = computed<DropDownItem[]>(() => {
        return (
            activeCampaignData?.value?.map(campaign => {
                return { value: campaign.campaign_id.toString(), label: campaign.campaign_name }
            }) ?? []
        )
    })

    const {
        data: sharedSetData,
        loading: sharedSetsLoading,
        mutate: mutateSharedSetData,
    } = useAPI<AdWords.SharedNegativeList[]>(Endpoint.GetSharedNegativeLists, {
        body: { type: 'keywords' },
        uniqueId: () => domainId.value,
        waitFor: () => domainId.value,
    })

    const existingSharedSetNames = computed(
        () => sharedSetData.value?.map(set => set.shared_set_name)
    )

    const negativeListSelection = ref()

    watch([sharedSetData, activeCampaignData], () => {
        negativeListSelection.value = {
            [Improvement.LocationEntity.NegativeList]: sortBy(
                sharedSetData.value?.map((set, i) => {
                    // const opteoSharedSet = sharedSets?.value?.find(sharedSet => {
                    //     return sharedSet.label === defaultSharedSetName.value
                    // })

                    return {
                        id: set.shared_set_resource_name,
                        label: set.shared_set_name,
                        type: 'negative-list',
                        checked: set.shared_set_resource_name === newNegativeListRn.value,
                        // ||
                        // (opteoSharedSet &&
                        //     set.shared_set_resource_name === opteoSharedSet.value),
                    }
                }) ?? [], //Show selected shared set first then keep alphabetic order
                c => {
                    if (c.checked) {
                        return 0
                    }
                    return
                }
            ),
            [Improvement.LocationEntity.Campaign]: sortBy(
                activeCampaignData?.value?.map((campaign, i) => {
                    return {
                        id: campaign.campaign_id,
                        label: campaign.campaign_name,
                        type: 'campaign',
                        checked: false,
                        children: campaign.adgroups_data.map((adGroup, x) => {
                            return {
                                id: adGroup.adgroup_id,
                                label: adGroup.adgroup,
                                type: 'adgroup',
                                checked: false,
                            }
                        }),
                    }
                })
            ),
        }
    })

    const searchTermWords: SearchTermsWords = ref(
        keyword.split(' ').map((word, index) => {
            return { word, index, selected: true }
        })
    )

    const selectedWords = computed(() => searchTermWords.value.filter(word => word.selected))

    const keywordText = computed(() =>
        selectedWords.value
            .filter(w => w.selected)
            .map(w => w.word)
            .join(' ')
    )

    const previewKeyword = computed(() => {
        if (!selectedWords.value.some(w => w.selected)) {
            return null
        }

        const words = selectedWords.value
            .filter(w => w.selected)
            .map(word => word.word)
            .join(' ')

        const matchType = keywordMatchTypes.value.find(matchType => matchType.selected)

        if (matchType?.matchType === enums.KeywordMatchType.PHRASE) {
            return `“${words}”`
        } else if (matchType?.matchType === enums.KeywordMatchType.EXACT) {
            return `[${words}]`
        } else {
            return words
        }
    })

    const keywordMatchTypes: KeywordMatchTypes = ref([
        { matchType: enums.KeywordMatchType.EXACT, selected: false },
        { matchType: enums.KeywordMatchType.PHRASE, selected: true },
        { matchType: enums.KeywordMatchType.BROAD, selected: false },
    ])

    function toggleMatchType(matchType: enums.KeywordMatchType) {
        const selectedKeywordMatchType = keywordMatchTypes.value.find(
            mt => mt.matchType === matchType
        )

        if (selectedKeywordMatchType) {
            keywordMatchTypes.value.forEach(mt => (mt.selected = false))
            selectedKeywordMatchType.selected = true
        }
    }

    const newNegativeListRn = ref('')
    async function mutateNegativeList(data: AdWords.SharedNegativeList) {
        newNegativeListRn.value = data.shared_set_resource_name
        mutateSharedSetData(() => [...sharedSetData?.value!, ...[data]])
    }

    const negativeListModalOpen = ref(false)

    const copy = {
        impressions: impressions === 1 ? 'impression' : 'impressions',
        clicks: clicks === 1 ? 'click' : 'clicks',
        conversions: conversions === 1 ? 'conversion' : 'conversions',
    }

    const showKeywordSelector = ref(false)

    const pushActionText = ref('Add Negative Keyword')
    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Adding negative keyword..',
        'Confirming changes..',
        'Negative keyword added successfully.',
    ])

    const entityPill = {
        type: Entity.EntityLocation.Word,
        content: keyword,
    }

    const onPushError = ref({ status: false, message: '' })

    const onPush: OnPushHandler<{
        shared_sets: AddRobotNegative.SharedSet[]
        is_add_to_account_level_negative_list?: boolean
    }> = () => {
        const noKeywordsSelected = !selectedWords.value.length

        if (noKeywordsSelected) {
            return { valid: false }
        }

        const keywordMatchType = keywordMatchTypes.value.find(matchType => matchType.selected)
            ?.matchType

        const isAccountLevelNegativeList = accountLevelEntity.value.checked

        const sharedSets = negativeListSelection?.value?.[Improvement.LocationEntity.NegativeList]
            .filter(
                (sharedSet: { checked: boolean; id: string; resource_name?: string }) =>
                    sharedSet.checked
            )
            .map((checkedSharedSet: { checked: boolean; id: string; resource_name?: string }) => {
                checkedSharedSet.resource_name = checkedSharedSet.id
                return checkedSharedSet
            })

        const ad_group_ids =
            negativeListSelection.value.campaign?.flatMap((parentCampaign: ActiveEntity) => {
                return parentCampaign.children
                    .filter(adGroup => adGroup.checked)
                    .map(adGroup => adGroup.id)
            }) ?? []

        const campaign_ids =
            negativeListSelection.value.campaign
                ?.filter((campaign: ActiveEntityChildren) => campaign.checked)
                .map((campaign: ActiveEntityChildren) => {
                    return campaign.id
                }) ?? []

        if (
            !sharedSets.length &&
            !isAccountLevelNegativeList &&
            !ad_group_ids.length &&
            !campaign_ids.length
        ) {
            scrollToSelector('.keyword-destination-selector')
            onPushError.value.status = true
            onPushError.value.message = 'Please select at least one destination for your negative.'

            return { valid: false }
        }
        onPushError.value.status = false

        return {
            valid: true,
            pushedData: {
                shared_sets: sharedSets,
                campaign_ids,
                ad_group_ids,
                is_add_to_account_level_negative_list: isAccountLevelNegativeList,
                search_term: keywordText.value,
                match_type: keywordMatchType,
            },
        }
    }

    return {
        title,
        pushMessages,
        onPush,
        lastUpdated,
        entityPill,
        keyword,
        destination,
        showKeywordSelector,
        accountLevelEntity,
        negativeListSelection,
        negativeListModalOpen,
        mutateNegativeList,
        copy,
        impressions,
        clicks,
        conversions,
        cost,
        searchTerms,
        defaultSharedSetName,
        existingSharedSetNames,
        mutateSharedSetData,
        pushActionText,
        campaigns,
        adjustSteps,
        onPushError,
        toggleMatchType,
        selectedWords,
        searchTermWords,
        keywordMatchTypes,
        KeywordMatchType: enums.KeywordMatchType,
        keywordText,
        previewKeyword,
    }
}

//            __
//    _(\    |@@|
//   (__/\__ \--/ __
//      \___|----|  |   __
//          \ }{ /\ )_ / _\
//          /\__/\ \__O (__
//         (--/\--)    \__/
//         _)(  )(_
//        `---''---`
