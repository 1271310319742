<template>
    <Section v-if="currentStepIndex === 0" :title="tab.name" :inner-shadow="false">
        <template #header-content>
            <div class="section-buttons">
                <SquareButton
                    ref="campaignsTableButton"
                    @click="campaignsPopoutOpen = !campaignsPopoutOpen"
                    class="campaigns-table-button"
                >
                    <template #prefix>
                        <CircleCampaignIcon :dark="true" :tooltip="false" />
                    </template>
                    <template #text>
                        <Text size="f-9" color="dark-blue" weight="500">
                            {{ campaignSelectionCount }}&nbsp;{{
                                pluralise(campaignSelectionCount, 'Campaign', 'Campaigns')
                            }}
                        </Text>
                    </template>
                    <template #suffix>
                        <div class="icon">
                            <TableEdit />
                        </div>
                    </template>
                </SquareButton>
                <div class="select-ngram-buttons">
                    <MiniSpreadChart
                        :items="spreadChartItems"
                        :currency-code="currencyCode"
                        :performance-metric-type="performanceMetricType"
                        :divisionsLength="SPREAD_CHART_LENGTH"
                        :loading="nGramDataLoading"
                        :hidden="showPMaxOptInMessage"
                        style="padding-right: 0.5rem"
                        @select-item="selectNgram"
                    />
                    <SquareButton @click="filtersModalOpen = true">
                        Filters
                        <template #suffix>
                            <Count :count="totalCheckedFilters" />
                        </template>
                    </SquareButton>
                    <Select v-model="lookbackWindow" :options="lookbackWindows" />
                </div>
            </div>
        </template>

        <!-- NGrams Selection Table -->
        <template #content>
            <div>
                <!-- pMax Search terms Opt in -->
                <div v-if="showPMaxOptInMessage" class="empty-state-container">
                    <Text size="f-8" style="width: 472px" align="center">
                        Click below to load your Performance Max search terms. Please note, this is
                        a resource intensive task, and will likely take a few minutes to complete.
                        While in progress, you can leave this page and continue with other tasks.
                        Opteo will send you an email when your n-grams are ready to view.
                    </Text>
                    <Spacer height="0px" />
                    <oButton
                        ref="pMaxOptInButton"
                        @clicked="optInToPMax"
                        :loading="pmaxRequestProgressStatus === 'in_progress'"
                    >
                        Load Search Terms
                    </oButton>
                </div>
                <NGramTableSkeleton v-else-if="nGramDataLoading" />

                <!-- This state should only show when an account has no search campaigns. -->
                <div v-else-if="noCampaignsSelected" class="empty-state-container">
                    <Text size="f-8" style="width: 510px" align="center">
                        No campaigns selected.
                    </Text>
                </div>

                <oTable
                    class="ngram-table"
                    v-else-if="
                        nGramItems.length ||
                        (searchedNgramText.length && searchedNgramItems.length === 0)
                    "
                    :headers="nGramToolHeaders"
                    :items="searchedNgramItems"
                    :order-by="nGramSorting.key"
                    :order="nGramSorting.order"
                    @sort="persistSort"
                    fixed-layout
                    :border-radius="24"
                    :per-page="25"
                    pagination-method="intersection"
                >
                    <template #header.ngram>
                        <Spacer width="1.5rem" />
                        <Tooltip content="Select All" placement="top">
                            <Checkbox :model-value="allNGramsSelected" @click="toggleAllNgrams" />
                        </Tooltip>
                        <Spacer width="1.25rem" />
                        <Text size="f-8" weight="500">N-Gram</Text>
                        <Spacer width="1rem" />
                        <oInput
                            ref="searchInput"
                            v-model="searchedNgramText"
                            type="text"
                            class="table-search-input"
                            name="ngramTextSearchInput"
                            autocomplete="off"
                        />
                    </template>
                    <template #header.vpm>
                        <Text as="span" size="f-8" weight="500">VPM</Text>
                        <Popout
                            v-model="vpiPopoverOpen"
                            :offset="[0, 16]"
                            :borderRadius="24"
                            trigger="mouseenter"
                            placement="top"
                            max-width="23rem"
                            wrapperClasses="vpi-popout"
                        >
                            <img
                                class="info-circle-icon"
                                style="min-width: 1rem"
                                src="@/assets/img/icon-info.svg"
                            />
                            <template #content>
                                <Text size="f-8" weight="400" style="padding: 1rem 1.25rem">
                                    VPM (Value Per Mille) is the average value generated by each
                                    impression of this n-gram.
                                </Text>
                            </template>
                        </Popout>
                    </template>
                    <template #column.ngram="{ value, row }">
                        <NGramCell
                            v-bind="row"
                            :ngram="value"
                            :avg-engagement-rate="avgEngagementRate"
                            :avg-c-t-r="avgCTR"
                        />
                    </template>
                    <template #column.cost="{ value }">
                        <Money :value="value" />
                    </template>
                    <template #column.impressions="{ value }">
                        <Number :value="value" />
                    </template>
                    <template #column.conversions="{ value }">
                        <Number :value="value" />
                    </template>
                    <template #column.conversionValue="{ value }">
                        <Money :value="value" />
                    </template>
                    <template #column.cpa="{ value }">
                        <Money :value="value" />
                    </template>
                    <template #column.roas="{ value }">
                        <Percent :value="value" />
                    </template>
                    <template #column.cpm="{ value }"> {{ formatCpm(value) }} </template>
                    <template #column.vpm="{ value }"> {{ formatVpm(value) }} </template>
                    <template #column.vsAvg="{ value, row }">
                        <Text size="f-8" weight="500" :color="row.vsAverageColor">
                            <Percent :value="value" :decimal-places="2" include-sign />
                        </Text>
                    </template>
                    <template #column.nscoreSortValue="{ row }">
                        <NScoreCell v-bind="row" />
                    </template>
                    <template #column.potentialSavings="{ value }">
                        <Pill><Money :value="value" />/month</Pill>
                    </template>
                    <template #column.actions="{ row }">
                        <ActionsCell :item="row" :item-type="'nGram'" />
                    </template>
                    <template
                        #empty-state
                        v-if="searchedNgramText.length > 0 && searchedNgramItems.length === 0"
                    >
                        <div class="ngrams-table-empty-state">
                            <Text as="h2" size="f-2">
                                {{ searchedNgramText }}
                            </Text>
                            <Text size="f-8" align="center">No n-grams matching search query.</Text>
                        </div>
                    </template>
                </oTable>
                <!-- Empty state due to filters -->
                <NGramTableSkeleton v-else :loading="false" />
            </div>
        </template>
    </Section>
    <Panel
        title="Campaigns"
        :model-value="campaignsPopoutOpen"
        @close="cancelCampaignSelection()"
        position="left"
        :width="1148"
    >
        <template #title>
            <div class="panel-label-container">
                <Text size="f-9" weight="400">
                    Select campaigns to include in your n-gram analysis.
                </Text>
            </div>
        </template>
        <template #content>
            <CampaignsTable :buttonRef="campaignsTableButton" />
        </template>
        <template #footer>
            <div class="campaigns-button-container">
                <oButton
                    size="extra-large"
                    color="dark-blue"
                    :disabled="relevantCampaignIds.length === 0"
                    @clicked="applyCampaignSelection()"
                >
                    Save Selected Campaigns
                    <template v-slot:icon>
                        <svg width="12" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1 11 11 1M11 1H3M11 1v8"
                                stroke="#fff"
                                stroke-width="2"
                                stroke-linecap="round"
                            />
                        </svg>
                    </template>
                </oButton>
            </div>
        </template>
    </Panel>
    <ChangeHistoryModal />
    <NegativeListModal />

    <!-- Second Step -->
    <template v-if="currentStepIndex === 1">
        <NGramAddNegatives :initially-selected-items="addNegativesInitiallySelectedItems" />
    </template>

    <Spacer v-if="currentStepIndex === 0 && selectedNGramItems.length > 1" />
    <Spacer v-if="currentStepIndex === 1" height="7rem" />

    <!-- Search Terms Panel -->
    <SearchTermsPanel @add-search-terms-as-negatives="handleAddSearchTermsAsNegatives" />

    <teleport to="body">
        <FloatingButtonGroup v-model="showFloatingButtonsGroup">
            <template #buttons>
                <SquareButton @click="negativeListModalOpen = true">
                    Negative Lists
                    <template #suffix>
                        <Count :count="sharedSetData?.length ?? 0" />
                    </template>
                </SquareButton>

                <SquareButton
                    @click="changeHistoryModalOpen = true"
                    :disabled="changeHistoryLoading || changeHistory?.length === 0"
                >
                    Change History
                    <template #suffix v-if="!changeHistoryLoading && changeHistory?.length !== 0">
                        <Count :count="changeHistory?.length ?? 0" />
                    </template>
                </SquareButton>
                <SquareButton
                    @click="downloadNgramCsv"
                    :disabled="nGramDataLoading || (showPMaxOptInMessage && performanceMaxActive)"
                    >Download CSV</SquareButton
                >
            </template>
        </FloatingButtonGroup>
    </teleport>

    <BatchBar
        :title="`N-Gram${initialNgramSelectionCount > 1 ? 's' : ''}`"
        :model-value="showBatchBar"
        @clear-selected="clearAllNgrams"
        :count="initialNgramSelectionCount"
        :full-screen="true"
    >
        <template #buttons>
            <oButton color="dark" @clicked="setPanel(ActivePanel.SearchTerms)">
                View Search Terms
            </oButton>
            <oButton color="blue" @clicked="goToNegativeDestination">
                Add as Negative {{ initialNgramSelectionCount > 1 ? 'Keywords' : 'Keyword' }}
                <template #icon><RightArrowIcon hex="#fff" /></template>
            </oButton>
        </template>
    </BatchBar>

    <KeywordsPanel />
    <NGramFiltersModal />
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'

import {
    Section,
    oTable,
    Spacer,
    Tooltip,
    Text,
    Checkbox,
    oButton,
    Percent,
    BatchBar,
    RightArrowIcon,
    MiniSpreadChart,
    Count,
    SquareButton,
    CircleCampaignIcon,
    TableEdit,
    Select,
    Number,
    Popout,
    oInput,
    Panel,
} from '@opteo/components-next'
import { useNGramTool } from '@/composition/toolkit/nGramTool/useNGramTool'
import { useNGramFilters } from '@/composition/toolkit/nGramTool/useNGramFilters'
import NGramTableSkeleton from '@/components/toolkit/ngram/NGramTableSkeleton.vue'
import NGramFiltersModal from '@/components/toolkit/ngram/NGramFiltersModal.vue'
import NGramAddNegatives from '@/components/toolkit/ngram/NGramAddNegatives.vue'
import KeywordsPanel from '@/components/toolkit/ngram/KeywordsPanel.vue'
import SearchTermsPanel from '@/components/toolkit/ngram/SearchTermsPanel.vue'
import ChangeHistoryModal from '@/components/toolkit/ngram/ChangeHistoryModal.vue'
import NegativeListModal from '@/components/toolkit/ngram/NegativeListModal.vue'
import Pill from '@/components/toolkit/ngram/Pill.vue'

import Money from '@/components/global/oMoney.vue'
import CampaignsTable from './CampaignsTable.vue'
import NGramCell from './NGramCell.vue'
import NScoreCell from './NScoreCell.vue'
import ActionsCell from './ActionsCell.vue'
import FloatingButtonGroup from './FloatingButtonGroup.vue'
import { useNGramAddNegatives } from '@/composition/toolkit/nGramTool/useNGramAddNegatives'
import { useNGramHeaders } from '@/composition/toolkit/nGramTool/useNGramHeaders'
import { useNgramPanels, ActivePanel } from '@/composition/toolkit/nGramTool/useNgramPanels'
import { useNGramChangeHistory } from '@/composition/toolkit/nGramTool/useNGramChangeHistory'
import { pluralise } from '@/lib/globalUtils'
import { useNGramNegativeLists } from '@/composition/toolkit/nGramTool/useNGramNegativeLists'

import type { PanelItem, SearchTermItem } from '@/composition/toolkit/nGramTool/types/index'

const {
    // Navigation
    currentStepIndex,
    goToNegativeDestination,
    currencyCode,
    tabLinks,
    downloadNgramCsv,
    performanceMaxActive,

    // Campaigns Filter
    applyCampaignSelection,
    cancelCampaignSelection,
    relevantCampaignIds,
    campaignsPopoutOpen,

    // Other Filters
    totalCheckedFilters,

    // NGram Tables
    nGramItems,
    allNGramsSelected,
    selectedNGramItems,
    initialNgramSelectionCount,
    toggleAllNgrams,
    clearAllNgrams,
    nGramDataLoading,
    searchedNgramText,
    searchedNgramItems,
    avgEngagementRate,
    avgCTR,
    campaignSelectionCount,
    noCampaignsSelected,
    selectNgram,

    // Indicator
    SPREAD_CHART_LENGTH,
    spreadChartItems,
    performanceMetricType,

    // Keywords Destinations
    sharedSetData,
    showPMaxOptInMessage,
    optInToPMax,
    pmaxRequestProgressStatus,
    pMaxOptInButton,

    // utils
    formatCpm,
    formatVpm,

    // Change History
    changeHistoryLoading,
    changeHistory,
} = useNGramTool()

const { addNegativesItemType } = useNGramAddNegatives()
const { nGramToolHeaders } = useNGramHeaders()
const { lookbackWindow, lookbackWindows, filtersModalOpen } = useNGramFilters()
const { setPanel, singleItemPanel, activePanel } = useNgramPanels()
const { changeHistoryModalOpen } = useNGramChangeHistory()
const { negativeListModalOpen } = useNGramNegativeLists()

const showFloatingButtonsGroup = computed(() => currentStepIndex.value === 0 && !showBatchBar.value)

// Campaigns Table Popout
const campaignsTableButton = ref()

type SortingOrder = { key: string; order: 'ASC' | 'DESC' | undefined }

const nGramSorting = ref<SortingOrder>({ key: 'nscoreSortValue', order: 'DESC' })

function persistSort(sorting: SortingOrder) {
    nGramSorting.value = sorting
}

const router = useRouter()

const tab = computed(() => {
    // Remove 'toolkitngramtool-' from the start and '-select' from the end
    const key = router.currentRoute.value.name
        ?.toString()
        .replace(/^toolkitngramtool-/, '') // Remove the prefix
        .replace(/-select$/, '') // Remove the suffix

    const tab = tabLinks.value.find(tab => tab.key === key)

    if (!key || !tab) return tabLinks.value[0]

    return tab
})

const vpiPopoverOpen = ref(false)

const showBatchBar = computed(
    () =>
        currentStepIndex.value === 0 &&
        initialNgramSelectionCount.value > 0 &&
        !singleItemPanel.value &&
        !activePanel.value &&
        !campaignsPopoutOpen.value &&
        !filtersModalOpen.value
)

// Add Negatives Logic

const selectedSearchTermItems = ref<SearchTermItem[]>([])
const addNegativesInitiallySelectedItems = computed<PanelItem[]>(() => {
    if (addNegativesItemType.value === 'nGram') {
        return selectedNGramItems.value
    } else {
        return selectedSearchTermItems.value
    }
})

const handleAddSearchTermsAsNegatives = ($event: SearchTermItem[]) => {
    addNegativesItemType.value = 'searchTerm'
    selectedSearchTermItems.value = $event
}
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.campaigns-table-button {
    padding-left: 0.9375rem;
}

.ngram-cell,
:deep(.ngram-cell) {
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
    width: 100%;
    cursor: pointer;
    overflow: hidden;
}

:deep(.vpi-popout) {
    padding-left: 0.5rem;
    display: flex;
    align-items: center;
}

:deep(.nscore-popout-wrapper) {
    display: flex;
    align-items: center;
}

.select-ngram-buttons {
    display: grid;
    grid-template-columns: 240px auto 146px;
    gap: 0.75rem;
}

.nscore-cell,
.conflicts-cell,
.keyword-source-cell,
:deep(.nscore-cell, .conflicts-cell, .keyword-source-cell) {
    display: flex;
    align-items: center;
}
.conflicts-cell {
    position: relative;
    cursor: pointer;
}

.icon {
    @include container;
    width: 1.5rem;
    height: 1.5rem;
    @include br-999;
    @include flex-center;
}

.info-circle-icon,
:deep(.info-circle-icon) {
    width: 1rem;
    cursor: pointer;
}

.campaigns-button-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.panel-label-container {
    @include container;
    padding: 0.875rem 1rem;
}

.fixed-footer-wrapper {
    @include flex-center;
    gap: 2rem;
    margin: auto;
    @include fixed;
    @include bg-opteo-white;
    @include z-10;
    @include container;
    border-radius: 0;
    @include pv-32;
    width: 100vw;
    bottom: 0;
    left: 0;
}
.fixed-footer-container {
    @include br-16;
    @include ph-24;
}

.empty-state-container {
    @include container;
    @include br-24;
    @include flex-center;
    padding: 7rem 2rem;
    flex-direction: column;
    gap: 0.875rem;
}

.shift-away-enter-active,
.shift-away-leave-active {
    transition-property: transform, visibility, opacity;
    transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
    transition-duration: 0.3s;
}

.shift-away-enter-from,
.shift-away-leave-to {
    opacity: 0;
    transform: translate(-50%, 0rem);
}

:deep(.table-search-input) {
    height: 2.5rem;
    max-width: 11.25rem;
    @include relative;
}

:deep(.table-search-input input) {
    padding: 10px 14px 10px 36px;
}
:deep(.table-search-input::before) {
    content: '';
    position: absolute;
    left: 14px;
    top: 0;
    bottom: 0;
    width: 12px;
    background: url('@/assets/img/searchIcon.svg') center / contain no-repeat;
}

.ngrams-table-empty-state {
    @include flex-center;
    flex-direction: column;
    gap: 1.5rem;
    padding: 6rem 0;
}
</style>
