import { computed, ref, nextTick } from 'vue'
import sortBy from 'lodash-es/sortBy'

import { nestedScrollToSelector } from '@/lib/globalUtils'
import { Endpoint } from '@/composition/api/endpoints'
import { useAPI, authRequest } from '@/composition/api/useAPI'
import { Targets } from '@opteo/types'
import { showSnackbar } from '@opteo/components-next'
import { useAccount } from '../account/useAccount'

/* 
    selectedCampaigns is kept outside the composition function such that its state
    is shared across callers of useCampaignGroups()
*/
const selectedCampaigns = ref<Targets.CampaignGroupCampaign[]>([])

export function useCampaignGroups() {
    const { accountId, performanceMode, accountPlatform } = useAccount()

    /*
        Data setup & computed values
    */
    const {
        data: campaignGroups,
        mutate: mutateCampaignGroups,
        loading: campaignGroupsLoading,
        error: campaignGroupsError,
    } = useAPI<Targets.CampaignGroup[]>(Endpoint.GetCampaignGroups, {
        uniqueId: () => accountId.value,
        waitFor: () => accountId.value,
    })

    const showCampaignGroupsError = computed(
        () =>
            campaignGroupsError.value || (campaignGroups.value && campaignGroups.value.length === 0)
    )

    const orderedCampaignGroups = computed<Targets.CampaignGroup[]>(() =>
        sortBy(campaignGroups.value, c => {
            if (c.campaignGroupType === Targets.CampaignGroupType.EXCLUDED) return '᳄᳄᳄' // A character that is likely to be sorted very last. Also it looks cool.
            return c.name.toLowerCase()
        })
    )

    const mutateCampaignGroup = (campaignGroup?: Targets.CampaignGroup) => {
        if (!campaignGroup) {
            mutateCampaignGroups()
            return
        }
        const newCampainGroups = campaignGroups.value?.filter(cg => cg.id !== campaignGroup.id)

        if (!newCampainGroups) return

        newCampainGroups?.push(campaignGroup)

        mutateCampaignGroups(() => newCampainGroups)
    }

    const selectedCampaignsSorted = computed(() =>
        sortBy(selectedCampaigns.value, c => c.campaignName)
    )

    /*
        Moving campaigns across groups
    */
    const selectedGroup = ref<Targets.CampaignGroup>()
    const moveCampaignPopoutOpen = ref<boolean>(false)
    const movingCampaigns = ref<boolean>(false)

    const handleMoveToModal = () => {
        if (!campaignGroups.value) {
            throw new Error('cannot move campaigns before campaignGroups is set')
        }

        // TODO: find a better way to pick the default selected group
        selectedGroup.value = campaignGroups.value[0]

        moveCampaignPopoutOpen.value = true
    }

    const moveCampaigns = async () => {
        if (!campaignGroups.value) {
            throw new Error('cannot move campaigns before campaignGroups is set')
        }

        if (!selectedGroup.value) {
            throw new Error('cannot move campaigns without selecting a campaign group')
        }

        movingCampaigns.value = true

        const campaignGroupsName = selectedGroup.value.name
        const campaignGroupId = selectedGroup.value.id

        const originalCampaigns = selectedCampaigns.value.map(c => {
            const campaignGroupId = campaignGroups.value?.find(g =>
                g.campaigns.map(gc => gc.campaignId).includes(c.campaignId)
            )?.id

            return {
                campaignId: c.campaignId,
                campaignGroupId,
            }
        })

        const campaigns: {
            campaignId: number
            campaignGroupId: string
        }[] = selectedCampaigns.value.map(c => {
            return {
                campaignId: c.campaignId,
                campaignGroupId,
            }
        })

        await authRequest(Endpoint.MoveCampaignToGroup, {
            body: {
                campaigns,
            },
        })

        await mutateCampaignGroups()

        movingCampaigns.value = false

        clearCampaignSelection()
        moveCampaignPopoutOpen.value = false

        showSnackbar({
            message: `Campaign${campaigns.length > 1 ? 's' : ''} moved to ${campaignGroupsName}`,
            timeout: 5000,
            actionText: 'Undo',
            actionHandler: async () => {
                await authRequest(Endpoint.MoveCampaignToGroup, {
                    body: {
                        campaigns: originalCampaigns,
                    },
                })

                await mutateCampaignGroups()
            },
        })
    }

    const clearCampaignSelection = () => {
        selectedCampaigns.value = []
    }

    /*
        Reset Groups
    */
    const resetModalShown = ref(false)
    const resetButtonLoading = ref(false)

    const resetCampaignGroups = async () => {
        resetButtonLoading.value = true

        await authRequest(Endpoint.ResetCampaignGroups)

        await mutateCampaignGroups()

        resetModalShown.value = false
        resetButtonLoading.value = false
        clearCampaignSelection()

        await nextTick()

        const firstCampaignGroupId = campaignGroups.value?.[0].id
        nestedScrollToSelector(`#cg_${firstCampaignGroupId}`, 'auto')
    }

    /*
        Create New Group
    */
    const createNewGroupLoading = ref(false)
    const createNewGroup = async () => {
        if (createNewGroupLoading.value) return // Avoid button spamming

        if (!campaignGroups?.value) {
            throw new Error('cannot create new group before groups are loaded')
        }

        createNewGroupLoading.value = true

        try {
            const customGroupNumber =
                campaignGroups.value.filter(cg => cg.name.includes('Custom Group')).length + 1

            const campaignGroupName = `Custom Group ${customGroupNumber}`

            const campaignGroupId = await authRequest(Endpoint.CreateCampaignGroup, {
                body: { campaignGroupName },
            })

            await mutateCampaignGroups()

            nestedScrollToSelector(`#cg_${campaignGroupId}`, 'auto')
        } finally {
            createNewGroupLoading.value = false
        }
    }

    const deleteGroup = async (campaignGroupId: string) => {
        const group = campaignGroups.value?.find(g => g.id === campaignGroupId)

        if (!group || !campaignGroups.value)
            throw new Error("Couldn't find a campaign group to delete")

        if (group.campaigns.length > 0) {
            throw new Error('Please empty this group before deleting it')
        }

        mutateCampaignGroups(
            () => campaignGroups.value?.filter(g => g.id !== campaignGroupId) ?? []
        )

        await authRequest(Endpoint.DeleteCampaignGroup, {
            body: {
                campaignGroupId: group.id,
            },
        })
    }

    /*
        Performance Mode
    */
    const isUsingCpa = computed(
        () => !performanceMode.value || performanceMode.value === Targets.PerformanceMode.CPA
    )

    const isUsingRoas = computed(() => performanceMode.value === Targets.PerformanceMode.ROAS)

    // Table data
    const campaignTableHeaders = computed(() => [
        {
            key: 'campaignName',
            text: 'Campaign',
            sortable: false,
            noPadding: true,
        },
        {
            key: 'campaignType',
            text: 'Type',
            sortable: true,
            width: 128,
        },
        {
            key: 'campaignStrategy',
            text: 'Strategy',
            sortable: true,
            width: 148,
        },
        {
            key: 'cost',
            text: 'Cost',
            sortable: true,
            width: 120,
        },
        {
            key: isUsingCpa.value ? 'conversions' : 'conversionsValue',
            text: isUsingCpa.value ? 'Conv.' : 'Value',
            sortable: true,
            width: 116,
        },
        {
            key: isUsingCpa.value ? 'campaignCpa' : 'campaignRoas',
            text: isUsingCpa.value ? 'CPA' : 'ROAS',
            sortable: true,
            width: 128,
        },
    ])

    // Batch Bar
    const showBatchBar = computed(() => {
        return selectedCampaignsSorted.value.length > 0 ? true : false
    })

    return {
        campaignGroups,
        showCampaignGroupsError,
        orderedCampaignGroups,
        selectedCampaignsSorted,
        moveCampaignPopoutOpen,
        moveCampaigns,
        clearCampaignSelection,
        resetCampaignGroups,
        resetButtonLoading,
        createNewGroup,
        resetModalShown,
        campaignGroupsLoading,
        mutateCampaignGroup,
        selectedCampaigns,
        deleteGroup,
        isUsingCpa,
        isUsingRoas,
        campaignTableHeaders,
        showBatchBar,
        selectedGroup,
        handleMoveToModal,
        movingCampaigns,
        accountPlatform,
    }
}
