<template>
    <div v-if="loading">
        <!-- Domain Skeleton -->
        <div class="domain-info-container">
            <Skeleton :width="36" :height="36" />
            <Spacer width="1rem" />
            <Skeleton :width="120" />
        </div>
        <!-- Content Skeleton -->
        <Spacer height="2.125rem" />
        <Skeleton :width="168" :height="12" />
        <Spacer height="1.3125rem" />
        <Skeleton :width="240" :height="24" />
        <Spacer height="1.5rem" />
        <Skeleton :width="380" :height="12" />
        <Spacer height="0.5rem" />
        <Skeleton :width="350" :height="12" />
        <Spacer height="0.5rem" />
        <Skeleton :width="320" :height="12" />
        <Spacer height="1.625rem" />
        <Skeleton :width="380" :height="12" />
        <Spacer height="0.5rem" />
        <Skeleton :width="350" :height="12" />
        <Spacer height="0.5rem" />
        <Skeleton :width="320" :height="12" />
        <Spacer height="2.4375rem" />
        <div class="skeleton-graph-container" />
        <Spacer height="2.125rem" />
        <Skeleton :width="240" :height="24" />
        <Spacer height="2.125rem" />
        <div class="skeleton-graph-container" />
    </div>
    <div v-else>
        <!-- Domain -->
        <div class="domain-info-container">
            <Avatar :color="accountColour" :platform="platform" show-account-type>
                {{ accountInitials }}
            </Avatar>
            <Spacer width="1rem" />
            <Text :style="{ color: accountColour }" as="h6" size="f-7" weight="500">
                {{ accountName }}
            </Text>
        </div>
        <!-- Spacer -->
        <Spacer height="1.875rem" />
        <Text as="span" size="f-9" color="gray">{{ lastUpdated }}</Text>
        <!-- Spacer -->
        <Spacer height="1.25rem" />
        <!-- Alert body -->
        <component v-if="alert" :is="asyncComponent" :key="alert?.id" />
    </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

import { useAlert } from '@/composition/alerts/useAlert'
import upperFirst from 'lodash-es/upperFirst'
import camelCase from 'lodash-es/camelCase'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import { Spacer, Text, Avatar } from '@opteo/components-next'
import { Platform } from '@opteo/types'

import Budget from '@/components/Alerts/types/Budget.vue'
import OverBudget from '@/components/Alerts/types/OverBudget.vue'
import SmartBiddingExperiment from '@/components/Alerts/types/SmartBiddingExperiment.vue'
import Conversion from '@/components/Alerts/types/Conversion.vue'
import Delta from '@/components/Alerts/types/Delta.vue'
import Flatline from '@/components/Alerts/types/Flatline.vue'
import ScheduledReport from '@/components/Alerts/types/ScheduledReport.vue'
import Skeleton from '@/components/util/Skeleton.vue'

export default defineComponent({
    name: 'Alert',
    components: {
        Avatar,
        Spacer,
        Text,
        Skeleton,
    },
    setup() {
        const { alert, alertType } = useAlert()

        const platform = computed(() => alert.value?.platform ?? Platform.Platform.GoogleAds)
        const accountName = computed(() => alert.value?.accountName ?? '')
        const accountInitials = computed(() => alert.value?.accountInitials)
        const accountColour = computed(() => alert.value?.accountColour)

        const loading = computed(() => !alert.value)
        /*
            New alert types must be added here.
        */
        const alertComponents = {
            Budget,
            Conversion,
            Delta,
            Flatline,
            ScheduledReport,
            OverBudget,
            SmartBiddingExperiment,
        }

        const asyncComponent = computed(() => {
            const componentName = upperFirst(
                camelCase(alertType.value)
            ) as keyof typeof alertComponents

            return alertComponents[componentName]
        })

        const lastUpdated = computed(() => {
            if (!alert.value) {
                return ''
            }
            // format: January 18th 2022 at 5:40pm
            return format(parseISO(alert.value.ts), `MMMM do y 'at' h:mmaaa`)
        })

        return {
            loading,
            alert,
            asyncComponent,
            accountColour,
            accountInitials,
            accountName,
            lastUpdated,
            platform,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.domain-info-container {
    @include inline-flex;
    @include items-center;
    @include container;
    @include pv-20;
    @include ph-24;
}
.skeleton-graph-container {
    @include container;
    width: 100%;
    height: 200px;
}
</style>
