import some from 'lodash-es/some'
import { computed, inject, ref } from 'vue'

import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import {
    checkImprovement,
    OnPushHandler,
    useImprovement,
} from '@/composition/improvement/useImprovement'
import { ProvideKeys } from '@/composition/useProvide'
import { CurrencyCode } from '@/composition/utils/types'
import { formatEntityPillLink } from '@/lib/globalUtils'
import { extractIdFromResourceName } from '@/lib/globalUtils/extractIdFromResourceName'
import { useRoas } from '@opteo/components-next'
import { DemoBids, Entity, Improvement, Targets } from '@opteo/types'

import { getDemographicsStatistics, getDemographicsTableHeaders, sortDemos } from './utils'

import type { EntityPillLinkProps } from '@/components/global/Entity/types'

interface AdjustDemoBids {
    body: DemoBids.DemoBodies
    single: boolean
    entity: typeof Entity.EntityLocation.AdGroup | typeof Entity.EntityLocation.Campaign
}

enum InvalidBidReasonRaw {
    BID_CHANGE_IS_TOO_SMALL = 'BID_CHANGE_IS_TOO_SMALL',
    BID_IN_WRONG_DIRECTION = 'BID_IN_WRONG_DIRECTION',
    BUDGET_CAPPED_CONSTRAINTS = `WON'T_BID_UP_FOR_ALREADY_BUDGET_CONSTRAINED_CAMPAIGNS`,
    MINIMUM_CLICKS_NOT_MET = `MINIMUM_CLICKS_NOT_MET`,
    EXCLUDED = 'SEGMENT_EXCLUDED',
}

export enum InvalidBidReason {
    BID_IN_WRONG_DIRECTION_OUTPERFORMING = `No adjustment proposed because segment $PERFORMANCE_MODE is performing better than the parent campaign group Target $PERFORMANCE_MODE (<b>$TARGET</b>).`,
    BID_IN_WRONG_DIRECTION_UNDERPERFORMING = `No adjustment proposed because segment $PERFORMANCE_MODE is underperforming compared to your campaign group Target $PERFORMANCE_MODE (<b>$TARGET</b>).`,
    BUDGET_CAPPED_CONSTRAINTS = `No adjustment proposed because parent campaign is limited by budget.`,
    BID_CHANGE_IS_TOO_SMALL = 'No adjustment proposed because segment $PERFORMANCE_MODE is within 3% of average.',
    DEFAULT = 'No adjustment proposed due to insufficient <b>clicks</b> in segment.',
    DISABLED = 'Segment disabled.',
    EXCLUDED = 'Segment excluded.',
}

interface TableItems {
    [key: string]: any
}

enum TableFlags {
    NA = 'N/A',
}

type PlacementWithMocked<T> = T & { mocked?: boolean }

interface MissingDemo {
    placement: string
    criteria: number
    ad_group_placement_cost: number
    ad_group_placement_conversions: number
    ad_group_placement_impressions: number
    ad_group_placement_all_conversions_value: number
    ad_group_placement_cpa: number
    ad_group_placement_roas: number
    current_bid: number
    new_bid_mod: number
    invalid: boolean
    mocked: boolean
    reason: string
    is_exclude?: boolean
}

export function useAdjustDemoBids() {
    const { improvement, lastUpdated, title } = useImprovement<AdjustDemoBids['body']>()
    const { body, rec_action } = checkImprovement(improvement)
    const missingPlacements: MissingDemo[] = []
    const [generalPlacementData] = body
    const segment = generalPlacementData.demographic
    const accountId = improvement.value?.account_id

    const AGE_ENTITIES = [
        '18 to 24',
        '25 to 34',
        '35 to 44',
        '45 to 54',
        '55 to 64',
        '65+',
        'Unknown',
    ]
    const GENDER_ENTITIES = ['Male', 'Female', 'Unknown']
    const INCOME_ENTITIES = [
        'Lower 50%',
        '41-50%',
        '31-40%',
        '21-30%',
        '11-20%',
        'Top 10%',
        'Unknown',
    ]
    const PARENTAL_ENTITIES = ['Parent', 'Not a parent', 'Unknown']

    const calculateCPA = function ({ cost, conversions }: { cost: number; conversions: number }) {
        return cost ? cost / conversions : 0
    }

    const calculateROAS = function ({
        cost,
        conversionsValue,
    }: {
        cost: number
        conversionsValue: number
    }) {
        return conversionsValue ? conversionsValue / cost : 0
    }

    function fillMissingPlacements(body: DemoBids.DemoBodies) {
        let ENTITY_TYPE_TO_CHECK = AGE_ENTITIES
        if (segment === DemoBids.AllowedCriteriaTypes.GENDER) {
            ENTITY_TYPE_TO_CHECK = GENDER_ENTITIES
        } else if (segment === DemoBids.AllowedCriteriaTypes.INCOME) {
            ENTITY_TYPE_TO_CHECK = INCOME_ENTITIES
        } else if (segment === DemoBids.AllowedCriteriaTypes.PARENTAL_STATUS) {
            ENTITY_TYPE_TO_CHECK = PARENTAL_ENTITIES
        }

        const missingEntities = ENTITY_TYPE_TO_CHECK.filter(placement => {
            return !body.find(placement_data => {
                return placement === placement_data.placement
            })
        })

        if (missingEntities.length > 0) {
            missingEntities.forEach(missing => {
                const placement = {
                    placement: missing,
                    criteria: 0,
                    ad_group_placement_cost: 0,
                    ad_group_placement_conversions: 0,
                    ad_group_placement_impressions: 0,
                    ad_group_placement_all_conversions_value: 0,
                    ad_group_placement_cpa: 0,
                    ad_group_placement_roas: 0,
                    current_bid: 0,
                    new_bid_mod: 0,
                    invalid: true,
                    mocked: true,
                    reason: 'MISSING_IN_BODY',
                    is_exclude: false,
                }

                missingPlacements.push(placement)
            })
        }
    }

    fillMissingPlacements(body)

    const {
        ad_group_id,
        ad_group,
        campaign_name,
        campaign_id,
        demographic,
        performance_mode: performanceMode,
        ad_group_conversions,
        ad_group_cost,
        ad_group_conversions_value,
        campaign_group_cpa: campaignGroupCpa,
        campaign_group: campaign_group_name,
        target_set,
        target,
    } = generalPlacementData as DemoBids.HighVolumeBody

    const isUsingROAS = performanceMode === Targets.PerformanceMode.ROAS

    const single = rec_action === Improvement.RecAction.AdjustSinglePlacementDemoBid

    const singleSegmentBidMod = single
        ? body.find(placement => !placement.invalid)
        : generalPlacementData

    function instanceOfAdGroup(object: any): object is DemoBids.HighVolumeBody {
        return !object?.items_in_placement
    }
    function instanceOfCampaign(object: any): object is DemoBids.LowVolumeBody {
        return object?.items_in_placement
    }

    const entity = ad_group_id ? Entity.EntityLocation.AdGroup : Entity.EntityLocation.Campaign

    const entityLabel = entity === 'adgroup' ? 'ad group' : 'campaign'

    const entityName = entity === 'adgroup' ? ad_group : campaign_name

    const deepLinkParams: EntityPillLinkProps<typeof entity>['deepLinkParams'] =
        entity === 'adgroup'
            ? {
                  campaignId: campaign_id,
                  adGroupId: ad_group_id,
              }
            : {
                  campaignId: campaign_id,
              }

    const entityPillLink = formatEntityPillLink({
        entityPillData: {
            type: entity,
            content: entityName,
        },
        deepLinkParams,
    })

    const avgAdGroupCpa = calculateCPA({ cost: ad_group_cost, conversions: ad_group_conversions })
    const avgAdGroupROAS = calculateROAS({
        cost: ad_group_cost,
        conversionsValue: ad_group_conversions_value,
    })

    const demographicLabel = demographic.replace('_', ' ')

    const entityPillLinkList = instanceOfCampaign(generalPlacementData)
        ? generalPlacementData?.items_in_placement?.map(adGroupCriteria => {
              return formatEntityPillLink({
                  entityPillData: {
                      type: 'adgroup',
                      content: adGroupCriteria.name,
                  },
                  deepLinkParams: {
                      campaignId: campaign_id,
                      adGroupId: extractIdFromResourceName(
                          adGroupCriteria.resource_name,
                          Entity.EntityLocation.Demographic
                      ),
                  },
              })
          })
        : []

    const sortedBody = sortDemos(segment, body, missingPlacements)

    const dynamicSegmentColumn = `column.${segment}`

    const segmentLabel = computed(() => {
        switch (segment) {
            case 'age':
                return 'Age Group'
            case 'gender':
                return 'Gender'
            case 'income':
                return 'Income'
            case 'parental_status':
                return 'Parental Status'
            default:
                break
        }
    })

    const tableHeaders = getDemographicsTableHeaders({
        performanceMode,
        segment,
        segmentLabel,
        isUsingROAS,
        recAction: rec_action,
    })

    const highlightedSegment = single ? singleSegmentBidMod?.placement : ''

    const newBidRaw = single ? singleSegmentBidMod?.new_bid_mod : 1

    const currentBidMod =
        entity === 'adgroup' && single && instanceOfAdGroup(singleSegmentBidMod)
            ? singleSegmentBidMod?.current_bid ?? 1 - 1
            : 1

    const newBidMod = single
        ? singleSegmentBidMod?.new_bid_mod ?? 1 - 1
        : generalPlacementData.new_bid_mod - 1

    const proposeIncreaseBid = newBidMod > currentBidMod

    const usingTarget = generalPlacementData.target_set

    let donutChartItems = sortedBody.map(
        (placement_data: PlacementWithMocked<(typeof sortedBody)[0]>) => {
            return {
                y: !placement_data.mocked ? placement_data.ad_group_placement_cost! : 0,
                label: placement_data.placement,
                highlighted: single && placement_data?.placement === singleSegmentBidMod?.placement,
            }
        }
    )

    const adjustTableHeaders = [
        { key: segment, text: segmentLabel.value, sortable: false },
        { key: 'cost', text: 'Cost', sortable: true },
        performanceMode === Targets.PerformanceMode.ROAS
            ? { key: 'conversions_value', text: 'Value', sortable: true, width: 104 }
            : { key: 'conversions', text: 'Conv.', sortable: true, width: 96 },
        performanceMode === Targets.PerformanceMode.ROAS
            ? { key: 'roas', text: 'ROAS', sortable: true }
            : { key: 'cpa', text: 'CPA', sortable: true },
        performanceMode === Targets.PerformanceMode.ROAS
            ? { key: 'avg-roas', text: 'Avg. ROAS', sortable: false }
            : { key: 'avg-cpa', text: 'Avg. CPA', sortable: false },
        performanceMode === Targets.PerformanceMode.ROAS
            ? { key: 'difference_roas', text: 'Diff.', sortable: true, width: 100 }
            : { key: 'difference_cpa', text: 'Diff.', sortable: true, width: 100 },
        {
            key: 'bid-adjustment',
            text: 'Bid Adj.',
            sortable: false,
            width: isUsingROAS ? 132 : 114,
        },
    ]

    const domainCurrencyCode = inject<CurrencyCode>(ProvideKeys.CurrencyCode)

    const segmentCpa = singleSegmentBidMod
        ? calculateCPA({
              cost: singleSegmentBidMod.ad_group_placement_cost,
              conversions: singleSegmentBidMod.ad_group_placement_conversions,
          })
        : 0
    const segmentROAS = singleSegmentBidMod
        ? calculateROAS({
              cost: singleSegmentBidMod.ad_group_placement_cost,
              conversionsValue: singleSegmentBidMod.ad_group_placement_all_conversions_value,
          })
        : 0

    const campaignGroupName = campaign_group_name
    const targetCpa = target
    const targetROAS = target

    const { displayValue: formattedTargetCpa } = useDomainMoney({
        value: targetCpa,
    }).value

    const formattedTargetROAS = useRoas({
        value: targetROAS,
    }).displayValue.value

    const segmentToAdGroupPerformanceRatio =
        performanceMode === Targets.PerformanceMode.ROAS
            ? avgAdGroupROAS > 0
                ? Math.abs(segmentROAS - avgAdGroupROAS) / avgAdGroupROAS
                : 0
            : avgAdGroupCpa > 0
            ? Math.abs(segmentCpa - avgAdGroupCpa) / avgAdGroupCpa
            : 0

    const segmentToCampaignGroupPerformanceRatio =
        performanceMode === Targets.PerformanceMode.CPA
            ? Math.abs(segmentCpa - target) / target
            : Math.abs(segmentROAS - target) / target

    function mapInvalidReasons(
        performanceMode: Targets.PerformanceMode,
        rawReason: string,
        targetSet: boolean,
        comparisonCPA: number,
        comparisonROAS: number,
        placementCPA: number,
        placementROAS: number,
        mocked: boolean
    ) {
        if (mocked) {
            return { reason: InvalidBidReason.DISABLED, width: 124 }
        }
        if (rawReason === InvalidBidReasonRaw.EXCLUDED) {
            return { reason: InvalidBidReason.EXCLUDED, width: 124 }
        }
        if (rawReason === InvalidBidReasonRaw.BID_IN_WRONG_DIRECTION && targetSet) {
            if (performanceMode === Targets.PerformanceMode.CPA) {
                if (placementCPA < comparisonCPA) {
                    return {
                        reason: InvalidBidReason.BID_IN_WRONG_DIRECTION_UNDERPERFORMING.replace(
                            '$TARGET',
                            formattedTargetCpa.value
                        ).replaceAll('$PERFORMANCE_MODE', 'CPA'),
                        width: 256,
                    }
                }

                return {
                    reason: InvalidBidReason.BID_IN_WRONG_DIRECTION_OUTPERFORMING.replace(
                        '$TARGET',
                        formattedTargetCpa.value
                    ).replaceAll('$PERFORMANCE_MODE', 'CPA'),
                    width: 256,
                }
            } else if (performanceMode === Targets.PerformanceMode.ROAS) {
                if (placementROAS > comparisonROAS) {
                    return {
                        reason: InvalidBidReason.BID_IN_WRONG_DIRECTION_UNDERPERFORMING.replace(
                            '$TARGET',
                            formattedTargetROAS
                        ).replaceAll('$PERFORMANCE_MODE', 'ROAS'),
                        width: 270,
                    }
                }

                return {
                    reason: InvalidBidReason.BID_IN_WRONG_DIRECTION_OUTPERFORMING.replace(
                        '$TARGET',
                        formattedTargetROAS
                    ).replaceAll('$PERFORMANCE_MODE', 'ROAS'),
                    width: 270,
                }
            }
        }
        if (rawReason === InvalidBidReasonRaw.BID_CHANGE_IS_TOO_SMALL) {
            return {
                reason: InvalidBidReason.BID_CHANGE_IS_TOO_SMALL.replaceAll(
                    '$PERFORMANCE_MODE',
                    'ROAS'
                ),
                width: 160,
            }
        }
        if (rawReason === InvalidBidReasonRaw.BUDGET_CAPPED_CONSTRAINTS) {
            return { reason: InvalidBidReason.BUDGET_CAPPED_CONSTRAINTS, width: 169 }
        }

        return { reason: InvalidBidReason.DEFAULT, width: 200 }
    }

    let tableItems = sortedBody.map((placement: PlacementWithMocked<(typeof sortedBody)[0]>) => {
        const placementCPA = !placement.mocked
            ? calculateCPA({
                  cost: placement.ad_group_placement_cost,
                  conversions: placement.ad_group_placement_conversions,
              })
            : 0

        const placementROAS = !placement.mocked
            ? calculateROAS({
                  cost: placement.ad_group_placement_cost,
                  conversionsValue: placement.ad_group_placement_all_conversions_value,
              })
            : 0

        const entityToCompareCPA = avgAdGroupCpa
        const entityToCompareROAS = avgAdGroupROAS

        const { reason, width } = mapInvalidReasons(
            performanceMode,
            placement.reason,
            target_set,
            entityToCompareCPA,
            entityToCompareROAS,
            placementCPA,
            placementROAS,
            placement.mocked ?? false
        )

        return {
            id: placement.placement,
            [`${segment}`]: placement.placement,
            cost: placement.ad_group_placement_cost,
            conversions: placement.ad_group_placement_conversions,
            conversions_value: placement.ad_group_placement_all_conversions_value,
            cpa: placementCPA,
            roas: placementROAS,
            'avg-cpa': entityToCompareCPA,
            'avg-roas': entityToCompareROAS,
            difference_cpa: calculateDifferencePercentage({
                entity: placementCPA,
                environment: entityToCompareCPA,
            }),
            difference_roas: calculateDifferencePercentage({
                entity: placementROAS,
                environment: entityToCompareROAS,
            }),
            'bid-adjustment': {
                value: !placement.mocked ? placement.new_bid_mod - 1 : TableFlags.NA,
                id: placement.criteria,
                invalid: placement.invalid,
                width,
                reason,
                current: (instanceOfAdGroup(placement) ? placement?.current_bid : 1) - 1,
            },
        }
    })

    function calculateDifferencePercentage({
        entity,
        environment,
    }: {
        entity: number
        environment: number
    }) {
        return (entity - environment) / environment
    }

    let adjustTableItems: TableItems = sortedBody.map(
        (placement: PlacementWithMocked<(typeof sortedBody)[0]>) => {
            const na_flag =
                placement.ad_group_placement_cost === 0 &&
                ((!isUsingROAS && placement.ad_group_placement_conversions === 0) ||
                    (isUsingROAS && placement.ad_group_placement_all_conversions_value === 0))

            const placementCPA = !na_flag
                ? !placement.mocked
                    ? calculateCPA({
                          cost: placement.ad_group_placement_cost,
                          conversions: placement.ad_group_placement_conversions,
                      })
                    : 0
                : TableFlags.NA
            const placementROAS = !na_flag
                ? !placement.mocked
                    ? calculateROAS({
                          cost: placement.ad_group_placement_cost,
                          conversionsValue: placement.ad_group_placement_all_conversions_value,
                      })
                    : 0
                : TableFlags.NA
            const entityToCompareCPA = avgAdGroupCpa
            const entityToCompareROAS = avgAdGroupROAS

            const { reason, width } = mapInvalidReasons(
                performanceMode,
                placement.reason,
                target_set,
                entityToCompareCPA,
                entityToCompareROAS,
                placementCPA === TableFlags.NA ? 0 : placementCPA,
                placementROAS === TableFlags.NA ? 0 : placementROAS,
                placement.mocked ?? false
            )
            return {
                id: placement.placement,
                [`${segment}`]: placement.placement,
                cost: placement.ad_group_placement_cost,
                conversions: placement.ad_group_placement_conversions,
                conversions_value: placement.ad_group_placement_all_conversions_value,
                cpa: placementCPA,
                roas: placementROAS,
                'avg-cpa': entityToCompareCPA,
                'avg-roas': entityToCompareROAS,
                difference_cpa: !placement?.mocked
                    ? placementCPA !== 0 && entityToCompareCPA !== 0
                        ? calculateDifferencePercentage({
                              entity: placementCPA === TableFlags.NA ? 0 : placementCPA,
                              environment: entityToCompareCPA,
                          })
                        : TableFlags.NA
                    : 'disabled',
                difference_roas: !placement?.mocked
                    ? placementROAS !== 0 && entityToCompareROAS !== 0
                        ? calculateDifferencePercentage({
                              entity: placementROAS === TableFlags.NA ? 0 : placementROAS,
                              environment: entityToCompareROAS,
                          })
                        : TableFlags.NA
                    : 'disabled',
                'bid-adjustment': {
                    value: !placement.invalid ? placement.new_bid_mod - 1 : 0,
                    id: placement.criteria,
                    invalid: placement.invalid,
                    width,
                    reason,
                },
            }
        }
    )

    const showSegmentCpa = computed(() => entity === 'adgroup' || entity === 'campaign')
    const showAdgroupCpa = computed(() => entity === 'adgroup')
    const showAvgAdgroupCpa = computed(() => entity === 'campaign')

    const showCampaignGroupCpa = computed(
        () => (entity === 'adgroup' && usingTarget) || (entity === 'campaign' && usingTarget)
    )

    const { displayValue: formattedSegmentCpa } = useDomainMoney({
        value: segmentCpa,
    }).value

    const formattedSegmentROAS = useRoas({
        value: segmentROAS,
    }).displayValue.value

    const { displayValue: formattedAdGroupCpa } = useDomainMoney({
        value: avgAdGroupCpa,
    }).value

    const formattedAdGroupROAS = useRoas({
        value: avgAdGroupROAS,
    }).displayValue.value

    const { displayValue: formattedAvgAdGroupsCpa } = useDomainMoney({
        value: avgAdGroupCpa,
    }).value

    const formattedAvgAdGroupsROAS = useRoas({
        value: avgAdGroupROAS,
    }).displayValue.value

    const comparisonCpaLabel = 'average ad group'
    const formattedComparisonCpa =
        entity === 'campaign' ? formattedAvgAdGroupsCpa : formattedAdGroupCpa

    const formattedComparisonROAS =
        entity === 'campaign' ? formattedAvgAdGroupsROAS : formattedAdGroupROAS

    const improvementStatisticsItems = computed(() =>
        getDemographicsStatistics(
            showSegmentCpa,
            performanceMode === Targets.PerformanceMode.ROAS
                ? formattedSegmentROAS
                : formattedSegmentCpa.value,
            showAdgroupCpa,
            performanceMode === Targets.PerformanceMode.ROAS
                ? formattedAdGroupROAS
                : formattedAdGroupCpa.value,
            showAvgAdgroupCpa,
            performanceMode === Targets.PerformanceMode.ROAS
                ? formattedAvgAdGroupsROAS
                : formattedAvgAdGroupsCpa.value,
            showCampaignGroupCpa,
            performanceMode === Targets.PerformanceMode.ROAS
                ? formattedTargetROAS
                : formattedTargetCpa.value,
            performanceMode
        )
    )

    const lookback_window = generalPlacementData.window

    const pushActionText = ref(single ? 'Apply Bid Adjustment' : 'Apply Bid Adjustments')
    const plural = single ? '' : 's'
    const pushMessages = computed(() => [
        `Connecting to Google Ads..`,
        `Applying demographic bid adjustment${plural}..`,
        `Confirming changes..`,
        `Bid adjustment${plural} applied successfully.`,
    ])

    let extraDetails: { resource_name: string; cpc_bid: number }[] = []

    const onReset = () => {
        extraDetails = []
        formatExtraDetails()
    }

    /* Single Entity Improvement Only */
    const updateBid = (value: number) => {
        if (entity === 'adgroup' && instanceOfAdGroup(singleSegmentBidMod)) {
            extraDetails = [{ resource_name: singleSegmentBidMod?.resource_name, cpc_bid: value }]

            return
        } else if (instanceOfCampaign(singleSegmentBidMod)) {
            extraDetails = singleSegmentBidMod?.items_in_placement.map(ad_group_data => {
                return { resource_name: ad_group_data?.resource_name, cpc_bid: +value.toFixed(2) }
            })
        }
    }

    formatExtraDetails()

    function formatCampaignLevelMultiExtraDetails() {
        extraDetails = []
        body.forEach(placement_data => {
            if (placement_data.invalid) {
                return
            }
            const { items_in_placement, new_bid_mod } = placement_data as DemoBids.LowVolumeBody
            return items_in_placement.forEach(ad_group_data => {
                extraDetails.push({
                    resource_name: ad_group_data.resource_name,
                    cpc_bid: +new_bid_mod.toFixed(2),
                })
            })
        })
    }

    function formatAdGroupLevelMultiExtraDetails() {
        extraDetails = []
        body.forEach(ad_group_data => {
            if (ad_group_data.invalid) {
                return
            }
            if (instanceOfAdGroup(ad_group_data)) {
                extraDetails.push({
                    resource_name: ad_group_data.resource_name,
                    cpc_bid: +ad_group_data.new_bid_mod.toFixed(2),
                })
            }
        })
    }

    function formatExtraDetails() {
        if (entity === 'adgroup') {
            formatAdGroupLevelMultiExtraDetails()
            return
        }
        formatCampaignLevelMultiExtraDetails()
    }

    const updateMultiplePlacements = (value: { id: string; value: number }) => {
        const { id, value: new_bid_mod } = value
        const formattedNewBidMod = 1 + new_bid_mod
        const roundedBid = formattedNewBidMod.toFixed(2)

        if (entity === 'adgroup') {
            const entity_to_mod = body.find(
                placement_data => +placement_data.criteria === +id
            ) as DemoBids.HighVolumeBody

            const extra_details_to_mod = extraDetails.find(extra_details => {
                const { criteria } = entity_to_mod
                return +extra_details.resource_name.split('~')[1] === +criteria
            })

            if (!extra_details_to_mod) {
                extraDetails.push({
                    resource_name: entity_to_mod?.resource_name,
                    cpc_bid: +roundedBid,
                })
                return
            }

            extra_details_to_mod.cpc_bid = +roundedBid
            return
        }

        const entity_criteria_to_mod = body.find(
            placement_data => +placement_data.criteria === +id
        ) as DemoBids.LowVolumeBody

        const extra_details_to_mod = extraDetails.filter(extra_details => {
            const { criteria } = entity_criteria_to_mod
            const criteriaMatchInExtraDetails = extra_details?.resource_name.split('~')[1]
            if (!criteriaMatchInExtraDetails) {
                return false
            }
            return +criteriaMatchInExtraDetails === criteria
        })

        if (extra_details_to_mod.length === 0) {
            entity_criteria_to_mod.items_in_placement.forEach(ad_group_data => {
                extraDetails.push({
                    resource_name: ad_group_data.resource_name,
                    cpc_bid: +roundedBid,
                })
            })
        }

        extra_details_to_mod.forEach(extra_detail => {
            extra_detail.cpc_bid = +roundedBid
        })
    }

    function validate(extraDetails: { resource_name: string; cpc_bid: number }[]) {
        return !some(extraDetails, ed => {
            const { cpc_bid: newBidMod, resource_name: resourceName } = ed
            /* Most Checks Already Handled By Component */
            return newBidMod < 0 || !resourceName
        })
    }

    const onPush: OnPushHandler = () => {
        let valid = true

        if (extraDetails) {
            valid = validate(extraDetails)
        }

        return {
            valid,
            pushedData: extraDetails,
        }
    }

    return {
        dynamicSegmentColumn,
        title,
        accountId,
        entity,
        entityLabel,
        entityName,
        entityPillLink,
        entityPillLinkList,
        demographic,
        demographicLabel,
        highlightedSegment,
        tableHeaders,
        tableItems,
        donutChartItems,
        domainCurrencyCode,
        proposeIncreaseBid,
        newBidMod,
        segmentToAdGroupPerformanceRatio,
        segmentROAS,
        avgAdGroupROAS,
        targetROAS,
        formattedSegmentCpa,
        formattedAdGroupCpa,
        formattedAvgAdGroupsCpa,
        formattedTargetCpa,
        formattedTargetROAS,
        formattedSegmentROAS,
        formattedAdGroupROAS,
        formattedAvgAdGroupsROAS,
        updateBid,
        onReset,
        updateMultiplePlacements,
        newBidRaw,
        segmentToCampaignGroupPerformanceRatio,
        improvementStatisticsItems,
        adjustTableHeaders,
        adjustTableItems,
        lookbackWindow: lookback_window,
        pushMessages,
        onPush,
        lastUpdated,
        campaignGroupCpa,
        avgAdGroupCpa,
        segmentCpa,
        useDomainMoney,
        performanceMode,
        isUsingROAS,
        Targets,
        pushActionText,
    }
}
