import { computed, Ref, ref } from 'vue'
import {
    OnPushHandler,
    UseImprovement,
    useImprovement,
    checkImprovement,
} from '@/composition/improvement/useImprovement'

import { useDomainMoney } from '@/composition/domain/useDomainMoney'

import { AreaChartTypes, useNumber, usePercent } from '@opteo/components-next'

import { Entity, Improvement, IncreaseKeywordBidV2 } from '@opteo/types'
import { format as formatDate, sub } from 'date-fns'
import { useDomain } from '@/composition/domain/useDomain'

import type { ImprovementStatistic } from '.'
import { EntityPillLinkProps, ValidEntity } from '@/components/global/Entity/types'
import { formatEntityPillLink, filterLocationsByEntityType } from '@/lib/globalUtils'

interface UseIncreaseKeywordBidV2 {
    formattedKeyword: string
    keywordEntityIds: EntityPillLinkProps<'keyword'>['deepLinkParams']
    bidChange: number
    currentBid: number
    adjustedBid: Ref<number>
    currencySymbol: Ref<string>
    formattedCurrentBid: string
    newBid: number
    formattedNewBid: string
    firstPageBid: number
    onBidUpdate: (updatedBid: number) => void
    entityPillList: EntityPillLinkProps<ValidEntity>[]
    impBidAdjustmentRef: Ref
    improvementStatsItems: ImprovementStatistic[]
    searchImpressionShare: number
    keywordQualityScore: number
    keywordClicks: number
    keywordImpressions: number
    keywordCost: number
    lookbackWindow: number
    hasNoImpressions: boolean
    costPerClick: number | null
    impressionSeries?: AreaChartTypes.AreaChartSeries[]
    imprShareSeries?: AreaChartTypes.AreaChartSeries[]
    formattedStartDate: string
    formattedEndDate: string
    handleBidUpdate: () => void
    resetBid: () => void
}

export function useIncreaseKeywordBidV2(): UseImprovement<UseIncreaseKeywordBidV2> {
    const { improvement, lastUpdated, title } = useImprovement<
        IncreaseKeywordBidV2.Body,
        typeof Improvement.RecAction.IncreaseKeywordBidV2
    >()

    const {
        body: {
            new_bid: newBid,
            keyword_metrics: {
                cpc_bid: currentBid,
                clicks: keywordClicks,
                cost: keywordCost,
                impressions: keywordImpressions,
                quality_score: keywordQualityScore,
            },
            formatted_keyword: formattedKeyword,
            search_impression_share: searchImpressionShare,
            first_page_cpc: firstPageBid,
            impression_series: impressionSeries,
            impr_share_series: imprShareSeries,
            bid_change: bidChange,
            lookback_window: lookbackWindow,
        },
        location,
    } = checkImprovement(improvement)

    // Ensure bid is formatted to two decimal places + type `number`
    const adjustedBid = ref(Math.round(newBid * 100) / 100)

    const entityPillList = location.map(loc => {
        return formatEntityPillLink({
            entityPillData: { type: loc.entity, content: loc.label },
            deepLinkParams: loc.entityIds,
        })
    })
    const keywordEntity = filterLocationsByEntityType(location, Entity.EntityLocation.Keyword)
    const keywordEntityIds = keywordEntity.entityIds

    function onBidUpdate(updatedBid: number) {
        adjustedBid.value = updatedBid
    }

    function handleBidUpdate() {
        pushActionText.value =
            isUpdatedBidIncreasing.value === true ? 'Increase Keyword Bid' : 'Decrease Keyword Bid'
    }

    function resetBid() {
        adjustedBid.value = Math.round(newBid * 100) / 100
        handleBidUpdate()
    }

    // Recommended Bid Adjustment
    const formattedCurrentBid = useDomainMoney({ value: currentBid }).value.displayValue.value
    const formattedNewBid = useDomainMoney({ value: newBid }).value.displayValue.value

    const improvementStatsItems: ImprovementStatistic[] = [
        {
            key: 'firstPageBid',
            value: useDomainMoney({ value: firstPageBid }).value.displayValue.value,
            title: 'First Page Bid',
        },
        {
            key: 'cost',
            value: useDomainMoney({ value: keywordCost }).value.displayValue.value,
            title: 'Cost',
        },
        {
            key: 'clicks',
            value: useNumber({ value: keywordClicks }).displayValue.value,
            title: keywordClicks === 1 ? 'Click' : 'Clicks',
        },
        {
            key: 'impressions',
            value: useNumber({ value: keywordImpressions }).displayValue.value,
            title: keywordImpressions === 1 ? 'Impression' : 'Impressions',
        },
        {
            key: 'qs',
            value: useNumber({ value: keywordQualityScore }).displayValue.value,
            title: 'Quality Score',
        },
        {
            key: 'searchImprShare',
            value: usePercent({ value: searchImpressionShare }).displayValue.value,
            title: 'Seach Impression Share',
        },
    ]

    const costPerClick = keywordCost > 0 ? keywordCost / keywordClicks : null

    if (costPerClick) {
        improvementStatsItems.splice(3, 0, {
            key: 'cpc',
            value: useDomainMoney({ value: costPerClick }).value.displayValue.value,
            title: 'CPC',
        })
    }

    /*
        Adjustment form and pushing bits
    */
    const adjustedBidChange = computed(() => ((adjustedBid.value - currentBid) / currentBid) * 100)
    const isUpdatedBidIncreasing = computed(() => adjustedBid.value > currentBid)

    const pushActionText = ref(
        isUpdatedBidIncreasing.value === true ? 'Increase Keyword Bid' : 'Decrease Keyword Bid'
    )

    // Get currencySymbol for input
    const { currencySymbol } = useDomain()

    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Adjusting keyword bid..',
        'Confirming changes..',
        'Keyword bid adjusted succesfully.',
    ])

    const impBidAdjustmentRef = ref()

    const hasNoImpressions = keywordImpressions === 0

    const onPush: OnPushHandler<IncreaseKeywordBidV2.ExtraDetails> = () => {
        const pushedData = {
            new_bid: adjustedBid.value,
        }

        const valid =
            typeof adjustedBidChange.value === 'number' &&
            (impBidAdjustmentRef.value ? impBidAdjustmentRef.value.valid : true)

        return {
            valid,
            pushedData,
        }
    }

    const formattedStartDate = formatDate(sub(new Date(), { days: lookbackWindow }), 'do MMM')
    const formattedEndDate = formatDate(sub(new Date(), { days: 1 }), 'do MMM')

    return {
        title,
        pushMessages,
        onPush,
        entityPillList,
        lastUpdated,
        currentBid,
        bidChange,
        formattedCurrentBid,
        newBid,
        formattedNewBid,
        improvementStatsItems,
        adjustedBid,
        currencySymbol,
        firstPageBid,
        searchImpressionShare,
        keywordQualityScore,
        keywordClicks,
        keywordImpressions,
        lookbackWindow,
        formattedKeyword,
        keywordEntityIds,
        keywordCost,
        onBidUpdate,
        impBidAdjustmentRef,
        hasNoImpressions,
        costPerClick,
        impressionSeries,
        imprShareSeries,
        formattedStartDate,
        formattedEndDate,
        pushActionText,
        handleBidUpdate,
        resetBid,
    }
}
