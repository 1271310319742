import { computed, ComputedRef, ref, Ref } from 'vue'
import { usePercent } from '@opteo/components-next'
import type { EntityPillProps } from '@opteo/components-next'
import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import {
    OnPushHandler,
    UseImprovement,
    useImprovement,
    checkImprovement,
} from '@/composition/improvement/useImprovement'
import { Entity } from '@opteo/types'

enum MatchType {
    BROAD = 'Broad',
    EXACT = 'Exact',
    PHRASE = 'Phrase',
}

interface BodyKeyword {
    keyword_id: string
    impressions: number
    clicks: number
    cost: number
    keyword: string
    match_type: MatchType
    campaign_id: string
    campaign: string
    adgroup_id: string
    adgroup: string
    quality_score: number
    ctr: number
    final_url: string
    ad_types: string
    normalized_keyword: string
    desktop_bid_adj: string
    mobile_bid_adj: string
    tablet_bid_adj: string
    winning?: boolean
    keyword_text_mt: string
}

interface Body {
    keyword_text: string
    better_keyword: BodyKeyword
    worse_keyword: BodyKeyword
}

type Keyword = { keyword: string; campaign: string; adGroup: string }
type KeywordStats = { title: string; content: string; color: 'green' | 'red' }
type TableHeader = { key: string; text: string }
type TableItem = { id: string; metric: string; winner: string; loser: string }

interface UsePauseDuplicateKeywords {
    entityPillList: EntityPillProps[]
    affectLosingDuplicate: Ref<boolean>
    actionCopy: ComputedRef<{
        infinitive: string
        present: string
        past: string
    }>
    keywordCopy: ComputedRef<string>
    winningKeyword: Keyword
    winningKeywordStats: KeywordStats[]
    losingKeyword: Keyword
    losingKeywordStats: KeywordStats[]
    tableHeaders: TableHeader[]
    tableItems: TableItem[]
}

function formatKeyword({ keyword, matchType }: { keyword: string; matchType: MatchType }): string {
    if (matchType === MatchType.PHRASE) {
        return `"${keyword}"`
    } else if (matchType === MatchType.EXACT) {
        return `[${keyword}]`
    } else {
        return keyword
    }
}

export function usePauseDuplicateKeywords(): UseImprovement<UsePauseDuplicateKeywords> {
    const { improvement, lastUpdated, title } = useImprovement<Body>()

    const {
        body: {
            keyword_text: keyword,
            better_keyword: {
                keyword: winningKeywordText,
                match_type: winningKeywordMatchType,
                campaign: winningKeywordCampaignName,
                adgroup: winningKeywordAdGroupName,
                clicks: winningKeywordClicks,
                cost: winningKeywordCost,
                ctr: winningKeywordCTR,
                impressions: winningKeywordImpressions,
                quality_score: winningKeywordQS,
            },
            worse_keyword: {
                keyword: losingKeywordText,
                match_type: losingKeywordMatchType,
                campaign: losingKeywordCampaignName,
                adgroup: losingKeywordAdGroupName,
                clicks: losingKeywordClicks,
                cost: losingKeywordCost,
                ctr: losingKeywordCTR,
                impressions: losingKeywordImpressions,
                quality_score: losingKeywordQS,
            },
        },
    } = checkImprovement(improvement)

    const affectLosingDuplicate = ref(true) // false = affecting winning duplicate

    const actionCopy = computed(() => {
        return {
            infinitive: 'Pause',
            present: 'Pausing',
            past: 'Paused',
        }
    })

    const keywordCopy = computed(() => (affectLosingDuplicate.value ? 'Losing' : 'Winning'))

    // KEYWORDS

    const winningKeyword: Keyword = {
        keyword: formatKeyword({ keyword: winningKeywordText, matchType: winningKeywordMatchType }),
        campaign: winningKeywordCampaignName,
        adGroup: winningKeywordAdGroupName,
    }

    const winningKeywordCTRPercent = usePercent({ value: winningKeywordCTR / 100 }).displayValue
        .value
    const winningKeywordCostMoney = useDomainMoney({ value: winningKeywordCost }).value.displayValue
        .value

    const winningKeywordStats: KeywordStats[] = [
        { title: 'Clicks', content: winningKeywordClicks.toString(), color: 'green' },
        { title: 'Cost', content: winningKeywordCostMoney, color: 'green' },
        { title: 'CTR', content: winningKeywordCTRPercent, color: 'green' },
        {
            title: 'Quality Score',
            content: winningKeywordQS > 0 ? winningKeywordQS.toString() : 'N/A',
            color: 'green',
        },
    ]

    const losingKeyword: Keyword = {
        keyword: formatKeyword({ keyword: losingKeywordText, matchType: losingKeywordMatchType }),
        campaign: losingKeywordCampaignName,
        adGroup: losingKeywordAdGroupName,
    }

    const losingKeywordCTRPercent = usePercent({ value: losingKeywordCTR / 100 }).displayValue.value
    const losingKeywordCostMoney = useDomainMoney({ value: losingKeywordCost }).value.displayValue
        .value

    const losingKeywordStats: KeywordStats[] = [
        { title: 'Clicks', content: losingKeywordClicks.toString(), color: 'red' },
        { title: 'Cost', content: losingKeywordCostMoney, color: 'red' },
        { title: 'CTR', content: losingKeywordCTRPercent, color: 'red' },
        {
            title: 'Quality Score',
            content: losingKeywordQS > 0 ? losingKeywordQS.toString() : 'N/A',
            color: 'red',
        },
    ]

    // TABLE

    const tableHeaders: TableHeader[] = [
        { key: 'metric', text: 'Metric' },
        { key: 'winner', text: 'Winning Duplicate' },
        { key: 'loser', text: 'Losing Duplicate' },
    ]

    const tableItems: TableItem[] = [
        {
            id: 'ctr',
            metric: 'Click Through Rate',
            winner: winningKeywordCTRPercent,
            loser: losingKeywordCTRPercent,
        },
        {
            id: 'qs',
            metric: 'Quality Score',
            winner: winningKeywordQS.toString(),
            loser: losingKeywordQS.toString(),
        },
        {
            id: 'cost',
            metric: 'Cost',
            winner: winningKeywordCostMoney,
            loser: losingKeywordCostMoney,
        },
        {
            id: 'clicks',
            metric: 'Clicks',
            winner: winningKeywordClicks.toString(),
            loser: losingKeywordClicks.toString(),
        },
        {
            id: 'impressions',
            metric: 'Impressions',
            winner: winningKeywordImpressions.toString(),
            loser: losingKeywordImpressions.toString(),
        },
    ]

    // REQUIREMENTS

    const entityPillList = [{ type: Entity.EntityLocation.Keyword, content: keyword }]

    const pushActionText = ref(`${actionCopy.value.infinitive} ${keywordCopy.value} Duplicate`)

    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        `${actionCopy.value.present} ${keywordCopy.value.toLowerCase()} duplicate..`,
        'Confirming changes..',
        `Duplicate keyword ${actionCopy.value.past.toLowerCase()} successfully.`,
    ])

    const onPush: OnPushHandler = () => {
        return { valid: true, pushedData: { value: affectLosingDuplicate.value ? 'red' : 'green' } }
    }

    return {
        title,
        lastUpdated,
        entityPillList,
        pushMessages,
        onPush,
        affectLosingDuplicate,
        actionCopy,
        keywordCopy,
        winningKeyword,
        winningKeywordStats,
        losingKeyword,
        losingKeywordStats,
        tableHeaders,
        tableItems,
        pushActionText,
    }
}
