<template>
    <div>
        <oTable
            :headers="costTableHeaders"
            :items="costTableRows"
            :per-page="6"
            orderBy="totalCostSaved"
            order="DESC"
            :borderRadius="24"
            class="impact-statistics-cost-section-table"
        >
            <template v-slot:header.account>
                <div class="table-search-container">
                    <Label class="table-search-label">Account Name</Label>
                    <Spacer width="1rem" />
                    <oInput
                        ref="searchInput"
                        type="text"
                        class="account-search-input"
                        name="accountSearchInputCost"
                        autocomplete="off"
                        v-model="searchedAccount"
                    />
                </div>
            </template>
            <template v-slot:column.account="cell">
                <ImpactStatsAccountNameCell
                    :domainName="cell.value.domainName"
                    :domainColor="cell.value.color"
                    :platform="cell.value.platform"
                    :initials="cell.value.initials"
                    :validating="validating"
                />
            </template>
            <template v-slot:column.accountBreakdown="cell">
                <oButton
                    color="white"
                    size="small"
                    :disabled="!cell.value.costSavingInstances.length"
                    @clicked="openModal(cell.value.costSavingInstances)"
                >
                    Detailed View
                </oButton>
            </template>
            <template v-slot:column.avgSavedPerImp="cell">
                <Money v-if="!validating" currency="USD" :value="cell.value" />
                <Skeleton v-else-if="validating" width="3rem" />
            </template>
            <template v-slot:column.totalCostSaved="cell">
                <Money v-if="!validating" currency="USD" :value="cell.value" />
                <Skeleton v-else-if="validating" width="3rem" />
            </template>
            <template v-if="validating" v-slot:column.impCount>
                <Skeleton width="2rem" />
            </template>
        </oTable>
        <!-- Account Breakdown Modal -->
        <Modal
            v-model="costBreakdownModalOpen"
            title="Cost Savings Breakdown"
            width="78.5rem"
            @closed="resetNumberOfVisibleRows"
        >
            <template #content>
                <ImpactStatisticsImprovementRow
                    v-for="(row, index) in slicedRows"
                    :improvement="row"
                    :class="index === slicedRows.length - 1 ? 'last' : ''"
                    @click="openInNewTab(row)"
                />
                <div
                    v-if="modalTableRows.length > slicedRows.length"
                    class="show-more-button-wrapper"
                >
                    <oButton color="white" @clicked="showMoreRows">Show More</oButton>
                </div>
            </template>
        </Modal>
    </div>
</template>
<script setup lang="ts">
// vue
import { computed, ref, PropType } from 'vue'
// types
import { IS } from '@opteo/types'
// local components
import ImpactStatsAccountNameCell from '@/components/impactStatistics/ImpactStatsAccountNameCell.vue'
import ImpactStatisticsImprovementRow from '@/components/impactStatistics/ImpactStatisticsImprovementRow.vue'
import Skeleton from '@/components/util/Skeleton.vue'
// Composition
import useImpactStatistics from '@/composition/impactStatistics/useImpactStatistics'
// components-next
import { oTable, Label, Spacer, oInput, Money, oButton, Modal } from '@opteo/components-next'
// lodash
import includes from 'lodash-es/includes'

const props = defineProps({
    data: {
        type: Array as PropType<IS.AccountCostTableOverview[]>,
        required: true,
    },
    validating: {
        type: Boolean,
        default: false,
    },
})

const { generateImprovementRouterLink, openUrlInNewTab } = useImpactStatistics()

const costBreakdownModalOpen = ref(false)
const searchedAccount = ref('')
const modalTableRows = ref<IS.CostSavingOverviewInstance[]>([])

// Costs savings table
const costTableHeaders = [
    { key: 'account', text: 'Account Name', noPadding: true },
    { key: 'totalCostSaved', text: 'Cost Saved', sortable: true },
    { key: 'impCount', text: 'Act. Count', sortable: true },
    { key: 'avgSavedPerImp', text: 'Per Act.', sortable: true },
    { key: 'accountBreakdown', text: 'Actions' },
]

const costTableRows = computed(() => {
    if (!searchedAccount.value) return props.data
    return props.data.filter(item =>
        includes(item.account.domainName.toLowerCase(), searchedAccount.value.toLowerCase())
    )
})

// Modal
const openModal = (instances: IS.CostSavingOverviewInstance[]) => {
    if (!instances.length) return
    modalTableRows.value = instances
    costBreakdownModalOpen.value = true
}

const openInNewTab = (instanceData: IS.CostSavingOverviewInstance) => {
    if (
        instanceData.type === IS.OverviewItemInstanceType.improvement &&
        instanceData.improvementId
    ) {
        openUrlInNewTab(
            generateImprovementRouterLink({
                accountId: instanceData.account.accountId,
                improvementId: instanceData.improvementId,
            })
        )
    }
}

const numberOfVisibleRows = ref(50)
const slicedRows = computed(() => {
    return modalTableRows.value.slice(0, numberOfVisibleRows.value)
})
const showMoreRows = () => {
    numberOfVisibleRows.value = numberOfVisibleRows.value + 50
}
const resetNumberOfVisibleRows = () => {
    numberOfVisibleRows.value = 50
}
</script>

<!-- Scoped -->
<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

@media screen and (max-width: 1279px) {
    .account-search-input {
        display: none;
    }
}

@media screen and (min-width: 1280px) {
    .account-search-input {
        max-width: 12rem;
        @include relative;
    }
    .account-search-input :deep(input) {
        padding: 10px 14px 10px 38px;
    }
    .account-search-input::before {
        content: '';
        position: absolute;
        left: 14px;
        top: 0;
        bottom: 0;
        width: 12px;
        background: url('@/assets/img/searchIcon.svg') center / contain no-repeat;
    }
}

// Modal
.show-more-button-wrapper {
    width: 100%;
    @include flex-center;
    @include mt-24;
}
</style>

<!-- Unscoped -->
<style lang="scss">
@media screen and (max-width: 1279px) {
    .impact-statistics-cost-section-table td:not(:first-child),
    .impact-statistics-cost-section-table th:not(:first-child) {
        padding-left: 1.125rem;
        padding-right: 1.125rem;
    }
    .impact-statistics-cost-section-table td:last-child {
        padding-right: 1.5rem;
    }
    .impact-statistics-cost-section-table .impact-stats-table-domain-name {
        max-width: 16rem;
    }
}

@media screen and (min-width: 1280px) {
    .impact-statistics-cost-section-table td:last-child {
        padding-right: 2.125rem;
    }
}
</style>
