import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { delay } from '@opteo/promise'
import { Account } from '@opteo/types'
import { Routes } from './routes'
import {
    checkLoggedIn,
    checkAuthValid,
    checkIfOverLimit,
    trackUserPageLoad,
} from '@/composition/auth/useAuth'

import { authRequest, Endpoint } from '@/composition/api/useAPI'

// Misc
import Login from '@/pages/login.vue'
import ResetPassword from '@/pages/reset.vue'
import ResetPasswordLink from '@/pages/resetlink.vue'
import PageNotFound from '@/pages/404.vue'
import EmailLink from '@/pages/link/[id].vue'
import InviteWelcome from '@/pages/InviteWelcome.vue'
import Thanks from '@/pages/thanks.vue'
import SharedChat from '@/pages/sharedchat/[id].vue'

// Onboarding
import CreateAccount from '@/pages/createaccount.vue'
import ConnectGoogleAds from '@/pages/user/connectgoogleads.vue'

// User
import User from '@/pages/user/index.vue'
import AccountCentre from '@/pages/user/accountcenter/index.vue'
import Chat from '@/pages/user/chat.vue'
import Alerts from '@/pages/user/alerts/index.vue'
import Alert from '@/pages/user/alerts/[id].vue'
import LinkedAccounts from '@/pages/user/linkedaccounts/index.vue'
import LinkedAccountsPlatform from '@/pages/user/linkedaccounts/platform.vue'
import GoogleAdsAccounts from '@/pages/user/linkedaccounts/googleads.vue'
import ImpactStatistics from '@/pages/user/impactstatistics.vue'
import UserSettings from '@/pages/user/settings.vue'
import BillingCentre from '@/pages/user/billing/index.vue'
import BillingCentreSubscription from '@/pages/user/billing/subscription.vue'
import NewPaymentMethod from '@/pages/user/newpaymentmethod.vue'
import Thankyou from '@/pages/user/thankyou.vue'
import Invoices from '@/pages/user/billing/invoices.vue'
import FeatureRequests from '@/pages/user/featurerequests.vue'

// Account
import AccountIndex from '@/pages/user/domain/index.vue'
import AccountSettings from '@/pages/user/domain/settings.vue'
import Notes from '@/pages/user/domain/notes.vue'
import Setup from '@/pages/user/domain/setup.vue'

// Improvements
import Improvements from '@/pages/user/domain/improvements/index.vue'
import ActiveImprovements from '@/pages/user/domain/improvements/active/index.vue'
import Improvement from '@/pages/user/domain/improvements/active/[id].vue'
import CompletedImprovements from '@/pages/user/domain/improvements/completed/index.vue'
import ImprovementCompleted from '@/pages/user/domain/improvements/completed/[id].vue'
import DismissedImprovements from '@/pages/user/domain/improvements/dismissed/index.vue'
import ImprovementDismissed from '@/pages/user/domain/improvements/dismissed/[id].vue'
import CampaignGroups from '@/pages/user/domain/improvements/campaigngroups/index.vue'

// Performance
import Performance from '@/pages/user/domain/performance/index.vue'
import PerformanceGraphs from '@/pages/user/domain/performance/graphs.vue'
import PerformanceSegments from '@/pages/user/domain/performance/segments.vue'
import PerformanceTable from '@/pages/user/domain/performance/table.vue'

// Reports
import Reports from '@/pages/user/domain/reports/index.vue'
import ReportsActive from '@/pages/user/domain/reports/active.vue'
import ReportsArchive from '@/pages/user/domain/reports/archive.vue'
import Report from '@/pages/user/domain/reports/report.vue'
import Slides from '@/pages/user/domain/reports/slides.vue'
import ReportDownload from '@/pages/user/domain/reports/download.vue'
import ReportCreate from '@/pages/user/domain/reports/create.vue'
import ReportUpdate from '@/pages/user/domain/reports/update.vue'

// Toolkit
import Toolkit from '@/pages/user/domain/toolkit/index.vue'
import ToolkitTools from '@/pages/user/domain/toolkit/tools.vue'
import ToolkitHistory from '@/pages/user/domain/toolkit/history.vue'
import ToolkitScorecard from '@/pages/user/domain/toolkit/scorecard/index.vue'
import ToolkitScorecardList from '@/pages/user/domain/toolkit/scorecard/list.vue'
import ToolkitScorecardCreate from '@/pages/user/domain/toolkit/scorecard/create.vue'
import ToolkitScorecardSingle from '@/pages/user/domain/toolkit/scorecard/scorecardSingle.vue'
import ToolkitSmartBiddingExperiments from '@/pages/user/domain/toolkit/smartbiddingexperiments/index.vue'
import ToolkitSmartBiddingExperimentsRecommendations from '@/pages/user/domain/toolkit/smartbiddingexperiments/recommendations.vue'
import ToolkitSmartBiddingExperimentsExperiment from '@/pages/user/domain/toolkit/smartbiddingexperiments/experiment.vue'
import ToolkitSmartBiddingExperimentsCreateFlow from '@/pages/user/domain/toolkit/smartbiddingexperiments/createFlow.vue'
import ToolkitSmartBiddingExperimentsActive from '@/pages/user/domain/toolkit/smartbiddingexperiments/active.vue'
import ToolkitSmartBiddingExperimentsCompleted from '@/pages/user/domain/toolkit/smartbiddingexperiments/completed.vue'
import ToolkitRsaWriter from '@/pages/user/domain/toolkit/rsawriter.vue'

// nGram Tool
import ToolkitNGramTool from '@/pages/user/domain/toolkit/ngram/index.vue'
import ToolkitNgramToolFlow from '@/pages/user/domain/toolkit/ngram/ngram-flow.vue'

// Shareable links
import ShareableSccorecard from '@/pages/shareable/shareableScorecard.vue'

// PDF rendering pages
import ScorecardPdf from '@/pages/pdf/scorecardPdf.vue'

const ImprovementRoutes: RouteRecordRaw = {
    path: 'improvements',
    name: Routes.Improvements,
    component: Improvements,
    redirect: { name: Routes.ImprovementsActive },
    children: [
        {
            name: Routes.ImprovementsActive,
            path: 'active',
            component: ActiveImprovements,
            children: [
                {
                    name: Routes.Improvement,
                    path: ':improvementId',
                    component: Improvement,
                },
            ],
        },
        {
            name: Routes.ImprovementsCompleted,
            path: 'completed',
            component: CompletedImprovements,
            children: [
                {
                    name: Routes.CompletedImprovement,
                    path: ':improvementId',
                    component: ImprovementCompleted,
                },
            ],
        },
        {
            name: Routes.ImprovementsDismissed,
            path: 'dismissed',
            component: DismissedImprovements,
            children: [
                {
                    name: Routes.DismissedImprovement,
                    path: ':improvementId',
                    component: ImprovementDismissed,
                },
            ],
        },
        {
            name: Routes.CampaignGroups,
            path: 'campaign-groups',
            component: CampaignGroups,
        },
    ],
}

const PerformanceRoutes: RouteRecordRaw = {
    path: 'performance',
    name: Routes.Performance,
    component: Performance,
    redirect: { name: Routes.PerformanceGraphs },
    children: [
        {
            name: Routes.PerformanceGraphs,
            path: 'graphs',
            component: PerformanceGraphs,
        },
        {
            name: Routes.PerformanceSegments,
            path: 'segments',
            component: PerformanceSegments,
        },
        {
            name: Routes.PerformanceTable,
            path: 'table',
            component: PerformanceTable,
        },
    ],
}

const ReportsRoutes: RouteRecordRaw = {
    path: 'reports',
    name: Routes.Reports,
    component: Reports,
    redirect: { name: Routes.ReportsActive },
    children: [
        {
            name: Routes.ReportsActive,
            path: 'active',
            component: ReportsActive,
            children: [
                {
                    name: Routes.ReportCreate,
                    path: 'create',
                    component: ReportCreate,
                },
                {
                    name: Routes.ReportActive,
                    path: ':reportId',
                    component: Report,
                    redirect: { name: Routes.ReportSlides },
                    children: [
                        {
                            name: Routes.ReportSlides,
                            path: 'slides',
                            component: Slides,
                        },
                        {
                            name: Routes.ReportDownload,
                            path: 'download',
                            component: ReportDownload,
                        },
                        {
                            name: Routes.ReportUpdate,
                            path: 'update',
                            component: ReportUpdate,
                        },
                    ],
                },
            ],
        },
        {
            name: Routes.ReportsArchive,
            path: 'archive',
            component: ReportsArchive,
            children: [
                {
                    name: Routes.ReportArchive,
                    path: ':reportId',
                    component: Report,
                    redirect: { name: Routes.ReportSlidesArchive },
                    children: [
                        {
                            name: Routes.ReportSlidesArchive,
                            path: 'slides',
                            component: Slides,
                        },
                        {
                            name: Routes.ReportDownloadArchive,
                            path: 'download',
                            component: ReportDownload,
                        },
                    ],
                },
            ],
        },
    ],
}

const RsaRoutes: RouteRecordRaw = {
    name: Routes.ToolkitRsaWriter,
    path: 'rsa-writer',
    component: ToolkitRsaWriter,
    children: [
        {
            name: Routes.ToolkitRsaWriterCampaign,
            path: 'campaign/:campaignId',
            component: ToolkitRsaWriter,
            children: [
                {
                    name: Routes.ToolkitRsaWriterAdGroup,
                    path: 'ad-group/:adGroupId',
                    component: ToolkitRsaWriter,
                    children: [
                        {
                            name: Routes.ToolkitRsaWriterAdEdit,
                            path: 'edit-ad/:adId',
                            component: ToolkitRsaWriter,
                            children: [
                                {
                                    name: Routes.ToolkitRsaWriterAdEditPreview,
                                    path: 'review',
                                    component: ToolkitRsaWriter,
                                },
                            ],
                        },
                        {
                            name: Routes.ToolkitRsaWriterAdNew,
                            path: 'create-ad/:draftId',
                            component: ToolkitRsaWriter,
                            children: [
                                {
                                    name: Routes.ToolkitRsaWriterAdNewPreview,
                                    path: 'review',
                                    component: ToolkitRsaWriter,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
}

const ToolkitRoutes: RouteRecordRaw = {
    name: Routes.Toolkit,
    path: 'toolkit',
    component: Toolkit,
    redirect: { name: Routes.ToolkitTools },
    children: [
        {
            name: Routes.ToolkitTools,
            path: 'tools',
            component: ToolkitTools,
            children: [
                {
                    name: Routes.ToolkitScorecard,
                    path: 'scorecard',
                    component: ToolkitScorecard,
                    redirect: { name: Routes.ToolkitScorecardList },
                    children: [
                        {
                            name: Routes.ToolkitScorecardList,
                            path: 'list',
                            component: ToolkitScorecardList,
                        },
                        {
                            name: Routes.ToolkitScorecardSingle,
                            path: ':scorecardId',
                            component: ToolkitScorecardSingle,
                        },
                        {
                            name: Routes.ToolkitScorecardCreate,
                            path: 'list',
                            component: ToolkitScorecardCreate,
                        },
                    ],
                },
                {
                    name: Routes.ToolkitSmartBiddingExperiments,
                    path: 'experiments',
                    component: ToolkitSmartBiddingExperiments,
                    redirect: { name: Routes.ToolkitSmartBiddingExperimentsRecommendations },
                    children: [
                        {
                            name: Routes.ToolkitSmartBiddingExperimentsRecommendations,
                            path: 'create',
                            component: ToolkitSmartBiddingExperimentsRecommendations,
                        },
                        {
                            name: Routes.ToolkitSmartBiddingExperimentsCreateFlow,
                            path: 'new',
                            component: ToolkitSmartBiddingExperimentsCreateFlow,
                        },
                        {
                            name: Routes.ToolkitSmartBiddingExperimentsExperiment,
                            path: 'experiment/:experimentId',
                            component: ToolkitSmartBiddingExperimentsExperiment,
                        },
                        {
                            name: Routes.ToolkitSmartBiddingExperimentsActive,
                            path: 'active',
                            component: ToolkitSmartBiddingExperimentsActive,
                        },
                        {
                            name: Routes.ToolkitSmartBiddingExperimentsCompleted,
                            path: 'completed',
                            component: ToolkitSmartBiddingExperimentsCompleted,
                        },
                    ],
                },
                RsaRoutes,
                {
                    name: Routes.ToolkitNGramTool,
                    path: 'ngram',
                    component: ToolkitNGramTool,
                    redirect: { name: Routes.ToolkitNGramToolSearch },
                    children: [
                        {
                            name: Routes.ToolkitNGramToolSearch,
                            path: 'search-campaigns',
                            redirect: { name: Routes.ToolkitNGramToolSearchSelect },
                            children: [
                                {
                                    name: Routes.ToolkitNGramToolSearchSelect,
                                    path: 'select',
                                    component: ToolkitNgramToolFlow,
                                },
                                {
                                    name: Routes.ToolkitNGramToolSearchConfirm,
                                    path: 'confirm',
                                    component: ToolkitNgramToolFlow,
                                },
                            ],
                        },
                        {
                            name: Routes.ToolkitNGramToolPmax,
                            path: 'performance-max',
                            redirect: { name: Routes.ToolkitNgramToolPmaxSelect },
                            children: [
                                {
                                    name: Routes.ToolkitNgramToolPmaxSelect,
                                    path: 'select',
                                    component: ToolkitNgramToolFlow,
                                },
                                {
                                    name: Routes.ToolkitNgramToolPmaxConfirm,
                                    path: 'confirm',
                                    component: ToolkitNgramToolFlow,
                                },
                            ],
                        },
                        {
                            name: Routes.ToolkitNGramToolShopping,
                            path: 'shopping-campaigns',
                            redirect: { name: Routes.ToolkitNGramToolShoppingSelect },
                            children: [
                                {
                                    name: Routes.ToolkitNGramToolShoppingSelect,
                                    path: 'select',
                                    component: ToolkitNgramToolFlow,
                                },
                                {
                                    name: Routes.ToolkitNGramToolShoppingConfirm,
                                    path: 'confirm',
                                    component: ToolkitNgramToolFlow,
                                },
                            ],
                        },
                    ],
                },
            ],
        },

        {
            name: Routes.ToolkitHistory,
            path: 'history',
            component: ToolkitHistory,
        },
    ],
}

const BillingRoutes: RouteRecordRaw = {
    path: 'billing',
    name: Routes.BillingCentre,
    component: BillingCentre,
    redirect: { name: Routes.BillingCentreSubscription },
    children: [
        {
            name: Routes.BillingCentreSubscription,
            path: 'subscription',
            component: BillingCentreSubscription,
        },
        {
            name: Routes.Invoices,
            path: 'invoices',
            component: Invoices,
        },
    ],
}

const AccountRoutes: RouteRecordRaw = {
    name: Routes.Account,
    path: 'account/:accountId',
    component: AccountIndex,
    redirect: { name: Routes.ImprovementsActive },
    children: [
        ImprovementRoutes,
        PerformanceRoutes,
        ReportsRoutes,
        ToolkitRoutes,
        {
            name: Routes.DomainSettings,
            path: 'settings/:scroll_position?',
            component: AccountSettings,
        },
        {
            name: Routes.Notes,
            path: 'notes',
            component: Notes,
        },
        {
            name: Routes.Setup,
            path: 'setup',
            component: Setup,
        },
    ],
    beforeEnter: (to, from, next) => {
        checkIfOverLimit(to, from, next)
    },
}

const AlertsRoutes: RouteRecordRaw = {
    name: 'alerts',
    path: Routes.Alerts,
    component: Alerts,
    children: [
        {
            name: Routes.Alert,
            path: ':alertId',
            component: Alert,
        },
    ],
    beforeEnter: (to, from, next) => {
        checkIfOverLimit(to, from, next)
    },
}

const UserRoutes: RouteRecordRaw[] = [
    BillingRoutes,
    AccountRoutes,
    AlertsRoutes,
    // Redirect old domain base URLs to /account
    // This can be removed eventually and is an extra backup to Netlify redirects
    {
        path: 'domain/:domainId/:catchAll(.*)',
        redirect: to => {
            const newPath = to.path.replace('domain', 'account')
            return newPath
        },
    },
    {
        path: 'domainlist', // Redirect old domain base URLs to /account. Useful for old links, eg from sent emails
        redirect: { name: Routes.AccountCentre },
    },
    {
        name: Routes.AccountCentre,
        path: 'accountcentre',
        component: AccountCentre,
    },
    {
        name: Routes.AccountCentreInit,
        path: 'accountcentre/init',
        component: AccountCentre,
    },
    {
        name: Routes.Chat,
        path: 'chat/:accountId?',
        component: Chat,
    },
    {
        name: Routes.LinkedAccountsPlatform,
        path: '', // No path here. This nested route exists only to provide a :key="platform" to the router-view so that the contents are re-rendered when the route changes
        component: LinkedAccountsPlatform,
        redirect: { name: Routes.LinkedAccountsGoogle },
        children: [
            {
                name: Routes.LinkedAccounts,
                path: 'linkedaccounts',
                component: LinkedAccounts,
                redirect: { name: Routes.LinkedAccountsGoogle },
                children: [
                    {
                        name: Routes.LinkedAccountsGoogle,
                        path: 'google',
                        component: GoogleAdsAccounts,
                    },
                    {
                        name: Routes.LinkedAccountsMicrosoft,
                        path: 'microsoft',
                        component: GoogleAdsAccounts,
                    },
                    {
                        name: Routes.LinkedAccountsMeta,
                        path: 'meta',
                        component: GoogleAdsAccounts,
                    },
                    {
                        name: Routes.LinkedAccountsLinkedIn,
                        path: 'linkedin',
                        component: GoogleAdsAccounts,
                    },
                ],
            },
        ],
    },
    {
        name: Routes.ImpactStatistics,
        path: 'impactstatistics',
        component: ImpactStatistics,
    },
    {
        name: Routes.FeatureRequests,
        path: 'featurerequests',
        component: FeatureRequests,
    },
    {
        name: Routes.UserSettings,
        path: 'settings',
        component: UserSettings,
    },
    {
        name: Routes.ConnectGoogleAds,
        path: 'connectgoogleads/:error?',
        component: ConnectGoogleAds,
    },
    {
        name: Routes.NewPaymentMethod,
        path: 'newpaymentmethod',
        component: NewPaymentMethod,
    },
    {
        name: Routes.Thankyou,
        path: 'thankyou/:reason?',
        component: Thankyou,
    },
]

const routes: Partial<RouteRecordRaw>[] = [
    {
        name: Routes.Root,
        path: '/',
        beforeEnter: (to, from, next) => {
            checkLoggedIn(to, from, next)
        },
    },
    {
        path: '/login',
        name: Routes.Login,
        component: Login,
        beforeEnter: (to, from, next) => {
            checkLoggedIn(to, from, next)
        },
    },
    {
        path: '/createaccount',
        name: Routes.CreateAccount,
        component: CreateAccount,
    },
    {
        path: '/reset',
        name: Routes.ResetPassword,
        component: ResetPassword,
    },
    {
        path: '/invitewelcome/:hash',
        name: Routes.InviteWelcome,
        component: InviteWelcome,
    },
    {
        path: '/resetlink/:email/:token', // Depends on rule defined in /public/_redirects
        name: Routes.ResetPasswordLink,
        component: ResetPasswordLink,
    },
    {
        path: `/user`,
        beforeEnter: (to, from, next) => {
            checkLoggedIn(to, from, next)
        },
    },
    {
        path: '/user/:id',
        name: Routes.User,
        component: User,
        beforeEnter: (to, from, next) => {
            checkAuthValid(to, from, next)
        },
        children: UserRoutes,
    },
    {
        name: Routes.Thanks,
        path: '/thanks',
        component: Thanks,
    },
    {
        name: Routes.EmailLink,
        path: '/link/:id',
        component: EmailLink,
    },
    {
        name: Routes.ShareableLinkScorecard,
        path: '/scorecard/:scorecardLinkId',
        component: ShareableSccorecard,
    },
    {
        name: Routes.PdfScorecard,
        path: '/pdf/scorecard',
        component: ScorecardPdf,
    },
    {
        name: Routes.PageNotFound,
        path: '/:catchAll(.*)',
        component: PageNotFound,
    },
    {
        name: Routes.SharedChat,
        path: '/c/:chatUuid',
        component: SharedChat,
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes as RouteRecordRaw[],
    async scrollBehavior(to, from, savedPosition) {
        /*
            Here we can control the scroll position after changing route.
            Here what we have so far:
            - When pressing "back" or "forward", savedPostition handles restoring scrollPos automatically
            - When doing a transition defined in transitionsNeedingSavedScrollPos, we'll restore the scrollPos from lastScrollPositionsPerRoute
            - Else scroll to top
        */

        if (
            to.name === Routes.ToolkitRsaWriter ||
            to.name === Routes.ToolkitRsaWriterCampaign ||
            to.name === Routes.ToolkitRsaWriterAdGroup ||
            to.name === Routes.ToolkitRsaWriterAdEdit ||
            to.name === Routes.ToolkitRsaWriterAdNew ||
            to.name === Routes.ToolkitRsaWriterAdEditPreview ||
            to.name === Routes.ToolkitRsaWriterAdNewPreview
        ) {
            await delay(0)
        }

        const transitionsNeedingSavedScrollPos = [
            [Routes.Improvement, Routes.ImprovementsActive],
            [Routes.CompletedImprovement, Routes.ImprovementsCompleted],
            [Routes.DismissedImprovement, Routes.ImprovementsDismissed],
        ]

        if (savedPosition) {
            return savedPosition
        }

        // scroll to bottom of active page if we successfully created an experiment
        if (
            from.name === Routes.ToolkitSmartBiddingExperimentsCreateFlow &&
            to.name === Routes.ToolkitSmartBiddingExperimentsActive
        ) {
            return { top: document.documentElement.scrollHeight }
        }

        if (
            transitionsNeedingSavedScrollPos.find(([fromName, toName]) => {
                return fromName === from.name && toName === to.name
            })
        ) {
            return { top: lastScrollPositionsPerRoute[to.name as string] }
        }

        return { top: 0 }
    },
})

/* Save the scroll position to this static object before changing each route */
const lastScrollPositionsPerRoute: Record<string, number> = {}
router.beforeEach((to, from) => {
    if (!from.name) {
        return
    }

    const scrollPos = document.documentElement.scrollTop || document.body.scrollTop

    lastScrollPositionsPerRoute[from.name as string] = scrollPos

    trackUserPageLoad(to).catch(err => {
        console.error(err)
    })
})

// Rewrite domainId to accountId for people coming in from very old links
router.beforeEach(async (to, from) => {
    const accountIdParam = to.params.accountId
    if (+accountIdParam > 0 && to.name) {
        // If it' a number...
        try {
            const accountId = await authRequest<Account.ID>(Endpoint.GetAccountIdFromDomainId, {
                body: {
                    domain_id: +accountIdParam,
                },
            })

            return {
                name: to.name as string,
                params: {
                    ...to.params,
                    accountId,
                },
            }
        } catch (e) {
            console.error(e)
            console.log('Could not rewrite domainId to accountId, redirecting to account centre...')

            return {
                name: Routes.AccountCentre,
                params: {
                    ...to.params,
                },
            }
        }
    }
})

export default router
