<template>
    <Text
        v-if="segments && segmentKey"
        as="h4"
        weight="600"
        size="f-5"
        style="letter-spacing: -0.0375rem"
    >
        {{ segmentsTitle[segmentKey] }}
    </Text>
    <Skeleton :height="23" v-else />
    <Spacer height="2rem" />
    <div class="segment-section-container">
        <div class="segment-chart-container">
            <SegmentsChart
                v-if="hasTableData"
                :items="chartItems"
                :axis="axisLabels"
                :target="target"
                :metric="chartMetric"
                :graph-keys="graphKeys"
            />
            <div v-else-if="segments && !chartItems?.length" class="segments-empty-state-container">
                <img src="@/assets/img/segments-empty-state.png" style="width: 220px" />
                <Spacer height="2rem" />
                <div class="center" style="max-width: 340px">
                    <Text as="h5" size="f-5" weight="600" align="center">
                        No Segments data available
                    </Text>
                    <Spacer height="0.875rem" />
                    <Text
                        as="p"
                        size="f-8"
                        align="center"
                        v-if="accountPlatform === Platform.Platform.LinkedInAds"
                    >
                        All segments are below LinkedIn's reporting minimum. Consider a longer time
                        range to gather more data.
                    </Text>
                    <Text as="p" size="f-8" align="center" v-else>
                        Your segments data will appear here. These segments are not compatible with
                        the selected campaign group.
                    </Text>
                </div>
            </div>
            <Skeleton v-else :height="375" :width="'100%'" class="chart-skeleton" />
        </div>
        <div class="segment-table-container" v-if="hasTableData">
            <oTable
                class="segment-table"
                :headers="[
                    {
                        key: 'segment',
                        text: 'Segment',
                        sortable: true,
                        noPadding: true,
                    },
                    {
                        key: 'cost',
                        text: 'Cost',
                        sortable: true,
                        noPadding: true,
                        width: 108,
                    },
                    isUsingCpa
                        ? {
                              key: 'conversions',
                              text: 'Conv.',
                              sortable: true,
                              noPadding: true,
                              width: 108,
                          }
                        : {
                              key: 'conversions_value',
                              text: 'Value',
                              sortable: true,
                              noPadding: true,
                              width: 108,
                          },
                    isUsingCpa
                        ? {
                              key: 'cpa',
                              text: 'CPA',
                              sortable: true,
                              noPadding: true,
                              width: 104,
                          }
                        : {
                              key: 'roas',
                              text: 'ROAS',
                              sortable: true,
                              noPadding: true,
                              width: 104,
                          },
                    {
                        key: 'target',
                        text: 'Target',
                        sortable: true,
                        noPadding: true,
                        width: 112,
                    },
                    {
                        key: 'difference',
                        text: 'Diff.',
                        sortable: true,
                        noPadding: true,
                        width: 120,
                    },
                ]"
                :items="tableItems"
                :per-page="aboveMobile ? 7 : 1"
                :no-shadow="true"
                :fixed-layout="true"
                responsive-breakpoint="767px"
                order-by="difference"
                :order="isUsingCpa ? 'ASC' : 'DESC'"
            >
                <!-- Segment Header -->
                <template #header.segment>
                    <div class="segment-label-column">
                        <span class="segment-header">Segment</span>
                    </div>
                </template>
                <!-- Segment -->
                <template #column.segment="cellData">
                    <Tooltip
                        placement="top-start"
                        :offset="[14, -8]"
                        :content="cellData.value.length > 34 ? cellData.value : ''"
                        :max-width="600"
                    >
                        <Text as="p" size="f-8" class="segment-label-column">
                            <span v-if="cellData.value === 'Connected_tv'" class="segment-label">
                                Connected TV
                            </span>
                            <span
                                v-else-if="cellData.value === 'Multi_channel'"
                                class="segment-label"
                            >
                                Multi Channel
                            </span>
                            <span
                                v-else-if="cellData.value === 'Performance_max'"
                                class="segment-label"
                            >
                                Performance Max
                            </span>
                            <span v-else class="segment-label">
                                {{ cellData.value }}
                            </span>
                        </Text>
                    </Tooltip>
                </template>
                <!-- Cost Header -->
                <template #header.cost>
                    <div class="segments-table-column">
                        <span class="segments-table-header">Cost</span>
                    </div>
                </template>
                <!-- Cost -->
                <template #column.cost="cost">
                    <Text
                        v-if="tableItems.length > 0"
                        as="p"
                        size="f-8"
                        class="segments-table-column"
                    >
                        <span class="segments-table-label">
                            <Money :value="cost.value" />
                        </span>
                    </Text>
                    <Skeleton v-else :height="16" :width="36" />
                </template>
                <!-- Conv. Header -->
                <template v-if="isUsingCpa" #header.conversions>
                    <div class="segments-table-column">
                        <span class="segments-table-header">Conv.</span>
                    </div>
                </template>
                <!-- Conversions -->
                <template v-if="isUsingCpa" #column.conversions="conversions">
                    <Text
                        v-if="tableItems.length > 0"
                        as="p"
                        size="f-8"
                        class="segments-table-column"
                    >
                        <span class="segments-table-label">
                            <Number :value="Math.round(conversions.value)" />
                        </span>
                    </Text>
                    <Skeleton v-else :height="16" :width="36" />
                </template>
                <!-- Value Header -->
                <template v-if="isUsingROAS" #header.conversions_value>
                    <div class="segments-table-column">
                        <span class="segments-table-header">Value</span>
                    </div>
                </template>
                <!-- Value -->
                <template v-if="isUsingROAS" #column.conversions_value="conversions_value">
                    <Text
                        v-if="tableItems.length > 0"
                        as="p"
                        size="f-8"
                        class="segments-table-column"
                    >
                        <span class="segments-table-label">
                            <Money :value="conversions_value.value" />
                        </span>
                    </Text>
                    <Skeleton v-else :height="16" :width="36" />
                </template>
                <!-- CPA Header -->
                <template v-if="isUsingCpa" #header.cpa>
                    <div class="segments-table-column">
                        <span class="segments-table-header">CPA</span>
                    </div>
                </template>
                <!-- CPA -->
                <template v-if="isUsingCpa" #column.cpa="cpa">
                    <Text
                        v-if="tableItems.length > 0"
                        as="p"
                        size="f-8"
                        class="segments-table-column"
                    >
                        <span class="segments-table-label">
                            <Money :value="cpa.value" />
                        </span>
                    </Text>
                    <Skeleton v-else :height="16" :width="36" />
                </template>
                <!-- ROAS Header -->
                <template v-if="isUsingROAS" #header.roas>
                    <div class="segments-table-column">
                        <span class="segments-table-header">ROAS</span>
                    </div>
                </template>
                <!-- ROAS -->
                <template v-if="isUsingROAS" #column.roas="roas">
                    <Text
                        v-if="tableItems.length > 0"
                        as="p"
                        size="f-8"
                        class="segments-table-column"
                    >
                        <span class="segments-table-label">
                            <Roas :value="roas.value" />
                        </span>
                    </Text>
                    <Skeleton v-else :height="16" :width="36" />
                </template>
                <!-- Target Header -->
                <template #header.target>
                    <div class="segments-table-column">
                        <span class="segments-table-header">Target</span>
                    </div>
                </template>
                <!-- Target -->
                <template #column.target="target">
                    <Text
                        v-if="tableItems.length > 0"
                        as="p"
                        size="f-8"
                        class="segments-table-column"
                    >
                        <span class="segments-table-label">
                            <Money v-if="isUsingCpa" :value="target.value" />
                            <Roas v-if="isUsingROAS" :value="target.value" />
                        </span>
                    </Text>
                    <Skeleton v-else :height="16" :width="36" />
                </template>
                <!-- Difference Header -->
                <template #header.difference>
                    <div class="segments-table-column">
                        <span class="segments-table-header">Diff.</span>
                    </div>
                </template>
                <!-- Difference -->
                <template #column.difference="cellData">
                    <div v-if="tableItems.length > 0 && isUsingCpa">
                        <Text
                            v-if="cellData.value < 0"
                            as="p"
                            size="f-8"
                            class="segments-table-column"
                            color="green"
                            weight="500"
                        >
                            <span class="segments-table-label" style="padding-right: 1.5rem">
                                <Percent :value="cellData.value" :include-sign="true" />
                            </span>
                        </Text>
                        <Text
                            v-else
                            as="p"
                            size="f-8"
                            class="segments-table-column"
                            color="red"
                            weight="500"
                        >
                            <span class="segments-table-label" style="padding-right: 1.5rem">
                                <Percent :value="cellData.value" :include-sign="true" />
                            </span>
                        </Text>
                    </div>
                    <div v-else-if="tableItems.length > 0 && !isUsingCpa">
                        <Text
                            v-if="cellData.value * 100 < 0"
                            as="p"
                            size="f-8"
                            class="segments-table-column"
                            color="red"
                            weight="500"
                        >
                            <span class="segments-table-label" style="padding-right: 1.5rem">
                                <Percent :value="cellData.value" :include-sign="true" />
                            </span>
                        </Text>
                        <Text
                            v-else
                            as="p"
                            size="f-8"
                            class="segments-table-column"
                            color="green"
                            weight="500"
                        >
                            <span class="segments-table-label" style="padding-right: 1.5rem">
                                <Percent :value="cellData.value" :include-sign="true" />
                            </span>
                        </Text>
                    </div>

                    <Skeleton v-else :height="16" :width="36" />
                </template>
            </oTable>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, inject, Ref } from 'vue'

import formatDate from 'date-fns/format'
import {
    Text,
    Spacer,
    oTable,
    Percent,
    SegmentsChart,
    Number,
    Tooltip,
    Roas,
} from '@opteo/components-next'
import { CurrencyCode } from '@/composition/utils/types'
import Money from '@/components/global/oMoney.vue'

import { Segments, Platform } from '@opteo/types'

import { Targets } from '@opteo/types'
import Skeleton from '@/components/util/Skeleton.vue'
import { ProvideKeys } from '@/composition/useProvide'
import useMediaQuery from '@/composition/global/useMediaQuery'
import { useAccount } from '@/composition/account/useAccount'

const props = defineProps<{
    segmentKey?: keyof typeof segmentsTitle
    selectedGroup?: Targets.CampaignGroup
    segments?: Segments.FormattedSegment[]
}>()

const { aboveMobile } = useMediaQuery()

const { accountPlatform } = useAccount()

const segmentsTitle = {
    campaign: 'Segment by Campaign',
    advertising_channel: 'Segment by Network',
    device: 'Segment by Device',
    match_type: 'Segment by Match Type',
    hour_of_day: 'Segment by Hour of Day',
    day_of_week: 'Segment by Day of Week',
    city: 'Segment by City',
    country: 'Segment by Country',
    state: 'Segment by US State',
    gender: 'Segment by Gender',
    age_range: 'Segment by Age Range',
    word: 'Top Words in Search Terms',
    word2: 'Top Pairs of Words in Search Terms',
    word3: 'Top Trios of Words in Search Terms',
    headline1: 'First Line of ETA Headline',
    headline2: 'Second Line of ETA Headline',
    member_industry: 'Segment by Industry',
    member_job_function: 'Segment by Job Function',
    member_job_title: 'Segment by Job Title',
    member_seniority: 'Segment by Seniority',
    company: 'Segment by Company',
    company_size: 'Segment by Company Size',
}

const allSegmentsConversionValuesZero = props.segments?.every(s => !s.conversions_value)

const domainCurrencyCode = inject<Ref<CurrencyCode>>(ProvideKeys.CurrencyCode)

const target = computed(() => props.selectedGroup?.performanceTarget ?? 0)

const isUsingCpa = computed(
    () => props.selectedGroup?.performanceMode === Targets.PerformanceMode.CPA
)

const isUsingROAS = computed(
    () => props.selectedGroup?.performanceMode === Targets.PerformanceMode.ROAS
)

const startDate = inject<Ref<Date>>(ProvideKeys.PerformanceStartDate)
const endDate = inject<Ref<Date>>(ProvideKeys.PerformanceEndDate)

const yAxisTitle = computed(
    () =>
        `Cost (${formatDate(startDate?.value ?? 0, 'dd/MM/yyyy')} → ${formatDate(
            endDate?.value ?? 0,
            'dd/MM/yyyy'
        )})`
)

const axisLabels = computed(() => {
    return {
        x: {
            dataType: 'percentage',
            label: `Percentage Difference from ${isUsingCpa.value ? 'CPA' : 'ROAS'} Target`,
        },
        y: {
            currency: domainCurrencyCode?.value ?? 'USD',
            dataType: 'money',
            label: yAxisTitle.value,
        },
    }
})

const chartMetric = isUsingCpa.value
    ? { dataType: 'percentage', inverted: true, label: 'CPA' }
    : { dataType: 'percentage', inverted: false, label: 'ROAS' }

const graphKeys = isUsingCpa.value
    ? ['Below CPA Target', 'Above CPA Target', 'Needs Attention']
    : ['Needs Attention', 'Below ROAS Target', 'Above ROAS Target']

const tableItems = computed(() => {
    if (!props.segments || props.segments.length === 0) {
        return []
    }
    return props.segments.map(segment => {
        const cpa = !segment.conversions && !segment.cost ? 0 : segment.cost / segment.conversions
        const roas =
            !segment.conversions_value || !segment.cost
                ? 0
                : segment.conversions_value / segment.cost

        const difference = isUsingCpa.value
            ? (cpa - target.value) / target.value
            : (roas - target.value) / target.value

        return {
            ...segment,
            id: segment.target_segment,
            segment: segment.segment_label,
            cpa,
            target: target.value,
            difference,
        }
    })
})

const chartItems = computed(() =>
    tableItems.value.map(point => {
        const diff = point.difference

        return {
            x: diff,
            y: point.cost,
            label: point.segment_label,
        }
    })
)

const hasTableData = computed(() => props.segments && chartItems?.value?.length)
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

// Segment name

.segment-label-column {
    height: 64px;
    @include pointer;
    @include flex;
    @include items-center;
    @include relative;
}
.segment-label-column .segment-header {
    max-width: 100%;
    overflow: hidden;
    @include flex;
    @include items-center;
    height: 64px;
    @include pl-24;
}
.segment-label-column .segment-label {
    max-width: 100%;
    overflow: hidden;
    @include flex;
    @include items-center;
    height: 64px;
    @include pl-24;
}
.segment-label-column .segment-label::after {
    content: '';
    background: linear-gradient(270deg, #fff 0.5rem, rgba(255, 255, 255, 0) 100%);
    @include absolute;
    top: 0;
    right: 0;
    width: 4rem;
    bottom: 0;
}

// Other columns

.segments-table-column {
    height: 64px;
    @include pointer;
    @include flex;
    @include items-center;
    @include relative;
}
.segments-table-column .segments-table-label {
    max-width: 100%;
    overflow: hidden;
    @include flex;
    @include items-center;
    height: 64px;
    @include pl-24;
}
.segments-table-column .segments-table-header {
    max-width: 100%;
    overflow: hidden;
    @include flex;
    @include items-center;
    height: 64px;
    @include pl-24;
}

.segments-table-column .segments-table-label::after {
    content: '';
    background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
    @include absolute;
    top: 0;
    right: 0;
    width: 1rem;
    bottom: 0;
}

// Container styles

.segment-section-container {
    @include container;
    max-width: 960px;
    margin: 0 auto;
    overflow: hidden;
    @include br-20;
}

.segment-chart-container {
    @include pa-64;
    @include w-100;
}

.segment-table-container {
    @include w-100;
    @include flex-center;
    border-top: 1px solid;
    @include opteo-border;
}

.chart-skeleton :deep(.skeleton) {
    border-radius: 0.75rem;
}

.segments-empty-state-container {
    @include flex;
    @include items-center;
    @include justify-center;
    @include tc;
    flex-direction: column;
    height: 24rem;
}

@media (max-width: $mq-1024-max) {
    .segment-chart-container {
        @include pa-32;
    }
}

@media screen and (max-width: $mq-480-max) {
    .segment-chart-container {
        padding: unset;
    }
}

@media screen and (max-width: $mq-767-max) {
    .segment-label-column {
        height: unset;
    }
    .segment-label-column .segment-header {
        height: unset;
        overflow: unset;
        display: unset;
        padding-left: unset;
    }
    .segment-label-column .segment-label {
        height: unset;
        overflow: unset;
        display: unset;
        padding-left: unset;
    }
    .segments-table-column {
        height: unset;
    }
    .segments-table-column .segments-table-header {
        height: unset;
        overflow: unset;
        display: unset;
        padding-left: unset;
    }
    .segments-table-column .segments-table-label {
        height: unset;
        overflow: unset;
        display: unset;
        padding-left: unset;
    }
    .segments-table-column .segments-table-label::after,
    .segment-label-column .segment-label::after {
        background: unset;
        top: unset;
        right: unset;
        width: unset;
        bottom: unset;
    }

    .segments-empty-state-container {
        @include pa-24;
    }

    :deep(.container) {
        box-shadow: unset !important;
    }
}
</style>
