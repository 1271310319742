import { Entity } from '@opteo/types'

// TODO(deepLinking): copied from backend. Ideally we want to create a new package, @opteo/utils, for this in order to be shared between luckymetrics/frontend and else
/**
 * @description Extracts entity id from resource name.
 * @param resourceName - The resource name. Can be undefined but not optional – this is because some old improvement bodies may not have a resource name, resulting in an error when trying to convert it in `formatLocation`.
 * @param entityLocation - The entity location.
 * @returns The entity id as a number or 0 if the resource name is undefined.
 */
export function extractIdFromResourceName(
    resourceName: string | undefined,
    entityLocation: Entity.LocationWithResourceName
) {
    if (typeof resourceName === 'undefined') {
        return 0
    }

    const isCriterionLocation = Object.values(Entity.CriterionLocation).some(
        criterionLocation => criterionLocation === entityLocation
    )

    const splitBy = isCriterionLocation ? '~' : '/'

    const entityId = Number(resourceName.split(splitBy).at(-1))

    if (!entityId || isNaN(entityId)) {
        throw new Error(
            `Failed to extract ${entityLocation} id from resource name: ${resourceName}`
        )
    }

    return entityId
}
