import round from 'lodash-es/round'
import { computed, ref } from 'vue'

import { useImprovement, checkImprovement } from '../useImprovement'
import { RemoveBroadKeywordsV2, Targets, Improvement } from '@opteo/types'
import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import { useNumber, useRoas } from '@opteo/components-next'

export function useRemoveBroadKeywordsV2() {
    const { improvement, lastUpdated, title } = useImprovement<
        RemoveBroadKeywordsV2.ReplaceBroadKeywordV2.Body,
        typeof Improvement.RecAction.RemoveBroadKeywordsV2
    >()
    const { body, location } = checkImprovement(improvement)

    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Pausing broad match keyword..',
        'Adding phrase match keyword..',
        'Keyword replaced successfully.',
    ])

    const isCpaMode =
        !body.performance_mode || body.performance_mode === Targets.PerformanceMode.CPA

    const replacementKeyword = `${body.keyword_text.replace(/\+/g, '')}`
    const entityPillList = location.map(loc => {
        return {
            entityPillData: { type: loc.entity, content: loc.label },
            deepLinkParams: loc.entityIds,
        }
    })

    const pushActionText = ref('Replace Keyword')

    const lookbackWindow = body.window
    const keywordText = body.keyword_text
    const searchTerms = body.search_terms
    const isAutoTargeting = !body.campaign_group_target_set

    const keywordConv = round(body.keyword_conversions, 1)
    const keywordConvValue = body.keyword_conversion_value
    const keywordCost = body.keyword_cost

    const keywordCpa = body.keyword_cost / body.keyword_conversions
    const targetCpa = body.campaign_group_cpa_target_or_avg ?? 0

    const keywordRoas = body.keyword_conversion_value / body.keyword_cost
    const targetRoas = body.campaign_group_roas_target_or_avg ?? 0

    const cpaItems = [
        {
            key: 'conv',
            value: useNumber({ value: keywordConv }).displayValue.value,
            title: keywordConv === 1 ? 'Conversion' : 'Conversions',
        },
        {
            key: 'keywordCpa',
            value: useDomainMoney({ value: keywordCpa }).value.displayValue.value,
            title: 'Keyword CPA',
        },

        // Make `title` variable based on settings in campaign groups – Target or Average
        {
            key: 'targetCpa',
            value: useDomainMoney({ value: body.campaign_group_cpa_target_or_avg ?? 0 }).value
                .displayValue.value,
            title: (isAutoTargeting ? 'Average' : 'Target') + ' CPA',
        },
    ]

    const roasItems = [
        {
            key: 'convValue',
            value: useDomainMoney({ value: keywordConvValue }).value.displayValue.value,
            title: 'Conversion Value',
        },
        {
            key: 'keywordRoas',
            value: useRoas({ value: keywordRoas }).displayValue.value,
            title: 'Keyword ROAS',
        },

        // Make `title` variable based on settings in campaign groups – Target or Average
        {
            key: 'targetRoas',
            value: useRoas({ value: body.campaign_group_roas_target_or_avg ?? 0 }).displayValue
                .value,
            title: (isAutoTargeting ? 'Average' : 'Target') + ' ROAS',
        },
    ]

    const impStatCommonItem = [
        {
            key: 'cost',
            value: useDomainMoney({ value: body.keyword_cost }).value.displayValue.value,
            title: 'Cost',
        },
    ]

    const impStatItems = isCpaMode
        ? [...impStatCommonItem, ...cpaItems]
        : [...impStatCommonItem, ...roasItems]

    // Conditional copy
    const isManualCpc = body.keyword_is_manual_cpc
    const originalKeywordBid = body.keyword_current_bid

    // Campaign Group has other match types
    const otherMatchtypesExistInCampaignGroup = body.campaign_group_has_non_broads

    const otherMatchTypesCpa = body.non_broads_avg_cpa ?? 0
    const broadKeywordCPA = body.broads_avg_cpa ?? 0
    const matchTypeCpaDiff = Math.abs((otherMatchTypesCpa - broadKeywordCPA) / broadKeywordCPA)

    const otherMatchTypesRoas = body.non_broads_avg_roas ?? 0
    const broadKeywordRoas = body.broads_avg_roas ?? 0
    const matchTypeRoasDiff = Math.abs((otherMatchTypesRoas - broadKeywordRoas) / broadKeywordRoas)

    return {
        title,
        lastUpdated,
        pushMessages,
        isCpaMode,
        keywordText,
        searchTerms,
        entityPillList,
        replacementKeyword,
        lookbackWindow,
        broadKeywordCPA,
        broadKeywordRoas,
        impStatItems,
        keywordCost,
        keywordCpa,
        keywordRoas,
        targetCpa,
        targetRoas,
        isAutoTargeting,
        isManualCpc,
        originalKeywordBid,
        otherMatchtypesExistInCampaignGroup,
        otherMatchTypesCpa,
        otherMatchTypesRoas,
        matchTypeCpaDiff,
        matchTypeRoasDiff,
        keywordConv,
        keywordConvValue,
        pushActionText,
    }
}
