import { computed, ComputedRef, ref } from 'vue'
import { groupBy, capitalize } from '@/lib/lodash'
import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import { AdScheduleChartTypes, usePercent } from '@opteo/components-next'
import type { EntityPillProps } from '@opteo/components-next'
import { Entity } from '@opteo/types'
import {
    UseImprovement,
    useImprovement,
    checkImprovement,
} from '@/composition/improvement/useImprovement'
import { useDomain } from '@/composition/domain/useDomain'

interface Timespan {
    start_hour: string
    end_hour: string
    start_minute: number
    end_minute: number
    cost: number
    conversions: number
    bid_modifier: number
    cpa: number
    cpa_ratio: number
    average_cpa: number
    previous_bid_modifier: number
    day_of_week: AdScheduleChartTypes.DaysOfWeek
}

const minuteValues = {
    ZERO: 0,
    FIFTEEN: 15,
    THIRTY: 30,
    FORTY_FIVE: 45,
}

interface SchedulePeriod {
    day_of_week: AdScheduleChartTypes.DaysOfWeek
    start_hour: string
    end_hour: string
    start_minute: keyof typeof minuteValues | 0
    end_minute: keyof typeof minuteValues | 0
}

interface Body {
    campaign_label: string
    is_temp_schedule: boolean
    timespans: Timespan[]
    schedule: SchedulePeriod[]
}

type TableHeader = { key: string; text: string }
type TableItem = { id: string; datetime: string; cpa: string; avgCpa: string; bidMod: string }

interface UseAdjustAdScheduleBids {
    entityPillList: EntityPillProps[]
    campaignDoesNotHaveAdSchedule: boolean
    adSchedule: AdScheduleChartTypes.AdSchedule
    tableHeaders: TableHeader[]
    tableItems: TableItem[]
    currencyCode?: ComputedRef<string | undefined>
}

export function useAdjustAdScheduleBids(): UseImprovement<UseAdjustAdScheduleBids> {
    const { improvement, lastUpdated, title } = useImprovement<Body>()
    const { currencyCode } = useDomain()
    const {
        body: {
            campaign_label: campaignName,
            is_temp_schedule: campaignDoesNotHaveAdSchedule,
            schedule,
            timespans,
        },
    } = checkImprovement(improvement)

    const tableHeaders: TableHeader[] = [
        { key: 'datetime', text: 'Day/Time' },
        { key: 'cpa', text: 'CPA' },
        { key: 'avgCpa', text: 'Avg. CPA' },
        { key: 'bidMod', text: 'Adjustment' },
    ]

    const adSchedule = {} as AdScheduleChartTypes.AdSchedule

    Object.entries(groupBy(timespans, ts => ts.day_of_week)).forEach(([day, periods]) => {
        adSchedule[day as AdScheduleChartTypes.DaysOfWeek] = periods.map(period => {
            return {
                start_hour: +period.start_hour,
                start_minute:
                    typeof period.start_minute === 'number' ? 0 : minuteValues[period.start_minute],
                end_hour: +period.end_hour,
                end_minute:
                    typeof period.end_minute === 'number' ? 0 : minuteValues[period.end_minute],
                bid_modifier: period.bid_modifier,
                previous_bid_modifier: 1,
                optimisation_target: 'cpa',
                window_value: period.cpa,
                window_target: period.average_cpa,
                window_difference: (period.cpa - period.average_cpa) / period.average_cpa,
            }
        })
    })

    function format(value: number | string) {
        return `0${value}`.slice(-2)
    }

    const tableItems: TableItem[] = schedule.map((period, index) => {
        const day = capitalize(period.day_of_week)
        const start = `${format(period.start_hour)}:${format(
            typeof period.start_minute === 'number' ? 0 : minuteValues[period.start_minute]
        )}`
        const end = `${format(period.end_hour)}:${format(
            typeof period.end_minute === 'number' ? 0 : minuteValues[period.end_minute]
        )}`
        const datetime = `${day} (${start} - ${end})`

        const timespan = timespans.find(ts => {
            const sameDay = ts.day_of_week === period.day_of_week
            const sameStartHour = ts.start_hour === period.start_hour
            const sameStartMinute = ts.start_minute === period.start_minute
            const sameEndHour = ts.end_hour === period.end_hour
            const sameEndMinute = ts.end_minute === period.end_minute

            return sameDay && sameStartHour && sameStartMinute && sameEndHour && sameEndMinute
        })

        if (timespan) {
            const cpa = useDomainMoney({ value: timespan.cpa }).value.displayValue.value
            const avgCpa = useDomainMoney({ value: timespan.average_cpa }).value.displayValue.value

            const bidMod = `${timespan.bid_modifier > 1 ? '+' : '-'}${
                usePercent({ value: Math.abs(timespan.bid_modifier - 1) }).displayValue.value
            }`

            return {
                id: index.toString(),
                datetime,
                cpa,
                avgCpa,
                bidMod,
            }
        }

        return {
            id: index.toString(),
            datetime,
            cpa: 'N/A',
            avgCpa: 'N/A',
            bidMod: 'N/A',
        }
    })

    const entityPillList = [{ type: Entity.EntityLocation.Campaign, content: campaignName }]

    const pushActionText = ref('Apply Ad Schedule Bids')
    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Adjusting Ad Schedule Bids..',
        'Confirming changes..',
        'Ad Schedule Bids adjusted successfully.',
    ])

    return {
        title,
        pushMessages,
        lastUpdated,
        entityPillList,
        campaignDoesNotHaveAdSchedule,
        tableHeaders,
        tableItems,
        adSchedule,
        currencyCode,
        pushActionText,
    }
}
