import { ref, Ref, computed, ComputedRef, watch } from 'vue'
import capitalise from 'lodash-es/capitalize'
import isEqual from 'lodash-es/isEqual'
import {
    OnPushHandler,
    UseImprovement,
    useImprovement,
    checkImprovement,
} from '@/composition/improvement/useImprovement'
import {
    DuplicateKeywords as DK,
    BiddingStrategies,
    Targets,
    Improvement,
    Entity,
} from '@opteo/types'
import { usePercent, useNumber, useRoas } from '@opteo/components-next'
import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import { fromMicros } from '../../../lib/globalUtils'

interface UsePauseDuplicateKeywordsV2 {
    locationEntityIds: Entity.Ids<Entity.EntityLocation>
    affectLosingDuplicate: Ref<boolean>
    keywordCopy: ComputedRef<string>
    affectLosingKeyword(): void
    actionCopy: { infinitive: string; present: string; past: string }
    diffConvActions: boolean
    winningKeyword: Keyword
    losingKeyword: Keyword
    winningKeywordStats: KeywordStats[]
    losingKeywordStats: KeywordStats[]
    winningProfitNegative: boolean
    losingProfitNegative: boolean
    keywordChecks: Ref<KeywordCheck[]>
    tableHeaders: TableHeader[]
    tableItems: TableItem[]
    betterCpa: boolean
    betterRoas: boolean
    betterProfit: boolean
    noQualityScore: boolean
    higherQualityScore: boolean
    higherCtr: boolean
    higherImpressions: boolean
    copyItem: {
        winningKeywordCpa: string
        losingKeywordCpa: string
        winningKeywordRoas: number
        losingKeywordRoas: number
        winningKeywordProfit: string
        losingKeywordProfit: string
        winningKeywordQS: number
        losingKeywordQS: number
        ctrDiff: string
        winningDaysWithImpressions: number
        losingDaysWithImpressions: number
    }
}

type Keyword = { keyword: string; campaign: string; matchType: string; adGroup: string }
type KeywordStats = { title: string; content: string; color: 'green' | 'red' }
type KeywordCheck = { name: string; description: string; checked: boolean }
type TableHeader = { key: string; text: string }
type TableItem = { id: string; metric: string; winner: string; loser: string }

function formatKeyword({ keyword, matchType }: { keyword: string; matchType: DK.MatchType }) {
    if (matchType === 'PHRASE') {
        return `"${keyword}"`
    } else if (matchType === 'EXACT') {
        return `[${keyword}]`
    } else {
        return keyword
    }
}

export function usePauseDuplicateKeywordsV2(): UseImprovement<UsePauseDuplicateKeywordsV2> {
    const { improvement, lastUpdated, title } = useImprovement<
        DK.Body,
        typeof Improvement.RecAction.PauseDuplicateKeywordsV2
    >()
    const {
        body: {
            winner: {
                keyword: winnerKeyword,
                metrics: winnerMetrics,
                campaign: winnerCampaign,
                ad_group: winnerAdGroup,
                bidding_strategy: winnerBiddingStrategy,
                conversion_action_names: winnerConversionActions,
            },
            loser: {
                keyword: loserKeyword,
                metrics: loserMetrics,
                campaign: loserCampaign,
                ad_group: loserAdGroup,
                bidding_strategy: loserBiddingStrategy,
                conversion_action_names: loserConversionActions,
            },
            justification,
            performance_mode: performanceMode,
        },
        location,
    } = checkImprovement(improvement)

    const locationEntityIds = location[0].entityIds

    const affectLosingDuplicate = ref(true) // false = affecting winning duplicate
    const keywordCopy = computed(() => (affectLosingDuplicate.value ? 'losing' : 'winning'))
    function affectLosingKeyword() {
        affectLosingDuplicate.value = true
    }
    const actionCopy = { infinitive: 'Pause', present: 'Pausing', past: 'Paused' }

    const diffConvActions = !isEqual(winnerConversionActions.sort(), loserConversionActions.sort())

    // KEYWORDS
    const winnerCost = fromMicros(winnerMetrics.cost_micros)
    const winnerQs =
        winnerMetrics.quality_score > 0 ? winnerMetrics.quality_score.toString() : 'N/A'
    const winnerCpa = fromMicros(winnerMetrics.cpa)
    const winnerRoas = winnerMetrics.roas
    const winnerProfit = fromMicros(winnerMetrics.profit)

    let winnerBiddingStrategyValue = winnerCpa
    let winnerBiddingStrategyText = 'CPA'

    if (winnerBiddingStrategy === BiddingStrategies.BiddingStrategy.Roas) {
        winnerBiddingStrategyValue = winnerRoas
        winnerBiddingStrategyText = 'ROAS'
    } else if (winnerBiddingStrategy === BiddingStrategies.BiddingStrategy.Profit) {
        winnerBiddingStrategyValue = winnerProfit
        winnerBiddingStrategyText = 'Profit'
    }

    const loserCost = fromMicros(loserMetrics.cost_micros)
    const loserQs = loserMetrics.quality_score > 0 ? loserMetrics.quality_score.toString() : 'N/A'
    const loserCpa = fromMicros(loserMetrics.cpa)
    const loserRoas = loserMetrics.roas
    const loserProfit = fromMicros(loserMetrics.profit)

    let loserBiddingStrategyValue = loserCpa
    let loserBiddingStrategyText = 'CPA'

    if (loserBiddingStrategy === BiddingStrategies.BiddingStrategy.Roas) {
        loserBiddingStrategyValue = loserRoas
        loserBiddingStrategyText = 'ROAS'
    } else if (loserBiddingStrategy === BiddingStrategies.BiddingStrategy.Profit) {
        loserBiddingStrategyValue = loserProfit
        loserBiddingStrategyText = 'Profit'
    }

    const winningKeyword: Keyword = {
        keyword: formatKeyword({
            keyword: winnerKeyword.text,
            matchType: winnerKeyword.match_type,
        }),
        matchType: winnerKeyword.match_type,
        campaign: winnerCampaign.name,
        adGroup: winnerAdGroup.name,
    }

    const losingKeyword: Keyword = {
        keyword: formatKeyword({
            keyword: loserKeyword.text,
            matchType: loserKeyword.match_type,
        }),
        matchType: loserKeyword.match_type,
        campaign: loserCampaign.name,
        adGroup: loserAdGroup.name,
    }

    const winningKeywordCTRPercent = usePercent({ value: winnerMetrics.ctr }).displayValue.value
    const winningKeywordCostMoney = useDomainMoney({ value: winnerCost }).value.displayValue.value
    const winningKeywordCpaMoney = useDomainMoney({ value: winnerCpa }).value.displayValue.value
    const winningKeywordRoas = useRoas({ value: winnerRoas }).displayValue.value
    const winnerFormattedBiddingStrategyValue =
        winnerBiddingStrategy === BiddingStrategies.BiddingStrategy.Roas
            ? winningKeywordRoas
            : winningKeywordCpaMoney
    const winningKeywordStats: KeywordStats[] = [
        {
            title: winnerBiddingStrategyText,
            content: winnerFormattedBiddingStrategyValue,
            color: 'green',
        },
        { title: 'Cost', content: winningKeywordCostMoney, color: 'green' },
        { title: 'CTR', content: winningKeywordCTRPercent, color: 'green' },
        { title: 'Quality Score', content: winnerQs, color: 'green' },
    ]

    const losingKeywordCTRPercent = usePercent({ value: loserMetrics.ctr }).displayValue.value
    const losingKeywordCostMoney = useDomainMoney({ value: loserCost }).value.displayValue.value
    const losingKeywordCpaMoney = useDomainMoney({ value: loserCpa }).value.displayValue.value
    const losingKeywordRoas = useRoas({ value: loserRoas }).displayValue.value

    const loserFormattedBiddingStrategyValue =
        loserBiddingStrategy === BiddingStrategies.BiddingStrategy.Roas
            ? losingKeywordRoas
            : losingKeywordCpaMoney
    const losingKeywordStats: KeywordStats[] = [
        {
            title: loserBiddingStrategyText,
            content: loserFormattedBiddingStrategyValue,
            color: 'red',
        },
        { title: 'Cost', content: losingKeywordCostMoney, color: 'red' },
        { title: 'CTR', content: losingKeywordCTRPercent, color: 'red' },
        { title: 'Quality Score', content: loserQs, color: 'red' },
    ]

    // KEYWORD PROFIT
    const winningProfitNegative = winnerMetrics.profit < 0
    const losingProfitNegative = loserMetrics.profit < 0

    // KEYWORD CHECKS
    const keywordChecks: Ref<KeywordCheck[]> = ref([
        {
            name: 'Keywords',
            description:
                winnerKeyword.text === loserKeyword.text ? 'are identical' : 'are equivalent',
            checked: true,
        },
        { name: 'Location', description: 'targets match', checked: true },
        { name: 'Ad Schedules', description: 'match', checked: true },
        { name: 'Language', description: 'settings match', checked: true },
        { name: 'Device', description: 'targets match', checked: true },
        { name: 'Audience', description: 'targets match', checked: true },
        { name: 'Demographic', description: 'targets match', checked: true },
        { name: 'Keyword Final URLs', description: 'match', checked: true },
    ])

    // TABLE
    const tableHeaders: TableHeader[] = [
        { key: 'metric', text: 'Metric' },
        { key: 'winner', text: 'Winning Duplicate' },
        { key: 'loser', text: 'Losing Duplicate' },
    ]

    const tableItems: TableItem[] = [
        {
            id: 'ctr',
            metric: 'Click Through Rate',
            winner: winningKeywordCTRPercent,
            loser: losingKeywordCTRPercent,
        },
        {
            id: 'qs',
            metric: 'Quality Score',
            winner: winnerMetrics.quality_score.toString(),
            loser: loserMetrics.quality_score.toString(),
        },
        {
            id: 'cost',
            metric: 'Cost',
            winner: winningKeywordCostMoney,
            loser: losingKeywordCostMoney,
        },
        {
            id: 'clicks',
            metric: 'Clicks',
            winner: useNumber({ value: winnerMetrics.clicks }).displayValue.value,
            loser: useNumber({ value: loserMetrics.clicks }).displayValue.value,
        },
        {
            id: 'impressions',
            metric: 'Impressions',
            winner: useNumber({ value: winnerMetrics.impressions }).displayValue.value,
            loser: useNumber({ value: loserMetrics.impressions }).displayValue.value,
        },
    ]

    const isUsingCpa = computed(
        () => !performanceMode || performanceMode === Targets.PerformanceMode.CPA
    )

    if (isUsingCpa.value) {
        tableItems.push(
            {
                id: 'conv',
                metric: 'Conversions',
                winner: (+winnerMetrics.all_conversions.toFixed(2)).toString(),
                loser: (+loserMetrics.all_conversions.toFixed(2)).toString(),
            },
            {
                id: 'cpa',
                metric: 'CPA',
                winner: winningKeywordCpaMoney,
                loser: losingKeywordCpaMoney,
            }
        )
    } else {
        tableItems.push(
            {
                id: 'conv_val',
                metric: 'Conversion Value',
                winner: useDomainMoney({ value: winnerMetrics.all_conversions_value }).value
                    .displayValue.value,
                loser: useDomainMoney({ value: loserMetrics.all_conversions_value }).value
                    .displayValue.value,
            },
            {
                id: 'roas',
                metric: 'ROAS',
                winner: winningKeywordRoas,
                loser: losingKeywordRoas,
            }
        )
    }

    // CONDITIONAL STATEMENTS
    const betterCpa = justification === DK.ImprovementJustification.LowerCpa
    const betterRoas = justification === DK.ImprovementJustification.HigherRoas
    const betterProfit = justification === DK.ImprovementJustification.HigherProfit
    const noQualityScore = justification === DK.ImprovementJustification.NoQualityScore
    const higherQualityScore = justification === DK.ImprovementJustification.HigherQualityScore
    const higherCtr = justification === DK.ImprovementJustification.HigherCTR
    const higherImpressions = justification === DK.ImprovementJustification.HigherImpressions

    // COPY ITEMS
    const copyItem = {
        winningKeywordCpa: winningKeywordCpaMoney,
        losingKeywordCpa: losingKeywordCpaMoney,

        winningKeywordRoas: winningKeywordRoas,
        losingKeywordRoas: losingKeywordRoas,

        winningKeywordProfit: useDomainMoney({ value: winnerMetrics.profit }).value.displayValue
            .value,
        losingKeywordProfit: useDomainMoney({ value: loserMetrics.profit }).value.displayValue
            .value,

        winningKeywordQS: winnerMetrics.quality_score,
        losingKeywordQS: loserMetrics.quality_score,

        // TODO: if loser ctr is 0 then do something else?
        ctrDiff: usePercent({
            value: (winnerMetrics.ctr - loserMetrics.ctr) / loserMetrics.ctr,
        }).displayValue.value,

        winningDaysWithImpressions: winnerMetrics.days_with_impressions,
        losingDaysWithImpressions: loserMetrics.days_with_impressions,
    }

    watch(
        affectLosingDuplicate,
        () =>
            (pushActionText.value = `${actionCopy.infinitive} ${capitalise(
                keywordCopy.value
            )} Duplicate`)
    )

    // AUXILIARY FUNCTIONS
    const pushActionText = ref(
        `${actionCopy.infinitive} ${capitalise(keywordCopy.value)} Duplicate`
    )

    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        `${actionCopy.present} ${keywordCopy.value} duplicate..`,
        'Confirming changes..',
        `Duplicate keyword ${actionCopy.past.toLowerCase()} successfully.`,
    ])

    const onPush: OnPushHandler<DK.ExtraDetails> = () => {
        return {
            valid: true,
            pushedData: { winner: !affectLosingDuplicate.value, delete_keyword: false },
        }
    }

    return {
        title,
        pushMessages,
        onPush,
        lastUpdated,
        affectLosingDuplicate,
        keywordCopy,
        affectLosingKeyword,
        actionCopy,
        diffConvActions,
        winningKeyword,
        losingKeyword,
        winningKeywordStats,
        losingKeywordStats,
        winningProfitNegative,
        losingProfitNegative,
        keywordChecks,
        tableHeaders,
        tableItems,
        betterCpa,
        betterRoas,
        betterProfit,
        noQualityScore,
        higherQualityScore,
        higherCtr,
        higherImpressions,
        copyItem,
        pushActionText,
        locationEntityIds,
    }
}
