<template>
    <EntityPillList
        v-if="
            formattedLocations.length > 1 ||
            (formattedLocations[0].content && formattedLocations[0].content.length > 30)
        "
        :items="formattedLocations"
        :scrollToRight="true"
        style="width: auto; max-width: calc(100% - 3.875rem)"
    />
    <EntityPill v-else v-bind="formattedLocations[0]" />
</template>

<script setup lang="ts">
import { EntityPill, EntityPillList } from '@opteo/components-next'
import { useImprovementLinkableLocations } from '@/composition/improvement/useImprovementLinkableLocations'

import type { Entity, Improvement } from '@opteo/types'

const props = defineProps<{
    locations: Improvement.AccountLocation<Entity.EntityLocation>[]
}>()

const { formattedLocations } = useImprovementLinkableLocations(props.locations)
</script>
