<template>
    <EntityPillList :items="formattedItems" />
</template>

<script setup lang="ts" generic="T extends ValidEntity[]">
import { computed } from 'vue'
import { EntityPillList, EntityPillProps } from '@opteo/components-next'

import { useDeepLinking } from '@/composition/global/useDeepLinking'
import { useEntityPillLink } from '@/composition/global/useEntityPillLink'

import type { EntityPillListLinkProps, ValidEntity } from './types'

const props = defineProps<EntityPillListLinkProps<T>>()

const { ocid } = useDeepLinking()

const formattedItems = computed<EntityPillProps[]>(() => {
    return props.items.map(item => {
        const { url, cursor } = useEntityPillLink({
            deepLinkParams: item.deepLinkParams,
            entityType: item.entityPillData.type,
            ocid,
        })

        if (!url) {
            return item.entityPillData
        }

        return {
            ...item.entityPillData,
            cursor,
            linkUrl: url,
        }
    })
})
</script>
