import { Entity, Improvement } from '@opteo/types'

import {
    UseImprovement,
    useImprovement,
    checkImprovement,
} from '@/composition/improvement/useImprovement'
import { computed, ref } from 'vue'
import { EntityPillLinkProps, ValidEntity } from '@/components/global/Entity/types'
interface MissingCampaign {
    id: number
    name: string
    advertising_channel_sub_type: number
    resource_name: string
}

interface Body {
    exclusion_list_id: string
    exclusion_list_name: string
    missing_campaigns: MissingCampaign[]
    placement_types: {
        urls: string[]
        counts: {
            [key: string]: number
        }
    }
}

interface UseResyncExclusionLists {
    exclusionListName: string
    entityPill: EntityPillLinkProps<'placement-exclusion-list'>
    formattedCampaignData: EntityPillLinkProps<ValidEntity>[]
}

export function useResyncExclusionLists(): UseImprovement<UseResyncExclusionLists> {
    const { improvement, lastUpdated, title } = useImprovement<
        Body,
        typeof Improvement.RecAction.ResyncExclusionLists
    >()

    const { body, location } = checkImprovement(improvement)

    const { missing_campaigns: campaigns, exclusion_list_name: exclusionListName } = body

    const formattedCampaignData: EntityPillLinkProps<'campaign'>[] = campaigns.map(campaign => {
        return {
            entityPillData: { type: 'campaign', content: campaign.name },
            deepLinkParams: { campaignId: campaign.id },
        }
    })

    const pushActionText = ref(campaigns.length === 1 ? 'Sync Campaign' : 'Sync Campaigns')

    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Applying placement exclusion list..',
        'Confirming changes..',
        'Exclusion list applied successfully.',
    ])

    const entityPill: EntityPillLinkProps<'placement-exclusion-list'> = {
        entityPillData: {
            type: Entity.EntityLocation.PlacementExclusionList,
            content: exclusionListName,
        },
        deepLinkParams: location[0].entityIds,
    }

    return {
        title,
        pushMessages,
        formattedCampaignData,
        exclusionListName,
        lastUpdated,
        entityPill,
        pushActionText,
    }
}
