import { TopSearchTerms } from '@opteo/types/improvement/types/skag/add_skag_v2'
import { ref, computed, Ref, unref, ComputedRef } from 'vue'

import { UsePercentParams } from '@/composition/utils/types'

import {
    OnPushHandler,
    Step,
    useImprovement,
    UseImprovement,
    checkImprovement,
} from '../useImprovement'

import { usePercent } from '@opteo/components-next'
import { useDomain } from '@/composition/domain/useDomain'

type FormElement = HTMLDivElement & { resolveAd: () => void; valid: boolean }

interface UseWriteAd {
    adWriter: Ref<FormElement | undefined>
    setNewAd: (ad: Ad) => void
    usePercent: (params: UsePercentParams) => any
    unref: <T>(ref: T | Ref<T>) => T
    root_domain: string
    improvementId: number
    adgroupLabel: string
    adGroupId: string
    campaignId: string
    topSearchTerms: TopSearchTerms[]
    legacy?: boolean
    clicks: number
    ctr: number
    cost: number
    impressions: number
    impr_abs_top: number
    newAd: Ref<Ad | undefined>
    currentAd: Ref<TextAdParams | undefined>
    domainId: ComputedRef<number>
}
interface Ad {
    description?: string
    destination_url?: string
    expanded_text_ad_description2?: string
    expanded_text_ad_headline3?: string
    headline_part_1?: string
    headline_part_2?: string
    path_1?: string
    path_2?: string
}

interface TextAdParams {
    headlineOne: string
    headlineTwo: string
    headlineThree: string
    descriptionOne: string
    descriptionTwo: string
    displayUrl: string
    pathOne: string
    pathTwo: string
}

// Move to @opteo/types
interface WriteAd {
    domain_id: string
    ad_id: string
    adgroup_id: string
    campaign_id: string
    raw_clicks: number
    clicks: number
    impressions: number
    impr_abs_top: number
    impr_top: number
    ad_type: number
    cost: number
    ctr: number
    raw_ctr: number
    campaign_label: string
    adgroup_label: string
    headline: string
    expanded_text_ad_headline3: string
    line_1: string
    line_2: string
    display_url: string
    headline_part_1: string
    headline_part_2: string
    description: string
    expanded_text_ad_description2: string
    path_1: string
    path_2: string
    root_domain: string
    adgroup_30d_cost: number
    top_search_terms: TopSearchTerms[]
    legacy?: boolean
}

export function useWriteAd(): UseImprovement<UseWriteAd> {
    const { domainId } = useDomain()
    const { improvement, lastUpdated, title } = useImprovement<WriteAd>()

    const {
        body: {
            headline_part_1,
            headline_part_2,
            expanded_text_ad_description2,
            expanded_text_ad_headline3,
            path_1,
            path_2,
            legacy,
            description,
            adgroup_id: adGroupId,
            campaign_id: campaignId,
            root_domain,
            top_search_terms: topSearchTerms,
            adgroup_label: adgroupLabel,
            clicks,
            ctr,
            cost,
            impressions,
            impr_abs_top,
        },
        improvement_id: improvementId,
    } = checkImprovement(improvement)

    const newAd = ref<Ad>({})
    const currentAd = computed(() => {
        return {
            headlineOne: headline_part_1,
            headlineTwo: headline_part_2,
            headlineThree: expanded_text_ad_headline3 ?? '',
            descriptionOne: description,
            descriptionTwo: expanded_text_ad_description2 ?? '',
            displayUrl: root_domain,
            pathOne: path_1,
            pathTwo: path_2,
        }
    })

    const adjustSteps = ref(['Open Ad Writer', 'Preview New Ad'])

    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        `Creating new ad..`,
        'Confirming changes..',
        `New ad created successfully.`,
    ])

    const adWriter = ref<FormElement>()

    function setNewAd(ad: Ad) {
        newAd.value = ad
    }

    function onStepComplete({ step }: { step: Step }): boolean {
        if (step === Step.ONE) {
            return adWriter.value?.resolveAd() ?? false
        }

        return true
    }

    const onPush: OnPushHandler<Ad> = () => {
        return { valid: true, pushedData: newAd.value! }
    }

    const pushActionText = ref('Push New Ad')

    return {
        title,
        pushMessages,
        lastUpdated,
        usePercent,
        unref,
        onPush,
        adWriter,
        onStepComplete,
        root_domain,
        domainId,
        improvementId,
        adgroupLabel,
        adGroupId,
        campaignId,
        topSearchTerms,
        setNewAd,
        newAd,
        legacy,
        clicks,
        ctr,
        cost,
        impressions,
        impr_abs_top,
        currentAd,
        pushActionText,
        adjustSteps,
    }
}
