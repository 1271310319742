<template>
    <div class="performance-mode-container">
        <oInput
            :model-value="performanceMode ?? 1"
            @updated="updatedPerformanceMode = $event"
            :items="performanceModeItems"
            name="performanceMode"
            type="select"
            label="Performance Mode"
        />
        <Spacer height="1rem" />
        <Text as="p" size="f-9" style="font-size: 0.75rem; line-height: 1.125rem" color="gray">
            Select the performance mode best suited to your account. Opteo will display your
            performance data and generate improvements based on your chosen performance mode.
        </Text>
        <div v-if="showUpdateButton" class="update-button-container">
            <oButton
                ref="updatePerformanceModeButton"
                :loading="updatingPerformanceMode"
                @clicked="updatePerformanceMode"
                size="medium"
            >
                Update Performance Mode
            </oButton>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'

import { oInput, oButton, Text, Spacer } from '@opteo/components-next'
import { useDomain } from '@/composition/domain/useDomain'
import { authRequest, Endpoint } from '@/composition/api/useAPI'
import { Targets } from '@opteo/types'
import { useAccount } from '@/composition/account/useAccount'

defineProps<{ showUpdateButton?: boolean }>()

const { performanceMode, mutateAccount } = useAccount()

const updatedPerformanceMode = ref(performanceMode.value)

const performanceModeItems = [
    {
        label: 'Cost Per Conversion (CPA)',
        value: Targets.PerformanceMode.CPA,
    },
    {
        label: 'Return On Ad Spend (ROAS)',
        value: Targets.PerformanceMode.ROAS,
    },
]

const updatingPerformanceMode = ref(false)
const updatePerformanceModeButton = ref()

async function updatePerformanceMode() {
    const performanceModeNeedsUpdating =
        updatedPerformanceMode.value && performanceMode.value !== updatedPerformanceMode.value

    if (!performanceModeNeedsUpdating) {
        updatePerformanceModeButton.value?.flashSuccess()
        return
    }

    updatingPerformanceMode.value = true

    await authRequest(Endpoint.UpdatePerformanceMode, {
        body: {
            performance_mode: updatedPerformanceMode.value,
        },
    })

    await mutateAccount()

    updatingPerformanceMode.value = false
    updatePerformanceModeButton.value?.flashSuccess()
}

// This is needed to trigger the function from the parent component
defineExpose({
    updatePerformanceMode,
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';
.section-content .performance-mode-container,
.settings-section .performance-mode-container {
    @include pa-32;
    @include pb-28;
}

.update-button-container {
    @include flex-center;
    @include ph-32;
    padding-top: 1.625rem;
    @include pb-4;
}
</style>
