<template>
    <ImprovementView
        :push-messages="pushMessages"
        :push-action-text="pushActionText"
        :isAdjustable="true"
        :on-push="onPush"
        @back-clicked="resetBid"
    >
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <EntityPillLinkList :items="entityPillList" :scrollToRight="true" />
                    <Spacer height="2rem" />
                </div>
                <Text>
                    The keyword listed above is eligible to run ads, but has
                    <b>Below First Page Bid</b>
                    status. Searchers are searching for this keyword, but your ads frequently appear
                    below the first page in Google's search results, and in some cases, not at all.
                </Text>
                <Spacer height="1rem" />
                <Text>
                    Consider increasing your keyword bid by
                    <b><Percent :value="bidChange" :decimal-places="0" /></b> — from
                    <b><Money :value="currentBid" /></b> to <b><Money :value="newBid" /></b> —
                    <span v-if="currentBid * 1.3 > firstPageBid"> in order to exceed the </span>
                    <span v-else>to move towards the</span> first page bid estimate of
                    <b><Money :value="firstPageBid" /></b>. Click
                    <Text as="span" color="green" weight="600">{{ pushActionText }}</Text> to
                    increase your keyword bid by
                    <b><Percent :value="bidChange" :decimal-places="0" /></b>. Click
                    <b>Adjust</b> to set a specific bid amount.
                </Text>
            </ImprovementHeader>
            <ImprovementHelpLink path="/en/articles/2410538-fix-below-first-page-bids">
                Learn how Opteo flagged this keyword and calculated the adjustment.
            </ImprovementHelpLink>
        </ImprovementContent>
        <ImprovementContent>
            <ImprovementStatistics
                title="Recommended Bid Adjustment"
                :items="[
                    {
                        key: 'bidAdjustment',
                        deltaMode: true,
                        beforeValue: formattedCurrentBid,
                        beforeTitle: 'Current CPC Bid',
                        afterValue: formattedNewBid,
                        afterTitle: 'Adjusted CPC Bid',
                    },
                ]"
            >
                <template #bidAdjustment>
                    Opteo recommends a
                    <Text as="span" weight="600" color="green">
                        <Percent :value="bidChange" :decimal-places="0" :include-sign="true" />
                    </Text>
                    bid adjustment.
                </template>
            </ImprovementStatistics>
        </ImprovementContent>
        <ImprovementContent header="Improvement Statistics">
            <ImprovementStatistics :items="improvementStatsItems">
                <template #firstPageBid>
                    Google currently recommends bidding
                    <b><Money :value="firstPageBid" /></b> on this keyword in order for your ads to
                    appear on the first page of search results.
                </template>
                <template #cost>
                    <b><Money :value="keywordCost" /></b> was spent on this keyword over the last
                    <b>{{ lookbackWindow }}</b> days.
                </template>
                <template #cpc>
                    You're paying an average of
                    <b><Money :value="keywordCost / keywordClicks" /></b> per click for this
                    keyword.
                </template>
                <template #impressions>
                    This keyword has generated <b><Number :value="keywordImpressions" /></b>
                    {{ +keywordImpressions === 1 ? 'impression' : 'impressions' }} over the past
                    <b>{{ lookbackWindow }}</b> days.
                </template>
                <template #clicks>
                    This keyword has generated <b><Number :value="keywordClicks" /></b>
                    {{ +keywordClicks === 1 ? 'click' : 'clicks' }} over the past
                    <b>{{ lookbackWindow }}</b> days.
                </template>
                <template #qs>
                    This keyword has a Quality Score of <b>{{ keywordQualityScore }}</b
                    >.
                </template>
                <template #searchImprShare>
                    Your ads were shown in Google's search results
                    <b><Percent :value="searchImpressionShare" /></b> of the time they were
                    eligible.
                </template>
            </ImprovementStatistics>
        </ImprovementContent>

        <ImprovementContent v-if="!hasNoImpressions">
            <div v-if="imprShareSeries">
                <Text as="h3" size="f-4" weight="600">Impression Statistics</Text>
                <Spacer height="1.5rem" />
                <Text>
                    The graph below indicates how often this keyword lost search impression share to
                    competitors because of low Ad Rank. A higher bid should help improve Ad Rank.
                </Text>
                <Spacer height="2rem" />
                <div class="graph-container">
                    <AreaChart
                        :series="imprShareSeries"
                        :show-zero="true"
                        :axis-titles="{
                            y: 'Search Impression Share (%)',
                            x: `Date (${formattedStartDate} → ${formattedEndDate})`,
                        }"
                        :metric="{
                            dataType: 'percentage',
                            label: 'Search Impression Shares',
                        }"
                        :max-item="1"
                        disable-fallback
                    />
                </div>
            </div>
            <div v-if="impressionSeries">
                <Spacer height="3rem" />
                <Text>
                    The graph below illustrates the consistency of impressions received by this
                    keyword over the last <b>{{ lookbackWindow }}</b> days. This keyword's
                    <b>Below First Page Bid</b> status could be caused by a recent adjustment, or
                    because of an ongoing issue.
                </Text>
                <Spacer height="2rem" />
                <div class="graph-container">
                    <AreaChart
                        :series="impressionSeries"
                        :show-zero="true"
                        :metric="{
                            label: 'Impressions',
                        }"
                        :axis-titles="{
                            y: 'Impressions',
                            x: `Date (${formattedStartDate} → ${formattedEndDate})`,
                        }"
                        disable-fallback
                    />
                </div>
            </div>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="lookbackWindow" />
        </ImprovementContent>

        <template v-slot:adjust-step-1>
            <ImprovementContent>
                <ImprovementHeader title="Adjust Keyword Bid">
                    <div v-if="aboveMobile">
                        <EntityPillLink
                            :entity-pill-data="{ type: 'keyword', content: formattedKeyword }"
                            :deep-link-params="keywordEntityIds"
                        />
                        <Spacer height="2rem" />
                    </div>
                    <Text as="p">
                        Consider increasing your bid for the keyword listed above. The current bid
                        for this keyword is <b><Money :value="currentBid" /></b>. For reference, the
                        first page bid estimate is <b><Money :value="firstPageBid" /></b>.
                    </Text>
                </ImprovementHeader>

                <InlineInputContainer :single-side="true">
                    <template #left-side>
                        <Text as="span" size="f-8" class="adjustment-text">Set keyword bid to</Text>
                    </template>
                    <template #input>
                        <oForm ref="bidInputForm">
                            <oInput
                                type="number"
                                name="bid"
                                v-model.number="adjustedBid"
                                :prefix="currencySymbol"
                                class="adjustment-input"
                                autofocus
                                required
                                min="0.01"
                                @updated="handleBidUpdate"
                            />
                        </oForm>
                    </template>
                </InlineInputContainer>
            </ImprovementContent>
        </template>
    </ImprovementView>
</template>
<script lang="ts" setup>
import { Text, Spacer, Percent, AreaChart, Number, oForm, oInput } from '@opteo/components-next'
import Money from '@/components/global/oMoney.vue'

import EntityPillLink from '@/components/global/Entity/EntityPillLink.vue'
import EntityPillLinkList from '@/components/global/Entity/EntityPillLinkList.vue'

import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import InlineInputContainer from '@/components/improvement/shared-components/InlineInputContainer.vue'

import { useIncreaseKeywordBidV2 } from '@/composition/improvement/types/useIncreaseKeywordBidV2'
import ImprovementBidAdjustment from '@/components/improvement/ImprovementBidAdjustment.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'

const {
    title,
    pushMessages,
    pushActionText,
    onPush,
    entityPillList,
    lastUpdated,
    currentBid,
    bidChange,
    formattedCurrentBid,
    newBid,
    formattedNewBid,
    improvementStatsItems,
    adjustedBid,
    firstPageBid,
    searchImpressionShare,
    keywordQualityScore,
    keywordClicks,
    keywordImpressions,
    lookbackWindow,
    formattedKeyword,
    keywordCost,
    impressionSeries,
    imprShareSeries,
    hasNoImpressions,
    formattedStartDate,
    formattedEndDate,
    onBidUpdate,
    currencySymbol,
    handleBidUpdate,
    resetBid,
    keywordEntityIds,
} = useIncreaseKeywordBidV2()

const { aboveMobile } = useMediaQuery()
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

// Adjustment

.input-container {
    @include flex;
    @include items-center;
}
.adjustment-input {
    width: 9.75rem;
}
.graph-container {
    @include container;
    @include pa-32;
}

// Responsive

@media (min-width: $mq-321-min) and (max-width: $mq-375-max) {
    .adjustment-input {
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .graph-container {
        box-shadow: unset;
        padding: unset;
    }
}

@media screen and (max-width: $mq-767-max) {
    .adjustment-input {
        @include w-100;
    }
}
</style>
