import { computed, Ref, inject, provide, ref, ComputedRef } from 'vue'
import { useRouter } from 'vue-router'
import { Account, Alert } from '@opteo/types'
import { useAlerts } from './useAlerts'
import { authRequest, Endpoint, useAPI } from '../api/useAPI'

interface UseAlert<AlertBody = unknown, ChartData = unknown> {
    alert: ComputedRef<Alert.Object<AlertBody, ChartData> | undefined>
    loading: Ref<boolean>
    alertType: Ref<Alert.Type | undefined>
    isCampaignLevel: Ref<boolean | undefined>
    accountId: Ref<Account.ID | undefined>
    markAsRead: () => Promise<void>
    alertModalOpen: Ref<boolean>
    currencyCode: ComputedRef<string>
}

const ALERT_INJECT_KEY = 'alert'

export function useAlert<AlertBody = unknown, ChartData = unknown>(): UseAlert<
    AlertBody,
    ChartData
> {
    const injected = inject<UseAlert<AlertBody, ChartData>>(ALERT_INJECT_KEY)

    if (!injected) {
        throw new Error(`Alert not yet injected, something is wrong. `)
    }

    return injected
}

export function setFloatingAlertId(id?: string) {
    currentlyFloatingAlertId.value = id
}

const currentlyFloatingAlertId = ref<string>()
/*
    example direct links:
    http://localhost:8080/user/416/alerts/6a8da2c4-4793-43d4-be50-39e3f04ef2b4
    http://localhost:8080/user/416/alerts/e7c963d4-b2f1-4e45-bebb-d683da816b5f
    http://localhost:8080/user/416/alerts/e7c963d4-b2f1-4e45-bebb-d683da816b5f

*/
export function provideAlert<AlertBody = unknown, ChartData = unknown>(): UseAlert<
    AlertBody,
    ChartData
> {
    const { alerts, loading, mutateAlert } = useAlerts()
    const { currentRoute } = useRouter()

    const alertModalOpen = ref(false)

    const alertId = computed(() => {
        return currentRoute.value.params.alertId || currentlyFloatingAlertId.value
    })

    const alert = computed(() => {
        return alerts.value?.find(alert => alert.hashId === alertId.value)
    }) as ComputedRef<Alert.Object<AlertBody, ChartData>>

    const alertType = computed(() => alert.value?.type)
    const isCampaignLevel = computed(() => alert.value?.isCampaignLevel)
    const accountId = computed(() => alert.value?.accountId)

    const currencyCode = computed(() => alert.value?.accountCurrencyCode)

    const markAsRead = async () => {
        if (!alert.value) {
            throw new Error('Alert must be set before marking as read.')
        }

        mutateAlert(alert.value.id, {
            ...alert.value,
            checked: true,
        })

        await authRequest(Endpoint.SetAlertsAllRead, {
            body: {
                alert_ids: [alert.value.id],
            },
        })
    }

    const toProvide = {
        alert,
        loading,
        alertType,
        isCampaignLevel,
        accountId,
        markAsRead,
        alertModalOpen,
        currencyCode,
    }

    provide(ALERT_INJECT_KEY, toProvide)

    return toProvide
}
