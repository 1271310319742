<template>
    <ImprovementView
        :push-messages="pushMessages"
        :push-action-text="pushActionText"
        :steps="adjustSteps"
        :on-push="onPush"
    >
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <EntityPillLink
                        :entity-pill-data="{ type: 'campaign', content: campaignName }"
                        :deep-link-params="locationEntityIds"
                    />
                    <Spacer height="2rem" />
                </div>

                <Text as="p">
                    The campaign listed above accounts for a significant portion of your spend.
                    Opteo recommends having at least <b>3</b> custom structured snippets for
                    high-spend campaigns. Campaign specific snippets should help to increase ad
                    relevance and conversions.
                </Text>
                <Spacer height="1rem" />
                <Text as="p">
                    Click <Text as="span" weight="600" color="blue">{{ adjustSteps[0] }}</Text> to
                    write, preview and add custom snippets to this campaign, replacing the
                    highlighted account-level snippets displayed below.
                </Text>
            </ImprovementHeader>
            <ImprovementHelpLink path="/improvements/extensions/add-extensions-to-campaign">
                Click here to learn more about the benefits of campaign-level extensions.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <Text as="h3" size="f-4" weight="600">Account Level Snippets</Text>
        </ImprovementContent>

        <ImprovementContent :ad-width="true">
            <!-- 
            NOTE: Always display to be the highest clicked ad in this campaign.
            Changes on the adjust step should not be reflected here.
         -->
            <TextAd
                :ad="highestClickedAdStatic"
                highlight="structured-snippets"
                :title-tag="adTitleTag"
            />
        </ImprovementContent>

        <ImprovementContent>
            <Spacer height="3rem" />
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="90" />
        </ImprovementContent>

        <template v-slot:adjust-step-1>
            <ImprovementContent>
                <ImprovementHeader title="Customise Structured Snippets">
                    <div v-if="aboveMobile">
                        <EntityPillLink
                            :entity-pill-data="{ type: 'campaign', content: campaignName }"
                            :deep-link-params="locationEntityIds"
                        />
                        <Spacer height="2rem" />
                    </div>
                    <Text as="p">
                        Use the form below to add campaign-level structured snippets to replace the
                        account level structured snippets highlighted below. Use the preview of your
                        highest clicked ad to see how your snippets will appear on the search engine
                        results page.
                    </Text>
                    <Spacer height="1rem" />
                    <Text as="p">
                        Click
                        <Text as="span" weight="600" color="green">{{ pushActionText }}</Text> to
                        push your structured snippets to Google Ads.
                    </Text>
                </ImprovementHeader>
            </ImprovementContent>

            <ImprovementContent :ad-width="true">
                <!-- Dynamic preview based on inputs -->
                <TextAd
                    :ad="highestClickedAd"
                    highlight="structured-snippets"
                    :title-tag="adTitleTag"
                />
            </ImprovementContent>
            <ImprovementContent>
                <Spacer height="3rem" />
                <div class="form-container">
                    <oForm ref="snippetForm">
                        <div class="form-inputs">
                            <oInput
                                v-model="selectedLanguage"
                                label="Ad Language"
                                :items="languagesDropDownItems"
                                name="adlanguage"
                                type="select"
                            />
                            <Spacer height="1.5rem" />
                            <oInput
                                v-model="selectedHeader"
                                ref="headerRef"
                                label="Header (Required)"
                                :items="headersDropDownItems"
                                type="select"
                                name="header"
                                required
                            />
                            <Spacer height="1.5rem" />
                            <div v-for="(extension, index) in snippetExtensions" v-bind:key="index">
                                <oInput
                                    v-model="extension.snippet"
                                    :name="`snippet${index}`"
                                    :label="`Snippet ${getNumberWord(index + 1)} ${
                                        extension.required ? `(Required)` : ``
                                    }`"
                                    :placeholder="`Snippet ${getNumberWord(index + 1)}`"
                                    :required="extension.required"
                                    :max-characters="MAXIMUM_SNIPPET_LENGTH"
                                />
                                <Spacer
                                    height="1.5rem"
                                    v-if="index + 1 < snippetExtensions.length"
                                />
                            </div>
                        </div>
                        <div class="button-container">
                            <oButton
                                :size="aboveMobile ? 'small' : 'medium'"
                                color="white"
                                title="Reset Form"
                                @clicked="resetSnippetExtensions(false)"
                                :fullWidth="aboveMobile ? false : true"
                            >
                                Reset Form
                            </oButton>
                            <Spacer v-if="aboveMobile" width="0.5rem" />
                            <Spacer v-else height="0.75rem" />
                            <oButton
                                :size="aboveMobile ? 'small' : 'medium'"
                                color="white"
                                title="Clear Form"
                                @clicked="resetSnippetExtensions(true)"
                                :fullWidth="aboveMobile ? false : true"
                            >
                                Clear Form
                            </oButton>
                        </div>
                    </oForm>
                </div>
            </ImprovementContent>
        </template>
    </ImprovementView>
</template>
<script lang="ts">
import { Text, Spacer, TextAd, oInput, oForm, oButton } from '@opteo/components-next'
import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import ImprovementCreatedDate from '@/components/improvement/ImprovementCreatedDate.vue'
import ImprovementContent from '@/components/improvement/ImprovementContent.vue'
import ImprovementHeader from '@/components/improvement/ImprovementHeader.vue'
import CircleButton from '@/components/global/CircleButton.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'
import EntityPillLink from '@/components/global/Entity/EntityPillLink.vue'

import { useAddCampaignLevelSnippetExtension } from '@/composition/improvement/types/useAddCampaignLevelSnippetExtension'
import { defineComponent } from 'vue'
import { getNumberWord } from '@/lib/globalUtils'

export default defineComponent({
    name: 'AddCampaignLevelSnippetExtension',
    components: {
        ImprovementCreatedDate,
        ImprovementContent,
        ImprovementHeader,
        ImprovementView,
        ImprovementHelpLink,
        EntityPillLink,
        Text,
        Spacer,
        TextAd,
        oInput,
        oForm,
        CircleButton,
        oButton,
    },
    setup() {
        const { aboveMobile } = useMediaQuery()
        return {
            getNumberWord,
            ...useAddCampaignLevelSnippetExtension(),
            aboveMobile,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.form-container {
    @include container;
    @include relative;
}
.form-inputs {
    @include pa-36;
}

.button-container {
    border-top: 1px solid;
    @include opteo-border;
    @include flex;
    @include items-center;
    @include ph-36;
    @include pv-28;
}

@media screen and (max-width: $mq-767-max) {
    .button-container {
        @include block;
        @include pa-24;
    }
    .form-inputs {
        @include pa-24;
    }
}
</style>
