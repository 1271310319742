import { computed, ref, Ref, ComputedRef, unref } from 'vue'
import forEach from 'lodash-es/forEach'
import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import { usePercent } from '@opteo/components-next'
import { CheckAdSpelling, Improvement } from '@opteo/types'
import { getCharCount } from '../../improvement/components/ad-writer/utils'

import {
    OnPushHandler,
    useImprovement,
    UseImprovement,
    checkImprovement,
} from '@/composition/improvement/useImprovement'

interface Error {
    headline_part_1: string | null
    headline_part_2: string | null
    headline_part_3: string | null
    description: string | null
    description2: string | null
}

interface UseCheckAdSpelling {
    improvement: Ref<CheckAdSpelling.CheckAdSpellingBody> | Ref
    displayedAdText: Ref<DisplayAdText> | Ref
    adContent: AdContent
    flaggedAdContent: AdContent
    rawAdContent: ComputedRef<AdContent>
    char_length: Ref<CharLength> | Ref
    updateDisplayedAdText: (event: string, section: CheckAdSpelling.Section) => void
    adFieldValidator: (value: string) => void
    editAd: (section: CheckAdSpelling.Section) => void
    headline1: Ref<HTMLElement | null>
    headline2: Ref<HTMLElement | null>
    headline3: Ref<HTMLElement | null>
    description: Ref<HTMLElement | null>
    description2: Ref<HTMLElement | null>
    highlightedSections: HighlightedSections
    pathOne: ComputedRef<string | undefined>
    pathTwo: ComputedRef<string | undefined>
    statItems: { title: string; key: string; value: any }[]
}

enum AdErrors {
    InvalidHeadlineOneLength = 'Invalid headline 1 length',
    InvalidHeadlineTwoLength = 'Invalid headline 2 length',
    InvalidHeadlineThreeLength = 'Invalid headline 3 length',
    InvalidHeadPathOneLength = 'Invalid path 1 length',
    InvalidHeadPathTwoLength = 'Invalid path 2 length',
    PathsCantContainSpaces = 'Paths cannot contain spaces',
    PathsCantContainPeriods = 'Paths cannot include periods',
    PathsCantContainSlashes = 'Paths cannot include slashes',
    PathOneBeforePathTwo = 'Must use path 1 before path 2',
    InvalidDescriptionOneLength = 'Invalid description 1 length',
    InvalidDescriptionTwoLength = 'Invalid description 2 length',
}

interface AdContent {
    headlineOne: string
    headlineTwo: string
    headlineThree: string
    displayUrl: string
    finalUrl: string
    pathOne?: string
    pathTwo?: string
    descriptionOne: string
    descriptionTwo: string
    callouts?: string[]
}

type DisplayAdText = {
    [section in CheckAdSpelling.Section]?: {
        visual?: any
        raw?: any
    }
}

type HighlightedSections = Partial<{ [section in `${CheckAdSpelling.Section}_err`]: string }>

type CharLength = {
    [section in CheckAdSpelling.Section]?: number
}

export function useCheckAdSpelling(): UseImprovement<UseCheckAdSpelling> {
    const headline1 = ref()
    const headline2 = ref()
    const headline3 = ref()
    const description = ref()
    const description2 = ref()
    const displayedAdText = ref<DisplayAdText>({})
    const char_length = ref<CharLength>({})

    const adKeys = {
        headline_part_1: 'headline1' as CheckAdSpelling.Section,
        headline_part_2: 'headline2' as CheckAdSpelling.Section,
        headline_part_3: 'headline3' as CheckAdSpelling.Section,
        description: 'description' as CheckAdSpelling.Section,
        description2: 'description2' as CheckAdSpelling.Section,
    }

    const { improvement, lastUpdated, title } =
        useImprovement<CheckAdSpelling.CheckAdSpellingBody>()
    const { body } = checkImprovement(improvement)

    const sections: CheckAdSpelling.Section[] = [
        'headline1',
        'headline2',
        'headline3',
        'description',
        'description2',
    ]

    const highlightedSections: HighlightedSections = {}

    sections.forEach((section: CheckAdSpelling.Section) => {
        if (body.mistakes[section]) {
            displayedAdText.value[section] = {
                visual: getVisualChanges(section, null, null),
                raw: getRawChanges(section),
            }
        } else {
            displayedAdText.value[section] = {
                visual: body[section],
                raw: body[section],
            }
        }
        char_length.value[section] = getCharCount(displayedAdText?.value[section]?.raw)
        highlightedSections[(section + '_err') as `${CheckAdSpelling.Section}_err`] =
            highlightMistakes(section)
    })

    const pathOne = computed(() => {
        return removeSpaces(body.path_1)
    })
    const pathTwo = computed(() => {
        return removeSpaces(body.path_2)
    })

    const adContent = {
        headlineOne: displayedAdText.value?.headline1?.visual ?? body.headline1,
        headlineTwo: displayedAdText.value?.headline2?.visual ?? body.headline2,
        headlineThree: displayedAdText.value?.headline3?.visual ?? body.headline3,
        displayUrl: body.root_domain,
        finalUrl: body.final_url,
        pathOne: pathOne.value,
        pathTwo: pathTwo.value,
        descriptionOne: displayedAdText.value?.description?.visual ?? body.description,
        descriptionTwo: displayedAdText.value?.description2?.visual ?? body.description2,
    }

    const rawAdContent = computed(() => {
        return {
            headlineOne: displayedAdText.value?.headline1?.raw ?? body.headline1,
            headlineTwo: displayedAdText.value?.headline2?.raw ?? body.headline2,
            headlineThree: displayedAdText.value?.headline3?.raw ?? body.headline3,
            displayUrl: body.root_domain,
            finalUrl: body.final_url,
            pathOne: pathOne.value,
            pathTwo: pathTwo.value,
            descriptionOne: displayedAdText.value?.description?.raw ?? body.description,
            descriptionTwo: displayedAdText.value?.description2?.raw ?? body.description2,
        }
    })

    const flaggedAdContent = {
        headlineOne: highlightedSections['headline1_err'] ?? body.headline1,
        headlineTwo: highlightedSections['headline2_err'] ?? body.headline2,
        headlineThree: highlightedSections['headline3_err'] ?? body.headline3,
        displayUrl: body.root_domain,
        finalUrl: body.final_url,
        pathOne: pathOne.value,
        pathTwo: pathTwo.value,
        descriptionOne: highlightedSections['description_err'] ?? body.description,
        descriptionTwo: highlightedSections['description2_err'] ?? body.description2,
    }

    function highlightMistakes(section: CheckAdSpelling.Section) {
        let rel_sentence = body[section]

        if (!body.mistakes[section]) {
            return rel_sentence
        }

        body.mistakes[section].forEach(bad_word => {
            const new_word_marked = `<span class="spelling-error">${bad_word}</span>`
            rel_sentence = rel_sentence.replace(bad_word, new_word_marked)
        })
        return rel_sentence
    }

    function checkAdValidity(ad: {
        headline_part_1: string
        headline_part_2: string
        headline_part_3: string
        description: string
        description2: string
    }): Error {
        const errors = {
            headline_part_1: '',
            headline_part_2: '',
            headline_part_3: '',
            description: '',
            description2: '',
        }

        if (!getCharCount(ad.headline_part_1) || getCharCount(ad.headline_part_1) > 30) {
            errors.headline_part_1 = AdErrors.InvalidHeadlineOneLength
        }

        if (!getCharCount(ad.headline_part_2) || getCharCount(ad.headline_part_2) > 30) {
            errors.headline_part_2 = AdErrors.InvalidHeadlineTwoLength
        }

        if (getCharCount(ad.headline_part_3) > 30) {
            errors.headline_part_3 = AdErrors.InvalidHeadlineThreeLength
        }

        if (!getCharCount(ad.description) || getCharCount(ad.description) > 90) {
            errors.description = AdErrors.InvalidDescriptionOneLength
        }

        if (getCharCount(ad.description2) > 90) {
            errors.description2 = AdErrors.InvalidDescriptionTwoLength
        }

        return errors
    }

    function getVisualChanges(
        section: CheckAdSpelling.Section,
        compare_to: string | null,
        new_words: string[] | null
    ) {
        let rel_sentence = body.suggestions[section]
        let check_words = body.corrections[section]

        if (compare_to !== null) {
            rel_sentence = compare_to
        }
        if (new_words !== null) {
            check_words = new_words
        }

        check_words.forEach(new_word => {
            const new_word_marked = `<span class="spelling-correction">${new_word}</span>`
            rel_sentence = rel_sentence.replace(new_word, new_word_marked)
        })
        return rel_sentence
    }

    function getRawChanges(section: CheckAdSpelling.Section) {
        return body.suggestions[section]
    }

    function updateDisplayedAdText(input: string, section: CheckAdSpelling.Section) {
        if (!section) {
            return
        }
        if (!displayedAdText.value[section]?.raw.length) {
            if (displayedAdText.value[section] && displayedAdText.value[section]?.visual) {
                //@ts-ignore
                displayedAdText.value[section].visual = ''
            }
        }
        //@ts-ignore
        displayedAdText.value[section].raw = input
        char_length.value[section] = getCharCount(input ?? '')
    }

    function removeSpaces(path: string) {
        if (path) {
            return path.replace(/ /g, '_')
        }
    }

    const statItems = [
        {
            title: 'Cost',
            key: 'cost',
            value: unref(
                useDomainMoney({
                    value: body.cost,
                }).value.displayValue
            ),
        },
        {
            title: 'Click Through Rate',
            key: 'ctr',
            value: unref(
                usePercent({
                    value: body.ctr,
                }).displayValue
            ),
        },
        {
            title: 'Clicks',
            key: 'clicks',
            value: body.clicks,
        },
        {
            title: 'Impressions',
            key: 'impressions',
            value: body.impressions,
        },
    ]

    const adjustSteps = ref(['Update Ad Creative'])

    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        `Replacing ad creative..`,
        'Confirming changes..',
        `Ad creative replaced successfully.`,
    ])

    const pushActionText = ref('Push New Ad Creative')

    const onPush: OnPushHandler<DisplayAdText> = () => {
        const valid = validate()

        return {
            valid,
            pushedData: unref(displayedAdText),
        }
    }

    function editAd(section: CheckAdSpelling.Section) {
        focus(section)
    }

    function focus(section: CheckAdSpelling.Section) {
        switch (section) {
            case 'headline1':
                headline1.value.inputRef.focus()
                break
            case 'headline2':
                headline2.value.inputRef.focus()
                break
            case 'headline3':
                headline3.value.inputRef.focus()
                break
            case 'description':
                description.value.inputRef.focus()
                break
            case 'description2':
                description2.value.inputRef.focus()
                break
            default:
                return
        }
    }

    function adFieldValidator(this: { label: string }) {
        let error_message
        const field = this.label
        const errors = checkAdValidity({
            headline_part_1: displayedAdText?.value?.headline1?.raw,
            headline_part_2: displayedAdText?.value?.headline2?.raw,
            headline_part_3: displayedAdText?.value?.headline3?.raw,
            description: displayedAdText?.value?.description?.raw,
            description2: displayedAdText?.value?.description2?.raw,
        })

        switch (field) {
            case 'Headline One':
                error_message = errors['headline_part_1']
                break
            case 'Headline Two':
                error_message = errors['headline_part_2']
                break
            case 'Headline Three':
                error_message = errors['headline_part_3']
                break
            case 'Description One':
                error_message = errors['description']
                break
            case 'Description Two':
                error_message = errors['description2']
                break
            default:
                return
        }

        return error_message
    }
    function validate() {
        let valid = true
        const errors = checkAdValidity({
            headline_part_1: displayedAdText?.value?.headline1?.raw,
            headline_part_2: displayedAdText?.value?.headline2?.raw,
            headline_part_3: displayedAdText?.value?.headline3?.raw,
            description: displayedAdText?.value?.description?.raw,
            description2: displayedAdText?.value?.description2?.raw,
        })

        forEach(errors, (error, key) => {
            const section: CheckAdSpelling.Section =
                adKeys[
                    key as keyof Pick<
                        Error,
                        | 'headline_part_1'
                        | 'headline_part_2'
                        | 'headline_part_3'
                        | 'description'
                        | 'description2'
                    >
                ]

            if (!section) {
                return
            }
            if (error) {
                valid = false
                if (!displayedAdText.value[section]?.raw.length) {
                    //@ts-ignore
                    displayedAdText.value[section].visual =
                        '<span style="opacity: 0.3">this field is required</span>'
                }
                focus(section)
            }
        })
        return valid
    }

    return {
        improvement,
        lastUpdated,
        title,
        displayedAdText,
        adContent,
        flaggedAdContent,
        rawAdContent,
        char_length,
        updateDisplayedAdText,
        editAd,
        onPush,
        pushMessages,
        headline1,
        headline2,
        headline3,
        description,
        description2,
        highlightedSections,
        pathOne,
        pathTwo,
        statItems,
        adFieldValidator,
        pushActionText,
        adjustSteps,
    }
}
