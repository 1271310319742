<template>
    <div class="impact-statistics-alert-row">
        <!-- Avatar -->
        <Tooltip :content="alert.account.domainName">
            <Avatar
                :color="alert.account.color"
                :platform="alert.account.platform"
                show-account-type
            >
                {{ alert.account.initials }}
            </Avatar>
        </Tooltip>
        <!-- Alert Title -->
        <div class="alert-title-wrapper">
            <h4 class="alert-title">
                {{ alert.title }}
            </h4>
            <span class="alert-subline">Generated on {{ alert.date }}</span>
        </div>
    </div>
</template>

<script lang="ts">
import { PropType } from 'vue'
import { Avatar, Tooltip, EntityPill, useMoney, Text, ColorTag } from '@opteo/components-next'
import { IS } from '@opteo/types'

export default {
    name: 'ImpactStatisticsAlertRow',
    props: {
        alert: { type: Object as PropType<IS.CriticalAlertOverviewInstance>, required: true },
    },
    components: { Avatar, Tooltip, EntityPill, useMoney, Text, ColorTag },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.impact-statistics-alert-row {
    @include flex;
    @include items-center;
    @include pl-24;
    @include br-24;
    @include opteo-background;
    transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.25s;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    outline: none;
    overflow: hidden;
    height: 5rem;
    @include mb-16;
    cursor: default;
    @include relative;
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;
}
.impact-statistics-alert-row.last {
    @include mb-0;
}
.alert-title-wrapper {
    @include ml-20;
}
.alert-title {
    @include opteo-foreground;
    font-size: 1rem;
    letter-spacing: -0.0125rem;
    font-weight: 500;
    line-height: 100%;
    margin-bottom: 0.25rem;
    white-space: nowrap;
}
.alert-subline {
    @include opteo-foreground;
    font-size: 0.6875rem;
    letter-spacing: -0.005rem;
    font-weight: 400;
    line-height: 130%;
    opacity: 0.3;
    white-space: nowrap;
}
</style>
