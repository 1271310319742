<template>
    <LeftNavBar />
    <MobileNavigation />
    <!-- Main App Container -->
    <div :class="[layout, 'user-container']">
        <router-view v-if="accountId" :key="accountId" />
        <router-view v-else />
    </div>

    <!-- Alert Modal -->
    <AlertModal />

    <!-- Plan enforcment Modal -->
    <PlanEnforcementModal v-if="user" />
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useTitle } from '@vueuse/core'

import { provideUser } from '@/composition/user/useUser'
import { provideAccountList } from '@/composition/user/useAccountList'
import { provideDeepLinking } from '@/composition/global/useDeepLinking'
import { layout } from '@/composition/layouts/useLayouts'

import LeftNavBar from '@/layouts/LeftNavBar.vue'
import MobileNavigation from '@/layouts/MobileNavigation.vue'
import AlertModal from '@/components/Alerts/AlertModal.vue'
import PlanEnforcementModal from '@/components/billing/PlanEnforcementModal.vue'
import { useRouter } from 'vue-router'
import { Account } from '@opteo/types'

export default defineComponent({
    name: 'App',
    components: {
        LeftNavBar,
        MobileNavigation,
        AlertModal,
        PlanEnforcementModal,
    },
    setup() {
        const { user, error } = provideUser()
        const { accountList } = provideAccountList(user)

        const router = useRouter()

        const { currentRoute } = router

        const accountId = computed(
            () => currentRoute.value.params.accountId as Account.ID | undefined
        )

        provideDeepLinking(accountId)

        const windowTitle = computed(() => {
            if (!accountList.value || !accountId) {
                return 'Opteo'
            }

            const domainRow = accountList.value.find(d => d.accountId === accountId.value)

            if (!domainRow) {
                return 'Opteo'
            }

            return `Opteo - ${domainRow.name} (${domainRow.tasksAvailable})`
        })
        // @ts-expect-error
        useTitle(windowTitle)

        return {
            user,
            error,
            layout,
            accountId,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
.full-screen.user-container {
    width: 100vw;
    margin-left: 0;
}
@media (min-width: $mq-768-min) {
    .user-container {
        // accounts for width of .left-nav-bar
        margin-left: 6rem;
    }
}
</style>
