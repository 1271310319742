<template>
    <ImprovementView :push-messages="pushMessages" :push-action-text="pushActionText">
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <EntityPill type="url" :content="url" />
                    <Spacer height="2rem" />
                </div>
                <Text as="p">
                    Opteo encountered a
                    <Text as="span" color="red" weight="600">{{ reason }}</Text> error when
                    attempting to visit the link above. This link could be wasting budget in the
                    following {{ copy.campaign }}:
                </Text>
                <Spacer height="2rem" />
                <div v-if="formattedCampaigns.length === 1">
                    <EntityPillLinkList
                        class="entity-pill"
                        v-if="
                            !aboveMobile &&
                            formattedCampaigns[0].entityPillData.content &&
                            formattedCampaigns[0].entityPillData.content.length > 30
                        "
                        :items="formattedCampaigns"
                    />
                    <div v-else>
                        <EntityPillLink
                            v-for="(campaign, index) in formattedCampaigns"
                            v-bind:key="index"
                            class="entity-pill"
                            :entity-pill-data="campaign.entityPillData"
                            :deep-link-params="campaign.deepLinkParams"
                        />
                    </div>
                </div>
                <div v-else>
                    <EntityPillLinkList :items="formattedCampaigns" />
                </div>
                <Spacer height="2rem" />
                <Text as="p">
                    Fix the broken link if possible, otherwise contact your development team or
                    website administrator. If the link cannot be fixed in the near future, consider
                    pausing any ad groups using the link as a destination until fixes can be made.
                    Once repaired, click
                    <Text as="span" color="green" weight="600">{{ pushActionText }}</Text> to
                    confirm and this improvement will be moved to <b>Completed</b>.
                </Text>
            </ImprovementHeader>
            <ImprovementHelpLink path="/en/articles/902713-fix-broken-links-sitelinks">
                Click here to learn how Opteo detects and flags broken sitelinks.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="30" />
        </ImprovementContent>
    </ImprovementView>
</template>

<script setup lang="ts">
import { EntityPill, Text, Spacer } from '@opteo/components-next'

import { EntityPillLink, EntityPillLinkList } from '@/components/global/Entity'

import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import ImprovementCreatedDate from '@/components/improvement/ImprovementCreatedDate.vue'
import ImprovementContent from '@/components/improvement/ImprovementContent.vue'
import ImprovementHeader from '@/components/improvement/ImprovementHeader.vue'

import useMediaQuery from '@/composition/global/useMediaQuery'
import { useFixBrokenSitelink } from '@/composition/improvement/types/useFixBrokenSitelink'

const { aboveMobile } = useMediaQuery()
const { title, lastUpdated, pushMessages, reason, copy, formattedCampaigns, url, pushActionText } =
    useFixBrokenSitelink()
</script>
