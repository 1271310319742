<template>
    <SettingsSection
        id="update-payment-information"
        title="Update Payment Information"
        :noInnerContentShadow="true"
        :max-width="600"
    >
        <div class="update-payment-info pa-24 m-auto">
            <Text v-if="currentPlan" size="f-8">
                <span v-if="creditCard?.last4"
                    >We seem to be having issues with your payment card ending in
                    <b>{{ creditCard?.last4 }}</b
                    >.</span
                >
                Please update your payment method to continue using Opteo. Because your account is
                past due, your chosen payment method will be charged
                <b><Money :value="currentPlan.price" currency="USD" /></b> upon completion.
            </Text>
        </div>
        <div class="flex justify-center pa-24">
            <oButton
                id="update-payment-method"
                size="medium"
                color="blue"
                @clicked="openPaymentModal"
            >
                Update Payment Method
            </oButton>
        </div>
    </SettingsSection>
    <PaymentMethodModal v-model="showPaymentMethodModal" />
</template>

<script lang="ts">
import { useSubscription } from '@/composition/billing/useSubscription'
import { useUser } from '@/composition/user/useUser'
import { oButton, Money, Text } from '@opteo/components-next'
import SettingsSection from '@/components/global/SettingsSection.vue'
import { defineComponent, ref } from 'vue'
import PaymentMethodModal from '@/components/billing/PaymentMethodModal.vue'

export default defineComponent({
    name: 'SubscriptionUpdateCC',
    components: {
        oButton,
        Money,
        SettingsSection,
        Text,
        PaymentMethodModal,
    },
    setup() {
        const { creditCard } = useSubscription()
        const { currentPlan } = useUser()

        const showPaymentMethodModal = ref(false)

        function openPaymentModal() {
            showPaymentMethodModal.value = true
        }

        return {
            creditCard,
            currentPlan,
            openPaymentModal,
            showPaymentMethodModal,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';

.update-payment-info {
    @include container;
}
</style>
