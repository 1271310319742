import { computed, ref } from 'vue'

import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import {
    checkImprovement,
    UseImprovement,
    useImprovement,
} from '@/composition/improvement/useImprovement'
import { Improvement, ReduceCampaignBids, Targets } from '@opteo/types'
import { enums } from '@opteo/types/gads'
import { EntityPillLinkProps, ValidEntity } from '@/components/global/Entity/types'

interface UseReduceCampaignBids {
    campaignName: string
    locationEntityId: EntityPillLinkProps<'campaign'>['deepLinkParams']
    bidTypeCopy: 'keyword' | 'ad group'
    campaignCpa: number
    campaignRoas: number
    budgetRatio: number
    daysWindow: number
    improvementStatistics: ImprovementStatistics[]
    isUsingRoas: boolean
}

interface ImprovementStatistics {
    key: string
    deltaMode: boolean
    beforeValue: string | number
    beforeTitle: string
    afterValue: string | number
    afterTitle: string
}

export function useReduceCampaignBids(): UseImprovement<UseReduceCampaignBids> {
    const { improvement, lastUpdated, title } = useImprovement<
        ReduceCampaignBids.Body,
        typeof Improvement.RecAction.ReduceCampaignBids
    >()
    const {
        body: {
            campaign_label: campaignName,
            campaign_advertising_channel_type: advertisingChannelType = null,
            campaign_cost: cost,
            campaign_clicks: clicks,
            campaign_cpa: campaignCpa,
            campaign_roas: campaignRoas,
            budget_ratio: budgetRatio,
            days_window: daysWindow,
            performance_mode: performanceMode,
        },
        location,
    } = checkImprovement(improvement)

    const locationEntityId = location[0].entityIds

    const cpc = clicks ? cost / clicks : 0

    const adjustedCpc = cpc * 0.9

    const adjustedClicks = adjustedCpc ? Math.round(cost / adjustedCpc) : 0

    const { displayValue: formattedBeforeCpc } = useDomainMoney({ value: cpc }).value
    const { displayValue: formattedAfterCpc } = useDomainMoney({ value: adjustedCpc }).value
    const { displayValue: formattedCost } = useDomainMoney({ value: cost }).value

    const improvementStatistics: ImprovementStatistics[] = [
        {
            key: 'cpcDelta',
            deltaMode: true,
            beforeValue: formattedBeforeCpc.value,
            beforeTitle: 'Current Avg. CPC',
            afterValue: formattedAfterCpc.value,
            afterTitle: 'Adjusted Avg. CPC',
        },
        {
            key: 'clicksDelta',
            deltaMode: true,
            beforeValue: clicks,
            beforeTitle: 'Current Clicks',
            afterValue: adjustedClicks,
            afterTitle: 'Adjusted Clicks',
        },
        {
            key: 'costDelta',
            deltaMode: true,
            beforeValue: formattedCost.value,
            beforeTitle: 'Current Cost',
            afterValue: formattedCost.value,
            afterTitle: 'Adjusted Cost',
        },
    ]

    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Reducing keyword bids..',
        'Confirming changes..',
        'Keyword bids reduced successfully.',
    ])

    const isDisplayCampaign = !!(
        advertisingChannelType && advertisingChannelType === enums.AdvertisingChannelType.DISPLAY
    )

    const pushActionText = isDisplayCampaign
        ? ref('Reduce Ad Group Bids')
        : ref('Reduce Keyword Bids')

    const bidTypeCopy = isDisplayCampaign ? 'ad group' : 'keyword'

    return {
        title,
        locationEntityId,
        pushMessages,
        lastUpdated,
        campaignName,
        campaignCpa,
        campaignRoas,
        budgetRatio,
        daysWindow,
        improvementStatistics,
        isUsingRoas: performanceMode === Targets.PerformanceMode.ROAS,
        pushActionText,
        bidTypeCopy,
    }
}
