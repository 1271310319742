import * as Sentry from '@sentry/browser'
import { computed, ref, unref, ComputedRef, Ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Improvement } from '@opteo/types'
import { useAPI, Endpoint } from '../api/useAPI'
import { Routes } from '@/router/routes'
import * as improvementPushing from './usePushImprovement'

import type { RecActionTypeMap } from './recActionTypeMap'

const { usePushImprovement } = improvementPushing // a bit of a hack to allow re-exporting the interface below

// All improvement type composables e.g. useAdjustKeywordBid should implement this interface
// Note: this isn't the return type for the useImprovement composable
export type UseImprovement<Data = Record<string, unknown>> = {
    title: ComputedRef<string>
    pushMessages: ComputedRef<string[]>
    pushActionText: Ref<string>
    lastUpdated: ComputedRef<Date>
    adjustSteps?: Ref<string[]>
    onPush?: OnPushHandler
    onStepComplete?: OnStepCompleteHandler
} & Data

export interface OnPushHandler<PushData = {}> extends improvementPushing.OnPushHandler<PushData> {}

export type OnStepCompleteHandler = ({ step }: { step: Step }) => boolean

export interface StepHandler {
    step?: Step
    invalid?: (message: string) => void
}

export enum Step {
    DEFAULT = 0,
    ONE = 1,
    TWO = 2,
    THREE = 3,
}

type ImprovementTypes =
    | Improvement.Object
    | Improvement.CompletedObject
    | Improvement.DismissedObject

// Optionally pass an improvement id, otherwise it comes from the route
export function useImprovement<
    TBody = unknown,
    TRecAction extends Improvement.RecAction | 'meta' = 'meta',
>(improvementId?: number | string) {
    const route = useRoute()
    const router = useRouter()

    const { pushImprovement } = usePushImprovement()

    const id = (improvementId ?? route.params?.improvementId) as number | string

    const currentStep = ref<Step>(Step.DEFAULT)

    // Current improvement view
    const isActiveView = computed(() => router.currentRoute.value.name === Routes.Improvement)
    const isCompletedView = computed(
        () => router.currentRoute.value.name === Routes.CompletedImprovement
    )
    const isDismissedView = computed(
        () => router.currentRoute.value.name === Routes.DismissedImprovement
    )

    const endpoint = isActiveView.value
        ? Endpoint.GetImprovement
        : isCompletedView.value
        ? Endpoint.GetCompletedImprovement
        : Endpoint.GetDismissedImprovement

    const body = computed(() => {
        return { improvement_id: id, mark_as_read: !!isActiveView.value }
    })

    const {
        data: improvement,
        loading,
        error,
    } = useAPI<Improvement.Object<TBody, RecActionTypeMap[TRecAction]>>(endpoint, {
        body: () => body.value,
        uniqueId: () => +id,
        waitFor: () => +id,
    })

    const lastUpdated = computed(
        () =>
            new Date(
                improvement.value?.last_updated ??
                    // @ts-expect-error TODO: fix type here. Also should figure out what to actually show in the UI... The <ImprovementCreatedDate> component isn't quite suited for dismissed improvements
                    improvement.value?.deferred_timestamp ??
                    new Date()
            )
    )

    const title = computed(() => improvement.value?.title as string)

    const impNotFound = computed(() => error.value?.message === 'improvement_not_found')

    watch(impNotFound, newVal => {
        if (newVal) {
            Sentry.captureException(new Error('IMPROVEMENT_NOT_FOUND: ' + id))
        }
    })

    const handleClose = () => {
        if (isCompletedView.value) router.push({ name: Routes.ImprovementsCompleted })
        else if (isDismissedView.value) router.push({ name: Routes.ImprovementsDismissed })
        else router.push({ name: Routes.ImprovementsActive })
    }

    return {
        improvement,
        loading,
        pushImprovement,
        lastUpdated,
        title,
        currentStep,
        impNotFound,
        handleClose,
    }
}

export function checkImprovement<T, TImprovementType extends Improvement.Type>(
    improvement: Ref<Improvement.Object<T, TImprovementType> | undefined>
) {
    const imp = unref(improvement)

    if (typeof imp === 'undefined') {
        throw new Error('Improvement not found.')
    }

    return imp
}

// Check if an improvement is of type completed
export function isCompletedImprovement(
    improvement: ImprovementTypes
): improvement is Improvement.CompletedObject {
    return (
        typeof (improvement as Improvement.CompletedObject).completed_improvement_id !== 'undefined'
    )
}

// Check if an improvement is of type dismissed
export function isDismissedImprovement(
    improvement: ImprovementTypes
): improvement is Improvement.DismissedObject {
    return typeof (improvement as Improvement.DismissedObject).deferred_until !== 'undefined'
}
