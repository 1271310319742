import { computed, ref } from 'vue'
import { Endpoint, useAPI } from '@/composition/api/useAPI'
import {
    OnPushHandler,
    useImprovement,
    checkImprovement,
} from '@/composition/improvement/useImprovement'

import { CampaignLevelExtensions, Improvement } from '@opteo/types'
import { useDomain } from '@/composition/domain/useDomain'
import { EntityPillLinkProps } from '@/components/global/Entity/types'

type FormElement = HTMLDivElement & { submit: () => void; valid: boolean }

// TODO: use shared type, prevent duplication
// consider importing SiteLink from TextAd
interface AdSitelink {
    title: string
    line1?: string
    line2?: string
    url: string
}

interface HighestClickedAd {
    headlineOne: string
    headlineTwo: string
    displayUrl: string
    descriptionOne: string
    sitelinks: AdSitelink[]
}

interface AdTitleTag {
    title: string
    classes: string
}

interface SitelinkExtension {
    isNew: boolean
    anchor: string
    line1: string
    line2: string
    url: string
    feedItemID?: string
}

interface SitelinkExample {
    title: string
    description: string
    url: string
    start_url: string
}

interface PushedData {
    is_new: boolean
    anchor: string
    line1: string
    line2: string
    url: string
    feed_item_id: string | undefined
}

const EXPECTED_SITELINK_COUNT = 4
const MAX_EXISTING_SITELINK_COUNT = 4
const MAXIMUM_ANCHOR_LENGTH = 25
const MAXIMUM_DESCRIPTION_LENGTH = 35

export function useAddCampaignLevelSitelinkExtension() {
    const { domainId } = useDomain()
    const { improvement, lastUpdated, title } = useImprovement<
        CampaignLevelExtensions.Body<CampaignLevelExtensions.SitelinkItem>,
        typeof Improvement.RecAction.AddCampaignLevelSitelinkExtension
    >()

    const {
        body: {
            campaign_name: campaignName,
            campaign_feed_items: campaignFeedItems,
            feed_items: generalFeedItems,
            highest_click_ad: {
                headline1: headlineOne,
                headline2: headlineTwo,
                display_url: displayUrl,
                description: descriptionOne,
            },
        },
        location,
    } = checkImprovement(improvement)

    // campaign mode or account mode, ie whether the prefilled sitelinks come from campaign-level or account-level
    const campaignMode = campaignFeedItems.length > 0
    const totalMissingFeedItems = EXPECTED_SITELINK_COUNT - campaignFeedItems.length

    const locationEntityIds = location[0].entityIds

    const sitelinkExtensions = ref<SitelinkExtension[]>([])
    const feedItems = campaignMode ? campaignFeedItems : generalFeedItems

    const anchorRefs = ref() // This will be an array of sitelink anchor oInputs. Used for focus().

    function resetSitelinkExtensions(clear: boolean) {
        sitelinkExtensions.value = []

        feedItems.forEach(
            ({ fields: { feed_item_id: feedItemID, anchor, line1, line2, url } }, index) => {
                if (index >= MAX_EXISTING_SITELINK_COUNT) {
                    return
                }

                sitelinkExtensions.value.push({
                    isNew: !campaignMode,
                    anchor: clear ? '' : anchor,
                    line1: clear ? '' : line1 || '',
                    line2: clear ? '' : line2 || '',
                    url: clear ? '' : url[0],
                    feedItemID,
                })
            }
        )

        while (sitelinkExtensions.value.length < EXPECTED_SITELINK_COUNT) {
            sitelinkExtensions.value.push({
                isNew: true,
                anchor: '',
                line1: '',
                line2: '',
                url: '',
            })
        }

        // focus first sitelink's anchor field
        const firstSitelink = (anchorRefs.value ?? [])[0]
        if (firstSitelink) {
            firstSitelink.inputRef.focus()
        }
    }

    resetSitelinkExtensions(false)

    const generateHighestClickAd = (showPlaceholders: boolean): HighestClickedAd => {
        const ad = { headlineOne, headlineTwo, displayUrl, descriptionOne }

        if (!sitelinkExtensions.value || !sitelinkExtensions.value.length) {
            return { ...ad, sitelinks: [] }
        }

        const sitelinks = sitelinkExtensions.value
            .filter(({ anchor }) => (showPlaceholders ? true : anchor.length))
            .map(({ anchor, line1, line2, url }, index) => {
                const title = anchor.length ? anchor : `Sitelink Anchor`

                let lineOne = line1
                let lineTwo = line2

                if (showPlaceholders) {
                    lineOne = line1.length ? line1 : 'Sitelink Link One'
                    lineTwo = line2.length ? line2 : 'Sitelink Link Two'
                }

                return { title: title, line1: lineOne, line2: lineTwo, url }
            })

        return { ...ad, sitelinks }
    }

    const highestClickedAdStatic = generateHighestClickAd(false)
    const highestClickedAd = computed(() => generateHighestClickAd(true))

    const adTitleTag: AdTitleTag = { title: 'Highest Clicked Ad', classes: 'opteo-blue' }
    const sitelinkForm = ref<FormElement>()

    const { data: sitelinkExamples, loading: sitelinkExamplesLoading } = useAPI<SitelinkExample[]>(
        Endpoint.GetSitelinkExamples,
        { uniqueId: () => domainId.value, waitFor: () => domainId.value }
    )

    const adjustSteps = ref([
        campaignMode ? 'Input Sitelink Extensions' : 'Customise Sitelink Extensions',
    ])

    const pushActionText = ref('Add Sitelink Extensions')
    const pushMessages = [
        'Connecting to Google Ads..',
        'Adding sitelink extensions..',
        'Confirming changes..',
        'Sitelink extensions added successfully.',
    ]

    const onPush: OnPushHandler<PushedData[]> = () => {
        const valid = !!sitelinkForm.value?.submit()

        if (!valid) {
            return { valid }
        }

        return {
            valid,
            pushedData: sitelinkExtensions.value.map(sitelinkExtension => {
                return {
                    is_new: sitelinkExtension.isNew,
                    anchor: sitelinkExtension.anchor,
                    line1: sitelinkExtension.line1,
                    line2: sitelinkExtension.line2,
                    url: sitelinkExtension.url,
                    feed_item_id: sitelinkExtension.feedItemID,
                }
            }),
        }
    }

    return {
        title,
        locationEntityIds,
        pushMessages,
        onPush,
        lastUpdated,
        campaignName,
        campaignMode,
        totalMissingFeedItems,
        highestClickedAd,
        highestClickedAdStatic,
        adTitleTag,
        sitelinkForm,
        sitelinkExtensions,
        anchorRefs,
        resetSitelinkExtensions,
        sitelinkExamples,
        sitelinkExamplesLoading,
        MAXIMUM_ANCHOR_LENGTH,
        MAXIMUM_DESCRIPTION_LENGTH,
        pushActionText,
        adjustSteps,
    }
}
