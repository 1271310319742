import type { Entity } from '@opteo/types'
import type { EntityPillProps } from '@opteo/components-next'

/**
 * @description Maps an array of string contents to an array of `EntityPillProps`
 * where each object represents a pill with the given `content` and entity location `type`.
 * @param contents - The array of string contents for the pills.
 * @param entityLocation - The `Entity.EntityLocation` for each pill.
 * @return An array of `EntityPillProps` with the given `content`s and entity location `type`s.
 */
export const getEntityPillListContents = (
    contents: string[],
    entityLocation: Entity.EntityLocation
): EntityPillProps[] => {
    return contents.map(
        (content: string): EntityPillProps => ({
            type: entityLocation,
            content: content,
        })
    )
}
