<template>
    <ImprovementView
        :push-messages="pushMessages"
        :push-action-text="pushActionText"
        :is-adjustable="true"
        :on-push="onPush"
        @back-clicked="affectLosingKeyword"
    >
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <EntityPillLink
                        :entityPillData="{ type: 'keyword', content: losingKeyword.keyword }"
                        :deepLinkParams="locationEntityIds"
                    />
                    <Spacer height="2rem" />
                </div>
                <Text as="p">
                    Consider pausing this keyword. It's a duplicate of another keyword and
                    performing worse than its counterpart. Pausing this keyword should help reduce
                    wasted spend, gather more accurate performance data, and maintain a well
                    structured account.
                </Text>

                <Spacer height="1rem" />

                <Text v-if="betterCpa" as="p">
                    Opteo recommends pausing the losing duplicate because the winning duplicate has
                    a significantly lower cost per conversion. For reference, the winning duplicate
                    has a CPA of
                    <b>{{ copyItem.winningKeywordCpa }}</b
                    >. In comparison, the losing duplicate has a CPA of
                    <b>{{ copyItem.losingKeywordCpa }}</b
                    >.
                </Text>

                <Text v-if="betterRoas" as="p">
                    Opteo recommends pausing the losing duplicate because the winning duplicate has
                    a significantly higher return on ad spend. For reference, the winning duplicate
                    has a ROAS of
                    <b>{{ copyItem.winningKeywordRoas }}</b
                    >. In comparison, the losing duplicate has a ROAS of
                    <b>{{ copyItem.losingKeywordRoas }}</b
                    >.
                </Text>

                <Text v-if="betterProfit" as="p">
                    Opteo recommends pausing the losing duplicate because the winning duplicate
                    generated
                    {{ winningProfitNegative ? 'a smaller' : 'more' }}
                    {{ winningProfitNegative ? 'loss' : 'profit' }}. For reference, the winning
                    duplicate generated a {{ losingProfitNegative ? 'loss' : 'profit' }} of
                    <b>{{ copyItem.winningKeywordProfit }}</b
                    >. In comparison, the losing duplicate generated a
                    {{ losingProfitNegative ? 'loss' : 'profit' }} of
                    <b>{{ copyItem.losingKeywordProfit }}</b
                    >.
                </Text>

                <Text v-if="noQualityScore" as="p">
                    Opteo recommends pausing the losing duplicate because the winning duplicate has
                    a higher quality score. For reference, the winning duplicate has a quality score
                    of
                    <b>{{ copyItem.winningKeywordQS }}</b
                    >. In comparison, the losing duplicate has not been assigned a quality score.
                </Text>

                <Text v-if="higherQualityScore" as="p">
                    Opteo recommends pausing the losing duplicate because the winning duplicate has
                    a higher quality score. For reference, the winning duplicate has a quality score
                    of <b>{{ copyItem.winningKeywordQS }}</b
                    >, whereas in comparison, the losing duplicate has a quality score of
                    <b>{{ copyItem.losingKeywordQS }}</b
                    >.
                </Text>

                <Text v-if="higherCtr" as="p">
                    Opteo recommends pausing the losing duplicate because the winning duplicate has
                    a
                    <Text as="span" weight="600" color="green">{{ copyItem.ctrDiff }} higher</Text>
                    click through rate. For reference — despite this difference in CTR — both
                    duplicates have an identical quality score.
                </Text>

                <Text v-if="higherImpressions" as="p">
                    Opteo recommends pausing the losing duplicate because the winning duplicate has
                    more impressions. For reference — despite this difference in impressions — both
                    duplicates have an identical quality score.
                </Text>

                <Spacer height="1rem" />

                <Text as="p">
                    Click
                    <Text as="span" color="green" weight="600">{{ pushActionText }}</Text> to
                    {{ actionCopy.infinitive.toLowerCase() }} the {{ keywordCopy }} duplicate. To
                    pause the winning duplicate, click <b>Adjust</b>. If you consider this
                    suggestion a false positive, click <b>Dismiss</b>.
                </Text>
            </ImprovementHeader>
            <ImprovementHelpLink path="/en/articles/902939-pause-duplicate-keyword">
                Click here to learn more about pausing duplicate keywords in Opteo.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <Text as="h3" size="f-4" weight="600">Keyword Comparison</Text>
            <Spacer height="3rem" />
            <Keyword
                :keyword="winningKeyword"
                :statistics="winningKeywordStats"
                :title-tag="{ title: 'Winning Duplicate', classes: 'opteo-green' }"
            />
            <Spacer height="3rem" />
            <Keyword
                :keyword="losingKeyword"
                :statistics="losingKeywordStats"
                :title-tag="{ title: 'Losing Duplicate', classes: 'opteo-red' }"
            />
        </ImprovementContent>

        <ImprovementContent>
            <Text as="h3" size="f-4" weight="600">Comparison Table</Text>
            <Spacer height="2rem" />
            <oTable
                :headers="tableHeaders"
                :items="tableItems"
                :responsive-mode="aboveSmallMobile ? 'columns' : 'stacked'"
                :responsive-col-size="aboveSmallMobile ? '55% 1fr' : ''"
                responsive-breakpoint="768px"
            >
                <!-- Winner Header -->
                <template #header.winner>
                    <Text as="span" size="f-8" weight="500" color="green">Winning Duplicate</Text>
                </template>
                <!-- Winner Header -->
                <template #header.loser>
                    <Text as="span" size="f-8" weight="500" color="red">Losing Duplicate</Text>
                </template>
                <!-- Quality Score Replace Zero -->
                <template #column.winner="cellData">
                    <div v-if="cellData.row.id === 'qs' && cellData.value === '0'">N/A</div>
                    <div v-if="cellData.row.id === 'qs' && cellData.value != '0'">
                        {{ cellData.value }}
                    </div>
                </template>
                <template #column.loser="cellData">
                    <div v-if="cellData.row.id === 'qs' && cellData.value === '0'">N/A</div>
                    <div v-if="cellData.row.id === 'qs' && cellData.value != '0'">
                        {{ cellData.value }}
                    </div>
                </template>
            </oTable>
            <Spacer v-if="diffConvActions" height="2rem" />
            <ImprovementNote v-if="diffConvActions">
                <b>Note:</b> These duplicate keywords are contained in different campaign groups
                which have different conversion types selected. Use caution when comparing CPA and
                conversions for the duplicate keywords in the table above.
            </ImprovementNote>
        </ImprovementContent>

        <ImprovementContent>
            <Text as="h3" size="f-4" weight="600">Keyword Checks</Text>
            <Spacer height="1.5rem" />
            <Text as="p">
                Having checked that both the winning and losing keywords share the following
                attributes, Opteo is confident that they are competing for the same traffic.
            </Text>

            <Spacer height="2rem" />

            <div class="keyword-checks-container">
                <div v-for="check in keywordChecks" :key="check.name" class="check-item">
                    <CheckIcon :checked="check.checked" :checkable="true" style="flex-shrink: 0" />
                    <Spacer width="0.75rem" />
                    <Text as="p" size="f-8"
                        ><b>{{ check.name }}</b> {{ check.description }}</Text
                    >
                </div>
            </div>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="30" />
        </ImprovementContent>

        <template v-slot:adjust-step-1>
            <ImprovementContent>
                <ImprovementHeader title="Select Duplicate Keyword">
                    <div v-if="aboveMobile">
                        <EntityPillLink
                            :entityPillData="{ type: 'keyword', content: losingKeyword.keyword }"
                            :deepLinkParams="locationEntityIds"
                        />
                        <Spacer height="2rem" />
                    </div>
                    <Text as="p">
                        In certain cases, you may need to pause the winning duplicate instead of the
                        losing duplicate. To pause the winning duplicate, select <b>Winning</b> from
                        the menu below.
                    </Text>
                </ImprovementHeader>

                <InlineInputContainer>
                    <template #mobile>
                        <Text as="span" size="f-8">Select a duplicate to pause</Text>
                    </template>
                    <template #left-side>
                        <Text as="span" size="f-8">Pause the</Text>
                    </template>
                    <template #input>
                        <oInput
                            v-model="affectLosingDuplicate"
                            type="select"
                            :items="[
                                { value: true, label: 'Losing' },
                                { value: false, label: 'Winning' },
                            ]"
                            class="select-duplicate-select"
                        />
                    </template>
                    <template #right-side>
                        <Text as="span" size="f-8">keyword duplicate.</Text>
                    </template>
                </InlineInputContainer>
            </ImprovementContent>
        </template>
    </ImprovementView>
</template>

<script lang="ts">
import { Text, Spacer, oTable, oInput, CheckIcon } from '@opteo/components-next'

import ImprovementView from '@/components/improvement/ImprovementView.vue'
import InlineInputContainer from '@/components/improvement/shared-components/InlineInputContainer.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import Keyword from '@/components/improvement/shared-components/Keyword.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'

import { usePauseDuplicateKeywordsV2 } from '@/composition/improvement/types/usePauseDuplicateKeywordsV2'
import { defineComponent } from 'vue'
import EntityPillLink from '@/components/global/Entity/EntityPillLink.vue'
import EntityPillLinkList from '@/components/global/Entity/EntityPillLinkList.vue'

export default defineComponent({
    name: 'PauseDuplicateKeywordsV2',
    components: {
        ImprovementView,
        InlineInputContainer,
        ImprovementHelpLink,
        EntityPillLink,
        EntityPillLinkList,
        Text,
        Spacer,
        Keyword,
        oTable,
        oInput,
        CheckIcon,
    },
    setup() {
        return { ...usePauseDuplicateKeywordsV2(), ...useMediaQuery() }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.select-duplicate-select {
    width: 7rem;
}

.keyword-checks-container {
    @include container;
    @include pa-28;
    @include grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.75rem;
}
.keyword-checks-container .check-item {
    @include container;
    @include flex;
    @include items-center;
    @include pa-16;
    @include w-100;
}

@media (min-width: 0px) and (max-width: $mq-767-max) {
    .select-duplicate-select {
        width: 100%;
    }
    .keyword-checks-container {
        grid-template-columns: 1fr;
        @include pa-24;
    }
}
</style>
