<template>
    <ImprovementView
        :push-messages="pushMessages"
        :push-action-text="pushActionText"
        :steps="adjustSteps"
        :on-push="onPush"
    >
        <ImprovementContent>
            <ImprovementHeader :title="title" />
            <Spacer height="2rem" />
            <Text v-if="isPlural" as="p">
                Consider adding call extensions to increase engagement with your ads. Click
                <Text weight="600" color="blue">{{ adjustSteps?.[0] }}</Text> to add a phone number
                to the campaigns listed below. Searchers will see your number alongside your ad, and
                can tap to call directly if viewing on a mobile.
            </Text>
            <Text v-else as="p">
                Consider adding a call extension to increase engagement with your ads. Click
                <Text weight="600" color="blue">{{ adjustSteps?.[0] }}</Text> to add a phone number
                to the campaign listed below. Searchers will see your number alongside your ad, and
                can tap to call directly if viewing on a mobile.
            </Text>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementHelpLink path="/improvements/extensions/add-call-extension">
                Read more about the benefits of call extensions
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <Text v-if="isPlural" as="h3">Campaigns Missing Call Extensions</Text>
            <Text v-else as="h3">Campaign Missing a Call Extension</Text>
            <Spacer height="2rem" />
            <div>
                <EntityPillLink
                    v-if="incompleteCampaigns"
                    v-for="campaign in incompleteCampaigns"
                    :key="campaign.campaignId"
                    type="campaign"
                    :entity-pill-data="{
                        type: Entity.EntityLocation.Campaign,
                        content: campaign.campaignName,
                    }"
                    :deep-link-params="{ campaignId: campaign.campaignId }"
                    class="mr2 mt2"
                />
                <EntityPill
                    v-else
                    v-for="(campaignName, index) in campaignNames"
                    :key="index"
                    type="campaign"
                    :content="campaignName"
                    class="mr2 mt2"
                />
            </div>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="90" />
        </ImprovementContent>

        <template v-slot:adjust-step-1>
            <ImprovementContent>
                <ImprovementHeader title="Input Phone Number" />
                <span class="flex shadow pa4">
                    <oForm ref="callForm">
                        <oInput
                            v-model="selectedCountryCode"
                            label="Country Code"
                            :items="countryCodes"
                            type="select"
                        />
                        <Spacer width="1rem" />
                        <oInput
                            v-model="telephoneNumber"
                            name="telephoneNumber"
                            required
                            label="Phone Number"
                            class="w-100"
                        />
                    </oForm>
                </span>
            </ImprovementContent>

            <ImprovementContent>
                <Text as="h5">Recommended Phone Number</Text>
                <Spacer height="1.5rem" />
                <Skeleton v-if="getPhoneNumberLoading" :width="200" />
                <Text v-else-if="recommendedTelephoneNumber" color="blue">
                    {{ recommendedTelephoneNumber }}
                </Text>
                <Text v-else>
                    We couldn't find a phone number associated with this Ads account.
                </Text>
            </ImprovementContent>
        </template>
    </ImprovementView>
</template>

<script setup lang="ts">
import { Entity } from '@opteo/types'
import { oInput, oForm, Text, Spacer, EntityPill } from '@opteo/components-next'

// Local Components
import { EntityPillLink } from '@/components/global/Entity'
import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import ImprovementHeader from '@/components/improvement/ImprovementHeader.vue'
import ImprovementContent from '@/components/improvement/ImprovementContent.vue'
import ImprovementCreatedDate from '@/components/improvement/ImprovementCreatedDate.vue'
import Skeleton from '@/components/util/Skeleton.vue'

// Composition
import { useAddCallExtension } from '@/composition/improvement/types/account-level-extensions/useAddCallExtension'

const {
    title,
    onPush,
    pushMessages,
    lastUpdated,
    campaignNames,
    incompleteCampaigns,
    countryCodes,
    selectedCountryCode,
    telephoneNumber,
    recommendedTelephoneNumber,
    getPhoneNumberLoading,
    callForm,
    pushActionText,
    adjustSteps,
    isPlural,
} = useAddCallExtension()
</script>
