import { useImprovement, checkImprovement } from '../useImprovement'
import { RemoveBroadKeywords, Entity } from '@opteo/types'
import type { EntityPillProps } from '@opteo/components-next'
import { computed, ref } from 'vue'

export function useRemoveBroadKeyword() {
    const { improvement, lastUpdated, title } = useImprovement<RemoveBroadKeywords.Body>()
    const { body } = checkImprovement(improvement)

    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Pausing broad match keyword..',
        'Adding phrase match keyword..',
        'Keyword replaced successfully.',
    ])

    const replacementKeyword = `${body.keyword_text.replace(/\+/g, '')}`

    const entityPillList: EntityPillProps[] = [
        { type: Entity.EntityLocation.Campaign, content: body.campaign_label },
        { type: Entity.EntityLocation.AdGroup, content: body.adgroup_label },
        { type: Entity.EntityLocation.Keyword, content: body.keyword_text },
    ]

    const pushActionText = ref('Replace Keyword')

    return {
        title,
        lastUpdated,
        pushMessages,
        body,
        entityPillList,
        replacementKeyword,
        pushActionText,
    }
}
