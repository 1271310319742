import { ref, Ref, ComputedRef, computed } from 'vue'
import { useDomainMoney } from '@/composition/domain/useDomainMoney'

import { getDomainFromUrl } from '../../improvement/components/ad-writer/utils'
import { AddSkagV2 } from '@opteo/types'

import {
    OnPushHandler,
    useImprovement,
    UseImprovement,
    checkImprovement,
    Step,
} from '@/composition/improvement/useImprovement'

import { useDomain } from '@/composition/domain/useDomain'

type FormElement = HTMLDivElement & { resolveAd: () => boolean }

interface Ad {
    description?: string
    destination_url?: string
    expanded_text_ad_description2?: string
    expanded_text_ad_headline3?: string
    headline_part_1?: string
    headline_part_2?: string
    path_1?: string
    path_2?: string
}

interface useAddSkagV2 {
    improvement: Ref<AddSkagV2.AddSkagV2Body> | Ref
    improvementId: number
    domainId: ComputedRef<number>
    bestAd: AddSkagV2.BestAd
    newAd: Ref<Ad> | Ref
    statsCost: ComputedRef<string>
    statsClicks: number
    statsConversions: number
    campaignId?: string
    adGroupId?: string
    topSearchTerms?: AddSkagV2.TopSearchTerms[]
    adDomain?: string
    setNewAd: (ad: Ad) => void
    adWriter: Ref<FormElement> | Ref
    currency: string | undefined
}

export function useAddSkagV2(): UseImprovement<useAddSkagV2> {
    const newAd = ref<Ad>({})
    const adWriter = ref<FormElement>()

    const { domainInfo, domainId } = useDomain()

    const currency = domainInfo.value?.currency_code

    const { improvement, lastUpdated, title } = useImprovement<any>()

    const {
        body: {
            clicks,
            cost,
            conversions,
            best_ad: bestAd,
            campaign_rn,
            ad_group_rn,
            top_search_terms: topSearchTerms,
        },
        improvement_id: improvementId,
    } = checkImprovement(improvement)

    const { displayValue: statsCost } = useDomainMoney({
        value: cost,
    }).value

    function setNewAd(ad: Ad) {
        newAd.value = ad
    }

    const campaignId = campaign_rn.split('/').pop()
    const adGroupId = ad_group_rn.split('/').pop()

    if (!bestAd) {
        throw new Error('best ad not found, this is really bad')
    }

    const { final_urls } = bestAd
    let adDomain
    if (final_urls && final_urls[0]) {
        adDomain = getDomainFromUrl(final_urls[0], true)
    }

    function onStepComplete({ step }: { step: Step }) {
        if (step === Step.ONE) {
            return adWriter.value?.resolveAd() ?? false
        }

        return true
    }

    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        `Creating SKAG..`,
        'Confirming changes..',
        `SKAG created successfully.`,
    ])

    const adjustSteps = ref(['Open Ad Writer', 'Preview New Ad'])

    const pushActionText = ref('Create SKAG')

    const onPush: OnPushHandler<any> = () => {
        return { valid: true, pushedData: newAd.value }
    }

    return {
        improvement,
        improvementId,
        domainId,
        title,
        onPush,
        pushMessages,
        onStepComplete,
        lastUpdated,
        bestAd,
        newAd,
        statsCost,
        statsClicks: clicks,
        statsConversions: conversions,
        campaignId,
        adGroupId,
        topSearchTerms,
        adDomain,
        setNewAd,
        adWriter,
        currency,
        pushActionText,
        adjustSteps,
    }
}
