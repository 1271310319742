import { computed, inject, ComputedRef, ref } from 'vue'
import some from 'lodash-es/some'

import { DonutChartTypes, useRoas } from '@opteo/components-next'
import {
    OnPushHandler,
    UseImprovement,
    useImprovement,
    checkImprovement,
} from '@/composition/improvement/useImprovement'
import { AdjustDeviceBidsV2, Targets } from '@opteo/types'
import { sortTable } from '@opteo/components-next'
import { CurrencyCode } from '@/composition/utils/types'
import { ProvideKeys } from '@/composition/useProvide'
import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import { Entity, TableHeader, TableItem } from './types'
import { useDeviceTables } from './useDeviceTables'
import { getProperDeviceLabel } from './utils'
import { useEntityInfo } from './useEntityInfo'

interface UseAdjustMultipleDeviceBids {
    entity: Entity
    entityLabel: string
    entityName: string
    deviceTableHeaders: TableHeader[]
    deviceTableItems: TableItem[]
    deviceAdjustTableHeaders: TableHeader[]
    deviceAdjustTableItems: TableItem[]
    donutChartItems: DonutChartTypes.DonutChartItem[]
    domainCurrencyCode?: CurrencyCode
    lookbackWindow: number
    onReset: () => void
    updateMultiplePlacements: (value: { id: string; value: number }) => void
    isUsingCpa: boolean
    entityPillList?: { content: string; type: string }[]
}

export function useAdjustMultipleDeviceBids(): UseImprovement<UseAdjustMultipleDeviceBids> {
    const { improvement, lastUpdated, title } =
        useImprovement<AdjustDeviceBidsV2.DeviceBidsRecActions>()

    const { body } = checkImprovement(improvement)

    const singleDeviceBidMod = body.devices.find(d => {
        return !d.invalid
    })

    if (!singleDeviceBidMod) {
        throw new Error('NO_BIDS_TO_CHANGE')
    }

    const isUsingCpa =
        !body.performance_mode || body.performance_mode === Targets.PerformanceMode.CPA

    const campaignGroupPerformanceMetric = isUsingCpa
        ? body.campaign_group.target_cpa
        : body.campaign_group.target_roas

    const donutChartItems = sortTable(
        'label',
        'ASC',
        body.devices.map(device => {
            return {
                y: device.cost,
                label: getProperDeviceLabel(device.device_label),
            }
        })
    )

    const domainCurrencyCode = inject<CurrencyCode>(ProvideKeys.CurrencyCode)

    const { displayValue: formattedTargetCpa } = useDomainMoney({
        value: campaignGroupPerformanceMetric ?? 0,
    }).value

    const { displayValue: formattedTargetRoas } = useRoas({
        value: campaignGroupPerformanceMetric ?? 0,
    })

    const formattedTarget = isUsingCpa ? formattedTargetCpa.value : formattedTargetRoas.value

    const targetSet =
        (isUsingCpa && body.campaign_group.target_cpa_set) ||
        (!isUsingCpa && body.campaign_group.target_roas_set)

    const { entity, entityLabel, entityName, entityAverage, entityPillList, campaignGroup } =
        useEntityInfo(body, isUsingCpa)

    const {
        deviceTableHeaders,
        deviceTableItems,
        deviceAdjustTableHeaders,
        deviceAdjustTableItems,
    } = useDeviceTables(body.devices, isUsingCpa, formattedTarget, entityAverage, targetSet)

    const extraDetails = ref<{ resource_name: string; cpc_bid: number }[]>([])

    function formatCampaignGroupLevelMultiExtraDetails() {
        extraDetails.value = []

        if (!campaignGroup.campaigns) throw new Error('This is the wrong rec action')

        const { campaigns } = campaignGroup

        body.devices.forEach(device_data => {
            if (device_data.invalid) {
                return
            }
            const { criteria: device_criteria, new_bid_mod } = device_data

            if (!new_bid_mod) {
                throw new Error('DEVICE_WITHOUT_BID_MOD')
            }

            campaigns.forEach(c => {
                const campaign_criteria_resource_name = c.resource_name.replace(
                    'campaigns',
                    'campaignCriteria'
                )
                const criteria_resource_name = `${campaign_criteria_resource_name}~${device_criteria}`

                extraDetails.value.push({
                    resource_name: criteria_resource_name,
                    cpc_bid: +new_bid_mod.toFixed(2),
                })
            })
        })
    }

    function formatAdGroupOrCampaignLevelMultiExtraDetails() {
        extraDetails.value = []
        body.devices.forEach(device => {
            if (device.invalid) {
                return
            }

            extraDetails.value.push({
                resource_name: device.resource_name!,
                cpc_bid: +device.new_bid_mod!.toFixed(2),
            })
        })
    }

    function formatExtraDetails() {
        if (entity === 'adgroup' || entity === 'campaign') {
            formatAdGroupOrCampaignLevelMultiExtraDetails()
            return
        }
        formatCampaignGroupLevelMultiExtraDetails()
    }

    formatExtraDetails()

    const updateMultiplePlacements = (value: { id: string; value: number }) => {
        const { id, value: new_bid_mod } = value
        const formattedNewBidMod = 1 + new_bid_mod

        /* Campaign or Ad Group Level Adjustment*/
        if (entity === 'adgroup' || entity === 'campaign') {
            const entityToMod = body.devices.find(device_data => +device_data.criteria === +id)
            if (!entityToMod) {
                return
            }
            const extraDetailsToMod = extraDetails.value.find(extra_details => {
                const { criteria } = entityToMod
                return +extra_details.resource_name.split('~')[1] === +criteria
            })

            if (!extraDetailsToMod) {
                extraDetails.value.push({
                    resource_name: entityToMod?.resource_name!,
                    cpc_bid: +formattedNewBidMod.toFixed(2),
                })
                return
            }

            extraDetailsToMod.cpc_bid = +formattedNewBidMod.toFixed(2)

            return
        }

        /* Campaign Group Level Adjustment */
        const entityCriteriaToMod = body.devices.find(
            placement_data => +placement_data.criteria === +id
        )

        if (!entityCriteriaToMod) {
            return
        }

        const extraDetailsToMod = extraDetails.value.filter(extra_details => {
            const { criteria } = entityCriteriaToMod
            const criteriaMatchInExtraDetails = extra_details?.resource_name.split('~')[1]
            if (!criteriaMatchInExtraDetails) {
                return false
            }
            return +criteriaMatchInExtraDetails === +criteria
        })

        if (extraDetailsToMod.length === 0 && campaignGroup.campaigns) {
            campaignGroup.campaigns.forEach(campaign_data => {
                const campaign_resource_name = campaign_data.resource_name.replace(
                    'campaigns',
                    'campaignCriteria'
                )
                extraDetails.value.push({
                    resource_name: `${campaign_resource_name}~${id}`,
                    cpc_bid: +formattedNewBidMod.toFixed(2),
                })
            })
            return
        }

        extraDetailsToMod.forEach(extra_detail => {
            extra_detail.cpc_bid = +formattedNewBidMod.toFixed(2)
        })
    }

    const lookbackWindow = body.window ?? 90

    const pushActionText = ref('Apply Bid Adjustments')

    const pushMessages = computed(() => [
        `Connecting to Google Ads..`,
        `Applying device bid adjustments..`,
        `Confirming changes..`,
        `Bid adjustments applied successfully.`,
    ])

    function validate(extraDetails: { resource_name: string; cpc_bid: number }[]) {
        return !some(extraDetails, ed => {
            const { cpc_bid: newBidMod, resource_name: resourceName } = ed

            /* Most Checks Already Handled By Component */
            return newBidMod < 0 || !resourceName
        })
    }

    const onReset = () => {
        extraDetails.value = []
        formatExtraDetails()
    }

    const onPush: OnPushHandler = () => {
        let valid = true

        if (extraDetails.value) {
            valid = validate(extraDetails.value)
        }

        return {
            valid,
            pushedData: extraDetails.value,
        }
    }

    return {
        title,
        pushActionText,
        entity,
        entityLabel,
        entityName,
        entityPillList,
        deviceTableHeaders,
        deviceAdjustTableHeaders,
        deviceAdjustTableItems,
        deviceTableItems,
        donutChartItems,
        domainCurrencyCode,
        lookbackWindow,
        pushMessages,
        onPush,
        lastUpdated,
        onReset,
        updateMultiplePlacements,
        isUsingCpa,
    }
}
