import { computed, Ref, ref } from 'vue'
import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import { useNumber, useRoas, ScatterPointChart } from '@opteo/components-next'

import {
    OnPushHandler,
    UseImprovement,
    useImprovement,
    checkImprovement,
} from '@/composition/improvement/useImprovement'
import { AddNegativeGeoKeyword, Entity, Improvement, Targets } from '@opteo/types'
import {
    EntityPillLinkProps,
    EntityPillListLinkProps,
    ValidEntity,
} from '@/components/global/Entity/types'
import { filterLocationsByEntityType, formatEntityPillLink } from '@/lib/globalUtils'

type DropDownItem = { value: string; label: string }

interface UseAddNegativeGeoKeyword {
    entityPill: EntityPillLinkProps<ValidEntity>
    campaignName: string
    campaignLocationData: Entity.Ids<'campaign'>
    locationName: string
    searchTerms: string[]
    searchTermCount: number
    cpa: number
    roas: number
    targetCpa: number
    targetRoas: number
    cpaDifference: number
    roasDifference: number
    clicks: number
    cost: number
    impressions: number
    conversions: number
    conversionsValue: number
    copy: {
        impressions: string
        clicks: string
        conversions: string
        targetOrAverage: string
    }
    targetedLocationsCount: number
    targetedLocationNames: EntityPillLinkProps<'location'>[]
    sharedSets: DropDownItem[]
    selectedSharedSet: Ref<string>
    isUsingCpa: boolean
    cpaImprovementStats: { key: string; value: string; title: string }
    roasImprovementStats: { key: string; value: string; title: string }
    autoTarget: boolean
}

interface NegativeList {
    name: string
    id: number
    resource_name: string
}

const addAsNegativeKeywordValue = 'negative_keyword'

export function useAddNegativeGeoKeyword(): UseImprovement<UseAddNegativeGeoKeyword> {
    const { improvement, lastUpdated, title } = useImprovement<
        AddNegativeGeoKeyword.Body,
        typeof Improvement.RecAction.AddNegativeGeoKeyword
    >()

    const {
        body: {
            campaign: campaignName,
            search_term_location: { name: locationName },
            search_terms: searchTerms,
            clicks,
            cost,
            impressions,
            all_conv: conversions,
            all_conv_value: conversionsValue,
            cpa,
            roas,
            target_cpa: targetCpa,
            target_roas: targetRoas,
            auto_target: autoTarget,
            targeted_locations: targetedLocations,
            negative_lists: negativeLists,
            performance_mode: performanceMode,
        },
        location,
    } = checkImprovement(improvement)

    const isUsingCpa = !performanceMode || performanceMode === Targets.PerformanceMode.CPA

    const searchTermCount = searchTerms.length
    const cpaDifference = (cpa - targetCpa) / targetCpa
    const roasDifference = (roas - targetRoas) / targetRoas
    const copy = {
        impressions: impressions === 1 ? 'impression' : 'impressions',
        clicks: clicks === 1 ? 'click' : 'clicks',
        conversions: conversions === 1 ? 'conversion' : 'conversions',
        targetOrAverage: autoTarget ? 'an average' : 'a target',
    }

    const campaignLocationData = filterLocationsByEntityType(
        location,
        Entity.EntityLocation.Campaign
    )

    const targetedLocationsCount = targetedLocations.length
    const targetedLocationNames = targetedLocations.map(location => {
        return formatEntityPillLink({
            entityPillData: { content: location.name!, type: Entity.EntityLocation.Location },
            deepLinkParams: campaignLocationData?.entityIds,
        })
    })

    const sharedSets: DropDownItem[] = [
        { value: addAsNegativeKeywordValue, label: 'Add Negative to Campaign (Default)' },
        ...(negativeLists ?? []).map((list: NegativeList) => {
            return { value: list.resource_name, label: list.name }
        }),
    ]
    const selectedSharedSet = ref(addAsNegativeKeywordValue)

    const entityPill = formatEntityPillLink({
        entityPillData: {
            type: Entity.EntityLocation.Campaign,
            content: campaignLocationData?.label,
        },
        deepLinkParams: campaignLocationData?.entityIds,
    })

    const cpaImprovementStats = [
        {
            key: 'cpa',
            value: useDomainMoney({
                value: cpa,
            }).value.displayValue.value,
            title: 'Location CPA',
        },
        {
            key: 'groupTargetCpa',
            value: useDomainMoney({
                value: targetCpa,
            }).value.displayValue.value,
            title: autoTarget ? `Campaign Group Average CPA` : `Campaign Group Target CPA`,
        },
    ]
    const roasImprovementStats = [
        {
            key: 'roas',
            value: useRoas({
                value: roas,
                decimalPlaces: 2,
            }).displayValue.value,
            title: 'Location ROAS',
        },
        {
            key: 'groupTargetRoas',
            value: useRoas({
                value: targetRoas,
                decimalPlaces: 2,
            }).displayValue.value,
            title: autoTarget ? `Campaign Group Average ROAS` : `Campaign Group Target ROAS`,
        },
    ]

    const pushActionText = ref('Add Negative Keyword')
    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Adding Negative Keyword..',
        'Confirming changes..',
        'Negative Keyword added successfully.',
    ])

    const onPush: OnPushHandler<AddNegativeGeoKeyword.SharedSet> = () => {
        if (selectedSharedSet.value === addAsNegativeKeywordValue) {
            return { valid: true }
        }

        return {
            valid: true,
            pushedData: negativeLists.find(
                (list: NegativeList) => list.resource_name === selectedSharedSet.value
            ),
        }
    }

    return {
        title,
        pushMessages,
        onPush,
        lastUpdated,
        entityPill,
        campaignName,
        locationName,
        searchTerms,
        searchTermCount,
        cpa,
        roas,
        targetCpa,
        targetRoas,
        cpaDifference,
        roasDifference,
        clicks,
        cost,
        impressions,
        conversions,
        conversionsValue,
        copy,
        targetedLocationNames,
        targetedLocationsCount,
        sharedSets,
        selectedSharedSet,
        pushActionText,
        isUsingCpa,
        autoTarget,
        cpaImprovementStats,
        roasImprovementStats,
        campaignLocationData,
    }
}
