<template>
    <ImprovementView :push-messages="pushMessages" :push-action-text="pushActionText">
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <EntityPillLink
                        :entityPillData="entityPill.entityPillData"
                        :deepLinkParams="entityPill.deepLinkParams"
                    />
                    <Spacer height="2rem" />
                </div>
                <Text as="p">
                    The placement exclusion list above has yet to be applied to the campaigns below.
                    Click <Text as="span" color="green" weight="600">{{ pushActionText }}</Text> to
                    apply this exclusion list to the following campaign<span
                        v-if="formattedCampaignData.length > 1"
                        >s</span
                    >:
                </Text>
                <Spacer height="2rem" />
                <div v-if="formattedCampaignData.length === 1">
                    <EntityPillLinkList
                        v-if="
                            !aboveMobile &&
                            formattedCampaignData[0].entityPillData.content &&
                            formattedCampaignData[0].entityPillData.content.length > 30
                        "
                        :items="formattedCampaignData"
                    />
                    <EntityPillLink
                        v-else
                        :entityPillData="formattedCampaignData[0].entityPillData"
                        :deepLinkParams="formattedCampaignData[0].deepLinkParams"
                    />
                </div>
                <EntityPillLinkList v-else :items="formattedCampaignData" />
            </ImprovementHeader>
            <ImprovementHelpLink path="/en/articles/2804818-sync-campaigns-to-exclusion-list">
                Click here to learn more about syncing exclusion lists with new campaigns.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="30" />
        </ImprovementContent>
    </ImprovementView>
</template>

<script lang="ts">
import { Text, oButton, Spacer, Ent } from '@opteo/components-next'

import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'

import { useResyncExclusionLists } from '@/composition/improvement/types/useResyncExclusionLists'
import EntityPillLink from '@/components/global/Entity/EntityPillLink.vue'
import EntityPillLinkList from '@/components/global/Entity/EntityPillLinkList.vue'

export default {
    name: 'ResyncExclusionLists',
    components: {
        ImprovementView,
        ImprovementHelpLink,
        Text,
        Spacer,
        Ent,
        oButton,
        EntityPillLink,
        EntityPillLinkList,
    },
    setup() {
        return { ...useResyncExclusionLists(), ...useMediaQuery() }
    },
}
</script>

<style>
.toggle-show-all-campaign-names-button {
    top: -0.5rem;
}
</style>
