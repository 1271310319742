import { AddSiteExclusions, Improvement } from '@opteo/types'
import { computed, ref } from 'vue'

import { useImprovement, checkImprovement, OnPushHandler } from '../useImprovement'

import emoji_DP from '@/assets/img/emoji/emoji_DP.png'
import emoji_ADULTISH from '@/assets/img/emoji/emoji_ADULTISH.png'
import emoji_JUVENILE from '@/assets/img/emoji/emoji_JUVENILE.png'
import emoji_PROFANITY from '@/assets/img/emoji/emoji_PROFANITY.png'
import emoji_TRAGEDY from '@/assets/img/emoji/emoji_TRAGEDY.png'

export function useAddSiteExclusions() {
    const { improvement, lastUpdated, title } = useImprovement<
        AddSiteExclusions.Body,
        typeof Improvement.RecAction.AddSiteExclusions
    >()
    const { body, location } = checkImprovement(improvement)

    const locationEntityIds = location[0].entityIds

    const categories = [
        {
            name: 'Death and Tragedy',
            id: 'TRAGEDY',
            image: emoji_TRAGEDY,
        },

        {
            name: 'Juvenile, Gross, and Bizarre',
            id: 'JUVENILE',
            image: emoji_JUVENILE,
        },
        {
            name: 'Profanity and Rough Language',
            id: 'PROFANITY',
            image: emoji_PROFANITY,
        },
        {
            name: 'Sexually Suggestive Content',
            id: 'ADULTISH',
            image: emoji_ADULTISH,
        },
        {
            name: 'Parked Domains',
            id: 'DP',
            image: emoji_DP,
        },
    ]

    const categoriesToExclude = ref(
        categories.map(category => {
            return {
                ...category,
                status: true,
            }
        })
    )

    const exclusionsTableItems = categories.map(category => {
        return {
            ...category,
            status: body.current_exclusions.includes(category.id),
        }
    })

    const exclusionsTableHeaders = [
        { key: 'category', text: 'Category' },
        { key: 'status', text: 'Current Status' },
    ]

    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Excluding site categories..',
        'Confirming changes..',
        'Site categories excluded successfully.',
    ])

    const excludeTableHeaders = [
        { key: 'category', text: 'Category' },
        { key: 'status', text: 'Action' },
    ]

    const updateCategoryStatus = (id: string, newValue: boolean) => {
        const category = categoriesToExclude.value.find(category => category.id === id)
        if (!category) {
            throw new Error('cannot update category that does not exist')
        }
        category.status = newValue
    }

    const atLeastOneSelected = computed(() =>
        categoriesToExclude.value.some(cat => cat.status === true)
    )

    const onPush: OnPushHandler<AddSiteExclusions.ExtraDetails> = () => {
        if (!atLeastOneSelected.value) {
            return {
                valid: false,
            }
        }

        const categoriesToPush: Record<string, boolean> = {}
        for (const category of categoriesToExclude.value) {
            categoriesToPush[category.id] = category.status
        }

        return {
            valid: true,
            pushedData: {
                ids: categoriesToPush,
            },
        }
    }

    const pushActionText = ref('Exclude Site Categories')

    return {
        title,
        locationEntityIds,
        lastUpdated,
        pushMessages,
        body,
        exclusionsTableHeaders,
        exclusionsTableItems,
        excludeTableHeaders,
        categoriesToExclude,
        onPush,
        atLeastOneSelected,
        updateCategoryStatus,
        pushActionText,
    }
}
