import { computed, provide, inject } from 'vue'
import { useRouter } from 'vue-router'
import { Domain } from '@opteo/types'
import { ProvideKeys } from '@/composition/useProvide'
import { authRequest, Endpoint, useAPI } from '../api/useAPI'
import { useAccountList } from '@/composition/user/useAccountList'
import formatISO from 'date-fns/formatISO'
import { IS_DEMO } from '@/lib/env'
import upperCase from 'lodash-es/upperCase'
import { useAccount } from '../account/useAccount'

export function provideDomain() {
    const { mutateDomainList } = useAccountList()
    const { accountId, domainId, mutateAccount, currencyCode, currencySymbol } = useAccount()

    const {
        data: domainInfo,
        loading,
        error,
        mutate,
    } = useAPI<Domain.Info>(Endpoint.GetDomainInfo, {
        body: () => ({ domain_id: domainId.value }),
        uniqueId: () => domainId.value,
        waitFor: () => domainId.value,
    })

    const { data: domainsWithLogo, mutate: mutateDomainLogo } = useAPI<Domain.Logo[]>(
        Endpoint.GetDomainsWithLogo,
        {
            uniqueId: () => domainId.value,
            waitFor: () => domainId.value,
        }
    )

    const domainColour = computed(() => domainInfo.value?.color_preference)
    const domainShadow = computed(() => domainInfo.value?.color_preference + '1a')

    const domainName = computed(() => domainInfo.value?.domain_name)
    const domainTimezone = computed(() => domainInfo.value?.adwords_timezone)
    const domainInitials = computed(() =>
        getDomainInitials(domainName.value as string).toUpperCase()
    )

    provide(
        ProvideKeys.CurrencyCode,
        computed(() => currencyCode.value)
    )

    const domainLogoUrl = computed(
        () => domainsWithLogo.value?.find(item => item.domain_id === domainId.value)?.logo_filename
    )

    const trackDomainVisit = async () => {
        const lastVisited = formatISO(new Date())
        // @ts-ignore TODO: Remove Date type from Domain.Info.last_visited
        mutateDomain({ last_visited: lastVisited })
        // @ts-ignore TODO: Remove Date type from Domain.Info.last_visited
        mutateDomainList(domainId.value, { last_visited: lastVisited })

        if (!IS_DEMO) await authRequest(Endpoint.TrackAccountVisit)
    }

    const performanceMode = computed(() => domainInfo.value?.performance_mode)

    const mutateDomain = (updatedDomain?: Partial<Domain.Info>) => {
        if (typeof updatedDomain === 'undefined') {
            mutate()
            mutateAccount() // TODO: Remove this line when possible. It's a hack to reflect any changes to useDomain in useAccount
            return
        }

        mutate(() => {
            if (typeof domainInfo.value === 'undefined') {
                throw new Error('domainInfo.value must be defined to be mutated')
            }
            return { ...domainInfo.value, ...updatedDomain }
        })
    }

    const toProvide = {
        domainId,
        accountId,
        domainInfo,
        domainColour,
        domainShadow,
        domainTimezone,
        loading,
        error,
        domainName,
        domainInitials,
        trackDomainVisit,
        mutateDomain,
        currencySymbol,
        currencyCode,
        domainLogoUrl,
        mutateDomainLogo,
        performanceMode,
    }

    provide('domain', toProvide)

    return toProvide
}

export function getDomainInitials(domainName: string) {
    return upperCase(domainName ? domainName.slice(0, 2) : '')
}

export function useDomain() {
    const injected = inject<ReturnType<typeof provideDomain>>('domain')

    if (!injected) {
        throw new Error(
            `useDomain not yet injected, something is wrong. useDomain() can only be called in a /accounts/ route.`
        )
    }

    return injected
}
