import { ref, computed, Ref, unref } from 'vue'
import { UsePercentParams } from '@/composition/utils/types'

import {
    OnPushHandler,
    Step,
    useImprovement,
    UseImprovement,
    checkImprovement,
} from '../useImprovement'

import { usePercent } from '@opteo/components-next'
import { AdComparisonV2 } from '@opteo/types'
import { TopSearchTerms } from '@opteo/types/improvement/types/skag/add_skag_v2'

type FormElement = HTMLDivElement & { resolveAd: () => void; valid: boolean }

interface UseWriteAd {
    adWriter: Ref<FormElement | undefined>
    setNewAd: (ad: Ad) => void
    usePercent: (params: UsePercentParams) => any
    unref: <T>(ref: T | Ref<T>) => T
    root_domain: string
    improvementId: number
    adgroupLabel: string
    adGroupId: number
    campaignId: number
    topSearchTerms: TopSearchTerms[]
    legacy?: boolean
    clicks: number
    ctr: number
    cost: number
    impressions: number
    impressions_absolute_top: number
    newAd: Ref<Ad | undefined>
    currentAd: Ref<TextAdParams | undefined>
    domainId: number
}
interface Ad {
    description?: string
    destination_url?: string
    expanded_text_ad_description2?: string
    expanded_text_ad_headline3?: string
    headline_part_1?: string
    headline_part_2?: string
    path_1?: string
    path_2?: string
}

interface TextAdParams {
    headlineOne: string
    headlineTwo: string
    headlineThree: string
    descriptionOne: string
    descriptionTwo: string
    displayUrl: string
    pathOne: string
    pathTwo: string
}

export function useWriteAdV2(): UseImprovement<UseWriteAd> {
    const { improvement, lastUpdated, title } = useImprovement<AdComparisonV2.WriteAd.Body>()
    const { body, improvement_id: improvementId } = checkImprovement(improvement)

    const {
        expanded_text_ad_headline1,
        expanded_text_ad_headline2,
        expanded_text_ad_headline3,
        expanded_text_ad_description,
        expanded_text_ad_description2,
        expanded_text_ad_path_1,
        expanded_text_ad_path_2,
        adgroup_id: adGroupId,
        campaign_id: campaignId,
        root_domain,
        top_search_terms: topSearchTerms,
        adgroup_label: adgroupLabel,
        clicks,
        ctr,
        cost,
        impressions,
        impressions_absolute_top,
        domain_id: domainId,
    } = body

    const newAd = ref<Ad>({})
    const currentAd = computed(() => {
        return {
            headlineOne: expanded_text_ad_headline1 ?? '',
            headlineTwo: expanded_text_ad_headline2 ?? '',
            headlineThree: expanded_text_ad_headline3 ?? '',
            descriptionOne: expanded_text_ad_description ?? '',
            descriptionTwo: expanded_text_ad_description2 ?? '',
            displayUrl: root_domain,
            pathOne: expanded_text_ad_path_1 ?? '',
            pathTwo: expanded_text_ad_path_2 ?? '',
        }
    })

    const adjustSteps = ref(['Open Ad Writer', 'Preview New Ad'])

    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        `Creating new ad..`,
        'Confirming changes..',
        `New ad created successfully.`,
    ])

    const adWriter = ref<FormElement>()

    function setNewAd(ad: Ad) {
        newAd.value = ad
    }

    function onStepComplete({ step }: { step: Step }): boolean {
        if (step === Step.ONE) {
            return adWriter.value?.resolveAd() ?? false
        }

        return true
    }

    const onPush: OnPushHandler<Ad> = () => {
        return { valid: true, pushedData: newAd.value! }
    }

    const pushActionText = ref('Push New Ad')

    return {
        title,
        pushMessages,
        lastUpdated,
        usePercent,
        unref,
        onPush,
        adWriter,
        onStepComplete,
        root_domain,
        domainId,
        improvementId,
        adgroupLabel,
        adGroupId,
        campaignId,
        topSearchTerms,
        setNewAd,
        newAd,
        clicks,
        ctr,
        cost,
        impressions,
        impressions_absolute_top,
        currentAd,
        pushActionText,
        adjustSteps,
    }
}
