import { ref, Ref, computed, watch, onMounted } from 'vue'
import { Endpoint, useAPI } from '@/composition/api/useAPI'

import { useNumber, useMoney, usePercent } from '@opteo/components-next'
import { sumBy, capitalize as capitalise } from '@/lib/lodash'
import { adaptDateToTimezone } from '@/composition/misc/useTimezone'
import { charts } from './chartConstants'
import { Platform } from '@opteo/types'
import {
    format as formatDate,
    isFirstDayOfMonth,
    isLastDayOfMonth,
    isMonday,
    isSunday,
} from 'date-fns'

import {
    TimePeriod,
    RawChartData,
    ChartData,
    ChartDataRanges,
    Chart,
    DataType,
    ChartType,
    ChartLabel,
    ChartMetric,
    ChartMarker,
    ChartItems,
    LineChartItem,
    AreaChartItem,
    UseGraphs,
    ChartID,
} from './types'
import { usePerformanceControls } from './usePerformanceControls'
import useMediaQuery from '../global/useMediaQuery'
import { useAccount } from '../account/useAccount'

const CONVERSION_VAL_KEY = 'conversion_value:::'
const CONVERSIONS_KEY = 'conversions:::'

export function useGraphs(): UseGraphs {
    const { currencyCode, accountPlatform } = useAccount()

    const {
        // DATE RANGE
        extendedStartDate,
        startDate,
        endDate,
        // SIDEBAR
        sidebarOpen,
        sidebarPresets,
        chartsEnabledStatuses,
        // VIEW OPTION
        selectedTimePeriod,
        // LAYOUT OPTION
        selectedLayoutOption,
        // CAMPAIGNS
        accountDataLoading,
        mutateAccountData,
        accountDataValidating,
        channels,
        selectedCampaignIds,
        selectedCampaignCount,
        entireChannelSelected,
        toggleChannel,
        toggleCampaign,
        // CONVERSION ACTIONS
        conversionActions,
        selectedConversionActions,
        selectedConversionActionCount,
        toggleConversionAction,
    } = usePerformanceControls()

    const {
        data: rawChartData,
        loading: rawChartDataLoading, // show skeletons
        mutate: updateChartData,
    } = useAPI<RawChartData[]>(Endpoint.GetPerformanceData, {
        body: () => ({
            campaign_ids: selectedCampaignIds.value,
            raw_from_date: extendedStartDate.value,
            raw_to_date: endDate.value,
            time_period: selectedTimePeriod.value,
        }),
        uniqueId: () => JSON.stringify(selectedCampaignIds.value), // items here will cause the skeletons to show
        waitFor: () => !accountDataLoading.value && currencyCode.value,
    })

    // Need to show skeletons while loading data
    const chartDataLoading = computed(() => rawChartDataLoading.value || accountDataLoading.value)

    // Items here will cause spinners to spin (but not skeletons)
    const toWatch = computed(() => {
        return JSON.stringify([
            extendedStartDate.value.getTime(),
            startDate.value.getTime(),
            endDate.value.getTime(),
            selectedTimePeriod.value,
            selectedConversionActions.value,
            selectedCampaignIds.value,
        ])
    })

    const chartDataValidating = ref(false)

    watch(toWatch, async () => {
        chartDataValidating.value = true
        await mutateAccountData()
        await updateChartData()
        chartDataValidating.value = false
    })

    const partialStart = computed(() => {
        switch (selectedTimePeriod.value) {
            case TimePeriod.MONTH:
                return !isFirstDayOfMonth(startDate.value)
            case TimePeriod.WEEK:
                return !isMonday(startDate.value)
            case TimePeriod.DAY:
                return false
        }
    })

    const partialEnd = computed(() => {
        switch (selectedTimePeriod.value) {
            case TimePeriod.MONTH:
                return !isLastDayOfMonth(endDate.value)
            case TimePeriod.WEEK:
                return !isSunday(endDate.value)
            case TimePeriod.DAY:
                return false
        }
    })

    const chartDataRanges: Ref<ChartDataRanges> = ref({
        selectedRangeChartData: [],
        previousRangeChartData: [],
    })

    const refresh = () => {
        const selectedRangeChartData: ChartData[] = []
        const previousRangeChartData: ChartData[] = []

        if (!rawChartData.value || !rawChartData.value.length) {
            return
        }

        rawChartData.value.forEach(rawDay => {
            const dailyChartData: ChartData = {
                ...rawDay,
                total_conversions: 0,
                total_conversions_value: 0,
            }

            Object.entries(rawDay).forEach(([key, val]) => {
                const keyIsConversion = key.includes(CONVERSIONS_KEY)
                const keyIsConversionValue = key.includes(CONVERSION_VAL_KEY)

                const conversionIsSelected = selectedConversionActions.value
                    .map(c => c.id)
                    .includes(key.replace(CONVERSIONS_KEY, ''))
                const conversionValueIsSelected = selectedConversionActions.value
                    .map(c => c.id)
                    .includes(key.replace(CONVERSION_VAL_KEY, ''))

                if (keyIsConversion && conversionIsSelected) {
                    dailyChartData.total_conversions += val
                }
                if (keyIsConversionValue && conversionValueIsSelected) {
                    dailyChartData.total_conversions_value += val
                }
            })

            // Formatted: 'yyyy-MM-dd'
            const { day: date } = dailyChartData
            /**
             * Local time adapted to the same time and date as UTC, without offset, different than what new Date(date)
             * E.g.: 2022-01-30 00h00m UTC turns into 2022-01-30 00h00m UTC-3, instead of 2022-01-29 21h00m UTC-3 (one day prior)
             */
            const adaptedDate = adaptDateToTimezone(new Date(date))

            if (
                selectedTimePeriod.value === TimePeriod.MONTH &&
                formatDate(adaptedDate, 'yyyy-MM') >= formatDate(startDate.value, 'yyyy-MM')
            ) {
                selectedRangeChartData.push(dailyChartData)
            } else if (
                selectedTimePeriod.value === TimePeriod.WEEK &&
                formatDate(adaptedDate, 'yyyy-ww') >= formatDate(startDate.value, 'yyyy-ww')
            ) {
                selectedRangeChartData.push(dailyChartData)
            } else if (
                selectedTimePeriod.value === TimePeriod.DAY &&
                formatDate(adaptedDate, 'yyyy-MM-dd') >= formatDate(startDate.value, 'yyyy-MM-dd')
            ) {
                selectedRangeChartData.push(dailyChartData)
            } else {
                previousRangeChartData.push(dailyChartData)
            }
        })

        chartDataRanges.value.selectedRangeChartData = selectedRangeChartData
        chartDataRanges.value.previousRangeChartData = previousRangeChartData

        allCharts.value = buildAllCharts().filter(c => c.platforms.includes(accountPlatform.value))
    }

    watch(rawChartData, () => refresh())

    onMounted(() => refresh())

    function buildChartConfig({
        id,
        label,
        chartType,
        dataType,
        positiveDeltaIsGood = true, // only need to declare if false (e.g. cost)
        chartMarkers = [], // only need to declare if markers exist
        buildChartData,
        sumDataItems,
    }: {
        id: ChartID
        label: ChartLabel
        chartType: ChartType
        dataType: DataType
        positiveDeltaIsGood?: boolean
        chartMarkers?: ChartMarker[]
        buildChartData: (data: ChartData[]) => ChartItems
        sumDataItems?: (points: ChartData[]) => number
    }): Chart {
        const enabled = !sidebarPresets.value.deselectedCharts.includes(id)

        const { selectedRangeChartData: selected, previousRangeChartData: previous } =
            chartDataRanges.value

        const chartData = buildChartData(selected)

        let showZero = true
        const platforms = charts.find(chartConstant => chartConstant.id === id)?.platforms ?? [
            Platform.Platform.GoogleAds,
        ]

        chartData.forEach(series => {
            series.items.forEach((item, index) => {
                if (partialStart.value && index === 0) {
                    item.dotted = true
                    item.label += ' (incomplete)'
                }

                if (partialEnd.value && index === series.items.length - 1) {
                    item.dotted = true
                    item.label += ' (incomplete)'
                }
            })
        })

        let chartMetric: ChartMetric = { label }

        switch (dataType) {
            case DataType.NUMBER:
                break
            case DataType.MONEY:
                chartMetric = { currency: currencyCode.value, dataType: DataType.MONEY }
                break
            case DataType.PERCENT:
                chartMetric = { dataType: DataType.PERCENT }
                break
        }

        if (typeof sumDataItems === 'undefined') {
            // Graphs that don't have a delta and summed value can exit here
            const currentValues = chartData
                .sort((a, b) => {
                    const valueCurrent = sumBy(a.items, i => i.y) / a.items.length
                    const valueChallenger = sumBy(b.items, i => i.y) / b.items.length

                    return valueChallenger - valueCurrent
                })
                .map(s => {
                    const value = sumBy(s.items, i => i.y) / s.items.length // get a brute-force avg

                    let formattedValue = ''

                    switch (dataType) {
                        case DataType.NUMBER:
                            formattedValue = useNumber({ value }).displayValue.value
                            break
                        case DataType.MONEY:
                            formattedValue = useMoney({
                                value,
                                currency: currencyCode.value,
                            }).displayValue.value
                            break
                        case DataType.PERCENT:
                            formattedValue = usePercent({ value, decimalPlaces: 0 }).displayValue
                                .value
                            break
                    }

                    return formattedValue
                })

            if (chartType === ChartType.AREA) currentValues.reverse()

            let maxItem

            if (label === ChartLabel.SEARCH_IMP_SHARE) {
                maxItem = 1
            }

            if (label === ChartLabel.QS_COMPONENTS) {
                maxItem = 3
            }

            if (label === ChartLabel.QS) {
                maxItem = 10
            }

            return {
                id,
                enabled,
                label,
                chartType,
                chartData,
                chartMetric,
                chartMarkers,
                showZero,
                currentValues,
                maxItem,
                platforms,
            }
        }

        const selectedTotal = sumDataItems(selected)
        const previousTotal = sumDataItems(previous)

        let total = ''
        let pTotal = ''

        switch (dataType) {
            case DataType.NUMBER:
                total = useNumber({ value: selectedTotal }).displayValue.value
                pTotal = useNumber({ value: previousTotal }).displayValue.value
                break
            case DataType.MONEY:
                total = useMoney({ value: selectedTotal, currency: currencyCode.value })
                    .displayValue.value
                pTotal = useMoney({ value: previousTotal, currency: currencyCode.value })
                    .displayValue.value
                break
            case DataType.PERCENT:
                total = usePercent({ value: selectedTotal }).displayValue.value
                pTotal = usePercent({ value: previousTotal }).displayValue.value
                break
        }

        const deltaValue = previousTotal
            ? (selectedTotal - previousTotal) / Math.abs(previousTotal)
            : 0

        const delta = { delta: deltaValue, reverse: !positiveDeltaIsGood }

        return {
            id,
            enabled,
            label,
            chartType,
            chartData,
            total,
            pTotal,
            delta,
            chartMetric,
            chartMarkers,
            showZero,
            platforms,
        }
    }

    const allCharts = ref<Chart[]>([])

    const { aboveMobile } = useMediaQuery()

    watch(aboveMobile, () => {
        refresh()
    })

    function generateXValue(date: ChartData) {
        const day = adaptDateToTimezone(new Date(date.day))

        if (selectedTimePeriod.value === TimePeriod.MONTH) {
            return { label: formatDate(day, 'MMMM yyyy') }
        }

        if (selectedTimePeriod.value === TimePeriod.WEEK) {
            const year = aboveMobile.value ? formatDate(day, 'y') : formatDate(day, 'yy')
            const label = `Week ${date.week}, ${year}`
            return {
                label,
            }
        }

        return { x: day }
    }

    function buildAllCharts() {
        return [
            // CLICKS CHART
            buildChartConfig({
                id: ChartID.CLICKS,
                label: ChartLabel.CLICKS,
                dataType: DataType.NUMBER,
                chartType: ChartType.AREA,
                buildChartData: (data): AreaChartItem[] => {
                    return [
                        {
                            name: ChartLabel.CLICKS,
                            items: data.map(date => {
                                return { y: +date.clicks, ...generateXValue(date) }
                            }),
                        },
                    ]
                },
                sumDataItems: items => sumBy(items, ({ clicks }) => +clicks),
            }),
            // COST CHART
            buildChartConfig({
                id: ChartID.COST,
                label: ChartLabel.COST,
                chartType: ChartType.AREA,
                dataType: DataType.MONEY,
                positiveDeltaIsGood: false, // high cost is bad
                buildChartData: (data): AreaChartItem[] => {
                    return [
                        {
                            name: ChartLabel.COST,
                            items: data.map(date => {
                                return { y: +date.cost, ...generateXValue(date) }
                            }),
                        },
                    ]
                },
                sumDataItems: items => sumBy(items, ({ cost }) => +cost),
            }),
            // CONVERSIONS CHART
            buildChartConfig({
                id: ChartID.CONVERSIONS,
                label: ChartLabel.CONVERSIONS,
                chartType: ChartType.AREA,
                dataType: DataType.NUMBER,
                buildChartData: (data): AreaChartItem[] => {
                    return selectedConversionActions.value
                        .map(conversionAction => {
                            return {
                                name: conversionAction.label,
                                items: data.map(date => {
                                    const key =
                                        `${CONVERSIONS_KEY}${conversionAction.id}` as unknown as keyof ChartData

                                    return {
                                        ...generateXValue(date),
                                        y: Math.round(date[key] as number) || 0,
                                    }
                                }),
                            }
                        })
                        .sort((a, b) => {
                            return sumBy(a.items, ({ y }) => y) - sumBy(b.items, ({ y }) => y)
                        })
                },
                sumDataItems: items => sumBy(items, ({ total_conversions }) => total_conversions),
            }),
            // CONVERSION VALUE CHART
            buildChartConfig({
                id: ChartID.CONV_VAL,
                label: ChartLabel.CONV_VAL,
                chartType: ChartType.AREA,
                dataType: DataType.MONEY,
                buildChartData: (data): AreaChartItem[] => {
                    return selectedConversionActions.value
                        .map(conversionAction => {
                            return {
                                name: conversionAction.label,
                                items: data.map(date => {
                                    const key =
                                        `${CONVERSION_VAL_KEY}${conversionAction.id}` as unknown as keyof ChartData

                                    return {
                                        ...generateXValue(date),
                                        y: Math.round(date[key] as number) || 0,
                                    }
                                }),
                            }
                        })
                        .sort((a, b) => {
                            return sumBy(a.items, ({ y }) => y) - sumBy(b.items, ({ y }) => y)
                        })
                },
                sumDataItems: items =>
                    sumBy(items, ({ total_conversions_value }) => total_conversions_value),
            }),
            // COST PER CONVERSION CHART
            buildChartConfig({
                id: ChartID.CPA,
                label: ChartLabel.CPA,
                chartType: ChartType.LINE,
                dataType: DataType.MONEY,
                positiveDeltaIsGood: false, // high cpa is bad
                buildChartData: (data): LineChartItem[] => {
                    return selectedConversionActions.value
                        .map(conversionAction => {
                            return {
                                name: conversionAction.label,
                                items: data.map(date => {
                                    const key =
                                        `${CONVERSIONS_KEY}${conversionAction.id}` as unknown as keyof ChartData
                                    const conversions = Math.round(date[key] as number) || 0
                                    const y = conversions ? +date.cost / conversions : 0

                                    return {
                                        ...generateXValue(date),
                                        y,
                                    }
                                }),
                            }
                        })
                        .sort((a, b) => {
                            return sumBy(a.items, ({ y }) => y) - sumBy(b.items, ({ y }) => y)
                        })
                },
                sumDataItems: items => {
                    const summedCost = sumBy(items, ({ cost }) => +cost)
                    const summedTotalConversions = sumBy(
                        items,
                        ({ total_conversions }) => total_conversions
                    )

                    return summedTotalConversions ? summedCost / summedTotalConversions : 0
                },
            }),
            // ROAS CHART
            buildChartConfig({
                id: ChartID.ROAS,
                label: ChartLabel.ROAS,
                chartType: ChartType.LINE,
                dataType: DataType.PERCENT,
                buildChartData: (data): LineChartItem[] => {
                    return selectedConversionActions.value
                        .map(conversionAction => {
                            return {
                                name: conversionAction.label,
                                items: data.map(date => {
                                    const key =
                                        `${CONVERSION_VAL_KEY}${conversionAction.id}` as unknown as keyof ChartData
                                    const conversionsValue = Math.round(date[key] as number) || 0
                                    const y = +date.cost ? conversionsValue / +date.cost : 0

                                    return {
                                        ...generateXValue(date),
                                        y,
                                    }
                                }),
                            }
                        })
                        .sort((a, b) => {
                            return sumBy(a.items, ({ y }) => y) - sumBy(b.items, ({ y }) => y)
                        })
                },
                sumDataItems: items => {
                    const summedTotalConversionValue = sumBy(
                        items,
                        ({ total_conversions_value }) => total_conversions_value
                    )
                    const summedCost = sumBy(items, ({ cost }) => +cost)

                    return summedCost ? summedTotalConversionValue / summedCost : 0
                },
            }),
            // CTR CHART
            buildChartConfig({
                id: ChartID.CTR,
                label: ChartLabel.CTR,
                chartType: ChartType.LINE,
                dataType: DataType.PERCENT,
                buildChartData: (data): LineChartItem[] => {
                    return [
                        {
                            name: ChartLabel.CTR,
                            items: data.map(date => {
                                const y = +date.impressions ? +date.clicks / +date.impressions : 0
                                return { y, ...generateXValue(date) }
                            }),
                        },
                    ]
                },
                sumDataItems: items => {
                    const summedClicks = sumBy(items, ({ clicks }) => +clicks)
                    const summedImpressions = sumBy(items, ({ impressions }) => +impressions)
                    return summedImpressions ? summedClicks / summedImpressions : 0
                },
            }),
            // COST PER CLICK CHART
            buildChartConfig({
                id: ChartID.CPC,
                label: ChartLabel.CPC,
                chartType: ChartType.LINE,
                dataType: DataType.MONEY,
                positiveDeltaIsGood: false, // high cpc is bad
                buildChartData: (data): LineChartItem[] => {
                    const chartData: LineChartItem[] = [{ name: 'Cost Per Click', items: [] }]

                    data.forEach(date => {
                        const cpc = +date.clicks ? +date.cost / +date.clicks : 0

                        chartData[0].items.push({
                            ...generateXValue(date),
                            y: cpc,
                        })
                    })

                    return chartData
                },
                sumDataItems: items => {
                    const summedCost = sumBy(items, ({ cost }) => +cost)
                    const summedClicks = sumBy(items, ({ clicks }) => +clicks)

                    return summedClicks ? summedCost / summedClicks : 0
                },
            }),
            // CONVERSION RATE CHART
            buildChartConfig({
                id: ChartID.CR,
                label: ChartLabel.CR,
                chartType: ChartType.LINE,
                dataType: DataType.PERCENT,
                buildChartData: (data): LineChartItem[] => {
                    return selectedConversionActions.value
                        .map(conversionAction => {
                            return {
                                name: conversionAction.label,
                                items: data.map(date => {
                                    const key =
                                        `${CONVERSIONS_KEY}${conversionAction.id}` as unknown as keyof ChartData
                                    const conversions = Math.round(date[key] as number) || 0
                                    const y = +date.clicks ? conversions / +date.clicks : 0

                                    return {
                                        ...generateXValue(date),
                                        y,
                                    }
                                }),
                            }
                        })
                        .sort((a, b) => {
                            return sumBy(a.items, ({ y }) => y) - sumBy(b.items, ({ y }) => y)
                        })
                },
                sumDataItems: items => {
                    const summedClicks = sumBy(items, ({ clicks }) => +clicks)
                    const summedTotalConversions = sumBy(
                        items,
                        ({ total_conversions }) => total_conversions
                    )

                    return summedClicks ? summedTotalConversions / summedClicks : 0
                },
            }),
            // IMPRESSIONS CHART
            buildChartConfig({
                id: ChartID.IMPRESSIONS,
                label: ChartLabel.IMPRESSIONS,
                chartType: ChartType.LINE,
                dataType: DataType.NUMBER,
                buildChartData: (data): LineChartItem[] => {
                    return [
                        {
                            name: ChartLabel.IMPRESSIONS,
                            items: data.map(date => {
                                return {
                                    y: +date.impressions,
                                    ...generateXValue(date),
                                }
                            }),
                        },
                    ]
                },
                sumDataItems: items => sumBy(items, ({ impressions }) => +impressions),
            }),
            // CONVERSION VALUE PER CONVERSION CHART
            buildChartConfig({
                id: ChartID.VAL_PER_CONV,
                label: ChartLabel.VAL_PER_CONV,
                chartType: ChartType.LINE,
                dataType: DataType.MONEY,
                buildChartData: (data): LineChartItem[] => {
                    return selectedConversionActions.value
                        .map(conversionAction => {
                            return {
                                name: conversionAction.label,
                                items: data.map(date => {
                                    const conversionsKey =
                                        `${CONVERSIONS_KEY}${conversionAction.id}` as unknown as keyof ChartData
                                    const conversions =
                                        Math.round(date[conversionsKey] as number) || 0

                                    const conversionValKey =
                                        `${CONVERSION_VAL_KEY}${conversionAction.id}` as unknown as keyof ChartData
                                    const conversionVal =
                                        Math.round(date[conversionValKey] as number) || 0

                                    const y = conversions ? conversionVal / conversions : 0

                                    return {
                                        ...generateXValue(date),
                                        y,
                                    }
                                }),
                            }
                        })
                        .sort((a, b) => {
                            return sumBy(a.items, ({ y }) => y) - sumBy(b.items, ({ y }) => y)
                        })
                },
            }),
            // SEARCH IMPRESSION SHARE CHART
            buildChartConfig({
                id: ChartID.SEARCH_IMP_SHARE,
                label: ChartLabel.SEARCH_IMP_SHARE,
                chartType: ChartType.AREA,
                dataType: DataType.PERCENT,
                buildChartData: (data): AreaChartItem[] => {
                    const chartData: AreaChartItem[] = [
                        { name: 'Impression Share Won', items: [] },
                        { name: 'Share Lost To Rank', items: [] },
                        { name: 'Share Lost To Budget', items: [] },
                    ]

                    data.forEach(date => {
                        const shareLostToRank = +date.market_impressions
                            ? +date.impressions_lost_to_rank / +date.market_impressions
                            : 0

                        const shareLostToBudget = +date.market_impressions
                            ? +date.impressions_lost_to_budget / +date.market_impressions
                            : 0

                        const shareWon = +date.market_impressions
                            ? 1 - shareLostToRank - shareLostToBudget
                            : 0

                        chartData[0].items.push({
                            ...generateXValue(date),
                            y: shareWon,
                        })
                        chartData[1].items.push({
                            ...generateXValue(date),
                            y: shareLostToRank,
                        })
                        chartData[2].items.push({
                            ...generateXValue(date),
                            y: shareLostToBudget,
                        })
                    })

                    return chartData
                },
            }),

            // QS CHART
            buildChartConfig({
                id: ChartID.QS,
                label: ChartLabel.QS,
                chartType: ChartType.LINE,
                dataType: DataType.NUMBER,
                buildChartData: (data): LineChartItem[] => {
                    return [
                        {
                            name: ChartLabel.QS,
                            items: data.map(date => {
                                return {
                                    y: +date.qs,
                                    ...generateXValue(date),
                                }
                            }),
                        },
                    ]
                },
                sumDataItems: items => sumBy(items, ({ qs }) => +qs) / items.length,
            }),
            // QUALITY SCORE COMPONENTS CHART
            buildChartConfig({
                id: ChartID.QS_COMPONENTS,
                label: ChartLabel.QS_COMPONENTS,
                chartType: ChartType.LINE,
                dataType: DataType.NUMBER,
                buildChartData: (data): AreaChartItem[] => {
                    const chartData: AreaChartItem[] = [
                        { name: 'Expected Click Through Rate', items: [] },
                        { name: 'Ad Relevance', items: [] },
                        { name: 'Landing Page Experience', items: [] },
                    ]

                    data.forEach(date => {
                        chartData[0].items.push({
                            ...generateXValue(date),
                            y: +date['qs_components:::qs_ctr'],
                        })
                        chartData[1].items.push({
                            ...generateXValue(date),
                            y: +date['qs_components:::qs_creative'],
                        })
                        chartData[2].items.push({
                            ...generateXValue(date),
                            y: +date['qs_components:::qs_landing_page'],
                        })
                    })

                    return chartData
                },
            }),
            // REACTIONS CHART
            buildChartConfig({
                id: ChartID.REACTIONS,
                label: ChartLabel.REACTIONS,
                chartType: ChartType.LINE,
                dataType: DataType.NUMBER,
                buildChartData: (data): LineChartItem[] => {
                    return [
                        {
                            name: ChartLabel.REACTIONS,
                            items: data.map(date => {
                                return {
                                    y: +date.reactions,
                                    ...generateXValue(date),
                                }
                            }),
                        },
                    ]
                },
                sumDataItems: items => sumBy(items, ({ reactions }) => +reactions),
            }),
            // COMMENTS CHART
            buildChartConfig({
                id: ChartID.COMMENTS,
                label: ChartLabel.COMMENTS,
                chartType: ChartType.LINE,
                dataType: DataType.NUMBER,
                buildChartData: (data): LineChartItem[] => {
                    return [
                        {
                            name: ChartLabel.COMMENTS,
                            items: data.map(date => {
                                return {
                                    y: +date.comments,
                                    ...generateXValue(date),
                                }
                            }),
                        },
                    ]
                },
                sumDataItems: items => sumBy(items, ({ comments }) => +comments),
            }),
            // SHARES CHART
            buildChartConfig({
                id: ChartID.SHARES,
                label: ChartLabel.SHARES,
                chartType: ChartType.LINE,
                dataType: DataType.NUMBER,
                buildChartData: (data): LineChartItem[] => {
                    return [
                        {
                            name: ChartLabel.SHARES,
                            items: data.map(date => {
                                return {
                                    y: +date.shares,
                                    ...generateXValue(date),
                                }
                            }),
                        },
                    ]
                },
                sumDataItems: items => sumBy(items, ({ shares }) => +shares),
            }),
            // ENGAGEMENT CHART
            buildChartConfig({
                id: ChartID.ENGAGEMENT,
                label: ChartLabel.ENGAGEMENT,
                chartType: ChartType.LINE,
                dataType: DataType.NUMBER,
                buildChartData: (data): LineChartItem[] => {
                    return [
                        {
                            name: ChartLabel.ENGAGEMENT,
                            items: data.map(date => {
                                return {
                                    y: +date.engagement,
                                    ...generateXValue(date),
                                }
                            }),
                        },
                    ]
                },
                sumDataItems: items => sumBy(items, ({ engagement }) => +engagement),
            }),
        ]
    }

    const allVisibleCharts = computed(() =>
        allCharts.value.filter(chart => {
            const foundChart = chartsEnabledStatuses.value.find(({ id }) => id === chart.id)

            if (!foundChart) {
                throw new Error('mismatch between graphConstants and allCharts')
            }
            return foundChart.enabled
        })
    )

    const fullScreenChart = ref()

    function openFullScreenPreview(chart: Chart) {
        fullScreenChart.value = chart

        // if scrolled down when accessing bottom charts, and then opening one full-screen, we want to re-scroll up
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }, 200)
    }

    return {
        // CHARTS
        currencyCode,
        chartDataLoading,
        chartDataValidating,
        allCharts,
        allVisibleCharts,
        // SIDEBAR
        sidebarOpen,
        capitalise,
        // LAYOUT OPTION
        selectedLayoutOption,
        // CAMPAIGNS
        accountDataLoading,
        channels,
        selectedCampaignCount,
        entireChannelSelected,
        toggleChannel,
        toggleCampaign,
        // CONVERSION ACTIONS
        conversionActions,
        selectedConversionActionCount,
        toggleConversionAction,

        // FULL SCREEN LOGIC
        fullScreenChart,
        openFullScreenPreview,
    }
}
