import { computed, ref } from 'vue'

import { useDomain } from '@/composition/domain/useDomain'
import { useAPI, Endpoint } from '@/composition/api/useAPI'
import { scrollTo } from '@/lib/globalUtils'

import { Segments, Targets, Platform } from '@opteo/types'
import { usePerformanceControls } from './usePerformanceControls'
import { useAccount } from '../account/useAccount'

const linkedInRoasIncompatibleSegments = [
    'member_industry',
    'member_job_function',
    'member_job_title',
    'member_seniority',
    'company',
    'company_size',
    'country',
    'state',
]

export function useSegments() {
    const { domainId } = useDomain()
    const { accountId, accountColor, accountPlatform } = useAccount()

    const {
        selectedGroup,
        campaignGroupItems,
        selectedGroupId,
        sidebarOpen,
        lookbackWindow,
        campaignGroupsLoading,
    } = usePerformanceControls()

    const isUsingCpa = computed(
        () => selectedGroup.value?.performanceMode === Targets.PerformanceMode.CPA
    )

    const isUsingROAS = computed(
        () => selectedGroup.value?.performanceMode === Targets.PerformanceMode.ROAS
    )
    const loadingAccount = computed(() => !accountColor.value)
    const bestSegmentsSelected = ref(true)

    const campaignGroupId = computed(() => {
        return selectedGroup.value?.id
    })

    const uniqueId = computed(
        () => `${accountId.value}||${campaignGroupId.value}||${lookbackWindow.value}`
    )

    const { data: segmentsData, loading: segmentsLoadingRaw } =
        useAPI<Segments.GetAllSegmentsResponse>(Endpoint.GetAllSegments, {
            waitFor: () => selectedGroup.value && !loadingAccount.value,
            body: () => {
                return {
                    campaignGroupId: campaignGroupId.value,
                    lookbackWindow: lookbackWindow.value,
                }
            },
            uniqueId: () => uniqueId.value,
        })

    const segmentsLoading = computed(() => {
        // If there are no campaign groups, segments will never load. Return false to prevent infinite loading.
        if (!campaignGroupsLoading.value && campaignGroupItems.value?.length === 0) {
            return false
        }

        return segmentsLoadingRaw.value
    })

    // we don't want to display segments that are ROAS incompatible (some LN segments), hence the re-mapping logic
    const allSegments = computed(() =>
        Object.fromEntries(
            Object.entries(segmentsData.value?.segments || {}).map(([key, value]) => {
                return [
                    key,
                    {
                        ...value,
                        isRoasIncompatibleSegment:
                            accountPlatform.value === Platform.Platform.LinkedInAds &&
                            isUsingROAS.value &&
                            linkedInRoasIncompatibleSegments.includes(key ?? 'campaign'),
                    },
                ]
            })
        )
    )

    const worstSegments = computed(() => {
        if (!segmentsData.value) {
            return
        }
        return segmentsData.value.worst_segments.map(row => {
            row.cpa = !row.conversions && !row.cost ? 0 : row.cost / row.conversions
            row.roas = !row.conversions_value || !row.cost ? 0 : row.conversions_value / row.cost

            row.difference = isUsingCpa.value
                ? (row.cpa - row.target) / row.target
                : (row.roas - row.target) / row.target

            return {
                ...row,
                labelIncSegment: `${row.segment_label} (in ${row.real_name})`,
            }
        })
    })

    const bestSegments = computed(() => {
        if (!segmentsData.value) {
            return
        }
        return segmentsData.value.best_segments.map(row => {
            row.cpa = !row.conversions && !row.cost ? 0 : row.cost / row.conversions
            row.roas = !row.conversions_value || !row.cost ? 0 : row.conversions_value / row.cost

            row.difference = isUsingCpa.value
                ? (row.cpa - row.target) / row.target
                : (row.roas - row.target) / row.target
            return {
                ...row,
                labelIncSegment: `${row.segment_label} (in ${row.real_name})`,
            }
        })
    })

    // Best Segments
    const bestSegmentsHeaders = computed(() => [
        { key: 'segment_label', text: 'Segment', sortable: true, noPadding: true },
        isUsingCpa.value
            ? { key: 'cpa', text: 'CPA', sortable: true, noPadding: true, width: 88 }
            : {
                  key: 'roas',
                  text: 'ROAS',
                  sortable: true,
                  noPadding: true,
                  width: 92,
              },
        { key: 'target', text: 'Target', sortable: true, noPadding: true, width: 92 },
        { key: 'difference', text: 'Diff.', sortable: true, noPadding: true, width: 92 },
    ])

    // Worst Segments
    const worstSegmentsHeaders = computed(() => [
        { key: 'segment_label', text: 'Segment', sortable: true, noPadding: true },
        isUsingCpa.value
            ? { key: 'cpa', text: 'CPA', sortable: true, noPadding: true, width: 88 }
            : {
                  key: 'roas',
                  text: 'ROAS',
                  sortable: true,
                  noPadding: true,
                  width: 92,
              },
        { key: 'target', text: 'Target', sortable: true, noPadding: true, width: 92 },
        { key: 'difference', text: 'Diff.', sortable: true, noPadding: true, width: 92 },
    ])

    const scrollToSegment = (targetSegment: string) => {
        scrollTo(targetSegment, 'smooth', 96)
    }

    const segmentKeys = Object.values(Segments.Segment)

    return {
        allSegments,
        segmentsLoading,
        selectedGroupId,
        selectedGroup,
        campaignGroupId,
        campaignGroupItems,
        bestSegmentsSelected,
        sidebarOpen,
        bestSegments,
        worstSegments,
        scrollTo,
        domainId,
        segmentsData,
        bestSegmentsHeaders,
        worstSegmentsHeaders,
        scrollToSegment,
        segmentKeys,
        isUsingCpa,
        isUsingROAS,
        uniqueId,
        accountPlatform,
    }
}
