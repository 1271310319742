<template>
    <ImprovementView
        :push-messages="pushMessages"
        :push-action-text="pushActionText"
        :steps="adjustSteps"
        :on-push="onPush"
    >
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <EntityPillLinkList :items="entityPillList" :scrollToRight="true" />
                    <Spacer height="2rem" />
                </div>
                <Text as="p">
                    <span v-if="singlePositiveKeyword">
                        This negative conflicts with an existing keyword.
                    </span>
                    <span v-else>
                        This negative conflicts with
                        <b>{{ positiveKeywordCount }}</b> keywords.
                    </span>
                    <span v-if="singlePositiveKeyword">
                        The presence of this negative means the keyword listed below cannot receive
                        impressions. Click the
                        <Text as="span" weight="600" color="blue">{{ adjustSteps[0] }}</Text> button
                        to either remove the negative keyword or pause the blocked
                        {{ positiveKeywordCopy.toLowerCase() }}.
                    </span>
                    <span v-else>
                        The presence of this negative means the keywords listed below cannot receive
                        impressions. Click
                        <Text as="span" weight="600" color="blue">{{ adjustSteps[0] }}</Text> to
                        either remove the negative keyword or pause the blocked
                        {{ positiveKeywordCopy.toLowerCase() }}.
                    </span>
                </Text>
                <Spacer height="1rem" />
            </ImprovementHeader>
            <ImprovementHelpLink path="/en/articles/2185476-fix-keyword-conflicts">
                Click here to learn more about fixing keyword conflicts in Opteo.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <Text v-if="singlePositiveKeyword" as="h3" size="f-4" weight="600"
                >Conflicting Keyword</Text
            >
            <Text v-else as="h3" size="f-4" weight="600">Conflicting Keywords</Text>
            <Spacer height="2rem" />
            <oTable :headers="tableHeaders" :items="tableItems" :border-radius="20" />
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="30" />
        </ImprovementContent>

        <template v-slot:adjust-step-1>
            <ImprovementContent>
                <ImprovementHeader :title="title">
                    <EntityPillLinkList :items="entityPillList" :scrollToRight="true" />
                    <Spacer height="2rem" />
                    <Text as="p">
                        Click
                        <Text as="span" weight="600" color="red">{{
                            removeNegativePushAction
                        }}</Text>
                        to remove the negative keyword listed above. This will unblock your
                        <span v-if="!singlePositiveKeyword"
                            ><b>{{ positiveKeywordCount }}</b> keywords</span
                        ><span v-else>keyword</span> and any other search terms blocked by this
                        negative.
                        <Spacer height="1rem" />
                        Click
                        <Text as="span" weight="600" color="green">{{
                            pauseKeywordsPushAction
                        }}</Text>
                        to pause the blocked <span v-if="singlePositiveKeyword">keyword</span
                        ><span v-else>keywords</span> listed in the table below. This will prevent
                        search terms including
                        <span v-if="singlePositiveKeyword">the listed keyword</span
                        ><span v-else>the listed keywords</span> from generating traffic.
                    </Text>
                    <Spacer height="2rem" />
                    <div class="select-action">
                        <Tabs
                            :router="false"
                            :links="[{ key: 'remove-negative' }, { key: 'pause-keyword' }]"
                            :active="pausingKeywords"
                            @clicked="setPausingKeywords"
                            class="pause-remove-tabs"
                        >
                            <template v-slot:remove-negative>
                                <Text
                                    as="span"
                                    :color="pausingKeywords === false ? 'red' : 'black'"
                                >
                                    {{ removeNegativePushAction }}
                                </Text>
                            </template>
                            <template v-slot:pause-keyword>
                                <Text
                                    as="span"
                                    :color="pausingKeywords === true ? 'green' : 'black'"
                                >
                                    {{ pauseKeywordsPushAction }}
                                </Text>
                            </template>
                        </Tabs>
                        <!-- Old -->
                        <!-- <div class="flex">
                        <oButton
                            size="large"
                            :color="pausingKeywords ? 'green' : 'white'"
                            @clicked="setPausingKeywords(true)"
                        >
                            {{ pauseKeywordsPushAction }}
                        </oButton>
                        <Spacer width="2rem" />
                        <oButton
                            size="large"
                            :color="pausingKeywords ? 'white' : 'red'"
                            @clicked="setPausingKeywords(false)"
                        >
                            {{ removeNegativePushAction }}
                        </oButton>
                    </div> -->
                    </div>
                </ImprovementHeader>
            </ImprovementContent>

            <ImprovementContent>
                <Text v-if="singlePositiveKeyword" as="h3" size="f-4" weight="600"
                    >Conflicting Keyword</Text
                >
                <Text v-else as="h3" size="f-4" weight="600">Conflicting Keywords</Text>
                <Spacer height="2rem" />
                <oTable :headers="tableHeaders" :items="tableItems" />
            </ImprovementContent>
        </template>
    </ImprovementView>
</template>
<script lang="ts">
import { Spacer, Text, Ent, oTable, oButton, Tabs } from '@opteo/components-next'

import EntityPillLinkList from '@/components/global/Entity/EntityPillLinkList.vue'
import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import ImprovementCreatedDate from '@/components/improvement/ImprovementCreatedDate.vue'
import ImprovementContent from '@/components/improvement/ImprovementContent.vue'
import ImprovementHeader from '@/components/improvement/ImprovementHeader.vue'
import ImprovementStatistics from '@/components/improvement/ImprovementStatistics.vue'

import { useFixNegativesBlockingKeywords } from '@/composition/improvement/types/useFixNegativesBlockingKeywords'
import useMediaQuery from '@/composition/global/useMediaQuery'

export default {
    name: 'FixNegativesBlockingKeywords',
    components: {
        ImprovementView,
        ImprovementHelpLink,
        ImprovementHeader,
        ImprovementContent,
        ImprovementStatistics,
        ImprovementCreatedDate,
        EntityPillLinkList,
        Spacer,
        Text,
        Ent,
        oTable,
        oButton,
        Tabs,
    },
    setup() {
        return { ...useFixNegativesBlockingKeywords(), ...useMediaQuery() }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.select-action {
    @include container;
    @include pa-32;
    @include flex;
    @include items-center;
    @include justify-center;
}
@media screen and (max-width: $mq-767-max) {
    .select-action {
        @include pa-24;
    }
}
@media (min-width: $mq-321-min) and (max-width: $mq-767-max) {
    .pause-remove-tabs {
        white-space: nowrap;
    }
}
@media screen and (max-width: $mq-320-max) {
    .pause-remove-tabs {
        text-align: center;
    }
}
</style>
