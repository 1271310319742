<template>
    <EntityPill v-bind="{ ...props.entityPillData }" :link-url="url" :cursor="cursor" />
</template>

<script setup lang="ts" generic="T extends ValidEntity">
import { EntityPill } from '@opteo/components-next'
import { useDeepLinking } from '@/composition/global/useDeepLinking'
import { useEntityPillLink } from '@/composition/global/useEntityPillLink'

import type { EntityPillLinkProps, ValidEntity } from './types'

const props = defineProps<EntityPillLinkProps<T>>()

const { ocid } = useDeepLinking()
const { url, cursor } = useEntityPillLink({
    deepLinkParams: props.deepLinkParams,
    entityType: props.entityPillData.type,
    ocid,
})
</script>
