<template>
    <PageHeader page-title="Performance" :no-margin="!aboveMobile">
        <template #middle-content>
            <Tabs :links="pageSelectorLinks">
                <template #graphs>Graphs</template>
                <template #segments>Segments</template>
                <template #table>Table</template>
            </Tabs>
        </template>

        <template #right-content>
            <div v-if="routeName === Routes.PerformanceSegments">
                <Select
                    v-model="lookbackWindowSelectValue"
                    :options="[
                        { value: '30', label: 'Last 30 Days' },
                        { value: '90', label: 'Last 90 Days' },
                        { value: '180', label: 'Last 180 Days' },
                    ]"
                />
            </div>
            <div v-else class="date-range-slot-inner">
                <DateRange
                    :presetDates="{ start: startDate, end: endDate }"
                    @apply="applyDateRange"
                    :disable-popout="!aboveMobile ? true : false"
                    :wrapper-classes="!aboveMobile ? 'inline-date-range-popout' : ''"
                >
                    <template v-if="!aboveMobile" #popout="{ api }">
                        <div class="mobile-datepicker">
                            <div v-if="api" class="buttons">
                                <Label>View Last</Label>
                                <template
                                    v-for="(
                                        { days, label, selected }, index
                                    ) in api.presetDateRanges"
                                    :key="days"
                                >
                                    <oButton
                                        color="white"
                                        @clicked="api.selectRange(days)"
                                        :class="{
                                            'preset-active': selected.value,
                                        }"
                                        fullWidth
                                        >{{ label }}</oButton
                                    >
                                    <Spacer
                                        v-if="index !== api.presetDateRanges.length - 1"
                                        height="0.75rem"
                                    />
                                </template>
                            </div>
                            <div class="apply">
                                <oButton color="white" @clicked="api.closePopout(false)"
                                    >Close</oButton
                                >
                                <oButton color="blue" @clicked="api.applyDateRange(true)"
                                    >Apply</oButton
                                >
                            </div>
                        </div>
                    </template>
                </DateRange>
            </div>
            <Spacer width="1rem" class="spacer" v-if="aboveMobile" />
            <ExpandableSidebarButton @clicked="toggleSidebarOpen" class="expandable-sidebar" />
        </template>
    </PageHeader>

    <ExpandableSidebar v-model="sidebarOpen" title="Performance">
        <div class="performance-sidebar">
            <div v-if="routeName === Routes.PerformanceGraphs">
                <!-- Graphs View -->
                <Label>Graphs View</Label>
                <oInput
                    v-model="selectedTimePeriod"
                    :button-mode="true"
                    :items="timePeriods"
                    layout="vertical"
                    name="radio"
                    type="radio"
                />
                <Spacer height="1.5rem" />

                <!-- Graphs Layout -->
                <div v-if="aboveMobile">
                    <Label>Graphs Layout</Label>
                    <oInput
                        v-model="selectedLayoutOption"
                        :button-mode="true"
                        :items="layoutOptions"
                        layout="vertical"
                        name="radio"
                        type="radio"
                    />
                    <Spacer height="1.5rem" />
                </div>
            </div>

            <div
                v-if="
                    routeName === Routes.PerformanceGraphs || routeName === Routes.PerformanceTable
                "
            >
                <Label>Campaigns ({{ selectedCampaignCount }})</Label>
                <div
                    v-for="(channel, index) in channels"
                    :key="index"
                    class="performance-sidebar-campaigns"
                >
                    <Tooltip
                        :offset="[0, -10]"
                        :content="channel.channel.length > 36 ? channel.channel : ''"
                        :max-width="600"
                    >
                        <oInput
                            :modelValue="entireChannelSelected(channel.channel)"
                            type="checkbox"
                            :button-mode="true"
                            :label="channel.channel"
                            @click="toggleChannel(channel.channel)"
                            class="campaign-channel"
                        />
                    </Tooltip>
                    <div
                        v-for="(campaign, campaignIndex) in channel.campaigns"
                        :key="campaignIndex"
                        class="performance-sidebar-campaigns"
                    >
                        <Tooltip
                            :content="campaign.name.length > 36 ? campaign.name : ''"
                            :max-width="600"
                        >
                            <oInput
                                :modelValue="campaign.selected"
                                type="checkbox"
                                :button-mode="true"
                                :label="campaign.name"
                                @click="toggleCampaign(campaign.id)"
                            />
                        </Tooltip>
                    </div>
                </div>
                <Spacer height="0.875rem" />
                <Label>Conversion Types ({{ selectedConversionActionCount }})</Label>
                <span v-for="(conversionAction, index) in conversionActions" v-bind:key="index">
                    <Tooltip
                        :content="conversionAction.label.length > 36 ? conversionAction.label : ''"
                        :max-width="600"
                    >
                        <oInput
                            :modelValue="conversionAction.selected"
                            type="checkbox"
                            :button-mode="true"
                            :label="conversionAction.label"
                            @click="toggleConversionAction(index)"
                        />
                    </Tooltip>
                </span>
            </div>
            <div v-if="routeName === Routes.PerformanceGraphs">
                <Spacer height="0.875rem" />
                <Label>Graphs ({{ selectedChartCount }})</Label>
                <span v-for="chart in chartsEnabledStatuses" :key="chart.id">
                    <oInput
                        :modelValue="chart.enabled"
                        type="checkbox"
                        :button-mode="true"
                        :label="chart.label"
                        @click="toggleChart(chart.id)"
                    />
                </span>
            </div>
            <div v-if="routeName === Routes.PerformanceSegments">
                <!-- Campaign Groups -->
                <Label>Campaign Groups</Label>
                <oInput
                    v-if="campaignGroupItems"
                    v-model="selectedGroupId[accountId]"
                    :button-mode="true"
                    :items="campaignGroupItems"
                    layout="vertical"
                    name="radio"
                    type="radio"
                />
            </div>
        </div>
    </ExpandableSidebar>

    <!-- Performance onboarding -->
    <OnboardingTour
        step-name="tour_performance"
        :buttonText="'Continue to Performance'"
        contentMaxWidth="472px"
    >
        <template v-slot:header>Performance</template>
        <template v-slot:copy>
            Performance is the home of your performance data. See graphs, find your top performing
            segments, make comparisons across time periods, and so much more. You can also use the
            datepicker and sidebar to filter and segment your data as required.
        </template>
    </OnboardingTour>

    <router-view />
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import {
    DateRange,
    Spacer,
    ExpandableSidebarButton,
    Text,
    Tabs,
    ExpandableSidebar,
    Label,
    oInput,
    oButton,
    Tooltip,
    Select,
} from '@opteo/components-next'
import PageHeader from '@/layouts/PageHeader.vue'
import PageContent from '@/layouts/PageContent.vue'
import { providePerformanceControls } from '@/composition/performance/usePerformanceControls'
import OnboardingTour from '@/components/User/OnboardingTour.vue'
import { Routes } from '@/router/routes'

import useMediaQuery from '@/composition/global/useMediaQuery'
import { useAccount } from '@/composition/account/useAccount'
import { Platform } from '@opteo/types'

export default defineComponent({
    name: 'Performance',
    setup() {
        const { accountPlatform } = useAccount()

        const pageSelectorLinks = computed(() =>
            [
                { key: 'graphs', to: { name: Routes.PerformanceGraphs } },
                { key: 'segments', to: { name: Routes.PerformanceSegments } },
                { key: 'table', to: { name: Routes.PerformanceTable } },
            ].filter(Boolean)
        )

        const { aboveMobile } = useMediaQuery()

        return {
            ...providePerformanceControls(),
            pageSelectorLinks,
            aboveMobile,
        }
    },
    components: {
        PageHeader,
        PageContent,
        Tabs,
        DateRange,
        Spacer,
        ExpandableSidebarButton,
        OnboardingTour,
        Text,
        ExpandableSidebar,
        Label,
        oInput,
        oButton,
        Tooltip,
        Select,
    },
})
</script>

<style>
/* Unscoped Button Width */
.performance-sidebar .button-mode {
    width: 100%;
    max-width: 100%;
    margin-bottom: 0.625rem;
}
</style>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';
.campaign-channel {
    @include fw-500;
}

.mobile-datepicker {
    @include mt-12;
    @include container;
    @include br-16;
    width: 17rem;
}
.mobile-datepicker .buttons {
    @include pa-24;
    border-bottom: 1px solid $opteo-light-gray;
    @include flex;
    flex-direction: column;
}
.mobile-datepicker .apply {
    @include pa-24;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.625rem;
}

.date-range-slot-inner,
:deep(.inline-date-range-popout) {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (max-width: $mq-767-max) {
    .expandable-sidebar {
        position: absolute;
        top: 32px;
        right: 48px;
    }
}

@media screen and (max-width: $mq-480-max) {
    .expandable-sidebar {
        position: absolute;
        top: 32px;
        right: 24px;
    }
}
</style>
