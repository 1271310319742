import { computed, ComputedRef, inject, Ref, ref } from 'vue'

import {
    OnPushHandler,
    UseImprovement,
    useImprovement,
    checkImprovement,
} from '@/composition/improvement/useImprovement'
import { BidFactoryChartTypes, usePercent, useRoas } from '@opteo/components-next'
import { useDomainMoney } from '@/composition/domain/useDomainMoney'

import { AdjustKeywordBidV2, BiddingStrategies, Entity, Improvement, Targets } from '@opteo/types'
import { CurrencyCode } from '@/composition/utils/types'
import { ProvideKeys } from '@/composition/useProvide'
import { useDomain } from '@/composition/domain/useDomain'

import type { ImprovementStatistic } from '.'
import { EntityPillLinkProps } from '@/components/global/Entity/types'
import { formatEntityPillLink, filterLocationsByEntityType } from '@/lib/globalUtils'

export interface ScatterPointChartItem {
    x: number
    y: number
    label: string
    highlighted?: boolean
}

interface UseAdjustKeywordBidV2 {
    isBidIncreasing: boolean
    keywordConversions: number
    keywordConversionsValue: number
    formattedKeyword: string
    // bidChange: number
    adjustedBidChange: ComputedRef<number>
    suggestedBidChange: ComputedRef<number>
    campaignGroupTargetCpa: number
    campaignGroupTargetRoas: number
    currencySymbol: Ref<string>
    adjustedBid: Ref<number>
    formattedCurrentBid: string
    formattedNewBid: string
    keywordCpa: number
    keywordRoas: number
    absoluteTopImpressionShare: number
    firstPageBid: number
    improvementStatsItems: ImprovementStatistic[]
    searchImpressionShare: number
    lookbackWindow: number
    isAutoTargeting: boolean
    conversionRate: number
    isUsingBidFactory: boolean
    isBidCapped?: boolean
    domainCurrencyCode?: ComputedRef<CurrencyCode>
    predictionItems?: BidFactoryChartTypes.BidFactoryChartItem[]
    simulationPoints?: BiddingStrategies.BidFactory.SimulationPoint[]
    scatterPointChartItems?: ScatterPointChartItem[]
    isUsingCpa: boolean
    handleBidUpdate: () => void
    resetBid: () => void
    locationEntityData: EntityPillLinkProps<'keyword'>
}

export function useAdjustKeywordBidV2(): UseImprovement<UseAdjustKeywordBidV2> {
    const {
        improvement,
        lastUpdated,
        title: _title,
    } = useImprovement<AdjustKeywordBidV2.Body, typeof Improvement.RecAction.AdjustKeywordBidV2>()

    const domainCurrencyCode = inject<ComputedRef<CurrencyCode>>(ProvideKeys.CurrencyCode)

    const {
        body: {
            new_bid: newBid,
            keyword_metrics: {
                cpc_bid: currentBid,
                conversions: keywordConversions,
                conversions_value: keywordConversionsValue,
                cpa: keywordCpa,
                roas: keywordRoas,
            },
            formatted_keyword: formattedKeyword,
            is_auto_targeting: isAutoTargeting,
            is_using_bid_factory: isUsingBidFactory,
            simulation_points: simulationPoints,
            prediction_items: predictionItems,
            campaign_group_target_cpa: campaignGroupTargetCpa,
            campaign_group_target_roas: campaignGroupTargetRoas,
            scatter_point_chart_items: scatterPointChartItems,
            is_bid_capped: isBidCapped,
            conversion_rate: conversionRate,
            search_impression_share: searchImpressionShare,
            absolute_top_impression_percentage: absoluteTopImpressionShare,
            first_page_cpc: firstPageBid,
            // bid_change: bidChange,
            lookback_window: lookbackWindow,
            performance_mode: performanceMode,
        },
        location,
    } = checkImprovement(improvement)

    const keywordLocation = filterLocationsByEntityType(location, Entity.EntityLocation.Keyword)
    const locationEntityData = formatEntityPillLink({
        entityPillData: { type: Entity.EntityLocation.Keyword, content: keywordLocation.label },
        deepLinkParams: keywordLocation.entityIds,
    })

    // Ensure bid is formatted to two decimal places + type `number`
    const adjustedBid = ref(Math.round(newBid * 100) / 100)

    function handleBidUpdate() {
        pushActionText.value =
            isUpdatedBidIncreasing.value === true ? 'Increase Keyword Bid' : 'Decrease Keyword Bid'
    }

    function resetBid() {
        adjustedBid.value = Math.round(newBid * 100) / 100
        handleBidUpdate()
    }

    const impBidAdjustmentRef = ref()

    // Improvement View Props
    const isBidIncreasing = newBid > currentBid
    const isUpdatedBidIncreasing = computed(() => adjustedBid.value > currentBid)

    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Applying keyword bid adjustment..',
        'Confirming changes..',
        'Bid adjustment applied successfully.',
    ])

    const suggestedBidChange = computed(
        () => (Math.round(newBid * 100) / 100 - currentBid) / currentBid
    )
    const adjustedBidChange = computed(() => (adjustedBid.value - currentBid) / currentBid)

    const onPush: OnPushHandler<AdjustKeywordBidV2.ExtraDetails> = () => {
        const pushedData = {
            new_bid: adjustedBid.value,
        }

        const valid =
            typeof adjustedBidChange.value === 'number' &&
            (impBidAdjustmentRef.value ? impBidAdjustmentRef.value.valid : true)

        return {
            valid,
            pushedData,
        }
    }

    const pushActionText = ref(
        isUpdatedBidIncreasing.value === true ? 'Increase Keyword Bid' : 'Decrease Keyword Bid'
    )

    const title = computed(() =>
        isUpdatedBidIncreasing.value === true
            ? `Increase Keyword Bid ${
                  usePercent({
                      value: adjustedBidChange.value,
                      stripped: true,
                      decimalPlaces: 0,
                  }).displayValue.value
              }`
            : `Decrease Keyword Bid ${
                  usePercent({
                      value: adjustedBidChange.value,
                      stripped: true,
                      decimalPlaces: 0,
                  }).displayValue.value
              }`
    )

    const { currencySymbol } = useDomain()

    // Recommended Bid Adjustment
    const formattedCurrentBid = useDomainMoney({ value: currentBid }).value.displayValue.value
    const formattedNewBid = useDomainMoney({ value: newBid }).value.displayValue.value

    const isUsingCpa = !performanceMode || performanceMode === Targets.PerformanceMode.CPA

    // Improvement Stats
    const improvementStatsItems = isUsingCpa
        ? [
              {
                  key: 'averageCPA',
                  value: useDomainMoney({ value: campaignGroupTargetCpa }).value.displayValue.value,
                  title: isAutoTargeting ? 'Average CPA' : 'Target CPA',
              },
              {
                  key: 'keywordCpa',
                  value: useDomainMoney({ value: keywordCpa }).value.displayValue.value,
                  title: 'Keyword CPA',
              },
          ]
        : [
              {
                  key: 'averageRoas',
                  value: useRoas({ value: campaignGroupTargetRoas }).displayValue.value,
                  title: isAutoTargeting ? 'Average ROAS' : 'Target ROAS',
              },
              {
                  key: 'keywordRoas',
                  value: useRoas({ value: keywordRoas }).displayValue.value,
                  title: 'Keyword ROAS',
              },
          ]

    improvementStatsItems.push(
        {
            key: 'searchImprShare',
            value: usePercent({ value: searchImpressionShare }).displayValue.value,
            title: 'Search Impr. Share',
        },
        {
            key: 'absoluteTopImprShare',
            value: usePercent({ value: absoluteTopImpressionShare }).displayValue.value,
            title: 'Absolute Top Impr. Share',
        },
        {
            key: 'firstPageBid',
            value: useDomainMoney({ value: firstPageBid }).value.displayValue.value,
            title: 'First Page Bid',
        }
    )

    return {
        title,
        pushMessages,
        onPush,
        lastUpdated,
        isBidIncreasing,
        keywordConversions,
        keywordConversionsValue,
        formattedKeyword,
        suggestedBidChange,
        adjustedBidChange,
        resetBid,
        campaignGroupTargetCpa,
        campaignGroupTargetRoas,
        adjustedBid,
        formattedCurrentBid,
        formattedNewBid,
        keywordCpa,
        keywordRoas,
        absoluteTopImpressionShare,
        firstPageBid,
        improvementStatsItems,
        searchImpressionShare,
        lookbackWindow,
        isAutoTargeting,
        conversionRate,
        domainCurrencyCode,
        predictionItems,
        isUsingBidFactory,
        simulationPoints,
        currencySymbol,
        scatterPointChartItems,
        isBidCapped,
        isUsingCpa,
        handleBidUpdate,
        pushActionText,
        locationEntityData,
    }
}
