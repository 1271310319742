import { computed, ref } from 'vue'
import uniqBy from 'lodash-es/uniqBy'
import orderBy from 'lodash-es/orderBy'
import {
    OnPushHandler,
    useImprovement,
    checkImprovement,
} from '@/composition/improvement/useImprovement'
import { useAPI, Endpoint } from '@/composition/api/useAPI'
import { CountryTld } from '@/composition/billing/invoices/useInvoices'
import { AddCallExtension } from '@opteo/types'

type FormElement = HTMLDivElement & { submit: () => void; valid: boolean }
type DropDownItem = { value: string; label: string }

export function useAddCallExtension() {
    const { improvement, lastUpdated, title } = useImprovement<AddCallExtension.Body>()

    const {
        body: { grey_adgroups_campaign_name_uniq: campaignNames, incompleteCampaigns },
    } = checkImprovement(improvement)

    const { data: getCountryTldData } = useAPI<CountryTld[]>(Endpoint.GetCountryTld)
    const { data: getPhoneNumberData, loading: getPhoneNumberLoading } = useAPI<string>(
        Endpoint.GetPhoneNumber
    )

    const countryCodes = computed<DropDownItem[]>(() => {
        if (!getCountryTldData.value) {
            return []
        }

        const uniqueCountries = uniqBy(
            getCountryTldData.value,
            ({ country_code: countryCode }) => countryCode
        )
        const orderedCountries = orderBy(
            uniqueCountries,
            ({ country_code: countryCode }) => countryCode
        )

        return orderedCountries
            .filter(({ international_dialing: internationalDialing }) => !!internationalDialing)
            .map(({ country_code: countryCode, international_dialing: internationalDialing }) => {
                return {
                    value: countryCode,
                    label: `${countryCode} (${internationalDialing})`,
                }
            })
    })

    const recommendedTelephoneNumber = computed(() => {
        if (
            !getPhoneNumberData.value ||
            getPhoneNumberData.value.trim() === 'I am empty' ||
            getPhoneNumberData.value.length < 8
        ) {
            return null
        }

        return getPhoneNumberData.value
    })
    const isPlural = campaignNames.length > 1

    const selectedCountryCode = ref('US')
    const telephoneNumber = ref('')

    const callForm = ref<FormElement>()

    const adjustSteps = ref(['Input Phone Number'])
    const pushActionText = ref(isPlural ? 'Add Call Extensions' : 'Add Call Extension')
    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Adding call extension..',
        'Confirming changes..',
        'Call extension added successfully.',
    ])

    const onPush: OnPushHandler<AddCallExtension.CallExtension> = () => {
        callForm.value?.submit()

        const valid = telephoneNumber.value.length > 0

        if (!valid) {
            return { valid }
        }

        return {
            valid,
            pushedData: {
                tel_number: telephoneNumber.value,
                country: selectedCountryCode.value,
            },
        }
    }

    return {
        title,
        onPush,
        pushMessages,
        lastUpdated,
        campaignNames,
        incompleteCampaigns,
        countryCodes,
        selectedCountryCode,
        telephoneNumber,
        recommendedTelephoneNumber,
        getPhoneNumberLoading,
        callForm,
        pushActionText,
        adjustSteps,
        isPlural,
    }
}
