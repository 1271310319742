import * as Sentry from '@sentry/browser'
import { computed, watch, inject, provide } from 'vue'
import { Api } from '@opteo/types'
import { useLocalStorage } from '@vueuse/core'

import { Endpoint, useAPI } from '../api/useAPI'
import { useIntercom } from '@/lib/intercom/useIntercom'
import { useProfitwell } from '@/lib/profitwell/useProfitwell'

import parseISO from 'date-fns/parseISO'
import getUnixTime from 'date-fns/getUnixTime'
import isDateBefore from 'date-fns/isBefore'
import subDate from 'date-fns/sub'
import { pushToDatalayer } from '@/lib/datalayer'
import { useAuth } from '../auth/useAuth'

import type { Billing } from '@opteo/types'

export function provideUser() {
    const { editUserAttributes } = useIntercom()
    const { profitwellIdentify } = useProfitwell()
    const { userId } = useAuth()

    const { data: user, error, mutate } = useAPI<Api.GetUserInfo.Response>(Endpoint.GetUser)

    const loading = computed(() => !user.value)
    const userInfo = computed(() => user.value?.profile)
    const platformConnectionStatus = computed(() => user.value?.profile.platformConnectionStatus)

    const isAgency = computed(() => user.value?.profile.avatar === 'agency')
    const isAdvertiser = computed(() => user.value?.profile.avatar === 'advertiser')
    const isAdmin = computed(() => user.value?.profile.role === 'admin')

    const currentPlan = computed(() => user.value?.profile.current_plan)
    const currentPlanCurrency = computed<Billing.CurrencyCode>(
        () => currentPlan.value?.currency.toUpperCase() ?? 'USD'
    )
    const totalActiveAccounts = computed(() => user.value?.profile.total_active_accounts ?? 0)
    const totalSpend30d = computed(() => user.value?.profile.total_usd_spend_last_30d ?? 0)

    const groupId = computed(() => user.value?.profile.group_id)
    const betaFeatures = computed(() => user.value?.profile.betaFeatures)

    const profileImageUrl = computed(() => user.value?.profile.avatar_filename)

    const hasReportsAccess = computed<boolean | undefined>(
        () => userInfo.value?.has_reports_access || currentPlan.value?.has_reports_access
    )

    const isOnLegacyPlan = computed(() =>
        [
            'plan-free',
            'plan-47',
            'adtorqueedge-plan',
            'plan-xs',
            'plan-s',
            'plan-m',
            'plan-l',
            'plan-v2-s',
            'plan-v2-rs',
            'plan-v2-m',
            'plan-v2-l',
            'plan-v2-xl',
            'plan-v3-s',
            'plan-v3-rs',
            'plan-v3-m',
            'plan-v3-l',
            'plan-v3-xl',
        ].includes(currentPlan.value?.id ?? '')
    )

    /* Mutate the `profile` section of userInfo */
    const mutateUserInfo = async (updatedUser?: Partial<Api.GetUserInfo.Response['profile']>) => {
        if (typeof updatedUser === 'undefined') {
            await mutate()
            return
        }
        // @ts-ignore Doesn't need to be async (?)
        mutate(() => {
            return {
                ...user?.value,
                profile: {
                    ...user?.value?.profile,
                    ...updatedUser,
                },
            }
        })
    }

    const intercomBaseUserAttributes = computed(() => {
        if (!userInfo.value) {
            return
        }

        const info = userInfo.value
        return {
            user_id: info.user_id,
            user_hash: info.intercom_hash,
            email: info.username,
            role: info.role,
            name: info.name,
            group_uuid: info.group_uuid,
            has_adwords_account: !!info.adwords_connection_date,
            created_at: getUnixTime(parseISO(info.account_creation_date)),
            company: {
                id: info.group_id,
            },
        }
    })

    watch(intercomBaseUserAttributes, newAttributes => {
        if (!newAttributes) {
            return
        }

        Sentry.setUser({ email: newAttributes.email, userId })
        editUserAttributes(newAttributes)
        profitwellIdentify(newAttributes.email)
    })

    const datalayerBaseUserAttributes = computed(() => {
        if (!userInfo.value) {
            return
        }
        const info = userInfo.value

        const user_attributes = {
            user_id: info.user_id,
            email: info.username,
            first_name: (info.name ?? '').split(' ')[0],
            last_name: (info.name ?? '').split(' ')[1],
            raw_domain_count: info.raw_domain_count,
            role: info.role,
            improvements_pushed: info.completed_improvement_count,
            linked_domains: info.active_domain_count,
            unlinked_domains: info.raw_domain_count - info.active_domain_count,
        }

        const team_attributes = {
            group_id: info.group_id,
            plan_id: info.plan,
            team_size: info.team_size,
            avatar: info.avatar,
            opteo_lifecycle_stage: info.opteo_lifecycle_stage,
            team_improvements_pushed: info.team_completed_improvement_count,
            total_usd_spend_last_30d:
                info.inactive_usd_spend_last_30d + info.total_usd_spend_last_30d,
            total_usd_spend_last_30d_linked: info.total_usd_spend_last_30d,
            total_usd_spend_last_30d_unlinked: info.inactive_usd_spend_last_30d,
            country_code: info.country_code,
        }

        return {
            user_attributes,
            team_attributes,
        }
    })

    const userLastIdentified = useLocalStorage<number>('userLastIdentified', 0)

    watch(datalayerBaseUserAttributes, newAttributes => {
        if (!newAttributes) {
            return
        }

        let identifiedRecently = true
        if (isDateBefore(userLastIdentified.value, subDate(Date.now(), { hours: 4 }))) {
            userLastIdentified.value = Date.now()
            identifiedRecently = false
        }

        pushToDatalayer('gtm_user_identified', {
            ...newAttributes,
            several_hours_since_last_identification: !identifiedRecently,
        })
    })

    const toProvide = {
        user,
        userInfo,
        platformConnectionStatus,
        loading,
        error,
        userId,
        groupId,
        betaFeatures,
        isAgency,
        isAdvertiser,
        hasReportsAccess,
        currentPlan,
        currentPlanCurrency,
        isAdmin,
        profileImageUrl,
        mutateUserInfo,
        totalActiveAccounts,
        totalSpend30d,
        isOnLegacyPlan,
    }

    provide('user', toProvide)

    return toProvide
}

export function useUser() {
    const injected = inject<ReturnType<typeof provideUser>>('user')

    if (!injected) {
        throw new Error(
            `User not yet injected, something is wrong. useUser() can only be called in a /user/ route.`
        )
    }

    return injected
}
