// These keys should match the route record "name" field
export enum Routes {
    // Misc
    Root = 'root',
    Login = 'login',
    ResetPassword = 'reset',
    ResetPasswordLink = 'resetlink',
    PageNotFound = '404',
    EmailLink = 'link',
    InviteWelcome = 'invitewelcome',
    Thanks = 'thanks',
    SharedChat = 'c',

    // Onboarding
    CreateAccount = 'createaccount',
    AfterGoogleSignup = 'aftergooglesignup',
    ConnectGoogleAds = 'connectgoogleads',
    ConnectMetaAds = 'connectmetaads',

    // User
    User = 'user',
    AccountCentre = 'accountcentre',
    AccountCentreInit = 'accountcentreinit',
    Chat = 'chat',
    Alerts = 'alerts',
    Alert = 'alert', // "/alerts/:id"
    UserSettings = 'usersettings',
    LinkedAccounts = 'linkedAccounts',
    LinkedAccountsPlatform = 'linkedAccountsplatform',
    LinkedAccountsGoogle = 'linkedAccountsgoogle',
    LinkedAccountsMicrosoft = 'linkedAccountsmicrosoft',
    LinkedAccountsMeta = 'linkedAccountsmeta',
    LinkedAccountsLinkedIn = 'linkedAccountslinkedin',
    BillingCentre = 'billingcentre',
    BillingCentreSubscription = 'billingcentresubscription',
    NewPaymentMethod = 'newpaymentmethod',
    Thankyou = 'thankyou',
    Invoices = 'invoices',
    FeatureRequests = 'featurerequests',
    ImpactStatistics = 'impactstatistics',

    // Account
    Account = 'account',

    // Domain (legacy)
    DomainSettings = 'domainsettings',
    Notes = 'notes',
    Setup = 'setup',

    // Improvement list pages
    Improvements = 'improvements',
    ImprovementsActive = 'improvementsactive',
    ImprovementsCompleted = 'improvementscompleted',
    ImprovementsDismissed = 'improvementsdismissed',

    // Campaign Groups
    CampaignGroups = 'campaigngroups',

    // Open Improvement (modal) pages
    Improvement = 'improvement', // "/active/:id"
    CompletedImprovement = 'completedimprovement', // "/completed/:id"
    DismissedImprovement = 'dismissedimprovement', // "/dismissed/:id"

    // Performance pages
    Performance = 'performance',
    PerformanceGraphs = 'performancegraphs',
    PerformanceSegments = 'performancesegments',
    PerformanceTable = 'performancetable',

    // Reports pages
    Reports = 'reports',
    ReportsActive = 'reportsactive',
    ReportsArchive = 'reportsarchive',
    ReportActive = 'reportactive', // "/report/:id"
    ReportArchive = 'reportarchive', // "/report/:id"
    ReportSlides = 'reportslides',
    ReportSlidesArchive = 'reportslidesarchive',
    ReportCreate = 'createreport',
    ReportDownload = 'downloadreport',
    ReportDownloadArchive = 'downloadreportarchive',
    ReportUpdate = 'updatereport',

    // Toolkit pages
    Toolkit = 'toolkit',
    ToolkitTools = 'toolkittools',
    ToolkitHistory = 'toolkithistory',
    ToolkitScorecard = 'toolkitscorecard',
    ToolkitScorecardList = 'toolkitscorecardlist',
    ToolkitScorecardSingle = 'toolkitscorecardsingle',
    ToolkitScorecardCreate = 'toolkitscorecardcreate',
    ToolkitSmartBiddingExperiments = 'toolkitsmartbiddingexpriments',
    ToolkitSmartBiddingExperimentsRecommendations = 'toolkitsmartbiddingexprimentsrecommendations',
    ToolkitSmartBiddingExperimentsExperiment = 'toolkitsmartbiddingexprimentsexperiment',
    ToolkitSmartBiddingExperimentsCreateFlow = 'toolkitsmartbiddingexprimentscreateflow',
    ToolkitSmartBiddingExperimentsActive = 'toolkitsmartbiddingexprimentsactive',
    ToolkitSmartBiddingExperimentsCompleted = 'toolkitsmartbiddingexprimentscompleted',
    ToolkitRsaWriter = 'toolkitrsawriter',
    ToolkitRsaWriterCampaign = 'toolkitrsawritercampaign',
    ToolkitRsaWriterAdGroup = 'toolkitrsawriteradgroup',
    ToolkitRsaWriterAdEdit = 'toolkitrsawriteradedit',
    ToolkitRsaWriterAdNew = 'toolkitrsawriteradnew',
    ToolkitRsaWriterAdNewPreview = 'toolkitrsawriteradnewpreview',
    ToolkitRsaWriterAdEditPreview = 'toolkitrsawriteradeditpreview',

    // Ngram Tool
    ToolkitNGramTool = 'toolkitngramtool',
    ToolkitNGramToolSearch = 'toolkitngramtool-search-campaigns',
    ToolkitNGramToolSearchSelect = 'toolkitngramtool-search-campaigns-select',
    ToolkitNGramToolSearchConfirm = 'toolkitngramtool-search-campaigns-confirm',
    ToolkitNGramToolPmax = 'toolkitngramtool-performance-max',
    ToolkitNgramToolPmaxSelect = 'toolkitngramtool-performance-max-select',
    ToolkitNgramToolPmaxConfirm = 'toolkitngramtool-performance-max-confirm',
    ToolkitNGramToolShopping = 'toolkitngramtool-shopping-campaigns',
    ToolkitNGramToolShoppingSelect = 'toolkitngramtool-shopping-campaigns-select',
    ToolkitNGramToolShoppingConfirm = 'toolkitngramtool-shopping-campaigns-confirm',

    // Shareable link pages
    ShareableLinkScorecard = 'shareablelinkscorecard',

    // PDF pages
    PdfScorecard = 'pdfscorecard',
}

export const QueryParam = {
    from: {
        scorecard: 'scorecard',
    },
} as const
