import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import { useNumber, usePercent, useRoas } from '@opteo/components-next'
import { AdComparisonV2, Entity, Improvement } from '@opteo/types'
import { computed, Ref, ref } from 'vue'
import { checkImprovement, useImprovement, UseImprovement, OnPushHandler } from '../useImprovement'
import { filterLocationsByEntityType } from '@/lib/globalUtils/filterLocationsByEntityType'
import { formatEntityPillLink } from '@/lib/globalUtils'
import { EntityPillLinkProps } from '@/components/global/Entity/types'

interface adData {
    ad: {
        headlineOne: string
        headlineTwo: string
        headlineThree: string
        descriptionOne: string
        descriptionTwo: string
        displayUrl: string
        pathOne: string
        pathTwo: string
        imageUrl: string
    }
    statistics: adStatistics[]
    titleTag: { title: string; classes: string }
}

interface adStatistics {
    name: string
    content: string
    color: string
}
interface UsePauseAd {
    adgroupLabel: string
    adgroupId: string
    adGroupLocationData: EntityPillLinkProps<'adgroup'>
    campaignLabel: string
    campaignId: number
    change: number
    measureMode: AdComparisonV2.PauseAd.MeasureMode
    bestAdData: adData
    worstAdData: adData
    comparisonTableHeaders: { key: string; text: string; sortable: boolean }[]
    selectedAdToPause: Ref<string>
    comparisonTableItems: {
        metric: string
        winner: string
        loser: string
    }[]
    stats: { title: string; value: string; key: string }[]
    adSelectItems: SelectItem[]
    adType: string
    overlappingDays: number

    handleSelectAdToPause: () => void
    resetAdToPause: () => void
}

interface SelectItem {
    label: string
    value: string
}

export function usePauseAdV2(): UseImprovement<UsePauseAd> {
    const { improvement, lastUpdated } = useImprovement<
        AdComparisonV2.PauseAd.Body,
        typeof Improvement.RecAction.PauseAdV2
    >()
    const { body, location } = checkImprovement(improvement)

    const {
        adgroup_label: adgroupLabel,
        adgroup_id: adgroupId,
        campaign_label: campaignLabel,
        campaign_id: campaignId,
        measure_mode: measureMode,
        ad_type: adType,
        confidence,
        overlapping_days: overlappingDays,
        best_ad,
        worst_ad,
    } = body

    const selectedAdToPause = ref<'winning' | 'losing'>('losing')

    const adGroupLocation = filterLocationsByEntityType(location, Entity.EntityLocation.AdGroup)
    const adGroupLocationData = formatEntityPillLink({
        entityPillData: { type: Entity.EntityLocation.AdGroup, content: adGroupLocation.label },
        deepLinkParams: adGroupLocation.entityIds,
    })

    const stats: { key: string; value: string; title: string }[] = []

    let change

    if (measureMode === AdComparisonV2.PauseAd.MeasureMode.CPI) {
        change = (best_ad.cpi - worst_ad.cpi) / worst_ad.cpi
        stats.push({
            key: 'cpi',
            value: usePercent({ value: change }).displayValue.value,
            title: 'CPI Improvement',
        })
    } else if (measureMode === AdComparisonV2.PauseAd.MeasureMode.CTR) {
        change = (best_ad.ctr - worst_ad.ctr) / worst_ad.ctr
        stats.push({
            key: 'ctr',
            value: usePercent({ value: change }).displayValue.value,
            title: 'CTR Improvement',
        })
    } else {
        change = (best_ad.vpi - worst_ad.vpi) / worst_ad.vpi

        stats.push({
            key: 'vpi',
            value: usePercent({ value: change }).displayValue.value,
            title: 'VPI Improvement',
        })
    }

    stats.push(
        {
            key: 'confidence',
            value: `${confidence.toString().slice(0, 5)}%`,
            title: 'Confidence',
        },
        {
            key: 'days-counted',
            value: useNumber({ value: overlappingDays }).displayValue.value,
            title: 'Days Counted',
        }
    )

    const bestAdData: adData = {
        ad: {
            headlineOne: best_ad.headline_part_1 ?? '',
            headlineTwo: best_ad.headline_part_2 ?? '',
            headlineThree: best_ad.expanded_text_ad_headline3 ?? '',
            descriptionOne: best_ad.description ?? '',
            descriptionTwo: best_ad.expanded_text_ad_description2 ?? '',
            displayUrl: best_ad.root_domain ?? '',
            pathOne: best_ad.path_1 ?? '',
            pathTwo: best_ad.path_2 ?? '',
            imageUrl: best_ad.image_url ?? '',
        },
        statistics: [
            {
                name: 'Clicks',
                content: useNumber({ value: best_ad.raw_clicks }).displayValue.value,
                color: 'green',
            },
        ],
        titleTag: { title: 'Winning Ad', classes: 'opteo-green' },
    }

    const worstAdData: adData = {
        ad: {
            headlineOne: worst_ad.headline_part_1 ?? '',
            headlineTwo: worst_ad.headline_part_2 ?? '',
            headlineThree: worst_ad.expanded_text_ad_headline3 ?? '',
            descriptionOne: worst_ad.description ?? '',
            descriptionTwo: worst_ad.expanded_text_ad_description2 ?? '',
            displayUrl: best_ad.root_domain ?? '',
            pathOne: best_ad.path_1 ?? '',
            pathTwo: best_ad.path_2 ?? '',
            imageUrl: worst_ad.image_url ?? '',
        },
        statistics: [
            {
                name: 'Clicks',
                content: useNumber({ value: worst_ad.raw_clicks }).displayValue.value,
                color: 'red',
            },
        ],
        titleTag: { title: 'Losing Ad', classes: 'opteo-red' },
    }

    const comparisonTableHeaders = [
        { key: 'metric', text: 'Metric', sortable: false },
        { key: 'winner', text: 'Winning Ad', sortable: false },
        { key: 'loser', text: 'Losing Ad', sortable: false },
    ]

    // Table row items
    const ctrRow = {
        metric: 'Click Through Rate',
        winner: usePercent({ value: best_ad.raw_ctr / 100 }).displayValue.value,
        loser: usePercent({ value: worst_ad.raw_ctr / 100 }).displayValue.value,
    }
    const clicksRow = {
        metric: 'Clicks',
        winner: useNumber({ value: best_ad.clicks }).displayValue.value,
        loser: useNumber({ value: worst_ad.clicks }).displayValue.value,
    }
    const adsTopImpShareRow = {
        metric: 'Abs. Top Impr. Share',
        winner: usePercent({ value: best_ad.impr_abs_top }).displayValue.value,
        loser: usePercent({ value: worst_ad.impr_abs_top }).displayValue.value,
    }
    const topImpShareRow = {
        metric: 'Top Impr. Share',
        winner: usePercent({ value: best_ad.impr_top }).displayValue.value,
        loser: usePercent({ value: worst_ad.impr_top }).displayValue.value,
    }
    const impressionsRow = {
        metric: 'Impressions',
        winner: useNumber({ value: best_ad.impressions }).displayValue.value,
        loser: useNumber({ value: worst_ad.impressions }).displayValue.value,
    }
    const costRow = {
        metric: 'Cost',
        winner: useDomainMoney({ value: best_ad.cost }).value.displayValue.value,
        loser: useDomainMoney({ value: worst_ad.cost }).value.displayValue.value,
    }
    const cpcRow = {
        metric: 'Cost Per Click',
        winner: useDomainMoney({ value: best_ad.cost / best_ad.clicks }).value.displayValue.value,
        loser: useDomainMoney({ value: worst_ad.cost / worst_ad.clicks }).value.displayValue.value,
    }
    const convRow = {
        metric: 'Conversions',
        winner: useNumber({ value: best_ad.conversions }).displayValue.value,
        loser: useNumber({ value: worst_ad.conversions }).displayValue.value,
    }

    const convRateRow = {
        metric: 'Conversion Rate',
        winner: usePercent({ value: best_ad.conversion_rate / 100 }).displayValue.value,
        loser: usePercent({ value: worst_ad.conversion_rate / 100 }).displayValue.value,
    }
    const convValueRow = {
        metric: 'Conversion Value',
        winner: useDomainMoney({ value: best_ad.conversion_value }).value.displayValue.value,
        loser: useDomainMoney({ value: worst_ad.conversion_value }).value.displayValue.value,
    }
    const cpaRow = {
        metric: 'Cost Per Acquisition',
        winner: useDomainMoney({ value: best_ad.cpa }).value.displayValue.value,
        loser: useDomainMoney({ value: worst_ad.cpa }).value.displayValue.value,
    }
    const roasRow = {
        metric: 'ROAS',
        winner: useRoas({ value: best_ad.conversion_value / best_ad.cost }).displayValue.value,
        loser: useRoas({ value: worst_ad.conversion_value / worst_ad.cost }).displayValue.value,
    }

    const convPerImpRow = {
        metric: 'Conv. Per Impression',
        winner: `${Math.round((best_ad.cpi + Number.EPSILON) * 100000) / 100000}`,
        loser: `${Math.round((worst_ad.cpi + Number.EPSILON) * 100000) / 100000}`,
    }
    const valuePerImpRow = {
        metric: 'Value Per Impression',
        winner: useDomainMoney({ value: best_ad.vpi }).value.displayValue.value,
        loser: useDomainMoney({ value: worst_ad.vpi }).value.displayValue.value,
    }

    // Two table items arrays for specific ordering based on CTR, CPI or VPI mode
    const ctrComparisonTableItems = [
        ctrRow,
        clicksRow,
        adsTopImpShareRow,
        topImpShareRow,
        impressionsRow,
        costRow,
        cpcRow,
        convRow,
        convValueRow,
        convRateRow,
        cpaRow,
        roasRow,
        convPerImpRow,
        valuePerImpRow,
    ]

    const cpiComparisonTableItems = [
        convPerImpRow,
        convRow,
        adsTopImpShareRow,
        topImpShareRow,
        impressionsRow,
        costRow,
        cpcRow,
        clicksRow,
        convRateRow,
        cpaRow,
        ctrRow,
    ]

    const vpiComparisonTableItems = [
        valuePerImpRow,
        convValueRow,
        adsTopImpShareRow,
        topImpShareRow,
        impressionsRow,
        costRow,
        cpcRow,
        clicksRow,
        convRateRow,
        roasRow,
        ctrRow,
    ]

    const comparisonTableItems =
        measureMode === AdComparisonV2.PauseAd.MeasureMode.CTR
            ? ctrComparisonTableItems
            : measureMode === AdComparisonV2.PauseAd.MeasureMode.CPI
            ? cpiComparisonTableItems
            : vpiComparisonTableItems

    if (measureMode === AdComparisonV2.PauseAd.MeasureMode.CPI) {
        // text ad dependent stats
        bestAdData.statistics.push({
            name: 'CPI',
            content: `${Math.round((best_ad.cpi + Number.EPSILON) * 100000) / 100000}`,
            color: 'green',
        })
        worstAdData.statistics.push({
            name: 'CPI',
            content: `${Math.round((worst_ad.cpi + Number.EPSILON) * 100000) / 100000}`,
            color: 'red',
        })
    } else if (measureMode === AdComparisonV2.PauseAd.MeasureMode.CTR) {
        // text ad dependent stats
        bestAdData.statistics.push({
            name: 'CTR',
            content: usePercent({ value: best_ad.ctr / 100 }).displayValue.value,
            color: 'green',
        })
        worstAdData.statistics.push({
            name: 'CTR',
            content: usePercent({ value: worst_ad.ctr / 100 }).displayValue.value,
            color: 'red',
        })
    } else {
        // text ad dependent stats
        bestAdData.statistics.push({
            name: 'VPI',
            content: useDomainMoney({ value: best_ad.vpi }).value.displayValue.value,
            color: 'green',
        })
        worstAdData.statistics.push({
            name: 'VPI',
            content: useDomainMoney({ value: worst_ad.vpi }).value.displayValue.value,
            color: 'red',
        })
    }

    // Resets pushActionText when pressing back
    function resetAdToPause() {
        selectedAdToPause.value = 'losing'
        pushActionText.value = 'Pause Losing Ad'
    }
    function handleSelectAdToPause() {
        pushActionText.value =
            selectedAdToPause.value === 'losing' ? 'Pause Losing Ad' : 'Pause Winning Ad'
    }

    const pushActionText = ref(
        selectedAdToPause.value === 'losing' ? 'Pause Losing Ad' : 'Pause Winning Ad'
    )

    // Dynamic title
    const title = computed(() => {
        return selectedAdToPause.value === 'losing' ? 'Pause Losing Ad' : 'Pause Winning Ad'
    })

    const pushMessages = computed(() =>
        selectedAdToPause.value === 'losing'
            ? [
                  'Connecting to Google Ads..',
                  'Pausing losing ad..',
                  'Confirming changes..',
                  'Losing ad paused successfully.',
              ]
            : [
                  'Connecting to Google Ads..',
                  'Pausing winning ad..',
                  'Confirming changes..',
                  'Winning ad paused successfully.',
              ]
    )

    const adSelectItems: SelectItem[] = [
        {
            label: 'Losing Ad',
            value: 'losing',
        },
        {
            label: 'Winning Ad',
            value: 'winning',
        },
    ]

    const onPush: OnPushHandler<AdComparisonV2.PauseAd.ExtraDetails> = () => {
        return {
            valid: true,
            pushedData: {
                pause_worst_ad: selectedAdToPause.value === 'losing',
            },
        }
    }

    return {
        title,
        lastUpdated,
        pushMessages,
        adgroupLabel,
        campaignLabel,
        change,
        bestAdData,
        worstAdData,
        comparisonTableHeaders,
        comparisonTableItems,
        stats,
        onPush,
        adSelectItems,
        selectedAdToPause,
        adType,
        measureMode,
        handleSelectAdToPause,
        pushActionText,
        resetAdToPause,
        overlappingDays,
        campaignId,
        adgroupId,
        adGroupLocationData,
    }
}
