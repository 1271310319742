import { computed, ref } from 'vue'
import { FixBrokenSitelink, Improvement } from '@opteo/types'

import { useImprovement, checkImprovement } from '@/composition/improvement/useImprovement'
import { formatEntityPillLink } from '@/lib/globalUtils'

import type { EntityPillLinkProps } from '@/components/global/Entity/types'

const isCampaignNames = (affectedCampaigns: unknown[]): affectedCampaigns is string[] => {
    return affectedCampaigns && typeof affectedCampaigns[0] === 'string'
}

export function useFixBrokenSitelink() {
    const { improvement, lastUpdated, title } = useImprovement<
        FixBrokenSitelink.Body,
        typeof Improvement.RecAction.FixBrokenLink
    >()

    const {
        body: { url, reason, affected_campaigns: affectedCampaigns },
    } = checkImprovement(improvement)

    const singleCampaign = affectedCampaigns.length === 1
    const copy = {
        spread: singleCampaign ? 'in' : 'across',
        campaign: singleCampaign ? 'campaign' : 'campaigns',
    }

    /**
     * `affectedCampaigns` will be an array of strings if the improvement is an old one. Hence, the data will not have ids (`deepLinkParams` is null).
     * Else, `affectedCampaigns` will be an array of campaigns with their respective names and ids (`deepLinkParams` is an object).
     */
    const formattedCampaigns: EntityPillLinkProps<'campaign'>[] = isCampaignNames(affectedCampaigns)
        ? affectedCampaigns.map(campaign =>
              formatEntityPillLink({
                  entityPillData: {
                      type: 'campaign',
                      content: campaign,
                  },
                  deepLinkParams: null,
              })
          )
        : affectedCampaigns.map(campaign =>
              formatEntityPillLink({
                  entityPillData: {
                      type: 'campaign',
                      content: campaign.campaignName,
                  },
                  deepLinkParams: {
                      campaignId: campaign.campaignId,
                  },
              })
          )

    const pushActionText = ref('Recheck Sitelink')
    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Checking broken link..',
        'Confirming link status..',
        'Broken link fixed successfully.',
    ])

    return {
        title,
        lastUpdated,
        pushMessages,
        reason,
        copy,
        formattedCampaigns,
        url,
        pushActionText,
    }
}
