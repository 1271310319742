<template>
    <div>
        <oTable
            class="impact-statistics-alerts-section-table"
            :headers="alertsTableHeaders"
            :items="alertsTableRows"
            :per-page="6"
            :borderRadius="24"
            order-by="budget"
            order="DESC"
        >
            <!-- Account Name -->
            <template v-slot:header.account>
                <div class="table-search-container">
                    <Label class="table-search-label">Account Name</Label>
                    <Spacer width="1rem" />
                    <oInput
                        ref="searchInput"
                        type="text"
                        class="account-search-input"
                        name="accountSearchInputCost"
                        autocomplete="off"
                        v-model="searchedAccount"
                    />
                </div>
            </template>
            <template v-slot:column.account="cell">
                <ImpactStatsAccountNameCell
                    :domainName="cell.value.domainName"
                    :domainColor="cell.value.color"
                    :platform="cell.value.platform"
                    :initials="cell.value.initials"
                    :validating="validating"
                />
            </template>
            <template v-slot:column.accountBreakdown="cell">
                <oButton
                    color="white"
                    size="small"
                    :disabled="!cell.value.instances.length"
                    @clicked="openModal(cell.value.instances)"
                >
                    Detailed View
                </oButton>
            </template>
            <!-- Skeletons -->
            <template v-if="validating" v-slot:column.budget>
                <Skeleton width="2.5rem" />
            </template>
            <template v-if="validating" v-slot:column.kpiAnom>
                <Skeleton width="2.5rem" />
            </template>
            <template v-if="validating" v-slot:column.zeroImpr>
                <Skeleton width="2.5rem" />
            </template>
            <template v-if="validating" v-slot:column.brokenLink>
                <Skeleton width="2.5rem" />
            </template>
        </oTable>
        <!-- Account Breakdown Modal -->
        <Modal
            v-model="alertBreakdownModalOpen"
            title="Critical Alerts Breakdown"
            width="78.5rem"
            @closed="resetNumberOfVisibleRows"
        >
            <template #content>
                <ImpactStatisticsAlertRow
                    v-for="(row, index) in slicedRows"
                    :alert="row"
                    :class="index === slicedRows.length - 1 ? 'last' : ''"
                />
                <div v-if="modalRows.length > slicedRows.length" class="show-more-button-wrapper">
                    <oButton color="white" @clicked="showMoreRows">Show More</oButton>
                </div>
            </template>
        </Modal>
    </div>
</template>
<script setup lang="ts">
// vue
import { computed, ref, PropType } from 'vue'
// types
import { AccountData } from '@/composition/impactStatistics/useImpactStatistics'
import { IS } from '@opteo/types'
// local components
import ImpactStatsAccountNameCell from '@/components/impactStatistics/ImpactStatsAccountNameCell.vue'
import ImpactStatisticsAlertRow from '@/components/impactStatistics/ImpactStatisticsAlertRow.vue'
import Skeleton from '@/components/util/Skeleton.vue'
// components-next
import { oTable, Label, Spacer, oInput, oButton, Modal } from '@opteo/components-next'
// lodash
import includes from 'lodash-es/includes'

export type AlertAccountBreakdown = {
    domainInfo: AccountData
    instances: { alerts: IS.CriticalAlert[]; improvements: IS.CriticalImprovementAlert[] }
}

const props = defineProps({
    data: {
        type: Array as PropType<IS.AccountAlertTableOverview[]>,
        required: true,
    },
    validating: {
        type: Boolean,
        default: false,
    },
})

const alertBreakdownModalOpen = ref(false)

const searchedAccount = ref('')

// Alerts section
const alertsTableHeaders = [
    { key: 'account', text: 'Account Name', noPadding: true },
    { key: 'budget', text: 'Budget', sortable: true },
    { key: 'kpiAnom', text: 'Metric', sortable: true },
    { key: 'zeroImpr', text: 'Zero Impr.', sortable: true },
    { key: 'brokenLink', text: 'Broken Link', sortable: true },
    { key: 'accountBreakdown', text: 'Actions' },
]

const alertsTableRows = computed(() => {
    if (!searchedAccount.value) return props.data

    return props.data.filter(item =>
        includes(item.account.domainName.toLowerCase(), searchedAccount.value.toLowerCase())
    )
})

const modalRows = ref<IS.CriticalAlertOverviewInstance[]>([])

const openModal = (instances: IS.CriticalAlertOverviewInstance[]) => {
    if (!instances.length) return
    modalRows.value = instances
    alertBreakdownModalOpen.value = true
}

const numberOfVisibleRows = ref(50)
const slicedRows = computed(() => {
    return modalRows.value.slice(0, numberOfVisibleRows.value)
})
const showMoreRows = () => {
    numberOfVisibleRows.value = numberOfVisibleRows.value + 50
}
const resetNumberOfVisibleRows = () => {
    numberOfVisibleRows.value = 50
}
</script>

<!-- Scoped -->
<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

@media screen and (max-width: 1279px) {
    .account-search-input {
        display: none;
    }
}

@media screen and (min-width: 1280px) {
    .account-search-input {
        max-width: 12rem;
        @include relative;
    }
    .account-search-input :deep(input) {
        padding: 10px 14px 10px 38px;
    }
    .account-search-input::before {
        content: '';
        position: absolute;
        left: 14px;
        top: 0;
        bottom: 0;
        width: 12px;
        background: url('@/assets/img/searchIcon.svg') center / contain no-repeat;
    }
}

// Modal
.show-more-button-wrapper {
    width: 100%;
    @include flex-center;
    @include mt-24;
}
</style>

<!-- Unscoped -->
<style lang="scss">
@media screen and (max-width: 1279px) {
    .impact-statistics-alerts-section-table td:not(:first-child),
    .impact-statistics-alerts-section-table th:not(:first-child) {
        padding-left: 1.125rem;
        padding-right: 1.125rem;
    }
    .impact-statistics-alerts-section-table td:last-child {
        padding-right: 1.5rem;
    }
    .impact-statistics-alerts-section-table td:nth-child(5),
    .impact-statistics-alerts-section-table th:nth-child(5) {
        padding-right: 0.5rem;
    }
    .impact-statistics-alerts-section-table .impact-stats-table-domain-name {
        max-width: 11rem;
    }
}

@media screen and (min-width: 1280px) {
    .impact-statistics-alerts-section-table td:last-child {
        padding-right: 2.125rem;
    }
}
</style>
