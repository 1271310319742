import capitalize from 'lodash-es/capitalize'
import { computed, ComputedRef, ref, Ref, watch, watchEffect } from 'vue'
import { sortBy } from 'lodash-es'

import { useAccount } from '@/composition/account/useAccount'
import { Endpoint, useAPI } from '@/composition/api/useAPI'
import { useDomain } from '@/composition/domain/useDomain'
import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import {
    checkImprovement,
    OnPushHandler,
    UseImprovement,
    useImprovement,
} from '@/composition/improvement/useImprovement'
import { Ad } from '@/composition/improvement/useImprovementAdPreviewTippy'
import { buildSerpPreviewUrl } from '@/lib/serp'
import { formatEntityPillLink, scrollToSelector } from '@/lib/globalUtils'
import { useNumber, useRoas } from '@opteo/components-next'
import { AdWords, CheckQueryRelevance, Entity, Improvement, Targets } from '@opteo/types'
import { enums } from '@opteo/types/gads'

import type { ImprovementStatistic } from '.'
import type { EntityPillLinkProps, ValidEntity } from '@/components/global/Entity/types'

type FormElement = HTMLDivElement & { submit: () => void }

type DropDownItem = { value: string; label: string }

type Metrics = { [metric: string]: { value: number; copy?: string } }

type SerpImage = { url: string; imageTitle: string }

type SearchTermsWords = Ref<{ word: string; index: number; selected: boolean }[]>

type KeywordMatchTypes = Ref<{ matchType: enums.KeywordMatchType; selected: boolean }[]>

enum KeywordType {
    POSITIVE = 'new-keyword',
    NEGATIVE = 'negative-keyword',
}

interface ActiveEntity {
    id: number
    label: string
    type: string
    uncheckable: boolean
    children: ActiveEntityChildren[]
}

interface ActiveEntityChildren {
    id: number
    label: string
    type: string
    checked: boolean
}

interface UseCheckQueryRelevance {
    adjustSteps: Ref<string[]>
    // LANDING PAGE
    entityPillList: EntityPillLinkProps<ValidEntity>[]
    campaignId: string
    adGroupId: string
    metrics: Metrics
    searchTerm: string
    serpImage: SerpImage
    // KEYWORD ADJUSTMENT
    resetState: () => void
    KeywordType: typeof KeywordType
    addingNegativeKeyword: Ref<KeywordType>
    showKeywordSelection: Ref<boolean>
    changeKeywordType: (keywordType: KeywordType) => void
    previewKeyword: ComputedRef<string | null>
    searchTermWords: SearchTermsWords
    KeywordMatchType: typeof enums.KeywordMatchType
    keywordMatchTypes: KeywordMatchTypes
    toggleWordSelection: (index: number) => void
    toggleMatchType: (index: number) => void
    toggleShowKeywordSelection: () => void
    sourceKeywordBid: ComputedRef<number>
    sourceKeywordBidMessage: ComputedRef<string>
    sourceKeywordBidLoading: Ref<boolean>
    // DROP DOWN LISTS
    sharedSets: ComputedRef<DropDownItem[]>
    // selectedSharedSet: Ref<string>
    sharedSetsLoading: Ref<boolean>
    campaigns: ComputedRef<DropDownItem[]>
    activeCampaignsLoading: Ref<boolean>
    inactiveCampaignsLoading: Ref<boolean>
    defaultDestinationIsInactive: Ref<boolean>
    // NEW ADGROUP
    allowNewAdGroupCreation: ComputedRef<boolean>
    dkiPopout: Ref<boolean>
    newAdGroupPopout: Ref<boolean>
    customFinalURL: Ref<string>
    customFinalUrlRef: Ref<string>
    negativeListModalOpen: Ref<boolean>
    creatingNewAdGroup: Ref<boolean>
    splitTestWithDKI: Ref<boolean>
    newAdGroupName: Ref<string>
    newAdGroupForm: Ref<FormElement | undefined>
    sampleAd: ComputedRef<Ad | undefined>
    sampleDKIad: ComputedRef<Ad | undefined>
    DKIselectionEnabled: ComputedRef<boolean | undefined>
    activeDestinations: ComputedRef<
        { key: string; name: string; active?: boolean; count?: number }[]
    >
    activeEntities: Ref<any>
    accountLevelEntity: Ref<{ id: string; label: string; type: string; checked: boolean }>
    onPushError: Ref<{ status: boolean; message: string }>
    adDataLoading: Ref<boolean>
    toggleCreatingNewAdGroup: () => void
    mutateNegativeList: (data: AdWords.SharedNegativeList) => Promise<void>
    isUsingRoas: boolean
    improvementStatistics: ImprovementStatistic[]
}

const MAX_SOURCE_KEYWORD_BID = 200_000

export function useCheckQueryRelevance(): UseImprovement<UseCheckQueryRelevance> {
    const { domainId, accountId } = useAccount()
    const { improvement, lastUpdated, title: _title } = useImprovement<CheckQueryRelevance.Body>()

    const {
        body: {
            search_term: searchTerm,
            keyword_text: keyword,
            campaign_label: campaignName,
            adgroup_label: adGroupName,
            campaign_id: campaignId,
            adgroup_id: adGroupId,
            keyword_id: keywordId,
            cost,
            impressions,
            clicks,
            conversions,
            conversions_value: conversionsValue,
            performance_mode: performanceMode,
        },
    } = checkImprovement(improvement)

    const { domainInfo } = useDomain()

    // LANDING PAGE
    const cpa = conversions ? cost / conversions : 0
    const roas = cost ? conversionsValue / cost : 0

    const isUsingRoas = performanceMode === Targets.PerformanceMode.ROAS

    const metrics: Metrics = {
        cpa: { value: cpa },
        roas: { value: roas },
        cost: { value: cost },
        impressions: { value: impressions },
        clicks: { value: clicks, copy: clicks === 1 ? 'click' : 'clicks' },
        conversions: { value: conversions, copy: conversions === 1 ? 'conversion' : 'conversions' },
        conversionsValue: { value: conversionsValue },
    }

    const serpImage: SerpImage = {
        url: buildSerpPreviewUrl({ searchTerm, accountId: accountId.value }),
        imageTitle: 'Google SERP Preview',
    }

    // KEYWORD ADJUSTMENT

    const addingNegativeKeyword = ref(KeywordType.POSITIVE)
    const showKeywordSelection = ref(false)

    const searchTermWords: SearchTermsWords = ref(
        searchTerm.split(' ').map((word: string, index: number) => {
            return { word, index, selected: true }
        })
    )

    const keywordMatchTypes: KeywordMatchTypes = ref([
        { matchType: enums.KeywordMatchType.EXACT, selected: false },
        { matchType: enums.KeywordMatchType.PHRASE, selected: true },
        { matchType: enums.KeywordMatchType.BROAD, selected: false },
    ])

    const selectedWords = computed(() => searchTermWords.value.filter(word => word.selected))

    // TODO: add relevant `Entities` type and solve errors that arise from doing this. Don't be naughty, don't use `any` 👀
    const newKeywordSelection = ref()
    const negativeKeywordSelection = ref()

    const accountLevelEntity = ref({
        id: 'entity-id',
        label: 'Account Level',
        type: 'account-level',
        checked: false,
    })

    const previewKeyword = computed(() => {
        if (!selectedWords.value.length) {
            return null
        }

        const words = selectedWords.value.map(word => word.word).join(' ')

        const matchType = keywordMatchTypes.value.find(matchType => matchType.selected)

        if (matchType?.matchType === enums.KeywordMatchType.PHRASE) {
            return `“${words}”`
        } else if (matchType?.matchType === enums.KeywordMatchType.EXACT) {
            return `[${words}]`
        } else {
            return words
        }
    })

    function changeKeywordType(keywordType: KeywordType) {
        addingNegativeKeyword.value = keywordType

        pushActionText.value =
            addingNegativeKeyword.value === KeywordType.NEGATIVE
                ? 'Add Negative Keyword'
                : 'Add New Keyword'
    }

    function toggleWordSelection(index: number) {
        const word = searchTermWords.value.find(word => word.index === index)

        if (word) {
            word.selected = !word.selected
        }
    }

    function toggleMatchType(matchType: enums.KeywordMatchType) {
        const selectedKeywordMatchType = keywordMatchTypes.value.find(
            mt => mt.matchType === matchType
        )

        if (selectedKeywordMatchType) {
            keywordMatchTypes.value.forEach(mt => (mt.selected = false))
            selectedKeywordMatchType.selected = true
        }
    }

    function toggleShowKeywordSelection() {
        showKeywordSelection.value = !showKeywordSelection.value
    }

    // SOURCE KEYWORD BID

    const { data: sourceKeywordBidData, loading: sourceKeywordBidLoading } = useAPI<number>(
        Endpoint.GetSourceKeywordBid,
        {
            body: { ad_group_id: adGroupId, keyword_id: keywordId },
            uniqueId: () => domainId.value,
            waitFor: () => domainId.value,
        }
    )

    const maxBid = computed(
        () =>
            domainInfo.value?.is_grant &&
            sourceKeywordBidData.value &&
            sourceKeywordBidData.value < MAX_SOURCE_KEYWORD_BID
    )

    const sourceKeywordBid = computed(() => {
        if (!sourceKeywordBidData.value) {
            return 0
        }

        if (maxBid.value) {
            return MAX_SOURCE_KEYWORD_BID / 1000000
        }

        return (sourceKeywordBidData.value || 0) / 1000000
    })

    const sourceKeywordBidMessage = computed(() => {
        if (maxBid.value) {
            return 'Bid (Maximum For Google Grants Accounts)'
        }

        return 'Bid (Set From Source Keyword)'
    })

    // SHARED SETS
    const {
        data: sharedSetData,
        loading: sharedSetsLoading,
        mutate: mutateSharedSetData,
    } = useAPI<AdWords.SharedNegativeList[]>(Endpoint.GetSharedNegativeLists, {
        body: { type: 'keywords' },
        uniqueId: () => domainId.value,
        waitFor: () => domainId.value,
    })

    const sharedSets = computed<DropDownItem[]>(() => {
        return (sharedSetData.value || []).map(sharedSet => {
            return { value: sharedSet.shared_set_resource_name, label: sharedSet.shared_set_name }
        })
    })

    // CAMPAIGNS AND ADGROUPS
    const defaultDestinationIsInactive = ref(false)

    const { data: activeCampaignData, loading: activeCampaignsLoading } = useAPI<
        AdWords.CampaignWithAdGroups[]
    >(Endpoint.GetCampaignsAndAdgroups, {
        body: { campaign_status_enabled: true },
        uniqueId: () => domainId.value,
        waitFor: () => domainId.value,
    })

    const { data: inactiveCampaignData, loading: inactiveCampaignsLoading } = useAPI<
        AdWords.CampaignWithAdGroups[]
    >(Endpoint.GetCampaignsAndAdgroups, {
        body: { campaign_status_enabled: false },
        uniqueId: () => domainId.value,
        waitFor: () => domainId.value,
    })

    const campaignData = computed<AdWords.CampaignWithAdGroups[]>(() => {
        const activeCampaigns = activeCampaignData.value || []
        const inactiveCampaigns = inactiveCampaignData.value || []

        return activeCampaigns.length ? activeCampaigns : inactiveCampaigns
    })

    const campaigns = computed<DropDownItem[]>(() => {
        return campaignData.value.map(campaign => {
            return { value: campaign.campaign_id.toString(), label: campaign.campaign_name }
        })
    })

    watch([campaigns, sharedSetData], () => {
        const improvementCampaign = campaigns.value.find(
            campaign => campaign.label === campaignName
        )

        const improvementAdGroup = campaignData.value
            .find(
                campaign =>
                    improvementCampaign && campaign.campaign_id === +improvementCampaign.value
            )
            ?.adgroups_data?.find(ag => adGroupId && ag.adgroup_id === +adGroupId)

        if (improvementCampaign?.value && improvementAdGroup) {
            defaultDestinationIsInactive.value = false
        } else {
            defaultDestinationIsInactive.value = true
        }

        newKeywordSelection.value = {
            [Improvement.LocationEntity.AdGroup]: sortBy(
                campaignData?.value
                    ?.filter(
                        c => c.advertising_channel_type != enums.AdvertisingChannelType.SHOPPING
                    )
                    ?.map((campaign, x) => {
                        return {
                            id: campaign.campaign_id,
                            label: campaign.campaign_name,
                            type: 'campaign',
                            uncheckable: true,
                            children: campaign.adgroups_data.map((adGroup, i) => {
                                return {
                                    id: adGroup.adgroup_id,
                                    label: adGroup.adgroup,
                                    type: 'adgroup',
                                    checked: adGroupId
                                        ? adGroup?.adgroup_id.toString() === adGroupId
                                        : i === 0 && x === 0,
                                    source:
                                        adGroupId && adGroup?.adgroup_id.toString() === adGroupId,
                                }
                            }),
                        }
                    }),
                //Show selected campaign/adgroup first then keep alphabetic order
                c => {
                    if (c.id === +campaignId) {
                        return 0
                    }
                    return
                }
            ),
        }

        negativeKeywordSelection.value = {
            [Improvement.LocationEntity.NegativeList]:
                sharedSetData.value?.map((set, i) => {
                    return {
                        id: set.shared_set_resource_name,
                        label: set.shared_set_name,
                        type: 'negative-list',
                        checked: set?.shared_set_resource_name === newNegativeListRn?.value,
                    }
                }) ?? [],
            [Improvement.LocationEntity.Campaign]: sortBy(
                campaignData.value.map((campaign, i) => {
                    return {
                        id: campaign.campaign_id,
                        label: campaign.campaign_name,
                        type: 'campaign',
                        checked: false,
                        children: campaign.adgroups_data.map((adGroup, x) => {
                            return {
                                id: adGroup.adgroup_id,
                                label: adGroup.adgroup,
                                type: 'adgroup',
                                checked: false,
                                source: adGroupId && adGroup?.adgroup_id.toString() === adGroupId,
                            }
                        }),
                    }
                }), //Show selected campaign/adgroup first then keep alphabetic order
                c => {
                    if (c.id === +campaignId) {
                        return 0
                    }
                    return
                }
            ),
        }
    })

    // MULTIPLE DESTINATIONS
    const activeDestinations = computed(() =>
        addingNegativeKeyword.value === KeywordType.POSITIVE
            ? [{ key: Improvement.LocationEntity.AdGroup, name: 'Ad Groups' }]
            : [
                  {
                      key: Improvement.LocationEntity.NegativeList,
                      name: 'Negative Lists',
                      active: true,
                      // ensure our account level "entity" is included in the count
                      count: accountLevelEntity.value.checked ? 1 : 0,
                  },
                  {
                      key: Improvement.LocationEntity.Campaign,
                      name: 'Campaigns',
                  },
              ]
    )

    const activeEntities = ref<
        typeof newKeywordSelection.value | typeof negativeKeywordSelection.value
    >()

    watchEffect(() => {
        const relevantEntities =
            addingNegativeKeyword.value === KeywordType.POSITIVE
                ? newKeywordSelection.value
                : negativeKeywordSelection.value

        activeEntities.value = relevantEntities
    })

    // CUSTOM FINAL URL
    const customFinalUrlRef = ref()
    const customFinalURL = ref('')

    // NEW NEGATIVE LIST
    const negativeListModalOpen = ref(false)
    const newNegativeListRn = ref('')

    async function mutateNegativeList(data: AdWords.SharedNegativeList) {
        newNegativeListRn.value = data.shared_set_resource_name
        mutateSharedSetData(() => [...sharedSetData?.value!, ...[data]])
    }

    // NEW ADGROUP
    const dkiPopout = ref(false)
    const newAdGroupPopout = ref(false)
    const creatingNewAdGroup = ref(false)
    const splitTestWithDKI = ref(false)
    const newAdGroupName = ref('')
    const newAdGroupForm = ref<FormElement>()

    const { data: sampleAdData, loading: adDataLoading } = useAPI<AdWords.AdStatic>(
        Endpoint.GetSQRSampleAd,
        {
            body: { ad_group_id: adGroupId },
            uniqueId: () => domainId.value,
            waitFor: () => domainId.value,
        }
    )

    const sampleAd = computed<Ad | undefined>(() => {
        if (sampleAdData.value) {
            return {
                headlineOne: sampleAdData.value.headline_part_1,
                headlineTwo: sampleAdData.value.headline_part_2,
                displayUrl: sampleAdData.value.display_url,
                pathOne: sampleAdData.value.path1,
                pathTwo: sampleAdData.value.path2,
                descriptionOne: sampleAdData.value.description,
            }
        }
    })

    const sampleDKIad = computed<Ad | undefined>(() => {
        if (sampleAdData.value) {
            const headlineOne = `{KeyWord:${searchTerm
                .split(' ')
                .map((word: string) => capitalize(word))
                .join(' ')}}`

            return {
                headlineOne,
                headlineTwo: sampleAdData.value.headline_part_2,
                displayUrl: sampleAdData.value.display_url,
                pathOne: sampleAdData.value.path1,
                pathTwo: sampleAdData.value.path2,
                descriptionOne: sampleAdData.value.description,
            }
        }
    })

    const DKIselectionEnabled = computed(
        () => sampleDKIad.value && sampleDKIad.value.headlineOne.length <= 30
    )

    const allowNewAdGroupCreation = computed<boolean>(() => {
        return false // TODO: implement support for creating new adgroups with RSAs
        //return !!sampleAd.value && !!sampleDKIad.value
    })

    function toggleCreatingNewAdGroup() {
        creatingNewAdGroup.value = !creatingNewAdGroup.value
    }

    // IMPROVEMENT STATISTICS
    const commonStatistics: ImprovementStatistic[] = [
        {
            key: 'cost',
            value: useDomainMoney({ value: cost }).value.displayValue.value,
            title: 'Cost',
        },
        {
            key: 'impressions',
            value: useNumber({ value: impressions }).displayValue.value,
            title: 'Impressions',
        },
    ]

    const roasStatistics: ImprovementStatistic[] = [
        {
            key: 'conversionsValue',
            value: useDomainMoney({ value: conversionsValue }).value.displayValue.value,
            title: 'Value',
        },
        {
            key: 'roas',
            value: useRoas({ value: roas }).displayValue.value,
            title: 'ROAS',
        },
    ]

    const cpaStatistics: ImprovementStatistic[] = [
        {
            key: 'conversions',
            value: useNumber({ value: conversions }).displayValue.value,
            title: 'Conversions',
        },
        {
            key: 'cpa',
            value: useDomainMoney({ value: !cost ? 0 : cost / conversions }).value.displayValue
                .value,
            title: 'CPA',
        },
    ]

    const improvementStatistics = [
        ...commonStatistics,
        ...(isUsingRoas ? roasStatistics : cpaStatistics),
    ]

    // REQUIREMENTS
    const entityPillList = [
        formatEntityPillLink({
            entityPillData: { type: Entity.EntityLocation.Campaign, content: campaignName },
            deepLinkParams: { campaignId: +campaignId },
        }),
        formatEntityPillLink({
            entityPillData: { type: Entity.EntityLocation.AdGroup, content: adGroupName },
            deepLinkParams: { campaignId: +campaignId, adGroupId: +adGroupId },
        }),
        formatEntityPillLink({
            entityPillData: { type: Entity.EntityLocation.Keyword, content: keyword },
            deepLinkParams: { campaignId: +campaignId, adGroupId: +adGroupId },
        }),
    ]

    const adjustSteps = ref(['Classify Keyword'])

    const pushActionText = ref(
        addingNegativeKeyword.value === KeywordType.NEGATIVE
            ? 'Add Negative Keyword'
            : 'Add New Keyword'
    )

    const title = computed(() => 'Classify Search Term')

    function resetState() {
        addingNegativeKeyword.value = KeywordType.POSITIVE
        showKeywordSelection.value = false
    }

    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        `Adding ${
            addingNegativeKeyword.value === KeywordType.NEGATIVE ? 'Negative Keyword' : 'Keyword'
        }..`,
        'Confirming changes..',
        `${
            addingNegativeKeyword.value === KeywordType.NEGATIVE ? 'Negative Keyword' : 'Keyword'
        } added successfully.`,
    ])

    const onPush: OnPushHandler<CheckQueryRelevance.ExtraDetails> = () => {
        const noKeywordsSelected = !selectedWords.value.length
        const newAdGroupUnNamed =
            addingNegativeKeyword.value === KeywordType.POSITIVE &&
            creatingNewAdGroup.value &&
            !newAdGroupName.value.length

        if (noKeywordsSelected) {
            showKeywordSelection.value = true
        }

        if (addingNegativeKeyword.value === KeywordType.POSITIVE && creatingNewAdGroup.value) {
            newAdGroupForm.value?.submit()
        }

        if (noKeywordsSelected || newAdGroupUnNamed) {
            return { valid: false }
        }

        const keywordText = selectedWords.value.map(word => word.word).join(' ')

        if (addingNegativeKeyword.value === KeywordType.NEGATIVE) {
            return addToNegativesValidation(keywordText)
        } else {
            return addExactKeywordValidation(keywordText)
        }
    }

    const onPushError = ref({ status: false, message: '' })

    function addToNegativesValidation(keywordText: string): {
        valid: boolean
        pushedData?: CheckQueryRelevance.AddToNegativesExtraDetails
    } {
        const keywordMatchType = keywordMatchTypes.value.find(matchType => matchType.selected)
            ?.matchType

        if (!keywordMatchType) {
            return { valid: false }
        }

        const isAccountLevelNegativeList = accountLevelEntity.value.checked

        const ad_group_ids =
            activeEntities.value.campaign?.flatMap((parentCampaign: ActiveEntity) => {
                return parentCampaign.children
                    .filter(adGroup => adGroup.checked)
                    .map(adGroup => adGroup.id)
            }) ?? []

        const campaign_ids =
            activeEntities.value.campaign
                ?.filter((campaign: ActiveEntityChildren) => campaign.checked)
                .map((campaign: ActiveEntityChildren) => {
                    return campaign.id
                }) ?? []

        const negative_list_resource_names =
            activeEntities.value['negative-list']
                ?.filter((negativeList: ActiveEntityChildren) => negativeList.checked)
                .map((negativeList: ActiveEntityChildren) => negativeList.id) ?? []

        if (
            !ad_group_ids.length &&
            !campaign_ids.length &&
            !negative_list_resource_names.length &&
            !isAccountLevelNegativeList
        ) {
            scrollToSelector('.keyword-destination-selector')
            onPushError.value.status = true
            onPushError.value.message = 'Please select at least one destination for your negative.'
            return { valid: false }
        }

        onPushError.value.status = false
        return {
            valid: true,
            pushedData: {
                action: 'add_to_negatives',
                details: {
                    is_add_to_account_level_negative_list: isAccountLevelNegativeList,
                    ad_group_ids,
                    campaign_ids,
                    negative_list_resource_names,
                    final_search_term: keywordText,
                    match_type: keywordMatchType,
                },
            },
        }
    }

    function addExactKeywordValidation(keywordText: string): {
        valid: boolean
        pushedData?: CheckQueryRelevance.AddExactKeywordExtraDetails
    } {
        const keywordMatchType = keywordMatchTypes.value.find(matchType => matchType.selected)
            ?.matchType

        if (!keywordMatchType) {
            return { valid: false }
        }

        const adgroups =
            activeEntities.value.adgroup?.flatMap((parentCampaign: ActiveEntity) => {
                return parentCampaign.children
                    .filter(adGroup => adGroup.checked)
                    .map(adGroup => {
                        return {
                            adgroup_id: adGroup.id,
                            campaign_id: parentCampaign.id ?? campaignId,
                            is_new_adgroup: creatingNewAdGroup.value,
                            new_adgroup_name: newAdGroupName.value,
                        }
                    })
            }) ?? []

        if (customFinalURL.value && !customFinalURL.value.includes('http')) {
            customFinalUrlRef.value?.setError(
                `The protocol (http:// or https://) is missing in your Custom Final URL.`
            )
            return { valid: false }
        }

        if (!adgroups.length) {
            scrollToSelector('.keyword-destination-selector')
            onPushError.value.status = true
            onPushError.value.message = 'Please select at least one destination for your keyword.'
            return { valid: false }
        }
        onPushError.value.status = false
        return {
            valid: true,
            pushedData: {
                action: 'add_exact_keyword',
                details: {
                    adgroups,
                    match_type: keywordMatchType,
                    final_search_term: keywordText,
                    final_url: customFinalURL.value,
                    dki_enabled: splitTestWithDKI.value,
                    best_ad:
                        sampleAdData.value ||
                        ({} as CheckQueryRelevance.AddExactKeywordExtraDetails['details']['best_ad']),
                    dki_ad: {
                        ...sampleAdData.value,
                        headline1: sampleDKIad.value?.headlineOne || '',
                    } as CheckQueryRelevance.AddExactKeywordExtraDetails['details']['dki_ad'],
                },
            },
        }
    }

    return {
        // REQUIREMENTS
        title,
        pushMessages,
        lastUpdated,
        onPush,
        pushActionText,
        adjustSteps,
        entityPillList,
        metrics,
        searchTerm,
        serpImage,
        resetState,
        KeywordType,
        addingNegativeKeyword,
        showKeywordSelection,
        changeKeywordType,
        previewKeyword,
        searchTermWords,
        KeywordMatchType: enums.KeywordMatchType,
        keywordMatchTypes,
        toggleWordSelection,
        toggleMatchType,
        toggleShowKeywordSelection,
        sourceKeywordBid,
        sourceKeywordBidMessage,
        sourceKeywordBidLoading,
        sharedSets,
        sharedSetsLoading,
        campaigns,
        activeCampaignsLoading,
        inactiveCampaignsLoading,
        defaultDestinationIsInactive,
        allowNewAdGroupCreation,
        dkiPopout,
        newAdGroupPopout,
        creatingNewAdGroup,
        splitTestWithDKI,
        newAdGroupName,
        newAdGroupForm,
        sampleAd,
        sampleDKIad,
        DKIselectionEnabled,
        adDataLoading,
        toggleCreatingNewAdGroup,
        isUsingRoas,
        improvementStatistics,
        activeDestinations,
        activeEntities,
        accountLevelEntity,
        customFinalURL,
        customFinalUrlRef,
        negativeListModalOpen,
        mutateNegativeList,
        onPushError,
        campaignId,
        adGroupId,
    }
}
