<template>
    <svg
        width="116"
        height="32"
        viewBox="0 0 116 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12.4537 5.88784H29.6473L29.8921 4.79559C30.2499 3.10071 28.9693 1.5 27.2368 1.5H10.5328C8.36717 1.5 6.5028 3.00655 6.032 5.11573L2.37861 21.9891C2.0208 23.684 3.30137 25.2847 5.03391 25.2847H6.05084L9.77956 8.03468C10.062 6.79177 11.1731 5.88784 12.4537 5.88784Z"
            fill="#00CDFA"
        />
        <path
            d="M30.6642 5.88867H29.6473L25.9186 23.1387C25.6549 24.3816 24.5438 25.2856 23.2633 25.2856H6.06971L5.8249 26.3778C5.46709 28.0727 6.74766 29.6734 8.4802 29.6734H25.1841C27.3498 29.6734 29.2142 28.1668 29.685 26.0577L33.3384 9.20309C33.6962 7.48939 32.3968 5.88867 30.6642 5.88867Z"
            fill="url(#paint0_linear_13995_81390)"
        />
        <path
            d="M25.9182 23.1387L29.6469 5.88867H12.4534C11.1728 5.88867 10.0617 6.77377 9.79806 8.03551L6.06934 25.2856H23.2629C24.5435 25.2856 25.6357 24.3816 25.9182 23.1387Z"
            fill="#3A00FF"
        />
        <path
            d="M11.6632 18.7029L10.4189 12.2344H12.3701L12.971 16.271L14.9434 12.2344H16.2583L17.0289 16.271L18.8386 12.2344H20.7898L17.6298 18.7029H15.9967L15.2191 14.9137L13.3174 18.7029H11.6632Z"
            fill="white"
        />
        <path
            d="M20.1045 17.6563L21.1225 16.4757C21.582 17.0412 22.1617 17.3311 22.8757 17.3311C23.3352 17.3311 23.7382 17.1402 23.8018 16.8221C23.823 16.6312 23.6958 16.4686 23.3988 16.3272C23.3282 16.2919 23.1726 16.2353 22.9252 16.1505C22.4374 15.9596 22.2253 15.8889 21.8012 15.6203C21.2073 15.2527 20.9245 14.7437 21.0235 13.8671C21.2144 12.6723 22.3102 12.0078 23.6958 12.0078C24.6431 12.0078 25.4207 12.3189 26.0499 12.9339L25.046 14.1074C24.6289 13.7186 24.1482 13.5277 23.6039 13.5277C23.1656 13.5277 22.904 13.655 22.8474 13.9307C22.7979 14.1569 22.9181 14.3478 23.2009 14.475C23.2928 14.5245 23.4837 14.6023 23.7665 14.7083C24.0492 14.8144 24.2896 14.9063 24.4875 15.0194C25.1238 15.288 25.8731 15.9172 25.6469 16.8786C25.5409 17.5078 25.2228 18.0027 24.6855 18.3562C24.1553 18.7096 23.512 18.8864 22.7909 18.8864C21.6315 18.8793 20.7337 18.4763 20.1045 17.6563Z"
            fill="white"
        />
        <path
            d="M43.8033 21.3156H41.9762L38.3975 10.5039H40.7708L42.9557 17.7745L45.4044 10.5039H46.7794L49.2845 17.7745L51.4318 10.5039H53.8051L50.2451 21.3156H48.4181L46.1201 14.2334L43.8033 21.3156Z"
            fill="black"
        />
        <path
            d="M55.8385 21.3153H53.7666V13.3478H55.7632V14.4215C56.2341 13.555 56.9687 13.1406 57.9481 13.1406C58.2683 13.1406 58.5885 13.1971 58.9088 13.3101L58.7392 15.2879C58.3814 15.1749 58.08 15.1184 57.7974 15.1184C57.1947 15.1184 56.7238 15.3256 56.3659 15.7211C56.0269 16.1167 55.8385 16.7194 55.8385 17.5293V21.3153Z"
            fill="black"
        />
        <path
            d="M61.6978 11.1826C61.6978 11.5405 61.5848 11.8419 61.3399 12.0867C61.0951 12.3316 60.7937 12.4446 60.4358 12.4446C60.0779 12.4446 59.7766 12.3316 59.5317 12.0867C59.2868 11.8419 59.1738 11.5405 59.1738 11.1826C59.1738 10.8247 59.2868 10.5234 59.5317 10.2973C59.7766 10.0525 60.0779 9.93945 60.4358 9.93945C60.7937 9.93945 61.0951 10.0525 61.3399 10.2973C61.5848 10.5234 61.6978 10.8247 61.6978 11.1826ZM61.453 13.3487V21.3162H59.381V13.3487H61.453Z"
            fill="black"
        />
        <path
            d="M67.8571 19.2248L68.4786 20.8447C67.8194 21.2779 67.0283 21.504 66.1242 21.504C65.2012 21.504 64.5231 21.2403 64.0522 20.7129C63.5814 20.1855 63.3553 19.3944 63.3553 18.3584V15.0622H62.0557V13.3293H63.3742V10.5039H65.4273V13.3293H68.0643V15.0622H65.4273V18.2266C65.4273 19.1683 65.804 19.6392 66.5574 19.6392C66.9341 19.6392 67.3862 19.5074 67.8571 19.2248Z"
            fill="black"
        />
        <path
            d="M76.4833 17.1526C76.4833 17.3975 76.4645 17.6989 76.408 18.0567H70.4182C70.7196 19.1869 71.4918 19.752 72.735 19.752C73.6579 19.752 74.5244 19.4883 75.3155 18.942L76.1254 20.3924C75.1083 21.127 73.9593 21.4848 72.6408 21.4848C71.247 21.4848 70.1733 21.0893 69.4199 20.2794C68.6853 19.4883 68.3086 18.49 68.3086 17.3222C68.3086 16.0978 68.6853 15.0995 69.4576 14.3273C70.2298 13.5362 71.2281 13.1406 72.4525 13.1406C73.6203 13.1406 74.5621 13.4985 75.3155 14.2143C76.1066 14.9489 76.4833 15.9283 76.4833 17.1526ZM72.4901 14.8547C71.9816 14.8547 71.5295 15.0054 71.1716 15.3067C70.8137 15.6081 70.5689 16.0037 70.437 16.5311H74.4867C74.3925 16.0225 74.1665 15.6081 73.8086 15.3067C73.4507 15.0054 73.0175 14.8547 72.4901 14.8547Z"
            fill="black"
        />
        <path
            d="M79.9496 21.5229C78.5557 21.5229 77.4633 21.0144 76.6533 19.9784L77.7458 18.6788C78.0283 19.0178 78.3674 19.2815 78.7629 19.4887C79.1585 19.6959 79.554 19.7901 79.9496 19.7901C80.2698 19.7901 80.5146 19.7147 80.7407 19.564C80.9479 19.4133 81.0609 19.225 81.0609 18.999C81.0609 18.7164 80.8725 18.4904 80.4958 18.3209C80.3828 18.2644 80.0626 18.1514 79.554 17.963C79.102 17.7935 78.7629 17.6616 78.5369 17.5298C77.6139 17.0966 77.1619 16.3996 77.1619 15.4578C77.1619 14.7609 77.4256 14.1959 77.9718 13.725C78.4992 13.2729 79.2338 13.0469 80.1379 13.0469C81.2681 13.0469 82.2099 13.4236 82.9256 14.1959L81.9085 15.5332C81.3434 15.0058 80.7407 14.7421 80.0814 14.7421C79.8177 14.7421 79.5917 14.7986 79.4222 14.9304C79.2526 15.0623 79.1585 15.213 79.1585 15.4013C79.1585 15.7404 79.3845 15.9852 79.8554 16.1359C80.8914 16.5315 81.5318 16.7763 81.7766 16.9082C82.6807 17.3791 83.114 18.0572 83.114 18.9236C83.114 19.6771 82.8126 20.2798 82.2287 20.7695C81.6636 21.2781 80.9102 21.5229 79.9496 21.5229Z"
            fill="black"
        />
        <path
            d="M83.4893 17.3234C83.4893 16.1368 83.8848 15.1573 84.6947 14.3474C85.5047 13.5374 86.5218 13.123 87.765 13.123C89.0081 13.123 90.0253 13.5374 90.8352 14.3474C91.6263 15.1385 92.0407 16.1368 92.0407 17.3234C92.0407 18.5101 91.6451 19.5084 90.8352 20.2995C90.0253 21.1094 89.0081 21.5049 87.765 21.5049C86.5218 21.5049 85.5047 21.1094 84.6947 20.2995C83.8848 19.5084 83.4893 18.5101 83.4893 17.3234ZM86.1828 18.9433C86.5971 19.3765 87.1246 19.6025 87.765 19.6025C88.3865 19.6025 88.8951 19.3765 89.3283 18.9433C89.7616 18.4912 89.9687 17.945 89.9687 17.3234C89.9687 16.7018 89.7616 16.1556 89.3283 15.7224C88.8951 15.2892 88.3865 15.0631 87.765 15.0631C87.1246 15.0631 86.5971 15.2892 86.1828 15.7224C85.7684 16.1556 85.5612 16.683 85.5612 17.3234C85.5612 17.9638 85.7684 18.5101 86.1828 18.9433Z"
            fill="black"
        />
        <path
            d="M94.9606 21.3153H92.8887V13.3478H94.9041V14.4591C95.1113 14.0636 95.4503 13.7434 95.9024 13.4985C96.3544 13.2536 96.8442 13.1406 97.4092 13.1406C98.2757 13.1406 99.0103 13.4232 99.613 14.0071C100.216 14.591 100.517 15.4009 100.517 16.4557V21.3342H98.4452V17.0208C98.4452 16.3992 98.2945 15.9095 97.9932 15.5704C97.6918 15.2314 97.2774 15.043 96.7688 15.043C96.2226 15.043 95.7894 15.2314 95.4503 15.6081C95.1113 15.9848 94.9418 16.4745 94.9418 17.0585V21.3153H94.9606Z"
            fill="black"
        />
        <path
            d="M104.058 11.1826C104.058 11.5405 103.945 11.8419 103.7 12.0867C103.455 12.3316 103.154 12.4446 102.796 12.4446C102.438 12.4446 102.137 12.3316 101.892 12.0867C101.647 11.8419 101.534 11.5405 101.534 11.1826C101.534 10.8247 101.647 10.5234 101.892 10.2973C102.137 10.0525 102.438 9.93945 102.796 9.93945C103.154 9.93945 103.455 10.0525 103.7 10.2973C103.945 10.5234 104.058 10.8247 104.058 11.1826ZM103.813 13.3487V21.3162H101.741V13.3487H103.813Z"
            fill="black"
        />
        <path
            d="M108.881 21.5044C107.656 21.5044 106.639 21.1088 105.829 20.3366C105.038 19.5643 104.643 18.566 104.643 17.3417C104.643 16.1174 105.038 15.1191 105.848 14.3468C106.658 13.5557 107.694 13.1602 108.937 13.1602C109.747 13.1602 110.5 13.3485 111.16 13.7441C111.819 14.1396 112.328 14.6858 112.648 15.3828L110.99 16.2869C110.5 15.477 109.822 15.0814 108.956 15.0814C108.297 15.0814 107.75 15.3074 107.336 15.7406C106.903 16.1739 106.696 16.7013 106.696 17.3417C106.696 18.0009 106.903 18.5472 107.317 18.9804C107.75 19.4136 108.278 19.6208 108.918 19.6208C109.333 19.6208 109.728 19.5078 110.124 19.2818C110.5 19.0557 110.783 18.7732 110.953 18.4153L112.629 19.3948C112.29 20.0352 111.781 20.5438 111.122 20.9393C110.444 21.2972 109.691 21.5044 108.881 21.5044Z"
            fill="black"
        />
        <defs>
            <linearGradient
                id="paint0_linear_13995_81390"
                x1="5.76428"
                y1="17.7837"
                x2="33.391"
                y2="17.7837"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#FFB3FF" />
                <stop offset="0.5335" stop-color="#FF76DF" />
                <stop offset="1" stop-color="#C239C6" />
            </linearGradient>
        </defs>
    </svg>
</template>
