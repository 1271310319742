<template>
    <oTable
        class="impact-statistics-time-section-table"
        :headers="timeTableHeaders"
        :items="timeTableRows"
        :per-page="6"
        :borderRadius="24"
        order-by="totalTimeSaved"
        order="DESC"
    >
        <!-- Account Name -->
        <template v-slot:header.account>
            <div class="table-search-container">
                <Label class="table-search-label">Account Name</Label>
                <Spacer width="1rem" />
                <oInput
                    ref="searchInput"
                    type="text"
                    class="account-search-input"
                    name="accountSearchInputCost"
                    autocomplete="off"
                    v-model="searchedAccount"
                />
            </div>
        </template>
        <template v-slot:column.account="cell">
            <ImpactStatsAccountNameCell
                :domainName="cell.value.domainName"
                :domainColor="cell.value.color"
                :platform="cell.value.platform"
                :initials="cell.value.initials"
                :validating="validating"
            />
        </template>
        <!-- Other cells formatting -->
        <template v-slot:column.totalTimeSaved="cell">
            <div>{{ formatFromMinutes(cell.value, true) }}</div>
        </template>
        <template v-slot:column.improvements="cell">
            <ImpactStatsTimeSavingsTableCell
                :count="cell.value"
                :timeSaved="cell.row.improvementsMinutesSaved"
            />
        </template>
        <template v-slot:column.reports="cell">
            <ImpactStatsTimeSavingsTableCell
                :count="cell.value"
                :timeSaved="cell.row.reportsMinutesSaved"
            />
        </template>
        <template v-slot:column.toolActions="cell">
            <ImpactStatsTimeSavingsTableCell
                :count="cell.value"
                :timeSaved="cell.row.toolActionsMinutesSaved"
            />
        </template>
    </oTable>
</template>
<script setup lang="ts">
// vue
import { PropType, computed, ref } from 'vue'
// types
import { IS } from '@opteo/types'
import { AccountData } from '@/composition/impactStatistics/useImpactStatistics'
// local components
import ImpactStatsTimeSavingsTableCell from './ImpactStatsTimeSavingsTableCell.vue'
import ImpactStatsAccountNameCell from '@/components/impactStatistics/ImpactStatsAccountNameCell.vue'
// components-next
import { oTable, Label, Spacer, oInput } from '@opteo/components-next'
// composition
import useImpactStatsTimeSavings from '@/composition/impactStatistics/useImpactStatsTimeSavings'
// lodash
import includes from 'lodash-es/includes'

const { formatFromMinutes } = useImpactStatsTimeSavings()

const props = defineProps({
    data: {
        type: Array as PropType<IS.AccountTimeTableOverview[]>,
        required: true,
    },
    validating: {
        type: Boolean,
        default: false,
    },
})

const searchedAccount = ref('')

// Time section
const timeTableHeaders = [
    { key: 'account', text: 'Account Name', noPadding: true },
    { key: 'totalTimeSaved', text: 'Time Saved', sortable: true },
    { key: 'improvements', text: 'Improvements', sortable: true },
    { key: 'reports', text: 'Reports Created', sortable: true },
    { key: 'toolActions', text: 'Tool Actions', sortable: true },
]
const timeTableRows = computed(() => {
    if (!searchedAccount.value) return props.data
    return props.data.filter(item =>
        includes(item.account.domainName.toLowerCase(), searchedAccount.value.toLowerCase())
    )
})
</script>

<!-- Scoped -->
<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

@media screen and (max-width: 1279px) {
    .account-search-input {
        display: none;
    }
}

@media screen and (min-width: 1280px) {
    .account-search-input {
        max-width: 12rem;
        @include relative;
    }
    .account-search-input :deep(input) {
        padding: 10px 14px 10px 38px;
    }
    .account-search-input::before {
        content: '';
        position: absolute;
        left: 14px;
        top: 0;
        bottom: 0;
        width: 12px;
        background: url('@/assets/img/searchIcon.svg') center / contain no-repeat;
    }
}
</style>

<!-- Unscoped -->
<style lang="scss">
@media screen and (max-width: 1279px) {
    .impact-statistics-time-section-table td:not(:first-child),
    .impact-statistics-time-section-table th:not(:first-child) {
        padding-left: 1.125rem;
        padding-right: 1.125rem;
    }
    .impact-statistics-time-section-table td:last-child,
    .impact-statistics-time-section-table th:last-child {
        padding-right: 1.75rem;
    }
    .impact-statistics-time-section-table .impact-stats-table-domain-name {
        max-width: 12rem;
    }
}

@media screen and (min-width: 1280px) {
    .impact-statistics-time-section-table td:last-child,
    .impact-statistics-time-section-table th:last-child {
        padding-right: 2.125rem;
    }
}
</style>
