import round from 'lodash/round'
import { computed, ComputedRef, inject, ref } from 'vue'
import { BarChartTypes, DonutChartTypes, useRoas } from '@opteo/components-next'
import { DisableInterestLocationsV2, Improvement, Targets } from '@opteo/types'

import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import {
    checkImprovement,
    OnPushHandler,
    useImprovement,
} from '@/composition/improvement/useImprovement'
import { formatEntityPillLink, fromMicros } from '@/lib/globalUtils'

type ChartMetric = { label: string; dataType: string; currency?: string }
type ChartMarker = { axis: string; label: string; value: number; labelPosition: string }
type TableHeader = { key: string; text: string; color?: 'green' | 'red' }

type TableItem = {
    id: string
    metric: string
    presence: string | number
    interest: string | number
}

function calculateCpa({ cost, conversions }: { cost: number; conversions: number }) {
    return conversions ? cost / conversions : 0
}

function calculateRoas({
    cost,
    conversionsValue,
}: {
    cost: number
    conversionsValue: number
}): number {
    return cost ? conversionsValue / cost : 0
}

export function useDisableInterestLocationsV2() {
    const { improvement, lastUpdated, title } = useImprovement<DisableInterestLocationsV2.Body>()

    const {
        body: {
            campaigns,
            bidding_strategy: { campaign_group_name: campaignGroupName },
            lookback_window: lookbackWindow,
            performance_mode: performanceMode,
        },
    } = checkImprovement(improvement)

    const isCpaMode = !performanceMode || performanceMode === Targets.PerformanceMode.CPA

    const domainCurrencyCode =
        inject<ComputedRef<string | undefined>>('domainCurrencyCode')?.value ?? 'USD'

    const multipleCampaigns = campaigns.length > 1

    const entityPillLink = multipleCampaigns
        ? formatEntityPillLink({
              entityPillData: {
                  type: 'campaign-group',
                  content: campaignGroupName,
              },
              deepLinkParams: null,
          })
        : formatEntityPillLink({
              entityPillData: {
                  type: 'campaign',
                  content: campaigns[0].campaign.name,
              },
              deepLinkParams: {
                  campaignId: campaigns[0].campaign.id,
              },
          })

    const entityPillLinkList = campaigns.map(({ campaign }) =>
        formatEntityPillLink({
            entityPillData: {
                type: 'campaign',
                content: campaign.name,
            },
            deepLinkParams: {
                campaignId: campaign.id,
            },
        })
    )

    // METRICS

    const metrics = campaigns.reduce(
        (accumulator, campaign) => {
            accumulator.interest.cost += campaign.metrics.interest.cost_micros
            accumulator.presence.cost += campaign.metrics.presence.cost_micros
            accumulator.total.cost += campaign.metrics.total.cost_micros

            if (isCpaMode) {
                accumulator.interest.conversions += campaign.metrics.interest.all_conversions
                accumulator.presence.conversions += campaign.metrics.presence.all_conversions
                accumulator.total.conversions += campaign.metrics.total.all_conversions
            } else {
                accumulator.interest.conversionsValue +=
                    campaign.metrics.interest.all_conversions_value
                accumulator.presence.conversionsValue +=
                    campaign.metrics.presence.all_conversions_value
                accumulator.total.conversionsValue += campaign.metrics.total.all_conversions_value
            }

            return accumulator
        },
        {
            interest: {
                cost: 0,
                conversions: 0,
                conversionsValue: 0,
                cpa: 0,
                roas: 0,
            },
            presence: {
                cost: 0,
                conversions: 0,
                conversionsValue: 0,
                cpa: 0,
                roas: 0,
            },
            total: {
                cost: 0,
                conversions: 0,
                conversionsValue: 0,
                cpa: 0,
                roas: 0,
            },
        }
    )

    metrics.interest.cost = fromMicros(metrics.interest.cost)
    metrics.presence.cost = fromMicros(metrics.presence.cost)
    metrics.total.cost = fromMicros(metrics.total.cost)

    if (isCpaMode) {
        metrics.interest.cpa = calculateCpa(metrics.interest)
        metrics.presence.cpa = calculateCpa(metrics.presence)
        metrics.total.cpa = calculateCpa(metrics.total)

        metrics.interest.conversions = Math.round(metrics.interest.conversions)
        metrics.presence.conversions = Math.round(metrics.presence.conversions)
    } else {
        metrics.interest.roas = calculateRoas(metrics.interest)
        metrics.presence.roas = calculateRoas(metrics.presence)
        metrics.total.roas = calculateRoas(metrics.total)
    }

    const costSavingPercent =
        metrics.interest.cost / (metrics.interest.cost + metrics.presence.cost)

    const performanceModeString = isCpaMode ? 'cpa' : 'roas'

    const performanceDifference = isCpaMode
        ? metrics.total.cpa - metrics.presence.cpa
        : metrics.presence.roas - metrics.total.roas
    const performanceDifferencePercent =
        performanceDifference / (isCpaMode ? metrics.total.cpa : metrics.total.roas)

    const conversionsForLocationsOfInterest = isCpaMode && metrics.interest.conversions > 0
    const conversionsValueForLocationsOfInterest =
        !isCpaMode && metrics.interest.conversionsValue > 0

    const interestCost = metrics.interest.cost

    // CPA | ROAS CHART (bar chart)
    const chartItems: BarChartTypes.BarChartItem[] = [
        {
            y: metrics.presence[performanceModeString],
            label: 'Location of Presence',
        },
        {
            y: metrics.interest[performanceModeString],
            label: 'Location of Interest',
            highlighted: true,
        },
    ]
    const chartMetric: ChartMetric = {
        label: performanceModeString.toUpperCase(),
        dataType: isCpaMode ? 'money' : 'roas',
        currency: domainCurrencyCode,
    }
    const chartMarkers: ChartMarker[] = [
        {
            axis: 'y',
            label: `Average ${performanceModeString.toUpperCase()}`,
            value: metrics.total[performanceModeString],
            labelPosition: 'end',
        },
    ]

    // COST CHART (donut chart)
    const costChartItems: DonutChartTypes.DonutChartItem[] = [
        {
            y: metrics.interest.cost / metrics.total.cost,
            label: 'Location of Interest',
            highlighted: true,
        },
        { y: metrics.presence.cost / metrics.total.cost, label: 'Location of Presence' },
    ]
    const costChartMetric: ChartMetric = { label: 'Cost (%)', dataType: 'percentage' }

    const formattedInterestCpa = useDomainMoney({ value: metrics.interest.cpa }).value.displayValue
        .value
    const formattedInterestRoas = useRoas({ value: metrics.interest.roas }).displayValue.value
    const formattedInterestCost = useDomainMoney({ value: metrics.interest.cost }).value
        .displayValue.value

    // TABLE
    const tableHeaders: TableHeader[] = [
        { key: 'metric', text: 'Metric' },
        { key: 'presence', text: 'Location Of Presence', color: 'green' },
        { key: 'interest', text: 'Location Of Interest', color: 'red' },
    ]

    const costTableItem: TableItem = {
        id: 'cost',
        metric: 'Cost',
        presence: useDomainMoney({ value: metrics.presence.cost }).value.displayValue.value,
        interest: useDomainMoney({ value: metrics.interest.cost }).value.displayValue.value,
    }

    const tableItems: TableItem[] = isCpaMode
        ? [
              costTableItem,
              {
                  id: 'conversions',
                  metric: 'Conversions',
                  presence: metrics.presence.conversions,
                  interest: metrics.interest.conversions,
              },
              {
                  id: 'cpa',
                  metric: 'CPA',
                  presence: useDomainMoney({ value: metrics.presence.cpa }).value.displayValue
                      .value,
                  interest: useDomainMoney({
                      value: metrics.interest.cost / metrics.interest.conversions,
                  }).value.displayValue.value,
              },
              {
                  id: 'total_cpa',
                  metric: 'Average CPA',
                  presence: useDomainMoney({ value: metrics.total.cpa }).value.displayValue.value,
                  interest: useDomainMoney({ value: metrics.total.cpa }).value.displayValue.value,
              },
              {
                  id: 'vs_average',
                  metric: 'CPA vs. Average',
                  presence: (metrics.presence.cpa - metrics.total.cpa) / metrics.total.cpa,
                  interest:
                      (metrics.interest.cost / metrics.interest.conversions - metrics.total.cpa) /
                      metrics.total.cpa,
              },
          ]
        : [
              costTableItem,
              {
                  id: 'conversionsValue',
                  metric: 'Conversion Value',
                  presence: metrics.presence.conversionsValue,
                  interest: metrics.interest.conversionsValue,
              },
              {
                  id: 'roas',
                  metric: 'ROAS',
                  presence: useRoas({ value: metrics.presence.roas }).displayValue.value,
                  interest: useRoas({
                      value: metrics.interest.conversionsValue / metrics.interest.cost,
                  }).displayValue.value,
              },
              {
                  id: 'total_roas',
                  metric: 'Average ROAS',
                  presence: useRoas({ value: metrics.total.roas }).displayValue.value,
                  interest: useRoas({ value: metrics.total.roas }).displayValue.value,
              },
              {
                  id: 'vs_average',
                  metric: 'ROAS vs. Average',
                  presence: (metrics.presence.roas - metrics.total.roas) / metrics.total.roas,
                  interest:
                      (metrics.interest.conversionsValue / metrics.interest.cost -
                          metrics.total.roas) /
                      metrics.total.roas,
              },
          ]

    const monthsInWindow =
        lookbackWindow === Improvement.LookbackWindow.ONE_YEAR ? 12 : lookbackWindow / 30

    const conversionLoss = round(metrics.interest.conversions / monthsInWindow, 1)
    const conversionValueLoss = round(metrics.interest.conversionsValue / monthsInWindow, 1)
    const costSavings = metrics.interest.cost / monthsInWindow

    const performanceBefore = metrics.total[performanceModeString]
    const performanceAfter = metrics.presence[performanceModeString]

    const pushActionText = ref('Disable Locations of Interest')
    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Disabling locations of interest..',
        'Confirming changes..',
        'Locations of interest disabled successfully.',
    ])

    const onPush: OnPushHandler = () => {
        return { valid: true }
    }

    return {
        title,
        pushMessages,
        onPush,
        lastUpdated,
        entityPillLink,
        entityPillLinkList,
        lookbackWindow,
        multipleCampaigns,
        domainCurrencyCode,
        isCpaMode,
        costSavingPercent,
        performanceDifferencePercent,
        conversionsForLocationsOfInterest,
        conversionsValueForLocationsOfInterest,
        interestCost,
        chartItems,
        costChartItems,
        costChartMetric,
        chartMetric,
        chartMarkers,
        tableHeaders,
        tableItems,
        conversionLoss,
        conversionValueLoss,
        costSavings,
        performanceBefore,
        performanceAfter,
        formattedInterestCpa,
        formattedInterestRoas,
        formattedInterestCost,
        pushActionText,
    }
}
