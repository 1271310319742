import { computed, ref, watch } from 'vue'
import { API_URL } from '@/lib/env'
import {
    OnPushHandler,
    useImprovement,
    checkImprovement,
} from '@/composition/improvement/useImprovement'
import { useDomainMoney } from '@/composition/domain/useDomainMoney'

import { Entity, ExcludePlacement, Improvement } from '@opteo/types'
import { Endpoint, useAPI } from '@/composition/api/useAPI'
import { useDomain } from '@/composition/domain/useDomain'

const defaultSharedSetMarker = 'url_placement'
const defaultSharedSetName = 'Shared url placement exclusion list (url_placement)'
const bogusNewSharedSetId = 123

interface SharedSet {
    shared_set_id: number
    shared_set_name: string
    shared_set_resource_name: string
}

export function useExcludePlacement() {
    const { domainId } = useDomain()
    const { improvement, lastUpdated, title } = useImprovement<ExcludePlacement.Body>()
    const { body } = checkImprovement(improvement)

    const { data: sharedSets } = useAPI<SharedSet[]>(Endpoint.GetSharedNegativeLists, {
        body: {
            type: 'placements',
        },
        // Make sure swrv cache considers the type. Without this uniqueId, SWRV will serve
        // keywords instead of placements if GetSharedNegativeLists was called with the
        // same domain ID for type=keywords in that same session.
        uniqueId: () => `placements|${domainId.value}`,
        waitFor: () => domainId.value,
    })

    const entityPillList = [{ type: Entity.EntityLocation.Placement, content: body.placement }]

    const sharedSetDropDownItems = computed(() => {
        if (!sharedSets.value) {
            return []
        }
        const opteoSharedSetExists = !!sharedSets.value.find(ss =>
            ss.shared_set_name.includes(defaultSharedSetMarker)
        )

        const items = sharedSets.value.map(ss => {
            return {
                label: ss.shared_set_name,
                value: ss.shared_set_id,
            }
        })

        if (!opteoSharedSetExists) {
            items.unshift({ label: defaultSharedSetName, value: bogusNewSharedSetId })
        }

        return items
    })

    const selectedSharedSet = ref<number>()

    // when the shared sets load in, use the first one that matches the default shared set name
    watch(sharedSets, newVal => {
        const opteoSharedSet = newVal?.find(ss =>
            ss.shared_set_name.includes(defaultSharedSetMarker)
        )
        if (opteoSharedSet) {
            selectedSharedSet.value = opteoSharedSet.shared_set_id
        } else {
            selectedSharedSet.value = bogusNewSharedSetId
        }
    })

    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Adding placement to exclusion list..',
        'Confirming changes..',
        'Placement excluded successfully.',
    ])

    const campaignPillList = body.campaigns.map(content => {
        return { type: Entity.EntityLocation.Campaign, content }
    })

    const website = body.placement.split('::')[0]

    const placementPreviewUrl = `${API_URL}/render/website?website=${website}`

    const stats = computed(() => {
        return [
            {
                key: 'cost',
                value: useDomainMoney({
                    value: body.cost,
                }).value.displayValue.value,
                title: 'Cost',
            },
            // {
            //     key: 'conversions',
            //     value: body.conversions,
            //     title: 'Conversions',
            // },
            {
                key: 'placementCpa',
                value: useDomainMoney({
                    value: body.cpa,
                }).value.displayValue.value,
                title: 'Placement CPA',
            },
            {
                key: 'campaignGroupCpa',
                value: useDomainMoney({
                    value: body.cpa_avg,
                }).value.displayValue.value,
                title: 'Campaign Group CPA',
            },
        ]
    })

    const onPush: OnPushHandler<ExcludePlacement.ExtraDetails> = () => {
        if (!selectedSharedSet.value || selectedSharedSet.value === bogusNewSharedSetId) {
            return {
                valid: true,
            }
        }

        return {
            valid: true,
            pushedData: {
                shared_set_id: selectedSharedSet.value,
            },
        }
    }

    const pushActionText = ref('Exclude Placement')

    return {
        title,
        pushMessages,
        onPush,
        lastUpdated,
        entityPillList,
        body,
        campaignPillList,
        placementPreviewUrl,
        stats,
        defaultSharedSetName,
        sharedSetDropDownItems,
        selectedSharedSet,
        pushActionText,
    }
}
