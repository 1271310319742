import { inject, ComputedRef, computed, ref } from 'vue'
import sumBy from 'lodash/sumBy'
import round from 'lodash/round'
import { Improvement, DisableSearchPartners, Gads, Targets, Entity } from '@opteo/types'
import { useImprovement, checkImprovement } from '../useImprovement'
import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import { CurrencyCode } from '@/composition/utils/types'
import { ProvideKeys } from '@/composition/useProvide'
import { fromMicros } from '@/lib/globalUtils'
import { EntityPill, useNumber, useRoas } from '@opteo/components-next'
import { EntityPillLinkProps, ValidEntity } from '@/components/global/Entity/types'

/**
 * To note:
 * - changed copy to note average vs target CPA
 * - changed bar chart horizontal line label to make it compatible with average vs target
 * - changed table campaign group label to be compatible with average vs target
 */

// TODO: move type to components-next
type ChartItem = { y: number; label: string; highlighted?: true }
type ChartMetric = { label: string; dataType: string; currency?: string }
type ChartMarker = { axis: string; label: string; value: number; labelPosition: string }
type TableHeader = { key: string; text: string; color?: 'green' | 'red' }

type TableItem = {
    id: string
    metric: string
    search: string | number
    partners: string | number
}

export function useDisableSearchPartners() {
    const { improvement, lastUpdated, title } = useImprovement<
        DisableSearchPartners.ImpTypes.Body,
        typeof Improvement.RecAction.DisableSearchPartners
    >()
    const { body } = checkImprovement(improvement)
    const domainCurrencyCode = inject<ComputedRef<CurrencyCode>>(ProvideKeys.CurrencyCode)

    const isUsingROAS = body.performance_mode === Targets.PerformanceMode.ROAS

    const { single, lookback_window: lookbackWindow, campaigns, group } = body

    const accountId = improvement.value?.account_id

    const entity = single ? Entity.EntityLocation.Campaign : Entity.EntityLocation.CampaignGroup

    const entityPillList = campaigns.map(({ campaign }) => ({
        entityPillData: {
            type: Entity.EntityLocation.Campaign,
            content: campaign.name,
        },
        deepLinkParams: { campaignId: campaign.id },
    }))

    // If campaign, pass campaignId. If campaign group, pass "null"
    const entityParams = single ? entityPillList[0].deepLinkParams : null

    const entityName = single
        ? entityPillList[0].entityPillData.content
        : body.group.campaign_group_name

    const google_metrics = campaigns.map(c => c.metrics[Gads.enums.AdNetworkType.SEARCH])
    const partners_metrics = campaigns.map(c => c.metrics[Gads.enums.AdNetworkType.SEARCH_PARTNERS])

    const googleCost = fromMicros(sumBy(google_metrics, c => c.cost_micros))
    const partnerCost = fromMicros(sumBy(partners_metrics, c => (c ?? {}).cost_micros ?? 0))

    const googleConversions = sumBy(google_metrics, c => c.all_conversions)
    const partnerConversions = sumBy(partners_metrics, c => (c ?? {}).all_conversions ?? 0)

    const googleConversionsValue = sumBy(google_metrics, c => c.all_conversions_value)
    const partnerConversionsValue = sumBy(
        partners_metrics,
        c => (c ?? {}).all_conversions_value ?? 0
    )

    const googleCostPercent = googleCost / (googleCost + partnerCost)
    const partnerCostPercent = partnerCost / (googleCost + partnerCost)

    const googleCpa = googleCost / googleConversions
    const partnerCpa = partnerCost / partnerConversions

    const googleRoas = googleConversionsValue / googleCost
    const partnerRoas = partnerConversionsValue / partnerCost

    const targetCpaOrAvg = group.target_cpa_set
        ? group.target_cpa ?? 0
        : (googleCost + partnerCost) / (googleConversions + partnerConversions)

    const targetRoasOrAvg = group.target_roas_set
        ? group.target_roas ?? 0
        : (googleConversionsValue + partnerConversionsValue) / (googleCost + partnerCost)

    const { displayValue: formattedPartnerCost } = useDomainMoney({
        value: partnerCost,
    }).value

    const { displayValue: formattedPartnerCpa } = useDomainMoney({
        value: partnerCpa,
    }).value

    const { displayValue: formattedPartnerRoas } = useRoas({
        value: partnerRoas,
    })

    const showBarChart = partnerConversions > 0

    const barChartItems: ChartItem[] = isUsingROAS
        ? [
              { y: googleRoas, label: 'Google Search' },
              { y: partnerRoas, label: 'Search Partners', highlighted: true },
          ]
        : [
              { y: googleCpa, label: 'Google Search' },
              { y: partnerCpa, label: 'Search Partners', highlighted: true },
          ]
    const barChartMetric: ChartMetric = isUsingROAS
        ? {
              label: 'ROAS',
              dataType: 'roas',
              currency: domainCurrencyCode?.value ?? 'USD',
          }
        : {
              label: 'CPA',
              dataType: 'money',
              currency: domainCurrencyCode?.value ?? 'USD',
          }

    // consider adding full name in tooltip
    const barChartMarkers: ChartMarker[] = isUsingROAS
        ? [
              {
                  axis: 'y',
                  label: `${group.target_roas_set ? 'Target' : 'Average'} ROAS`,
                  value: targetRoasOrAvg,
                  labelPosition: 'end',
              },
          ]
        : [
              {
                  axis: 'y',
                  label: `${group.target_cpa_set ? 'Target' : 'Average'} CPA`,
                  value: targetCpaOrAvg,
                  labelPosition: 'end',
              },
          ]

    const donutChartItems: ChartItem[] = [
        { y: googleCostPercent, label: 'Google Search' },
        { y: partnerCostPercent, label: 'Search Partners', highlighted: true },
    ]
    const donutChartMetric: ChartMetric = { label: 'Cost (%)', dataType: 'percentage' }

    const tableHeaders: TableHeader[] = [
        { key: 'metric', text: 'Metric' },
        { key: 'search', text: 'Google Search', color: 'green' },
        { key: 'partners', text: 'Search Partners', color: 'red' },
    ]
    const tableItems: TableItem[] = [
        {
            id: 'cost',
            metric: 'Cost',
            search: useDomainMoney({ value: googleCost }).value.displayValue.value,
            partners: useDomainMoney({ value: partnerCost }).value.displayValue.value,
        },
    ]

    if (isUsingROAS) {
        tableItems.push(
            {
                id: 'conversionsValue',
                metric: 'Conversion Value',
                search: useDomainMoney({ value: googleConversionsValue }).value.displayValue.value,
                partners: useDomainMoney({ value: partnerConversionsValue }).value.displayValue
                    .value,
            },
            {
                id: 'roas',
                metric: 'ROAS',
                search: useRoas({ value: googleRoas }).displayValue.value,
                partners: useRoas({ value: partnerRoas }).displayValue.value,
            },
            {
                id: 'targetRoasOrAvg',
                metric: group.target_roas_set ? `Target ROAS` : `Average ROAS`,
                search: useRoas({ value: targetRoasOrAvg }).displayValue.value,
                partners: useRoas({ value: targetRoasOrAvg }).displayValue.value,
            },
            {
                id: 'vsAverage',
                metric: group.target_roas_set ? `ROAS vs. Target` : `ROAS vs. Average`,
                search: (googleRoas - targetRoasOrAvg) / targetRoasOrAvg,
                partners: (partnerRoas - targetRoasOrAvg) / targetRoasOrAvg,
            }
        )
    } else {
        tableItems.push(
            {
                id: 'conversions',
                metric: 'Conversions',
                search: useNumber({ value: googleConversions }).displayValue.value,
                partners: useNumber({ value: partnerConversions }).displayValue.value,
            },
            {
                id: 'cpa',
                metric: 'CPA',
                search: useDomainMoney({ value: googleCpa }).value.displayValue.value,
                partners: useDomainMoney({ value: partnerCpa }).value.displayValue.value,
            },
            {
                id: 'targetCpaOrAvg',
                metric: group.target_cpa_set ? `Target CPA` : `Average CPA`,
                search: useDomainMoney({ value: targetCpaOrAvg }).value.displayValue.value,
                partners: useDomainMoney({ value: targetCpaOrAvg }).value.displayValue.value,
            },
            {
                id: 'vsAverage',
                metric: group.target_cpa_set ? `CPA vs. Target` : `CPA vs. Average`,
                search: (googleCpa - targetCpaOrAvg) / targetCpaOrAvg,
                partners: (partnerCpa - targetCpaOrAvg) / targetCpaOrAvg,
            }
        )
    }

    // TO WIRE UP

    const monthsInWindow = lookbackWindow === 365 ? 12 : lookbackWindow / 30

    const conversionLoss = round(partnerConversions / monthsInWindow, 1)
    const conversionValueLoss = partnerConversionsValue / monthsInWindow
    const costSavings = partnerCost / monthsInWindow
    const costSavingPercent = -partnerCost / (partnerCost + googleCost)

    const cpaBefore = (googleCost + partnerCost) / (googleConversions + partnerConversions)
    const roasBefore =
        (googleConversionsValue + partnerConversionsValue) / (googleCost + partnerCost)
    const cpaAfter = googleCpa
    const roasAfter = googleRoas
    const cpaDecreasePercent = cpaAfter / cpaBefore - 1
    const roasIncreasePercent = roasAfter / roasBefore - 1

    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Disabling search partner sites..',
        'Confirming changes..',
        'Search partner sites disabled successfully.',
    ])

    const pushActionText = ref('Disable Search Partner Sites')

    const campaignPerformanceDescription = isUsingROAS
        ? body.group.target_cpa_set
            ? 'a below-target ROAS'
            : 'a below average ROAS'
        : body.group.target_cpa_set
        ? 'an above-target CPA'
        : 'an above average CPA'

    return {
        title,
        accountId,
        single,
        entity,
        entityName,
        entityPillList,
        entityParams,
        domainCurrencyCode,

        partnerCost: formattedPartnerCost.value,
        partnerCpa: formattedPartnerCpa.value,
        partnerRoas: formattedPartnerRoas.value,
        partnerConversions,
        partnerConversionsValue,

        donutChartItems,
        donutChartMetric,
        showBarChart,
        barChartItems,
        barChartMetric,
        barChartMarkers,
        tableHeaders,
        tableItems,

        lastUpdated,
        pushMessages,
        lookbackWindow,

        conversionLoss,
        conversionValueLoss,
        costSavings,
        costSavingPercent,
        cpaDecreasePercent,
        roasIncreasePercent,
        cpaBefore,
        roasBefore,
        cpaAfter,
        roasAfter,
        pushActionText,
        campaignPerformanceDescription,
        isUsingROAS,
        group,
    }
}
