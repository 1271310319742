import { sortCollectionBy } from '.'

import type { Improvement } from '@opteo/types'
import type { EntityPillLinkProps, ValidEntity } from '@/components/global/Entity/types'

/**
 * @description TS can't infer the type of `deepLinkParams` based on the fields of `entityPillData`,
 * so we can use this helper function to have type-safety.
 * @remarks See: https://stackoverflow.com/questions/73896339/why-typescript-cant-infer-the-generic-type-by-its-fields
 * @template T - The type of entity
 * @param params.EntityPillLinkProps
 * @returns A type-safe `EntityPillLinkProps`
 */
export const formatEntityPillLink = <T extends ValidEntity>({
    entityPillData,
    deepLinkParams,
}: EntityPillLinkProps<T>): EntityPillLinkProps<T> => {
    return {
        entityPillData,
        deepLinkParams,
    }
}

/**
 * @description Formats a list of `EntityPillLinkProps` and optionally sorts them by the provided `EntityType` array
 * @template T - The type of entity
 * @param params.entities - The list of entity pill link props to format
 * @param params.sortByEntities - The optional list of entities to sort by
 * @returns The formatted and (optionally) sorted list of entity pill link props
 */
export const formatEntityPillLinkList = <T extends ValidEntity>({
    entities,
    sortByEntities,
}: {
    entities: EntityPillLinkProps<T>[]
    sortByEntities?: T[]
}) => {
    if (sortByEntities) {
        return sortCollectionBy<EntityPillLinkProps<T>>({
            collectionToSort: entities,
            sortingValue: entity => entity.entityPillData.type,
            arrayToSortBy: sortByEntities,
        })
    }

    return entities.map(entity => formatEntityPillLink<T>(entity))
}

export const improvementLocationToEntityPillLink = <T extends ValidEntity>({
    entity,
    label,
    entityIds,
}: Improvement.AccountLocation<T>) => {
    return formatEntityPillLink({
        entityPillData: {
            type: entity,
            content: label,
        },
        deepLinkParams: entityIds,
    })
}
