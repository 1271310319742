import { checkImprovement, useImprovement } from '@/composition/improvement/useImprovement'

import { CheckProductGroups } from '@opteo/types'
import { provide, ref, onMounted } from 'vue'

export enum BiddingStrategy {
    Cpa = 'cpa',
    Roas = 'roas',
    Profit = 'profit',
}

export function useResyncProductGroups() {
    const { improvement, lastUpdated, title } =
        useImprovement<CheckProductGroups.ResyncProductGroup.Body>()

    const {
        product_group_label: productGroupLabel,
        new_product_groups_attribute: suggestedSubdivison,
        lookback_window: lookbackWindow,
        generated_product_group_tree: generatedProductGroupTree,
        unsynced_product_group_depth: unsyncedProductGroupDepth,
        current_product_group_tree: productGroupTree,
        ad_group_criterion_resource_name: resyncedProductGroup,
        new_product_groups: newProductGroups,
    } = checkImprovement(improvement).body

    const unsyncedProductGroups = generatedProductGroupTree.length

    provide('generatedProductGroupTree', generatedProductGroupTree)
    provide('resyncedProductGroup', resyncedProductGroup)
    provide('newProductGroups', newProductGroups.length)

    const hovered = ref(false)
    const activeTable = ref()
    const hoveredProductGroup = ref<CheckProductGroups.ProductGroup>()
    const labelDistance = ref()

    function updateHoveredBoolean(payload: { mouseenter: boolean; current: boolean }) {
        hovered.value = payload.mouseenter
        activeTable.value = payload.current ? 'current' : 'resynced'
    }

    provide('updateHoveredBoolean', updateHoveredBoolean)

    function updateHoveredItem(payload: {
        item: CheckProductGroups.ProductGroup
        distance: number
    }) {
        hoveredProductGroup.value = payload.item
        labelDistance.value = payload.distance
    }
    provide('updateHoveredItem', updateHoveredItem)

    const entityPillList = [
        'All Products',
        ...(improvement.value?.body.product_groups_hierarchy ?? []),
    ].map(group => {
        return {
            type: 'product-group',
            content: group,
        }
    })

    const pushMessages = [
        'Connecting to Google Ads..',
        'Creating new product groups..',
        'Confirming changes..',
        'Product groups synced successfully.',
    ]

    const pushActionText = ref('Resync Product Group')

    const tableContainer = ref<HTMLDivElement>()
    const throttledConnectElements = ref()
    const svg = ref()

    onMounted(() => {
        if (window.innerWidth > 768) {
            throttledConnectElements.value = window.addEventListener('resize', drawSVG, false)
            drawSVG()
        }
    })

    function drawSVG() {
        connectElements('everything-else-bucket', 'new-product-group-container', 'rgba(0,0,0,0.15)')
    }

    function createSVG() {
        const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
        svg.setAttribute('id', 'svg-canvas')
        svg.setAttribute('style', 'position:absolute; top:0px; left:0px; pointer-events:none;')
        svg.setAttribute('width', `${tableContainer.value?.offsetWidth}px`)
        svg.setAttribute('height', `${tableContainer.value?.offsetHeight}px`)
        svg.setAttributeNS(
            'http://www.w3.org/2000/xmlns/',
            'xmlns:xlink',
            'http://www.w3.org/1999/xlink'
        )
        tableContainer.value?.appendChild(svg)
        return svg
    }

    function findPosition(target: Element) {
        if (!target) {
            return
        }
        const container = tableContainer.value?.getBoundingClientRect()
        const { top, right, bottom, left } = target.getBoundingClientRect()

        return {
            top: top - (container?.top ?? 0),
            right: right - (container?.x ?? 0),
            bottom,
            left: left - (container?.x ?? 0),
        }
    }

    function connectElements(bucketClass: string, newProductGroupsClass: string, colour: string) {
        const bucket = document.querySelector(`.${bucketClass}`) as HTMLDivElement
        const newProductGroups = document.querySelector(
            `.${newProductGroupsClass}`
        ) as HTMLDivElement

        if (!bucket || !newProductGroups) return

        const bucketPosition = findPosition(bucket)
        const x1 = bucketPosition?.right ?? 0
        const y1 = (bucketPosition?.top ?? 0) + (bucket?.offsetHeight ?? 0) / 2

        const newProductGroupsPosition = findPosition(newProductGroups)
        const x2 = newProductGroupsPosition?.left ?? 0
        const y2 = (newProductGroupsPosition?.top ?? 0) + bucket?.offsetHeight * 1.5

        drawLine(x1, y1, x2, y2, colour)
    }

    function drawLine(x1: number, y1: number, x2: number, y2: number, colour: string) {
        if (!svg.value) {
            svg.value = createSVG()
        }
        const shape = document.createElementNS('http://www.w3.org/2000/svg', 'path')
        const diff = 8
        const tension = diff / 2
        const width = x2 - x1
        const middlePoint = width / 4

        const path = `M${x1},${y1} L${x1 + middlePoint - diff},${y1} C${
            x1 + middlePoint - diff + tension
        },${y1} ${x1 + middlePoint},${y1 + diff - tension} ${x1 + middlePoint},${y1 + diff}  L${
            x1 + middlePoint
        },${y2 - diff} C${x1 + middlePoint},${y2 - diff + tension} ${
            x1 + middlePoint + diff - tension
        },${y2} ${x1 + middlePoint + diff},${y2}  L${x2} ${y2}`

        shape.setAttributeNS(null, 'd', path)
        shape.setAttributeNS(null, 'fill', 'none')
        shape.setAttributeNS(null, 'stroke', colour)
        shape.setAttributeNS(null, 'stroke-width', '1')
        shape.classList.add('bezier-line')
        svg.value.textContent = ''
        svg.value.appendChild(shape)
    }

    return {
        hovered,
        activeTable,
        labelDistance,
        hoveredProductGroup,
        productGroupLabel,
        title,
        lastUpdated,
        suggestedSubdivison,
        entityPillList,
        pushMessages,
        lookbackWindow,
        unsyncedProductGroups,
        unsyncedProductGroupDepth,
        productGroupTree,
        resyncedProductGroup,
        generatedProductGroupTree,
        tableContainer,
        pushActionText,
    }
}
