import { ref, computed } from 'vue'
import clone from 'lodash-es/clone'
import orderBy from 'lodash-es/orderBy'
import { format } from 'date-fns'
import { useAPI, Endpoint } from '@/composition/api/useAPI'
import { delay } from '@/lib/globalUtils'

import type { Billing } from '@opteo/types'

// TODO(types): Get these types from @opteo/types
export interface StripeCustomerCharges {
    id: string
    total: number
    amount: number
    currency: Billing.LowercaseCurrencyCode
    issue_date: string
    created: number
    refunds: { data: any[]; total_count: number }
    total_count: number
    refunded: number
    state: string
    paid: string
    data: StripeCustomerCharges[]
    stripe_invoice_url?: string
    not_supported: boolean
    receipt_url?: string
    description: string
    plan: { id: string }
    invoice: { lines: { data: StripeCustomerCharges[] }; hosted_invoice_url: string }
}
interface OpteoCustomer {
    cc_up_front?: boolean
    default_payment_method?: any //Stripe.PaymentMethod
    id: string
}

// TODO(types): Move these to @opteo/types
export interface CountryTld {
    country: string
    country_code: string
    international_dialing: string
    tld: string
}
interface InvoiceData {
    company_name: string
    country_code: string
    vat_country: string
    vat_number: string
    invoice_address: InvoiceAddress
    send_invoice_email: string
}
export interface InvoiceAddress {
    country: string
    country_code: string
    company_name: string
    street_line_1: string
    street_line_2: string
    city: string
    region: string
    postal_code: string
}
export interface InvoicesVatDataProps {
    vatCountry: string
    vatNumber: string
    adminEmail: string
    invoiceAddress: InvoiceAddress
}

export interface PricingPlan {
    id: number
    name: string
    price: number
    period_interval: string
    account_limit: number
    spend_limit: number
    has_reports_access: number
    currency: string
}

export function useInvoices() {
    const updateButton = ref()
    const updatingInvoiceSettings = ref(false)
    const updatedInvoiceSettings = ref(false)
    const InvoicesSettingsRef = ref()

    const showInvoicesSettingsModal = ref(false)
    const { data: stripeCustomer } = useAPI<OpteoCustomer>(Endpoint.GetStripeCustomer)
    const { data: pricingPlans } = useAPI<PricingPlan[]>(Endpoint.GetPricingPlans)

    const { data: customerCharges } = useAPI<StripeCustomerCharges>(Endpoint.GetCustomerCharges, {
        waitFor: () => stripeCustomer?.value?.id,
        body: () => {
            return { stripe_customer_id: stripeCustomer?.value?.id }
        },
    })

    const { data: invoiceData, mutate } = useAPI<InvoiceData>(Endpoint.GetInvoiceData)

    const vatData = computed(() => {
        const invoiceAddress = {
            ...invoiceData?.value?.invoice_address,
            country_code: invoiceData?.value?.country_code ?? '',
            company_name: invoiceData?.value?.company_name ?? '',
        }
        return {
            vatCountry: invoiceData?.value?.vat_country,
            vatNumber: invoiceData?.value?.vat_number,
            adminEmail: invoiceData?.value?.send_invoice_email,
            invoiceAddress,
        }
    })

    const invoices = computed(() => {
        const paid_invoices = customerCharges?.value?.data?.filter(i => i?.paid)
        if (!paid_invoices) {
            return
        }
        const all_plans_data = pricingPlans.value
        if (!all_plans_data) {
            return
        }

        const pricing_plans = Object.keys(all_plans_data)

        paid_invoices.forEach((invoice: StripeCustomerCharges) => {
            const invoice_not_supported = invoice.invoice?.lines.data.find(
                i =>
                    !pricing_plans.includes(i?.plan?.id) ||
                    i.description.includes('cents') ||
                    i.description.includes('Extra') ||
                    i.description.includes('Account')
            )

            if (invoice_not_supported) {
                invoice.not_supported = true
                invoice.stripe_invoice_url = invoice.invoice.hosted_invoice_url
            }

            invoice.total = invoice.amount / 100
            invoice.issue_date = format(invoice.created * 1000, 'MMMM do yyyy')

            if (invoice.refunds.total_count && invoice.refunds.total_count > 0) {
                const refunded_invoice = clone(invoice)
                let amount_refunded = 0
                invoice.refunds.data.forEach(item => (amount_refunded += item.amount))
                refunded_invoice.total = amount_refunded / 100
                refunded_invoice.state = 'Refund'
                refunded_invoice.refunded = 1
                refunded_invoice.created = invoice.refunds.data[0].created
                refunded_invoice.issue_date = format(
                    refunded_invoice.created * 1000,
                    'MMMM do yyyy'
                )

                if (refunded_invoice.not_supported) {
                    refunded_invoice.stripe_invoice_url = invoice.receipt_url
                }
                paid_invoices.push(refunded_invoice)
            }
            invoice.state = 'Paid Invoice'
            invoice.refunded = 0
        })

        const sorted_invoices = orderBy(paid_invoices, 'created', 'desc')

        return sorted_invoices
    })

    const invoiceTableRows = computed(() =>
        (invoices.value ?? []).map((invoice: StripeCustomerCharges) => ({
            id: invoice.id + invoice.state,
            chargeId: invoice.id,
            invoice: invoice.issue_date,
            total: invoice.total,
            type: invoice.state,
            download: invoice,
        }))
    )

    function resetUnsavedValues() {
        mutate()
    }

    async function updateVatForm() {
        updatingInvoiceSettings.value = true
        try {
            await InvoicesSettingsRef.value?.updateVatForm()
        } catch (err) {
            updatingInvoiceSettings.value = false
            updateButton.value.flashError()
            return
        }
        updatingInvoiceSettings.value = false
        updatedInvoiceSettings.value = true
        await delay(2000)
        updatedInvoiceSettings.value = false
    }

    return {
        invoices,
        invoiceTableRows,
        updatingInvoiceSettings,
        updatedInvoiceSettings,
        vatData,
        showInvoicesSettingsModal,
        resetUnsavedValues,
        updateVatForm,
        InvoicesSettingsRef,
        updateButton,
    }
}
