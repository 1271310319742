import { checkImprovement, useImprovement, UseImprovement } from '../useImprovement'
import { PauseKeyword } from '@opteo/types'
import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import { useNumber } from '@opteo/components-next'
import { computed, ref } from 'vue'

interface UsePauseKeyword {
    campaignLabel: string
    adgroupLabel: string
    keywordText: string
    statistics: Statistics[]
    formattedCost: string
    formattedConversions: string
    formattedTargetCpa: string
    TOTAL_DAYS: number
    campaignGroup: string
    keywordCpa: string | null
}

interface Statistics {
    key: string
    title: string
    value: string
}

const TOTAL_DAYS = 60

export function usePauseKeyword(): UseImprovement<UsePauseKeyword> {
    const { improvement, lastUpdated, title } = useImprovement<PauseKeyword.Body>()
    const { body } = checkImprovement(improvement)

    const {
        campaign_label: campaignLabel,
        adgroup_label: adgroupLabel,
        keyword_text_mt: keywordText,
        conversion_name: campaignGroup,
        cost,
        cpa_target,
        conversions,
    } = body

    const formattedCost = useDomainMoney({ value: cost }).value.displayValue.value
    const formattedConversions = useNumber({ value: conversions }).displayValue.value
    const formattedTargetCpa = useDomainMoney({ value: cpa_target }).value.displayValue.value

    const statistics: Statistics[] = [
        {
            key: 'cost',
            value: formattedCost,
            title: `Cost`,
        },
        {
            key: 'conversions',
            value: formattedConversions,
            title: 'Conversions',
        },
        {
            key: 'averageCpa',
            value: formattedTargetCpa,
            title: 'Average CPA',
        },
    ]

    const keywordCpa = conversions
        ? useDomainMoney({ value: cost / conversions }).value.displayValue.value
        : null

    if (keywordCpa) {
        statistics.push({ key: 'keywordCpa', value: keywordCpa, title: 'Keyword CPA' })
    }

    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Pausing keyword..',
        'Confirming changes..',
        'Keyword paused successfully.',
    ])

    const pushActionText = ref('Pause Keyword')

    return {
        title,
        pushMessages,
        lastUpdated,
        campaignLabel,
        adgroupLabel,
        keywordText,
        statistics,
        formattedCost,
        formattedConversions,
        formattedTargetCpa,
        TOTAL_DAYS,
        campaignGroup,
        keywordCpa,
        pushActionText,
    }
}
