import useSWRV, { SWRVCache } from 'swrv'
import { watch, unref, computed, ref, onUnmounted } from 'vue'
import * as Sentry from '@sentry/browser'
import { watchOnce } from '@vueuse/core'
import '@/lib/fetch'
import { useAuth, getCurrentCredentials } from '../auth/useAuth'
import { debug } from '@/lib/log'
import { API_URL } from '@/lib/env'
import { Endpoint } from './endpoints'
import { ApiError } from './ApiError'

import LocalStorageCache from './swrvCache'

const SHARED_HEADERS = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
}

const DEFAULT_API_OPTIONS: UseApi = {
    debug: false,
}

const cache = new LocalStorageCache()
// const cache = new SWRVCache() // Use this to use the default in-memory SWRV cache

interface UseApi {
    // Use the callback style if the body contains reactive data
    body?: Record<string, any> | (() => Record<string, any>)
    debug?: boolean
    // By default, fetches for a key will be debounced by 2000ms. Set this to 0 to disable.
    dedupingInterval?: number
    // Set to true to get an incrementing ref (progressStep) controlled by the backend.
    // See luckymetrics common/request_progress.ts for more information.
    pollForProgress?: boolean
    // Use this if you need to wait for dependent useAPI data
    waitFor?: () => any
    // Use this to help SWRV distinguish between entities using
    // the same endpoint (eg report_id or improvement_id)
    uniqueId?: () => string | number | undefined
}

export function useAPI<T>(endpoint: Endpoint, options?: UseApi) {
    const { clearSession, userId, token, accountId } = useAuth()

    const swrvKeyIfReady = () => {
        /* 
            Since we use POST requests for the fetcher, our endpoints don't include the params needed
            to help SWRV see that the requests are different. `swrvKey` includes a the most
            specific ID possible to make sure that SWRV doesn't serve
            an incorrect cached result.

            This won't be needed when routes have IDs in their actual endpoint URLs, 
            eg /api/user/{user_id}/domains/{domain_id}/improvements/{imp_id}
        */
        const uniqueId = unref(
            typeof options?.uniqueId === 'function' ? options.uniqueId() : options?.uniqueId
        )

        // The swrv key will always at least include endpoint + userId
        let swrvKey = `${endpoint}:${userId.value}:${uniqueId}`

        // Pass a falsy value into the swrvKey if the waitFor() is falsy.
        // See https://github.com/Kong/swrv#dependent-fetching for details
        const prereq = options?.waitFor ? options.waitFor() : true
        return prereq && swrvKey
    }

    const requestId =
        Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)

    const { data, error, isValidating, mutate } = useSWRV<T>(
        swrvKeyIfReady,
        () => {
            return fetcher(
                endpoint,
                options,
                {
                    token: token?.value as string,
                    userId: userId?.value,
                    accountId: accountId?.value,
                },
                requestId
            )
        },
        {
            cache,
            shouldRetryOnError: false,
            revalidateOnFocus: false,
            dedupingInterval: options?.dedupingInterval ?? 2000,
            // By default, SWRV ignores mutate calls when the browser tab is hidden. We don't want this. https://github.com/Kong/swrv/blob/b621aac02b7780a4143c5743682070223e793b10/src/use-swrv.ts#L241
            isDocumentVisible: () => true,
        }
    )

    /**
     * Mutate immediately (or when waitFor() is ready) when first calling useAPI().
     *
     * If the data is not yet in the cache, this will do nothing
     * because there is already a mutation in flight implicit in the useSWRV() call above.
     *
     * If the data is already in the cache (likely preloaded in from swrvCache.ts:restoreCache()),
     * this will trigger a revalidation, which we want.
     */

    if (swrvKeyIfReady()) {
        mutate()
    } else {
        watchOnce(
            () => swrvKeyIfReady(),
            () => mutate()
        )
    }

    // Loading if data & error are both undefined OR we're still waiting for the waitFor() to be ready
    const loading = computed(
        () =>
            (data.value === undefined && error.value === undefined) ||
            !swrvKeyIfReady() ||
            !cache.get(swrvKeyIfReady()) // Avoids showing data for a new swrvKey while revalidating
    )

    watch(error, newError => {
        // If the token is invalid, clear session and redirect to login
        if (newError instanceof ApiError && newError.status === 403) {
            clearSession()
        } else {
            Sentry.captureException(newError)
        }
    })

    const progressStep = ref(0)

    let intervalId: any = 0

    if (options?.pollForProgress) {
        intervalId = setInterval(async () => {
            await pollForProgress()
        }, 500)
    }

    async function pollForProgress() {
        if (options?.pollForProgress) {
            const { progress } = await fetchProgress(requestId)
            progressStep.value = progress
        }
    }

    // Stop polling when the request is no longer loading (but do one last poll)
    watch(isValidating, _isValidating => {
        if (!_isValidating) {
            clearInterval(intervalId)
            progressStep.value = 1
        }
    })

    // Stop polling if the component is unmounted (eg closed before loading is complete)
    onUnmounted(() => clearInterval(intervalId))

    return { data, error, loading, isValidating, mutate, progressStep }
}

// noAuthRequest does an unauthenticated POST request to the Opteo API (e.g. login, register)
export async function noAuthRequest<T>(path: string, body: Record<string, any>) {
    const response = await fetch(`${API_URL}${path}`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: SHARED_HEADERS,
    })
    return response.json() as unknown as T
}

interface RequestBody {
    request_id: string
    token: string
    meta: {
        function: string
        args: RequestMetaArgs | Omit<RequestMetaArgs, 'domain_id'>
    }
}

type RequestMetaArgs = Record<string, unknown> & { user_id: string; domain_id: string }

interface FetcherAuth {
    token: string
    userId: string | number | null
    accountId: string | number | null
}

export interface FetcherError {
    error: true
    status: number
    statusText: string
    message: any
}

const fetcher = async (
    endpoint: Endpoint,
    options: UseApi = DEFAULT_API_OPTIONS,
    auth: FetcherAuth,
    requestId: string
): Promise<any> => {
    const fn = endpoint.split('/').join(':')
    const postData = typeof options.body === 'function' ? options.body() : options.body

    const body: RequestBody = {
        // TODO: Generate this in the backend instead (?)
        request_id: 'frontend-dev-test',
        token: auth.token as string,
        meta: {
            function: fn,
            args: {
                request_id: requestId,
                user_id: auth.userId,
                account_id: auth.accountId,
                ...postData,
            },
        },
    }

    const requestUrl = `${API_URL}/api/${endpoint}`

    if (options.debug) {
        debug(`API request to ${requestUrl}`)
        debug(`Request body: ${JSON.stringify(body, null, 2)}`)
    }

    try {
        const response = await fetch(requestUrl, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: SHARED_HEADERS,
        })

        // Catch all client & server errors
        const isError = response.status >= 400 && response.status <= 599

        let data = response.statusText
        try {
            const raw = await response.json()
            data = raw.data
        } catch (e) {}

        // api specific error
        if (isError) {
            throw new ApiError(data, response.status, endpoint)
        }

        return data
    } catch (err) {
        if (err instanceof ApiError) {
            // Just throw the error if it's an ApiError
            throw err
        }

        // It's not an ApiError, meaning that fetch failed in a nasty way (eg cors or lost internet connection)
        // We log to sentry here extra hard before throwing.
        if (err instanceof Error) {
            Sentry.setContext('request', {
                requestUrl,
                body,
                options,
            })
            Sentry.captureException(err)
            throw err
        }
    }
}

/**
 * Cancellable functions:
 *  - ability to cancel the request by calling /cancel-request with ID
 *  - this causes the backend to throw an error, which we catch and ignore in the backend
 *
 * Polling-based progress:
 * - backend returns 204 immediately (by checking for use_polling)
 * - frontend polls /request-progress every 1000ms
 * - after 10 minutes, the request is cancelled and we give up
 */
export const authRequestWithProgress = <T = any>(
    endpoint: Endpoint,
    options: UseApi = DEFAULT_API_OPTIONS
) => {
    const requestId =
        Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)

    const progressStep = ref(0)
    const data = ref<T>()

    let promiseResolve = (v: T) => {}
    let promiseReject = (e: Error) => {}
    const dataPromise = new Promise<T>(function (resolve, reject) {
        promiseResolve = resolve
        promiseReject = reject
    })

    let intervalId: any = 0

    intervalId = setInterval(async () => {
        await pollForProgress()
    }, 1000)

    async function pollForProgress() {
        const { progress, result, error } = await fetchProgress(requestId)

        progressStep.value = progress

        if (result) {
            progressStep.value = 1
            promiseResolve(result)
            data.value = result
            clearInterval(intervalId)
        }
        if (error) {
            promiseReject(new Error(error))
            clearInterval(intervalId)
        }
    }

    authRequest<T>(endpoint, {
        ...options,
        body: {
            ...options.body,
            request_id: requestId,
            use_polling: true,
        },
    })

    async function cancel() {
        clearInterval(intervalId)
        await noAuthRequest<{
            progress: number
            result: T | null
            error: Error | null
        }>('/request-progress/cancel', { requestId })
    }

    setTimeout(() => {
        if (!data.value) {
            cancel()
            promiseReject(new Error('Timeout after 10 minutes for ' + endpoint))
        }
    }, 600_000)

    return { dataPromise, progressStep, data, cancel }
}

function fetchProgress(requestId: string) {
    return noAuthRequest<{
        progress: number
        result?: any
        error?: string
    }>('/request-progress', { requestId })
}

export const authRequest = <T = any>(endpoint: Endpoint, options: UseApi = DEFAULT_API_OPTIONS) => {
    /*
         We cannot call getFetcherAuth() here because, down the stack, it calls useRouter(), 
         which cannot be called outside the setup() lifecycle.

         Instead, we call getCurrentCredentials(), which is the same thing but not reactive.
    */

    const { token, userId, accountId } = getCurrentCredentials()

    if (!token) {
        throw new Error('token must be set before calling authRequest()')
    }

    return fetcher(endpoint, options, { token, userId, accountId }, '') as Promise<T>
}

export const generateApiUrl = (path: string) => {
    if (path[0] !== '/') {
        throw new Error(`path must start with a slash.`)
    }
    return `${API_URL}${path}`
}

export { Endpoint }
