<template>
    <ImprovementView
        :push-messages="pushMessages"
        :push-action-text="pushActionText"
        :is-adjustable="true"
        :on-push="onPush"
        :title="title"
        @back-clicked="resetAdToPause"
    >
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <EntityPillLink
                        :entityPillData="adGroupLocationData.entityPillData"
                        :deepLinkParams="adGroupLocationData.deepLinkParams"
                    />
                    <Spacer height="2rem" />
                </div>
                <Text as="p">
                    In the ad group listed above, the
                    <Text as="span" weight="600" color="green">winning</Text> ad is performing
                    <b><Percent :value="change" /></b> better than the
                    <Text as="span" weight="600" color="red">losing</Text> ad (using
                    {{ measureMode.toUpperCase() }} as the primary performance metric). Click
                    <Text as="span" weight="600" color="green">{{ pushActionText }}</Text> to pause
                    the losing ad and channel spend into a more successful alternative.
                </Text>
            </ImprovementHeader>
            <ImprovementHelpLink path="/en/articles/900213-pause-losing-ad">
                Click here to learn how Opteo makes a fair comparison between ads.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <Text as="h3" size="f-4" weight="600">Ad Comparison</Text>
        </ImprovementContent>
        <ImprovementContent :adWidth="true" v-if="adType === 'IMAGE_AD'">
            <DisplayAd
                :display-ad-url="bestAdData.ad.imageUrl"
                :featured-statistics="bestAdData.statistics"
                :title-tag="bestAdData.titleTag"
            />
            <Spacer height="3rem" />
            <DisplayAd
                :display-ad-url="worstAdData.ad.imageUrl"
                :featured-statistics="worstAdData.statistics"
                :title-tag="worstAdData.titleTag"
            />
            <Spacer height="3rem" />
        </ImprovementContent>
        <ImprovementContent :adWidth="true" v-else>
            <TextAd
                :ad="bestAdData.ad"
                :featured-statistics="bestAdData.statistics"
                :title-tag="bestAdData.titleTag"
            />
            <Spacer height="3rem" />
            <TextAd
                :ad="worstAdData.ad"
                :featured-statistics="worstAdData.statistics"
                :title-tag="worstAdData.titleTag"
            />
            <Spacer height="3rem" />
        </ImprovementContent>

        <ImprovementContent>
            <Text as="h3" size="f-4" weight="600">Comparison Table</Text>
            <Spacer height="2rem" />
            <oTable
                :headers="comparisonTableHeaders"
                :items="comparisonTableItems"
                responsive-mode="columns"
                responsive-breakpoint="768px"
            >
                <!-- Winner Header -->
                <template #header.winner>
                    <Text as="span" size="f-8" weight="500" color="green">Winning Ad</Text>
                </template>
                <!-- Winner Header -->
                <template #header.loser>
                    <Text as="span" size="f-8" weight="500" color="red">Losing Ad</Text>
                </template>
            </oTable>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementStatistics :items="stats">
                <!-- Comparison -->
                <template v-slot:cpi>
                    The winning ad is performing
                    <b>{{ stats[0].value }}</b> better than the losing ad.
                </template>
                <template v-slot:ctr>
                    The winning ad is performing
                    <b>{{ stats[0].value }}</b> better than the losing ad.
                </template>
                <template v-slot:vpi>
                    The winning ad is performing
                    <b>{{ stats[0].value }}</b> better than the losing ad.
                </template>
                <!-- Confidence -->

                <template v-slot:confidence>
                    Opteo is
                    <b>{{ stats[1].value }}</b> certain that this difference is statistically
                    significant.
                </template>
                <!-- Days Counted -->
                <template v-slot:days-counted>
                    Clicks were only counted from days where both ads were running at the same time
                    to discount weekday variance and seasonality.
                </template>
            </ImprovementStatistics>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="overlappingDays" />
        </ImprovementContent>

        <template v-slot:adjust-step-1>
            <ImprovementContent>
                <ImprovementHeader :title="title">
                    <Text as="p">
                        By default Opteo will pause the
                        <Text as="span" weight="500" color="red">Losing Ad</Text>. In some cases,
                        you may want to pause the
                        <Text as="span" weight="500" color="green">Winning Ad</Text>
                        instead. Use the dropdown below to choose which ad to pause.
                    </Text>
                </ImprovementHeader>
                <InlineInputContainer>
                    <template #mobile>
                        <Text as="span" size="f-8">In this comparison, pause the</Text>
                    </template>
                    <template #left-side>
                        <Text as="span" size="f-8">Pause the</Text>
                    </template>
                    <template #input>
                        <oInput
                            v-model="selectedAdToPause"
                            :items="adSelectItems"
                            type="select"
                            @updated="handleSelectAdToPause"
                        />
                    </template>
                    <template #right-side>
                        <Text as="span" size="f-8">in this comparison.</Text>
                    </template>
                </InlineInputContainer>
                <Spacer height="3rem" />
                <Text as="h3" size="f-4" weight="600">Comparison Table</Text>
                <Spacer height="2rem" />
                <oTable
                    :headers="comparisonTableHeaders"
                    :items="comparisonTableItems"
                    responsive-mode="columns"
                    responsive-breakpoint="768px"
                >
                    <!-- Winner Header -->
                    <template #header.winner>
                        <Text as="span" size="f-8" weight="500" color="green">Winning Ad</Text>
                    </template>
                    <!-- Loser Header -->
                    <template #header.loser>
                        <Text as="span" size="f-8" weight="500" color="red">Losing Ad</Text>
                    </template>
                </oTable>
            </ImprovementContent>
        </template>
    </ImprovementView>
</template>

<script lang="ts">
import { usePauseAdV2 } from '@/composition/improvement/types/usePauseAdV2'
import useMediaQuery from '@/composition/global/useMediaQuery'

// components-next
import {
    EntityPill,
    EntityPillList,
    Spacer,
    Text,
    Percent,
    TextAd,
    useNumber,
    oTable,
    oInput,
} from '@opteo/components-next'

// Improvement Components
import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import ImprovementCopy from '@/components/improvement/ImprovementCopy.vue'
import DisplayAd from '@/components/improvement/shared-components/DisplayAd.vue'
import InlineInputContainer from '@/components/improvement/shared-components/InlineInputContainer.vue'
import ImprovementStatistics from '@/components/improvement/ImprovementStatistics.vue'
import ImprovementCreatedDate from '@/components/improvement/ImprovementCreatedDate.vue'
import ImprovementContent from '@/components/improvement/ImprovementContent.vue'
import Ent from '@/components/util/Ent.vue'
import EntityPillLink from '@/components/global/Entity/EntityPillLink.vue'

export default {
    name: 'PauseAdV2',
    components: {
        ImprovementView,
        InlineInputContainer,
        ImprovementHelpLink,
        ImprovementCopy,
        ImprovementStatistics,
        ImprovementCreatedDate,
        ImprovementContent,
        EntityPillLink,
        EntityPill,
        EntityPillList,
        Ent,
        Spacer,
        Text,
        Percent,
        TextAd,
        DisplayAd,
        oTable,
        oInput,
    },
    methods: { useNumber },
    setup() {
        return { ...usePauseAdV2(), ...useMediaQuery() }
    },
}
</script>
