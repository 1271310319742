<template>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 0c-.5523 0-1 .447715-1 1 0 .55228.4477 1 1 1h2.5858L8.29289 8.29289c-.39052.39053-.39052 1.02369 0 1.41422.39053.39049 1.02369.39049 1.41422 0L16 3.41421V6c0 .55228.4477 1 1 1s1-.44772 1-1V1c0-.552285-.4477-1-1-1h-5ZM8 2h-.64281c-1.08378-.00001-1.95793-.00002-2.66581.05782-.72885.05955-1.36904.18536-1.96133.48715-.94081.47936-1.70571 1.24427-2.185082 2.18508-.301787.59229-.4276 1.23248-.4871494 1.96133-.05783609.70788-.0578281 1.58203-.05781835 2.66581v1.28561c-.00000975 1.0838-.00001774 1.9579.05781835 2.6658.0595494.7289.1853624 1.3691.4871494 1.9614.479372.9408 1.244272 1.7057 2.185082 2.185.59229.3018 1.23248.4276 1.96133.4872C5.39926 18 6.27342 18 7.35722 18h1.28556c1.0838 0 1.95792 0 2.66582-.0578.7289-.0596 1.3691-.1854 1.9614-.4872.9408-.4793 1.7057-1.2442 2.185-2.185.3018-.5923.4276-1.2325.4872-1.9614C16 12.6007 16 11.7266 16 10.6428V10c0-.55228-.4477-1-1-1s-1 .44772-1 1v.6c0 1.1366-.0008 1.9289-.0512 2.5458-.0494.6051-.1416.9528-.2758 1.2162-.2876.5645-.7465 1.0234-1.311 1.311-.2634.1342-.6111.2264-1.2162.2758C10.5289 15.9992 9.73661 16 8.6 16H7.4c-1.1366 0-1.92892-.0008-2.54576-.0512-.60517-.0494-.95286-.1416-1.21621-.2758-.56449-.2876-1.02343-.7465-1.31105-1.311-.13418-.2634-.22636-.6111-.2758-1.2162C2.00078 12.5289 2 11.7366 2 10.6V9.4c0-1.1366.00078-1.92892.05118-2.54576.04944-.60517.14162-.95286.2758-1.21621.28762-.56449.74656-1.02343 1.31105-1.31105.26335-.13419.61104-.22636 1.21621-.2758C5.47108 4.00078 6.2634 4 7.4 4H8c.55229 0 1-.44771 1-1 0-.55228-.44771-1-1-1Z"
            fill="#000"
            fill-opacity="0.16"
        />
    </svg>
</template>
