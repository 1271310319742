<template>
    <ImprovementView
        :push-messages="pushMessages"
        :push-action-text="pushActionText"
        :steps="adjustSteps"
        :on-push="onPush"
    >
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <EntityPillLink
                        :entityPillData="{ type: 'campaign', content: campaignName }"
                        :deepLinkParams="locationEntityIds"
                    />
                    <Spacer height="2rem" />
                </div>
                <Text v-if="campaignMode" as="p">
                    The campaign listed above accounts for a significant portion of your spend.
                    Opteo recommends having at least <b>{{ MINIMUM_CALLOUT_EXTENSIONS }}</b> custom
                    callout extensions for high-spend campaigns. Campaign specific callouts should
                    help to increase ad relevance and conversions.
                </Text>

                <Text v-else as="p">
                    The campaign listed above accounts for a significant portion of your spend.
                    Opteo recommends adding custom callout extensions for high-spend campaigns.
                    Adding campaign specific callouts should help to increase ad relevance and
                    conversions.
                </Text>

                <Spacer height="1rem" />
                <Text v-if="campaignMode" as="p">
                    Click <Text weight="600" color="blue" as="span">{{ adjustSteps[0] }}</Text> to
                    write, preview and add <b>{{ totalMissingFeedItems }}</b> more campaign-specific
                    callout<span v-if="totalMissingFeedItems > 1">s</span>
                    to this campaign. Existing callouts for this campaign are highlighted below.
                </Text>
                <Text v-else as="p">
                    Click <Text weight="600" color="blue" as="span">{{ adjustSteps[0] }}</Text> to
                    write, preview and add campaign-specific callouts to this campaign, replacing
                    the highlighted account-level callouts below.
                </Text>
            </ImprovementHeader>
            <ImprovementHelpLink path="/improvements/extensions/add-extensions-to-campaign">
                Click here to learn more about the benefits of campaign-level extensions.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <Text v-if="campaignMode" as="h3" size="f-4" weight="600">
                Existing Callout Extensions
            </Text>
            <Text v-else as="h3" size="f-4" weight="600">Account Level Callouts</Text>
        </ImprovementContent>

        <ImprovementContent :ad-width="true">
            <TextAd :ad="highestClickedAd" highlight="callouts" :title-tag="adTitleTag" />
            <Spacer height="4rem" />
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="lookbackWindow" />
        </ImprovementContent>

        <template #adjust-step-1>
            <ImprovementContent>
                <ImprovementHeader
                    :title="
                        campaignMode ? 'Input Callout Extensions' : 'Customise Callout Extensions'
                    "
                >
                    <div v-if="aboveMobile">
                        <EntityPillLink
                            :entityPillData="{ type: 'campaign', content: campaignName }"
                            :deepLinkParams="locationEntityIds"
                        />
                        <Spacer height="2rem" />
                    </div>
                    <Text v-if="campaignMode" as="p">
                        Use the form below to add campaign-level callout extensions. Add at least
                        <b>{{ totalMissingFeedItems }}</b> more campaign-specific
                        {{ totalMissingFeedItems > 1 ? 'callouts' : 'callout' }}
                        to the campaign above. Use the preview of your highest clicked ad to see how
                        your callouts will appear on the search engine results page.
                    </Text>
                    <Text v-else as="p">
                        Use the form below to add campaign-level callout extensions to replace the
                        account level callout extensions highlighted below. Use the preview of your
                        highest clicked ad to see how your callouts will appear on the search engine
                        results page.
                    </Text>
                    <Spacer height="1rem" />
                    <Text as="p">
                        Click
                        <Text as="span" weight="600" color="green">{{ pushActionText }}</Text> to
                        push your callout extensions to Google Ads.
                    </Text>
                </ImprovementHeader>
            </ImprovementContent>

            <ImprovementContent :ad-width="true">
                <TextAd :ad="highestClickedAd" highlight="callouts" :title-tag="adTitleTag" />
            </ImprovementContent>

            <ImprovementContent>
                <Spacer height="3rem" />
                <Text as="h5" weight="600">Create Callout Extensions</Text>
                <Spacer height="2rem" />
                <div class="callouts-form-container">
                    <oForm ref="calloutForm">
                        <div class="callout-inputs">
                            <div v-for="(extension, index) in calloutExtensions" :key="index">
                                <oInput
                                    :ref="
                                        el => {
                                            inputRefs[index] = el
                                        }
                                    "
                                    v-model="extension.calloutText"
                                    :name="`extension${index}`"
                                    :placeholder="`Callout ${getNumberWord(index + 1)}`"
                                    :label="`Callout ${getNumberWord(index + 1)}`"
                                    :required="index < MINIMUM_CALLOUT_EXTENSIONS"
                                    :max-characters="MAXIMUM_CALLOUT_LENGTH"
                                />
                                <Spacer
                                    height="1.5rem"
                                    v-if="index + 1 < calloutExtensions.length"
                                />
                            </div>
                        </div>
                        <div class="button-container">
                            <oButton
                                :disabled="calloutExtensions.length === MAXIMUM_CALLOUT_EXTENSIONS"
                                color="white"
                                :size="aboveMobile ? 'small' : 'medium'"
                                @clicked="addCalloutExtensions"
                                :fullWidth="aboveMobile ? false : true"
                            >
                                Add Callout
                            </oButton>
                            <Spacer v-if="!aboveMobile" height="0.75rem" />
                            <div class="form-buttons">
                                <oButton
                                    color="white"
                                    :size="aboveMobile ? 'small' : 'medium'"
                                    @clicked="resetCalloutExtensions(false)"
                                    :fullWidth="aboveMobile ? false : true"
                                >
                                    Reset Form
                                </oButton>
                                <Spacer v-if="aboveMobile" width="0.5rem" />
                                <Spacer v-else height="0.75rem" />
                                <oButton
                                    color="white"
                                    :size="aboveMobile ? 'small' : 'medium'"
                                    @clicked="resetCalloutExtensions(true)"
                                    :fullWidth="aboveMobile ? false : true"
                                >
                                    Clear Form
                                </oButton>
                            </div>
                        </div>
                    </oForm>
                </div>
            </ImprovementContent>
        </template>
    </ImprovementView>
</template>
<script lang="ts">
import { oInput, oForm, oButton, Text, Spacer, TextAd } from '@opteo/components-next'
import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import ImprovementCreatedDate from '@/components/improvement/ImprovementCreatedDate.vue'
import ImprovementContent from '@/components/improvement/ImprovementContent.vue'
import ImprovementHeader from '@/components/improvement/ImprovementHeader.vue'
import EntityPillLink from '@/components/global/Entity/EntityPillLink.vue'
import CircleButton from '@/components/global/CircleButton.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'

import { useAddCampaignLevelCalloutExtension } from '@/composition/improvement/types/useAddCampaignLevelCalloutExtension'
import { defineComponent } from 'vue'
import { getNumberWord } from '@/lib/globalUtils'

export default defineComponent({
    name: 'AddCampaignLevelCalloutExtension',
    components: {
        ImprovementCreatedDate,
        ImprovementContent,
        ImprovementHeader,
        ImprovementView,
        ImprovementHelpLink,
        EntityPillLink,
        oInput,
        oForm,
        oButton,
        Text,
        Spacer,
        TextAd,
        CircleButton,
    },
    setup() {
        const { aboveMobile } = useMediaQuery()
        return { getNumberWord, ...useAddCampaignLevelCalloutExtension(), aboveMobile }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.callouts-form-container {
    @include container;
    position: relative;
}
.callout-inputs {
    @include pa-36;
}
.button-container {
    border-top: 1px solid;
    @include opteo-border;
    @include flex;
    @include items-center;
    @include justify-between;
    @include ph-36;
    @include pv-28;
}
.form-buttons {
    @include flex;
    @include items-center;
}

@media screen and (max-width: $mq-767-max) {
    .button-container {
        @include block;
        @include pa-24;
    }
    .form-buttons {
        @include block;
    }
    .callout-inputs {
        @include pa-24;
    }
}
</style>
