<template>
    <div
        class="button-container"
        :class="active ? 'active' : 'inactive'"
        @click="$emit('clicked', category)"
    >
        <!-- Title -->
        <div class="title-text-wrapper">
            <Text class="title-text">{{ title }}</Text>
            <!-- needsData (totalSubscriptionCost, allTime, year, 90d) -->
            <Tooltip
                v-if="category === 'cost'"
                :maxWidth="264"
                :offset="[0, 12]"
                :content="tooltipText"
            >
                <InfoIcon class="subscription-cost-info" :hex="active ? '#0035ec' : '#aaaab0'" />
            </Tooltip>
        </div>

        <Spacer height="0.25rem" />

        <!-- Amount -->
        <div v-if="!$props.validating">
            <Text class="main-text" v-if="category === 'cost'">
                <Money currency="USD" :value="amount" />
            </Text>
            <Text v-else class="main-text">
                {{ formattedAmount }}
            </Text>
            <Spacer height="0.625rem" />
        </div>
        <div v-else-if="$props.validating">
            <Spacer height="14px" />
            <Skeleton
                :color="active ? 'blue' : '#ececef'"
                width="62.5%"
                class="main-text-skeleton"
            />
            <Spacer height="23px" />
        </div>

        <!-- Text depending on category -->
        <div v-if="!$props.validating">
            <Text class="sub-text" v-if="category === 'cost'">
                {{ periodText }}, you have saved an estimated
                <span class="bold-text"><Money currency="USD" :value="amount" /></span> by
                performing
                <span class="bold-text">{{ improvementsPushed }}</span>
                {{ improvementsPushed === 0 || improvementsPushed > 1 ? 'actions' : 'action' }}.
            </Text>
            <Text class="sub-text" v-else-if="category === 'time'">
                {{ periodText }}, you have saved around
                <span class="bold-text">{{ formattedAmount }}</span> across all team members
                connected.
            </Text>
            <Text class="sub-text" v-else-if="category === 'alerts'">
                {{ periodText }}, <span class="bold-text">{{ formattedAmount }}</span>
                {{ amount > 1 ? 'issues were' : 'issue was' }}
                caught before they could develop into problems.
            </Text>
        </div>
        <div v-else-if="$props.validating">
            <Skeleton width="12.5rem" height="12px" :color="active ? 'blue' : '#ececef'" />
            <Spacer height="0.5rem" />
            <Skeleton width="10rem" height="12px" :color="active ? 'blue' : '#ececef'" />
            <Spacer height="0.5rem" />
            <Skeleton width="7.5rem" height="12px" :color="active ? 'blue' : '#ececef'" />
            <Spacer height="0.25rem" />
        </div>
    </div>
</template>
<script setup lang="ts">
// Vue
import { computed } from 'vue'
// frontend
import useImpactStatsTimeSavings from '@/composition/impactStatistics/useImpactStatsTimeSavings'
import Skeleton from '@/components/util/Skeleton.vue'
import InfoIcon from '@/components/global/InfoIcon.vue'
// @components-next
import { Text, Spacer, Money, Tooltip } from '@opteo/components-next'

const props = defineProps({
    category: {
        type: String,
        required: true,
    },
    amount: {
        type: Number,
        required: true,
    },
    improvementsPushed: {
        type: Number,
        default: 0,
    },
    active: {
        type: Boolean,
        default: false,
    },
    period: {
        type: String,
        required: true,
    },
    validating: {
        type: Boolean,
        default: false,
    },
    tooltipText: {
        type: String,
        required: false,
        default: '',
    },
})

const { minutesToDuration } = useImpactStatsTimeSavings()

const title = computed(() => {
    if (props.category === 'cost') return 'Total Cost Savings'
    else if (props.category === 'time') return 'Total Time Savings'
    else if (props.category === 'alerts') return 'Critical Alerts Found'
    else return ''
})

const formattedAmount = computed(() => {
    if (props.category === 'time') {
        if (props.amount === 0) return '0m'

        const duration = minutesToDuration(props.amount)
        return `${duration.hours > 0 ? duration.hours + 'h' : ''} ${
            duration.minutes > 0 ? duration.minutes + 'm' : ''
        }`
    } else return props.amount
})

const periodText = computed(() => {
    if (props.period === 'alltime') return 'Since signing up'
    else if (props.period === 'last365days') return 'Over the last year'
    else if (props.period === 'last90days') return 'Over the last 90 days'
    else {
        throw new Error(`Invalid date range was passed to OverviewButton: ${props.period}`)
    }
})
</script>

<!-- Scoped -->
<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';
.button-container {
    @include pv-32;
    @include ph-36;
    // @include br-32;
    @include br-28;
    flex: 1;
    cursor: pointer;
    min-width: 0;
    overflow: hidden;
}

.main-text {
    color: #aaaab0;
    font-weight: 700;
    font-size: 3.5rem;
    line-height: 4.5rem;
    letter-spacing: -0.075rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.title-text-wrapper {
    @include flex;
    @include items-center;
    gap: 0.5625rem;
}
.subscription-cost-info {
    @include block;
    opacity: 0.32;
}
.title-text {
    color: #aaaab0;
    @include f-6;
    line-height: 1.75rem;
    @include fw-600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.sub-text {
    color: #aaaab0;
    @include f-8;
    font-weight: 400;
    max-width: 13.5rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    .bold-text {
        font-weight: 700;
    }
}

// hover over inactive button
.inactive:hover {
    background-color: $opteo-light-gray;
}

// active styles
.active {
    background-color: $opteo-dark-blue-translucent;
}
.active .main-text,
.active .title-text,
.active .sub-text {
    @include opteo-dark-blue;
}

// Responsive
@media screen and (max-width: 1279px) {
    .main-text {
        color: #aaaab0;
        font-weight: 700;
        font-size: 3rem;
        line-height: 4rem;
        letter-spacing: -0.075rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
@media screen and (min-width: 1280px) and (max-width: 1800px) {
    .main-text {
        color: #aaaab0;
        font-weight: 700;
        font-size: 3.5rem;
        line-height: 4.5rem;
        letter-spacing: -0.075rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
@media screen and (min-width: 1801px) {
    .main-text {
        color: #aaaab0;
        font-weight: 700;
        font-size: 4rem;
        line-height: 5rem;
        letter-spacing: -0.1rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>

<!-- Unscoped -->

<style lang="scss">
// Responsive
@media screen and (max-width: 1279px) {
    .main-text-skeleton .skeleton {
        height: 44px !important;
    }
}
@media screen and (min-width: 1280px) and (max-width: 1800px) {
    .main-text-skeleton .skeleton {
        height: 52px !important;
    }
}
@media screen and (min-width: 1801px) {
    .main-text-skeleton .skeleton {
        height: 60px !important;
    }
}
</style>
