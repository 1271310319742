<template>
    <SettingsSection
        id="subscription-status"
        title="Status"
        :noInnerContentShadow="true"
        style="position: sticky; top: 2rem; margin-bottom: 2rem !important"
    >
        <div class="subscription-status-wrapper">
            <!-- 
            invoiceAmount seems to take longest to load so
            <Skeleton /> visibility is based on this variable
        -->
            <div v-if="loading" class="subscription-status-container">
                <!-- Account Status Skeletons -->
                <div class="subscription-status-row-skeleton">
                    <Skeleton :height="14" :width="88" />
                    <Skeleton :height="14" :width="48" />
                </div>
                <!-- Next Invoice Skeletons -->
                <div class="subscription-status-row-skeleton">
                    <Skeleton :height="14" :width="72" />
                    <Skeleton :height="14" :width="120" />
                </div>
                <!-- Invoice Amount Skeletons -->
                <div class="subscription-status-row-skeleton">
                    <Skeleton :height="14" :width="90" />
                    <Skeleton :height="14" :width="56" />
                </div>
                <!-- Payment Method Skeletons -->
                <div class="subscription-status-row-skeleton">
                    <Skeleton :height="14" :width="112" />
                    <Skeleton :height="14" :width="74" />
                </div>
            </div>
            <div v-else class="subscription-status-container">
                <!-- Account Status -->
                <div class="subscription-status-row account-status">
                    <Text size="f-9">Account Status</Text>
                    <Text size="f-8" :color="subscriptionStatusColor">
                        {{ accountStatus || cancellationState }}
                    </Text>
                </div>

                <!-- Next Invoice -->
                <div v-if="nextChargeDate || cancelsOn" class="subscription-status-row">
                    <Text v-if="latestSubscription?.cancel_at_period_end" size="f-9">
                        Subscription End
                    </Text>
                    <Text v-else-if="!accountStatus.includes('trial')" size="f-9"
                        >Next Invoice</Text
                    >
                    <Text v-else-if="accountStatus === 'trialing'" size="f-9">First Invoice</Text>
                    <Text v-else-if="accountStatus === 'trial ended'" size="f-9">Trial Ended</Text>
                    <Text size="f-8">
                        {{ nextChargeDate || cancelsOn }}
                    </Text>
                </div>

                <!-- Invoice Amount -->
                <div v-if="invoiceAmount" class="subscription-status-row">
                    <Text size="f-9">Invoice Amount</Text>
                    <Text size="f-8">
                        <Money :value="invoiceAmount" :currency="currentPlanCurrency" />
                    </Text>
                </div>

                <!-- Payment Method -->
                <div v-if="creditCard" class="subscription-status-row">
                    <Text size="f-9">Payment Method</Text>
                    <div class="payment-method-last-four" v-if="creditCard">
                        <Text size="f-8">**** {{ creditCard.last4 }}</Text>
                        <Spacer width="0.5rem" height="0.25rem" />
                        <component :is="`${creditCard.brand}-logo`" />
                    </div>
                    <Text v-else size="f-8">None</Text>
                </div>
            </div>
            <!-- Payment Method Button -->
            <div v-if="!loading" class="payment-method-button-container">
                <oButton v-if="creditCard" color="blue" size="medium" @clicked="openPaymentModal()">
                    Update Payment Method
                </oButton>
                <oButton v-else color="blue" size="medium" @clicked="openPaymentModal()">
                    Add Payment Method
                </oButton>
            </div>
            <!-- Payment Method Button Skeleton -->
            <div v-else class="payment-method-button-container">
                <Skeleton :width="192" :height="44" />
            </div>
        </div>
    </SettingsSection>
    <!-- Update Payment Method Modal -->

    <PaymentMethodModal v-model="showPaymentMethodModal" />
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'

import {
    oButton,
    Money,
    VisaLogo,
    MastercardLogo,
    MaestroLogo,
    AmexLogo,
    Modal,
    Text,
    Spacer,
} from '@opteo/components-next'
import SettingsSection from '@/components/global/SettingsSection.vue'

import CreditCardForm from '@/components/billing/CreditCardForm.vue'

import { useSubscription } from '@/composition/billing/useSubscription'
import { useUser } from '@/composition/user/useUser'
import Skeleton from '@/components/util/Skeleton.vue'
import PaymentMethodModal from './PaymentMethodModal.vue'

export default defineComponent({
    name: 'SubscriptionStatus',
    components: {
        oButton,
        Money,
        VisaLogo,
        MastercardLogo,
        MaestroLogo,
        AmexLogo,
        Modal,
        CreditCardForm,
        SettingsSection,
        Text,
        Skeleton,
        Spacer,
        PaymentMethodModal,
    },
    setup() {
        const {
            latestSubscription,
            userType,
            creditCard,
            coupon,
            nextChargeDate,
            cancelsOn,
            loading,
        } = useSubscription()

        const { currentPlan, currentPlanCurrency } = useUser()

        const cancellationState = computed(() => {
            return 'Cancelled'
        })

        const subscriptionStatusColor = computed(() => {
            if (latestSubscription.value?.status === 'trialing' && !creditCard.value?.last4) {
                return 'black'
            }
            if (
                latestSubscription.value?.status === 'active' &&
                creditCard.value?.last4 &&
                !userType.value.includes('update_cc')
            ) {
                return 'green'
            }

            if (
                latestSubscription.value?.status === 'canceled' ||
                userType.value.includes('update_cc') ||
                !latestSubscription.value?.status
            ) {
                return 'red'
            }
        })

        const accountStatus = computed(() => {
            if (!latestSubscription.value) {
                return 'Cancelled'
            }

            if (latestSubscription.value.cancel_at_period_end) {
                return 'Set to Cancel'
            }

            if (latestSubscription.value.status === 'active') {
                return 'Active'
            }

            const subscription_status = userType.value.includes('update_cc')
                ? 'Past Due'
                : userType.value

            return subscription_status
        })

        const invoiceAmount = computed(() => {
            if (userType.value.includes('cancel')) {
                return 0
            }

            const currentPlanPrice = currentPlan.value?.price ?? 0

            if (!coupon.value) {
                return currentPlanPrice
            }

            if (coupon.value.amount_off) {
                return Math.max((currentPlanPrice ?? 0) - coupon.value.amount_off / 100, 0)
            }
            const percent_off = 1 - (coupon.value.percent_off ?? 0) / 100 ?? 1

            return (currentPlanPrice ?? 0) * percent_off
        })

        const showPaymentMethodModal = ref(false)

        function openPaymentModal() {
            showPaymentMethodModal.value = true
        }

        return {
            latestSubscription,
            userType,
            cancellationState,
            subscriptionStatusColor,
            loading,
            invoiceAmount,
            currentPlanCurrency,
            creditCard,
            accountStatus,
            nextChargeDate,
            cancelsOn,
            showPaymentMethodModal,
            openPaymentModal,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';

.subscription-status-wrapper {
    box-shadow: none;
    border-radius: 0;
}
.subscription-status-container {
    @include ph-32;
    @include pv-28;
    max-width: 19.25rem;
    margin: 0 auto;
    @include container;
}

.subscription-status-row {
    @include flex;
    @include items-center;
    @include justify-between;
    text-transform: capitalize;
    @include mb-12;
}
.subscription-status-row.account-status {
    text-transform: none;
}
.subscription-status-row:last-child {
    @include mb-0;
}
.subscription-status-row p {
    line-height: 1.25rem;
    font-size: 0.8125rem;
}

.subscription-status-row-skeleton {
    @include flex;
    @include items-center;
    @include justify-between;
    @include mb-20;
}
.subscription-status-row-skeleton:last-child {
    @include mb-0;
}

.payment-method-last-four {
    @include flex;
    @include items-center;
}

.payment-method-button-container {
    @include w-100;
    @include flex;
    @include justify-center;
    @include pt-32;
}

@media screen and (max-width: $mq-767-max) {
    .subscription-status-wrapper {
        @include container;
        overflow: hidden;
    }
    .subscription-status-container {
        max-width: unset;
        border-bottom: 1px solid $opteo-light-gray;
        box-shadow: none;
        border-radius: 0;
    }
    .payment-method-button-container {
        @include pv-32;
    }
}
</style>
