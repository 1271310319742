import { computed, ref } from 'vue'
import keyBy from 'lodash-es/keyBy'
import round from 'lodash-es/round'
import sortBy from 'lodash-es/sortBy'
import {
    OnPushHandler,
    UseImprovement,
    useImprovement,
    checkImprovement,
} from '@/composition/improvement/useImprovement'
import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import { AreaChartTypes, usePercent } from '@opteo/components-next'
import sub from 'date-fns/sub'
import add from 'date-fns/add'
import isBefore from 'date-fns/isBefore'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'

import { Entity, FixBelowFirstPageBid } from '@opteo/types'
import { useDomain } from '@/composition/domain/useDomain'

export function useFixBelowFirstPageBid(): UseImprovement {
    const { improvement, lastUpdated, title } = useImprovement<FixBelowFirstPageBid.Body>()

    const { body } = checkImprovement(improvement)

    const entityPillList = [
        {
            type: Entity.EntityLocation.Campaign,
            content: body.campaign_name,
        },
        {
            type: Entity.EntityLocation.AdGroup,
            content: body.adgroup_name,
        },
        {
            type: Entity.EntityLocation.Keyword,
            content: body.keyword_text,
        },
    ]
    /* 
        Format things for stats blocks
    */
    const { displayValue: formattedCurrentBid } = useDomainMoney({
        value: body.keyword_max_cpc,
    }).value
    const { displayValue: formattedNewBid } = useDomainMoney({
        value: body.new_cpc_bid,
    }).value

    const stats = computed(() => {
        return [
            {
                key: 'firstPageBid',
                value: useDomainMoney({
                    value: body.first_page_cpc,
                }).value.displayValue.value,
                title: 'First Page Bid',
            },
            {
                key: 'cost',
                value: useDomainMoney({
                    value: body.stats.cost,
                }).value.displayValue.value,
                title: 'Cost',
            },
            {
                key: 'cpc',
                value: useDomainMoney({
                    value: body.stats.avg_cpc,
                }).value.displayValue.value,
                title: 'CPC',
            },
            {
                key: 'impressions',
                value: body.stats.impressions,
                title: 'Impressions',
            },
            {
                key: 'clicks',
                value: body.stats.clicks,
                title: 'Clicks',
            },
            {
                key: 'qs',
                value: body.stats.qs,
                title: 'Quality Score',
            },
            {
                key: 'searchImprShare',
                value: usePercent({
                    value: body.stats.search_impr_share / 100,
                }).displayValue.value,
                title: 'Seach Impression Share',
            },
        ]
    })

    /*
        Gather data points for charts
    */
    const startDate = sub(new Date(), { days: 30 })
    const endDate = sub(new Date(), { days: 1 })
    const formattedStartDate = format(startDate, 'do MMM')
    const formattedEndDate = format(endDate, 'do MMM')

    const last30Days = keyBy(body.daily_stats, 'date')
    let cursor = new Date(startDate)
    while (isBefore(cursor, endDate)) {
        const date = format(cursor, 'yyyy-MM-dd')

        if (!last30Days[date]) {
            last30Days[date] = {
                date,
                impressions: 0,
                search_impr_share: 0,
                search_lost_isrank: 0,
            }
        }

        cursor = add(cursor, { days: 1 })
    }

    const dailyStatsSorted = sortBy(Object.values(last30Days), p => +parseISO(p.date))

    const impressionSeries: AreaChartTypes.AreaChartSeries[] = [
        {
            name: 'Impressions',
            items: dailyStatsSorted.map(p => {
                return { x: parseISO(p.date), y: +p.impressions }
            }),
        },
    ]

    const imprShareSeries: AreaChartTypes.AreaChartSeries[] = [
        {
            name: 'Impression Share Won',
            items: dailyStatsSorted.map(({ search_impr_share, date }) => {
                // sometimes search_impr_share can be '--' resulting in NaN here
                return { x: parseISO(date), y: +search_impr_share ? +search_impr_share / 100 : 0 }
            }),
        },
        {
            name: 'Share Lost To Rank',
            items: dailyStatsSorted.map(({ search_impr_share, date }) => {
                // sometimes search_impr_share can be '--' resulting in NaN here
                return { x: parseISO(date), y: +search_impr_share ? +search_impr_share / 100 : 0 }
            }),
        },
    ]

    /*
        Adjustment form and pushing bits
    */
    const bidToPush = ref(round(body.new_cpc_bid, 2))

    const pushActionText = ref(
        bidToPush.value > body.keyword_max_cpc ? 'Increase Keyword Bid' : 'Decrease Keyword Bid'
    )

    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Adjusting keyword bid..',
        'Confirming changes..',
        'Keyword bid adjusted succesfully.',
    ])

    const { currencySymbol } = useDomain()

    const bidInputForm = ref()
    const onPush: OnPushHandler<FixBelowFirstPageBid.ExtraDetails> = () => {
        // if the form exists (we are in adjust mode)
        if (bidInputForm.value) {
            // trigger validation
            const formData = bidInputForm.value.submit()

            if (!formData) {
                return { valid: false }
            }
        }
        return {
            valid: true,
            pushedData: { new_bid: bidToPush.value },
        }
    }

    return {
        title,
        body,
        pushMessages,
        onPush,
        entityPillList,
        lastUpdated,
        formattedCurrentBid,
        formattedNewBid,
        stats,
        currencySymbol,
        bidInputForm,
        bidToPush,
        formattedStartDate,
        formattedEndDate,
        impressionSeries,
        imprShareSeries,
        pushActionText,
    }
}
