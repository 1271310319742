import { Entity, Improvement } from '@opteo/types'

import EntityPillLink from './EntityPillLink.vue'
import EntityPillLinkList from './EntityPillLinkList.vue'
import { ValidEntity } from './types'

const isValidEntity = (entityLocation: Entity.EntityLocation): entityLocation is ValidEntity => {
    return ValidEntity.some(validEntity => validEntity === entityLocation)
}

export const isLinkableLocation = (
    location: Improvement.AccountLocation
): location is Improvement.AccountLocation<ValidEntity> => {
    return isValidEntity(location.entity)
}

export { EntityPillLink, EntityPillLinkList }
