import { computed, ref } from 'vue'
import {
    OnPushHandler,
    useImprovement,
    UseImprovement,
} from '@/composition/improvement/useImprovement'

export function useCreateBrandCampaign(): UseImprovement {
    const { improvement, lastUpdated, title } = useImprovement<{
        potential_keywords: string[]
        branding_keyword: string
    }>()

    const potentialKeywordsList = computed(
        () =>
            improvement.value?.body.potential_keywords.map((keyword: string) => ({
                type: 'keyword',
                content: keyword,
            }))
    )

    const brandingKeyword = ref(improvement.value?.body.branding_keyword)

    const pushActionText = ref('Recheck Campaigns')
    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Searching for campaign..',
        'Confirming campaign contents..',
        'Brand campaign verified successfully.',
    ])

    const onPush: OnPushHandler = () => {
        return {
            valid: true,
        }
    }

    return {
        potentialKeywordsList,
        lastUpdated,
        title,
        onPush,
        pushMessages,
        pushActionText,
        brandingKeyword,
    }
}
