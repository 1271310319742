<template>
    <ImprovementView :push-messages="pushMessages" :push-action-text="pushActionText">
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <EntityPillLink
                        :entityPillData="{ type: 'campaign', content: campaignName }"
                        :deepLinkParams="locationEntityId"
                    />
                    <Spacer height="2rem" />
                </div>
                <Text as="p">
                    The campaign listed above is limited by budget and underperforming relative to
                    your target
                    {{ isUsingRoas ? 'ROAS' : 'CPA' }}. Reducing {{ bidTypeCopy }} bids for this
                    campaign should result in a lower CPC. Click
                    <Text as="span" weight="600" color="green">{{ pushActionText }}</Text> to lower
                    {{ bidTypeCopy }} bids across this campaign by <b><Percent :value="0.1" /></b>.
                </Text>
                <Spacer height="2rem" />
                <ImprovementNote>
                    <!-- ROAS and conversion value -->
                    <span v-if="campaignRoas > 0 && isUsingRoas">
                        <b>Note:</b> This campaign has a relatively low ROAS of
                        <b><Roas :value="campaignRoas" /></b> and
                    </span>

                    <!-- CPA and conversions -->
                    <span v-else-if="campaignCpa > 0 && !isUsingRoas">
                        <b>Note:</b> This campaign has a relatively high CPA of
                        <b><Money :value="campaignCpa" /></b> and
                    </span>

                    <!-- If No Conversions -->
                    <span v-else>
                        <b>Note:</b> This campaign has generated
                        <span v-if="isUsingRoas">no conversion value</span>
                        <span v-else><b>0</b> conversions</span>
                        and
                    </span>
                    spent
                    <b><Percent :value="budgetRatio" :decimal-places="0" /></b> of its budget over
                    the last week. Lowering your {{ bidTypeCopy }} bids should boost efficiency and
                    reduce wasted spend. If you're interested in learning more, click the link
                    below.
                </ImprovementNote>
            </ImprovementHeader>
            <ImprovementHelpLink path="/en/articles/900274-reduce-campaign-keyword-bids">
                Click here to learn more about reducing bids in a budget-limited campaign.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <Text as="h3" size="f-4" weight="600">Improvement Projections</Text>
            <Spacer height="2rem" />
            <Text as="p">
                Below are a series of key metrics from the
                <b>last {{ daysWindow }} days</b> compared with predictions for the
                <b>next {{ daysWindow }} days</b> if all {{ bidTypeCopy }} bids are reduced by the
                recommended amount.
            </Text>
            <Spacer height="2rem" />
            <ImprovementStatistics title="" :items="improvementStatistics">
                <template v-slot:cpcDelta>
                    Lowering your bids by <b><Percent :value="0.1" /></b> should reduce your average
                    CPC by roughly <b><Percent :value="0.1" /></b>.
                </template>
                <template v-slot:clicksDelta>
                    With a reduced cost per click, you'll be able to fit more clicks into your
                    budget.
                </template>
                <template v-slot:costDelta>
                    As long as there's Google Ads inventory available at a lower bid price, your
                    cost should remain stable and somewhere close to your budget.
                </template>
            </ImprovementStatistics>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="daysWindow" />
        </ImprovementContent>
    </ImprovementView>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

import { Spacer, Text, Percent, Roas } from '@opteo/components-next'

import Money from '@/components/global/oMoney.vue'
import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import ImprovementCreatedDate from '@/components/improvement/ImprovementCreatedDate.vue'
import ImprovementContent from '@/components/improvement/ImprovementContent.vue'
import ImprovementHeader from '@/components/improvement/ImprovementHeader.vue'
import ImprovementStatistics from '@/components/improvement/ImprovementStatistics.vue'
import ImprovementNote from '@/components/improvement/ImprovementNote.vue'

import { useReduceCampaignBids } from '@/composition/improvement/types/useReduceCampaignBids'
import useMediaQuery from '@/composition/global/useMediaQuery'
import EntityPillLink from '@/components/global/Entity/EntityPillLink.vue'

export default defineComponent({
    name: 'ReduceCampaignBids',
    components: {
        ImprovementContent,
        ImprovementHeader,
        ImprovementNote,
        ImprovementView,
        ImprovementHelpLink,
        ImprovementStatistics,
        ImprovementCreatedDate,
        EntityPillLink,
        Spacer,
        Text,
        Money,
        Percent,
        Roas,
    },
    setup() {
        return { ...useReduceCampaignBids(), ...useMediaQuery() }
    },
})
</script>
