import { computed } from 'vue'

import { isLinkableLocation } from '@/components/global/Entity'
import { useDeepLinking } from '@/composition/global/useDeepLinking'
import { useEntityPillLink } from '@/composition/global/useEntityPillLink'

import type { EntityPillProps } from '@opteo/components-next'
import type { Improvement } from '@opteo/types'

/**
 * @description Formats the locations as sent from the API into an array of `EntityPillProps`.
 * This will include the `linkUrl` and `cursor` props if the location is linkable and the account has an OCID associated with it.
 * @param locations - The locations to format, as sent from the API
 * @returns An array of `EntityPillProps`
 */
export function useImprovementLinkableLocations(locations: Improvement.AccountLocation[]) {
    const { ocid } = useDeepLinking()

    const formatAccountLocations = (locations: Improvement.AccountLocation[]) => {
        return locations.map(location => {
            const { entity: type, label: content, ...rest } = location

            const formattedLocation: EntityPillProps = {
                type,
                content,
                ...rest,
            }

            if (!isLinkableLocation(location)) {
                return formattedLocation
            }

            const { url, cursor } = useEntityPillLink({
                deepLinkParams: location.entityIds,
                entityType: location.entity,
                ocid,
            })

            return { ...formattedLocation, linkUrl: url, cursor } satisfies EntityPillProps
        })
    }

    const formattedLocations = computed<EntityPillProps[]>(() => {
        return formatAccountLocations(locations)
    })

    return { formattedLocations }
}
