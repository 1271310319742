import { computed, ComputedRef, Ref, unref } from 'vue'
import { nanoid } from 'nanoid'
// composable so functions can be used in both ChatInput and ChatQuery

type EntityType = 'campaign' | 'adgroup' | 'keyword'
type EntityState = 'complete' | 'semi-complete' | 'incomplete'

type MatchedEntity = {
    id: string
    state: EntityState
    type: EntityType | string
    name: string | undefined
    resourceName: string | undefined
    startIndex: number
    endIndex: number
}

const entityRegex =
    /@((campaign|adgroup|keyword):)([^\s,.?]+)?|@(campaign(?!\w)|campaig(?!\w)|campai(?!\w)|campa(?!\w)|camp(?!\w)|cam(?!\w)|ca(?!\w)|c(?!\w)|adgroup(?!\w)|adgrou(?!\w)|adgro(?!\w)|adgr(?!\w)|adg(?!\w)|ad(?!\w)|a(?!\w)|keyword(?!\w)|keywor(?!\w)|keywo(?!\w)|keyw(?!\w)|key(?!\w)|ke(?!\w)|k(?!\w))/g

const isChrome = computed(
    () => /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
)
const isFirefox = computed(() => navigator.userAgent.toLowerCase().indexOf('firefox') > -1)
const isWindows = computed(() => navigator.userAgent.indexOf('Windows') != -1)

// TODO: entities type
export function useChatEntities(query: Ref<string> | string, entities: any) {
    const matchedEntities = computed(() => {
        let matches = []
        let match: RegExpExecArray | null

        while ((match = entityRegex.exec(unref(query))) !== null) {
            const id = nanoid()

            // incomplete entity
            if (match[4] !== undefined) {
                matches.push({
                    id: id,
                    state: 'incomplete',
                    type: match[4],
                    name: '',
                    resourceName: undefined,
                    startIndex: match.index,
                    endIndex: entityRegex.lastIndex,
                })
            }

            const semiComplete = match[1] !== undefined
            const entityList = getEntitiesByType(match[2] as EntityType)
            const matchedEntityItem =
                match[3] &&
                entityList?.find(entity => {
                    const potentialMatch = match[3]

                    if (potentialMatch === entity.resourceName) {
                        // matching resource id found after ":"
                        return entity.name
                    }
                })

            // semi-complete entity
            if (
                (semiComplete && !match[3]) ||
                (semiComplete && match[3] !== undefined && !matchedEntityItem)
            ) {
                matches.push({
                    id: id,
                    state: 'semi-complete',
                    type: match[2] as EntityType,
                    name: match[3] ?? '',
                    resourceName: undefined,
                    startIndex: match.index,
                    endIndex: entityRegex.lastIndex,
                })
            }

            // complete entity
            if (semiComplete && match[3] !== undefined && matchedEntityItem) {
                matches.push({
                    id: id,
                    state: 'complete',
                    type: match[2] as EntityType,
                    name: matchedEntityItem.name,
                    resourceName: match[3],
                    startIndex: match.index,
                    endIndex: entityRegex.lastIndex,
                })
            }
        }

        return matches
    })

    const HTML = computed(() => {
        // set outputHTML to the unref'd initial query string
        let outputHTML = unref(query)

        // sort the array by startIndex in descending order
        const reversedMatchEntities = matchedEntities.value.sort(
            (a, b) => b.startIndex - a.startIndex
        )

        // iterate through the matches in reverse, i.e start from the end of the string
        // this means the startIndex wont shift despite the string getting longer when replacing the corresponding matched substrings with the converted HTML
        for (const entity of reversedMatchEntities) {
            const incomplete = entity.state === 'incomplete'
            const entityName = entity.name ?? ''
            const resourceName = entity.resourceName ?? ''

            // windows is unable to correctly select the entityName span when it is empty
            // this is a workaround to reduce the glitchy effect when selecting on this specific OS + browser combination
            const resourceNameSpan = computed(() =>
                isWindows.value && (isChrome.value || isFirefox.value)
                    ? `<span class="resource-name" style="opacity:0">${resourceName}</span>`
                    : `<span class="resource-name" >${resourceName}</span>`
            )

            // concatenate HTML string based on entity state
            const HTMLToInsert = [
                `<div class="entity-tag" data-id="${entity.id}" data-type="${entity.type}" data-state="${entity.state}" data-resource-name="${resourceName}">`, // opening div
                `<span class="at">@${entity.type}</span>`,
                !incomplete && `<span class="separator">:</span>`,
                !incomplete && `<span class="name" data-name="${entityName}">`, // opening entityName span
                entity.state === 'semi-complete' && `${entityName}`,
                !incomplete && `</span>`, // closing entityName span
                entity.state === 'complete' && `${resourceNameSpan.value}`,
                `</div>`, // closing div
            ]
                .filter(Boolean)
                .join('')

            const start = outputHTML.slice(0, entity.startIndex)
            const end = outputHTML.slice(entity.endIndex)

            // concatenate HTMLToInsert string with with previous iterations and remaining string
            outputHTML = start + HTMLToInsert + end
        }

        return outputHTML
    })

    // utility functions
    function getEntitiesByType(type: EntityType) {
        return unref(entities)[`${type}s`]
    }

    return {
        matchedEntities,
        HTML,
        getEntitiesByType,
    }
}
